import { t } from "i18next";
import React, { useState } from "react";
import { CameraLicense, CreateCameraLicense, OptionType } from "../../common/store";
import TextInputComponent from "../shared/TextInputComponent";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import RadioComponent from "../shared/RadioComponent";
import API from "../../common/API";
import { useAuth } from "react-oidc-context";
import LoadingPageComponent from "../shared/LoadingPageComponent";

export default function CameraLicensingComponent({deploymentId}:{deploymentId:number}){
    const auth = useAuth()
    const [currentChoice, setCurrentChoice] = useState<OptionType>({i1:20, label: t("package:basic"), i2:1})
    const [registrationKey, setRegistrationKey] = useState<string>("")
    const [responseKey, setResponseKey] = useState<string>("")
    const [licenseTerm, setLicenseTerm] = useState<string>("12")
    const [loading, setLoading] = useState<boolean>(false)

    const cameraDetails:OptionType[] = [{i1:20, label: t("package:basic"), i2:1}, {i1:40, label: t("package:essentials"), i2:2},{i1:125, label: t("package:small"), i2:3},{i1:500, label: t("package:mid"), i2:4},
    {i1:1000, label: t("package:large"), i2:5}, {i1:-1, label: t("package:enterprise"), i2:6}]
    const licenseTermOptions:OptionType[] = [{ value: '12', label: `1 ${t('years')}` },{ value: '60', label: `5 ${t('years')}` }]

    const getResponseKey = async () => {
        var object:CreateCameraLicense = {RequestKey: registrationKey, Package: currentChoice.i2!, Feature:0, Duration: Number(licenseTerm) }
        try {
            setLoading(true)
            const response: CameraLicense | null = await API.post(`api/v1/cameraLicense/${deploymentId}`, object, auth)
            if (response != null)
            {
                setResponseKey(response.responseKey!)
                setLoading(false)
            }
        }
        catch (e: any) {
            alert(e.errors?.Error!)
            setLoading(false)
        }
    }

    return (
        <div className="card">
            <div className="card-header">
                <h3 className='mb-4 header-text '><FontAwesomeIcon icon="cube" className="fs-3 me-2" />{`${t('cameraLicensing')}`}</h3>
            </div>
            <div className="card-body row mb-3">
                {cameraDetails.map((e: OptionType) => (
                    <div key={e.i2} className={`mb-3 col-12 col-xxl-4`}>
                        <div className="card border-3 p-0 mb-1">
                            <div className="card-header  border-0">
                                <h3 className=' text-begin header-text'>{e.label}</h3>
                            </div>
                            <div className="card-body bg-opacity-75 border-0 py-0 px-0 row">
                                <div className={"col-6 "}>
                                    <p className={"text-begin px-3 py-2 h5"} >{e.i1 == -1 ? `${t('unlimited')}` : e.i1} {`${t('license:cameras')}`}</p>
                                </div>
                                <div className="col-4">
                                    <button className={currentChoice.label == e.label && currentChoice != null ? "btn btn-success col-12" : "btn btn-secondary col-12"}
                                onClick={() => setCurrentChoice != null ? setCurrentChoice(e) : {}}>{`${t('select')}`}</button>
                                </div>
                            </div>
                        </div>
                        

                    </div>
                ))}
                <div className="col-12 text-end mt-3 row">
                    <RadioComponent  radioClass={"col-12 col-sm-7 col-form-label"} options={licenseTermOptions} currentVal={licenseTerm} setCurrentVal={setLicenseTerm}  />
                </div>
                <div className="col-12 row mt-4">
                    <div className="col-12 col-xxl-10">
                        <TextInputComponent labelClass={"col-5 col-sm-2 col-form-label text-begin mb-4 ms-3"} inputClass={"col-7 col-sm-8"}  value={registrationKey} label={t('license:registrationKey')} setText={setRegistrationKey}
                            validator={() => /[A-Za-z0-9]{5}-[A-Za-z0-9]{5}-[A-Za-z0-9]{5}-[A-Za-z0-9]{5}-[A-Za-z0-9]{5}/.test(registrationKey)} validateText={"Invalid Request Key"} upperCase/>
                    </div>
                    <div className={`d-inline col-12 col-xxl-2 text-center`} >
                        <button className={"btn btn-primary col-10"} disabled={registrationKey == "" || !(/[A-Za-z0-9]{5}-[A-Za-z0-9]{5}-[A-Za-z0-9]{5}-[A-Za-z0-9]{5}-[A-Za-z0-9]{5}/.test(registrationKey))} onClick={() => getResponseKey()}>
                            <span className="me-3">{`${t('response')}`}</span><FontAwesomeIcon icon="check-circle" className="fs-4 d-inline" />
                        </button>
                    </div>
                </div>
                {loading && <LoadingPageComponent/>}
                {responseKey != "" && !loading && <div className="col-12  mt-3">
                    <div className="col-12 col-xxl-10">
                        <TextInputComponent  appendClass="ms-2 btn btn-outline-secondary" onClick={() => {navigator.clipboard.writeText(responseKey)}} append={"copy"} 
                            value={responseKey} label={t('license:responseKey')} labelClass={"col-5 col-sm-3 col-form-label text-begin mb-4 ms-3"} inputClass={"col-12 col-sm-7 "} noEdit />
                    </div>
                </div>}
            </div>
        </div>
    )
}