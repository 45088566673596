export enum DeploymentType{
    Unkown =-1,
    Local=0,
    Hosted=1
}

export enum PaymentMethod{
    Prepaid=0,
    Invoice=1
}

export enum LicenseTier {
    ProtectorNet = 0,
    Odyssey = 1,
    ProtectorNetCloud = 2,
    OdysseyCloud = 3,
    ACE = 4,
    USERCOUNT = 5
}

export enum FeatureSelection{
    NoFeatures = 0,
    Essentials = 1,
    Small = 2,
    Mid = 4,
    Enterprise = 8,
    //MultiCardFormat = 16,  // Sub Feature 1
    //Additional5Partitions = 32, // Sub Feature 2
    //Additional10Partitions = 64, // Sub Feature 2
    //Additional20Partitions = 128,
    Large = 256,
    Basic = 512,
    Standard = 1024
}
