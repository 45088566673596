import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { GetUserRequest, PhoneNumberType } from "../../common/store";
import TextInputComponent from "../shared/TextInputComponent";
import { validate as EmailValidate } from 'email-validator';
import { useTranslation } from "react-i18next";
import RequiredFields from "../shared/RequiredFields";

export default function ({addUsersList, language, timezone, setAddUsersList, adminEmail}:
    {addUsersList:GetUserRequest[], language?:string, timezone?:string, setAddUsersList:(value: React.SetStateAction<GetUserRequest[]>) => void , adminEmail:string}) {

        const { t } = useTranslation();        
        const [email, setEmail] = useState<string>("")
        const [isAdmin, setIsAdmin] = useState<boolean>(false)
        const [manageCompany, setManageCompany] = useState<boolean>(false)
        const [manageDeployments, setManageDeployments] = useState<boolean>(false)
        const [manageHostedDeployments, setManageHostedDeployments] = useState<boolean>(false)
        const [manageLicenses, setManageLicenses] = useState<boolean>(false)
        const [managePayments, setManagePayments] = useState<boolean>(false)
        
        const handleAddUser = () => {
            if ( (!EmailValidate(email)))
            {
                alert(t('user:emailValidationError'))
                return
            }
            if ((adminEmail == email))
            {
                alert("Duplicate Email As Admin")
                return
            }
            const phoneNumber:PhoneNumberType = {type: '', number: '', extension: ''}
            const object:GetUserRequest = {
                firstName:'FirstName', lastName:'LastName',
                language: language!, timezone: timezone!,
                email: email, id: 0, phoneNumber: phoneNumber ,
                isAdmin: isAdmin, lastLoggedIn:'',
                manageCompany: manageCompany, manageDeployments: manageDeployments,
                manageHostedDeployments: manageHostedDeployments, manageLicenses: manageLicenses,
                managePayments: managePayments
            }
            addUsersList?.push(object)
            setEmail('')
            setIsAdmin(false)
            setManageCompany(false)
            setManageDeployments(false)
            setManageLicenses(false)
            setManageHostedDeployments(false)
        }

        const handleDeleteUser = (index:number) => {
                const newUserArray = [...addUsersList]
                newUserArray.splice(index, 1)
                setAddUsersList(newUserArray)
        }

    return (
        <div>
            <div className="  card shadow border-1 border-light rounded-3 w-lg-50">
                <div className="card-header p-3 border-1 border-light bg-light rounded-3">
                    <button className="btn float-end btn-primary p-2" disabled={ email == ''} onClick={() => handleAddUser()}>
                        <FontAwesomeIcon icon="plus" />
                    </button>
                    <h2 className='mb-0 mt-0 header-text text-dark '>{t('register:newUser')}</h2>
                </div>
                <div className="card-body bg-opacity-75 row ">
                    <RequiredFields />
                    <div className="col-sm-12 form-group row mb-3 mt-3">
                        <div className="col-1 d-none d-sm-block"></div>
                        <TextInputComponent mandatory labelClass="col-sm-2 align-bottom pt-2 ms-1 text-begin" inputClass="col-12 col-sm-6" label={t('email')} value={email}  setText={setEmail}/>
                    </div>
                    <div className="col-sm-12 form-group row" >
                        <div className="col-1 d-none d-sm-block"></div>
                        <label className="col-9 col-sm-2 col-form-label text-begin" ><h3>{t('user:systemAdmin')}</h3></label>
                        <div className="col-2 form-check form-switch">
                            <input className="form-check-input mt-2 " type="checkbox" id="flexSwitchCheckDefault" checked={isAdmin} onChange={(e) => setIsAdmin(!isAdmin)}  />
                        </div>
                    </div>
                    <div className="col-sm-12 form-group row " >
                        <div className="col-1 d-none d-sm-block"></div>
                        <label className="col-9 col-sm-2 col-form-label text-begin" ><h3>{t('user:manageCompany')}</h3></label>
                        <div className="col-2 form-check form-switch">
                            <input className=" form-check-input mt-2 " type="checkbox" id="flexSwitchCheckDefault" checked={manageCompany} onChange={(e) => setManageCompany(!manageCompany)}  />
                        </div>
                    </div>
                    <div className="col-sm-12 form-group row" >
                        <div className="col-1 d-none d-sm-block"></div>
                        <label className="col-9 col-sm-2 col-form-label text-begin" ><h3>{t('user:manageDeployments')}</h3></label>
                        <div className="col-2 form-check form-switch">
                            <input className=" form-check-input mt-2 " type="checkbox" id="flexSwitchCheckDefault" checked={manageDeployments} onChange={(e) => setManageDeployments(!manageDeployments)}  />
                        </div>
                    </div>
                    <div className="col-sm-12 form-group row" >
                        <div className="col-1 d-none d-sm-block"></div>
                        <label className="col-9 col-sm-2 col-form-label text-begin" ><h3>{t('user:manageLicenses')}</h3></label>
                        <div className="col-2 form-check form-switch">
                            <input className=" form-check-input mt-2 " type="checkbox" id="flexSwitchCheckDefault" checked={manageLicenses} onChange={(e) => setManageLicenses(!manageLicenses)}  />
                        </div>
                    </div>
                    <div className="col-sm-12 form-group row" >
                        <div className="col-1 d-none d-sm-block"></div>
                        <label className="col-9 col-sm-2 col-form-label text-begin" ><h3>{t('user:managePayments')}</h3></label>
                        <div className="col-2 form-check form-switch">
                            <input className=" form-check-input mt-2 " type="checkbox" id="flexSwitchCheckDefault" checked={managePayments} onChange={(e) => setManagePayments(!managePayments)}  />
                        </div>
                    </div>
                    <div className="col-sm-12 form-group row" >
                        <div className="col-1 d-none d-sm-block"></div>
                        <label className="col-9 col-sm-2 col-form-label text-begin" ><h3>{t('user:manageHostedDeployments')}</h3></label>
                        <div className="col-2 form-check form-switch">
                            <input className=" form-check-input mt-2 " type="checkbox" id="flexSwitchCheckDefault" checked={manageHostedDeployments} onChange={(e) => setManageHostedDeployments(!manageHostedDeployments)}  />
                        </div>
                    </div>
                </div>
            </div>

            {addUsersList.length != 0 && 
            <div className="card border-1 border-light">
                <table className="my-0 table d-none d-sm-block">
                    <thead>
                        <tr>
                            <th className="col-1">{`${t('email')}`}</th>
                            <th className="col-1">{`${t('admin')}`}</th>
                            <th className="col-1">{`${t('company')}`}</th>
                            <th className="col-1 ">{`${t('deployments')}`}</th>
                            <th className="col-1 ">{`${t('licenses')}`}</th>
                            <th className="col-1 ">{`${t('payment')}`}</th>
                            <th className="col-1 ">{`${t('hostedDeployments')}`}</th>
                            <th className=""></th>
                        </tr>
                    </thead>
                    <tbody>
                        { addUsersList.map ((e:GetUserRequest) => (<tr className="">
                            <td className=""><h5>{e.email}</h5></td>
                            <td className=""><input type="checkbox" checked={e.isAdmin} readOnly /></td>
                            <td className=""><input type="checkbox" checked={e.manageCompany} readOnly /></td>
                            <td className=""><input type="checkbox" checked={e.manageDeployments} readOnly /></td>
                            <td className=""><input type="checkbox" checked={e.manageLicenses} readOnly /></td>
                            <td className=""><input type="checkbox" checked={e.managePayments} readOnly /></td>
                            <td className=""><input type="checkbox" checked={e.manageHostedDeployments} readOnly />
                            
                            <button className="btn p-0 d-sm-none ms-5" onClick={() => handleDeleteUser(addUsersList.indexOf(e))}>
                                <FontAwesomeIcon className="text-danger" icon="delete-left" />
                            </button>
                            
                            </td>
                            <td className="col-sm-1">
                                <button className="btn p-0 d-none d-sm-block" onClick={() => handleDeleteUser(addUsersList.indexOf(e))}>
                                    <FontAwesomeIcon className="text-danger" icon="delete-left" />
                                </button>
                            </td>
                        </tr>))}
                    </tbody>
                </table>
                <table className="my-0 table d-block d-sm-none">
                    <thead>
                        <tr>
                            <th className="col-1">{`${t('email')}`}</th>
                            <th className="col-1">{`${t('admin')}`}</th>
                            <th className=""></th>
                        </tr>
                    </thead>
                    <tbody>
                        { addUsersList.map ((e:GetUserRequest) => (<tr className="">
                            <td className=""><h5>{e.email}</h5></td>
                            <td className=""><input type="checkbox" checked={e.isAdmin} readOnly /></td>
                            <td className="">
                                <button className="btn p-0" onClick={() => handleDeleteUser(addUsersList.indexOf(e))}>
                                    <FontAwesomeIcon className="text-danger" icon="delete-left" />
                                </button>
                            </td>
                        </tr>))}
                    </tbody>
                </table>
            </div>
            }

            

        </div>
    )

}