import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";

export function PasswordComponent({labelText, password,  setNewPassword, mandatory }:
    {labelText: string, password:string, setNewPassword:(value: React.SetStateAction<string>) => void , mandatory?:boolean }) {
        
    const [showPassword, setShowPassowrd] = useState<boolean>(false)
    const handleClickShowPassword =() => {
        setShowPassowrd(!showPassword)
    }
    
    return(
        <div className="form-group row mb-4 ">
            <div className="col-1 d-none d-sm-block"></div>
            <label className=" col-4 col-sm-2 col-form-label text-begin edit-label-text ms-3" >
                <h3 className="d-none d-sm-block">{labelText}</h3>
                <h3 className="d-block d-sm-none fs-4">{labelText} {mandatory != null && <FontAwesomeIcon icon="star-of-life" className="fs-6 mt-2 d-inline d-sm-none text-center" />}</h3>
            </label>
            <div className="col-7 col-sm-5">
                <div className="input-group ">
                    <input  type={showPassword ? "text" : "password"} name="" id={labelText} className="form-control input-text" value={password} onChange={e => setNewPassword(e.target.value)} />
                    <div className="input-group-append border-0 ">
                        <span className=" bg-transparent form-control input-text">
                            <FontAwesomeIcon icon="eye" onClick={handleClickShowPassword} />
                        </span>
                    </div>
                </div>
            </div>
            {mandatory != null &&
                <div className="col-1 d-none d-sm-block">
                    <FontAwesomeIcon icon="star-of-life" className="fs-6 mt-2" />
                </div>
            }
        </div>
    )
}