import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useAuth } from "react-oidc-context";
import API from "../../common/API";
import { CompanyFeatures, HostedDeviceType, HostedPackage, Invoice, OptionType, Pending, Tenant } from "../../common/store";
import LoadingPageComponent from "../shared/LoadingPageComponent";
import { ModalComponent } from "../shared/ModalComponent";
import TextInputComponent from "../shared/TextInputComponent";
import Payment from "../../views/Payment/ElavonPayment";
import RadioComponent from "../shared/RadioComponent";
import { getCompanyFeatureGate, showACECard } from "../../redux/slices/config";
import useAppSelector from "../../hooks/useAppSelector";
import LicenseAddOnTextComponent from "../shared/LicenseAddOnTextComponent";
import { LicenseTier } from "../shared/Enums";
import { useNavigate } from "react-router-dom";


export function HostedLicenseComponent({ deploymentId, noHostedPackage, hostedPackage, tierType, tenant, pending, refresh, status, prepaid , userIdentifier, acePaid, userPaid, setHostedStatus , type}:
    { deploymentId: number, noHostedPackage: boolean, hostedPackage: HostedPackage | undefined, tierType: number, tenant?: Tenant, pending?: Pending, refresh: Function, status: string, prepaid:boolean, userIdentifier:any, acePaid:boolean, userPaid:boolean , type:number, setHostedStatus:(value: React.SetStateAction<string>) => void}) {
    const { t } = useTranslation();
    const [amount, setAmount] = useState<number>(1)
    var auth = useAuth();
    const navigate = useNavigate();
    const showACE = useAppSelector(showACECard)
    const [showAddModal, setShowAddModal] = useState<boolean>(false);
    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
    const [showUndoDeleteModal, setUndoShowDeleteModal] = useState<boolean>(false);
    const [showScheduleModal, setShowScheduleModal] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false)
    const [invoice, setInvoice] = useState<Invoice | null>()
    const [section, setSection] = useState<string>("details")
    const [hostedTerm, setHostedTerm] = useState<string>(hostedPackage?.packageTerm?.toString()!)
    const hostedTermOptions: OptionType[] = [{ value: '12', label: t('deployment:yearly') }, { value: '3', label: t('deployment:quarterly'), i1: 1 }]
    const [multicard, setMulticard] = useState<boolean>(hostedPackage?.multiCardEnabled!)
    const [userCountCode, setUserCountCode] = useState<string>("");
    const [isUserCountChecked, setIsUserCountChecked] = useState<boolean>(false);
    const [isACEEnabled, setIsACEEnabled] = useState<boolean>(tenant?.extraOptions == 1);
    const companyFeatureGate: CompanyFeatures = useAppSelector(getCompanyFeatureGate)
    const [userCount, setUserCount] = useState<number>(tenant?.numOfUsers!);
    const [isACEChecked, setIsACEChecked] = useState<boolean>(false);
    const [aceCode, setAceCode] = useState<string>('');
    const [prepaidCode, setPrepaidCode] = useState<string>("");
    const [isPrepaidChecked, setIsPrepaidChecked] = useState<boolean>(false);
    const [panelCount, setPanelCount] = useState<number>(pending?.pendingNumOfPanels!);

    const [doorsAmount, setDoorsAmount] = useState<number>(0)
    const [elevatorsAmount, setElevatorsAmount] = useState<number>(0)
    const [iOExpandersAmount, setIOExpandersAmount] = useState<number>(0)
    const [updateDoors, setUpdateDoors] = useState<boolean|null>(null) // False for delete, true for Add
    const [updateElevators, setUpdateElevators] = useState<boolean|null>(null)
    const [updateIOExpanders, setUpdateIOExpanders] = useState<boolean|null>(null)
    const [showUpdateDevicesModal, setShowUpdateDevicesModal] = useState<boolean>(false)

    const disableButtons = status == t("deployment:disabled") || status == t("deployment:pastdue")
    const AddPanelsInvoice = async () => {
        setLoading(true)
        if (amount < 1) {
            alert("Cannot Add Less Than 1 Panel")
            return;
        }
        try {
            setShowAddModal(false)
            var object = { DeploymentId: deploymentId, NumPanels: amount }
            const response: Invoice | null = await API.post(`api/v1/hosteddeployment/invoice/pendingAdd`, object, auth)
            setInvoice(response)
            setLoading(false)
            setSection(t('payment'))
        }
        catch (e: any) {
            setLoading(false)
            alert(e.errors?.Error!)
        }
    }

    const GetUpdateInvoice = async () => {
        try {
            if (doorsAmount + elevatorsAmount + iOExpandersAmount < 1)
            {
                alert("Cannot Create Empty System")
                return;
            }
            var object = { NumberOfDoors: doorsAmount, NumberOfElevators: elevatorsAmount, NumberOfIOExpanders: iOExpandersAmount }
            const response: Invoice | null = await API.post(`api/v1/hosteddeployment/invoice/updateToOdyssey/` + deploymentId, object, auth)
            setInvoice(response)
            setLoading(false)
            setSection(t('upgradePayment'))
        }
        catch (e: any) {
            setLoading(false)
            alert(e.errors?.Error!)
        }
    }

    const UndoPendingDeletion = async () => {
        setLoading(true)
        try {
            setUndoShowDeleteModal(false)
            const response: any = await API.put(`api/v1/hosteddeployment/removePendingDelete/${pending?.id}`, {}, auth)
            await refresh()
            setLoading(false)
        }
        catch (e: any) {
            setLoading(false)
            alert(e.errors?.Error!)
        }
    }

    const UpdateSchedule = async () => {
        setLoading(true)
        try {
            setShowScheduleModal(false)
            var object = { DeploymentId: deploymentId, Schedule: Number(hostedTerm) }
            const response: any = await API.put(`api/v1/hosteddeployment/updateSchedule`, object, auth)
            await refresh()
            setLoading(false)
        }
        catch (e: any) {
            setLoading(false)
            alert(e.errors?.Error!)
        }
    }

    const DeletePanelsPending = async () => {
        setLoading(true)
        try {
            setShowDeleteModal(false)
            var object = { DeploymentId: deploymentId, NumPanels: amount }
            const response: any = await API.post(`api/v1/hosteddeployment/pendingDelete`, object, auth)
            await refresh()
            setLoading(false)
        }
        catch (e: any) {
            setLoading(false)
            alert(e.errors?.Error!)
        }
    }

    const ToggleMulticard = async () => {
        setMulticard(!multicard)
        try {
            const response: any = await API.put(`api/v1/hosteddeployment/toggleMulticard/${deploymentId}`, {}, auth)

        }
        catch (e: any) {
            alert(e.errors?.Error!)
        }
    }

    const PostFunction = async (isinvoice?: boolean) => {
        if (isinvoice == true) {
            try {
                var object = { DeploymentId: deploymentId, NumPanels: amount, InvoiceId: invoice?.invoiceNumber }
                const response: Invoice | null = await API.post(`api/v1/hosteddeployment/addPanelCount`, object, auth)
            }
            catch (e: any) {
                alert(e.errors?.Error!)
            }
        }

        setSection("details")
        setLoading(true)
        await refresh()
        setLoading(false)
    }

    const PostUpgradeFunction = async (isInvoice?: boolean) => {
        try {
            const url = `api/v1/hosteddeployment/${deploymentId}/UpgradeToOdysseyPackage/${isInvoice == null ? false : isInvoice}/${invoice?.invoiceNumber}`
            var object = { NumberOfDoors: doorsAmount, NumberOfElevators: elevatorsAmount, NumberOfIOExpanders: iOExpandersAmount }
            const response: any = await API.post(url, object, auth)
        }
        catch (e: any) {
            alert(e.errors?.Error!)
        }
        
        setDoorsAmount(0)
        setElevatorsAmount(0)
        setIOExpandersAmount(0)
        setSection("details")
        setLoading(true)
        await refresh()
        setLoading(false)
    }
    const isValidCard = async (code: string, cardType: LicenseTier) => {
        if (prepaid && code.length > 0 ) {
            var object = { Code: code }
            try {
                var response: any = await API.post(`api/v1/license/IsValidCard`, object, auth)
                var responseTier = response.tier;
                if (responseTier == cardType){
                    if(responseTier == LicenseTier.ACE){
                        setIsACEEnabled(true)
                    }else if(responseTier == LicenseTier.USERCOUNT){
                        setUserCount(Number.parseInt(response.value))
                    }
                    
                    return response;
                }else
                    return false;
            } catch (e: any) {
                console.log(e)
                alert(e.errors?.Error!)
                return false;
            }
        } else {
            return false;
        }
    }

    const OnChecked = async (licenseType : string)  : Promise<boolean> => {
        if(licenseType == "ACE"){
            var isValid = aceCode.length == 29 && /[A-Za-z0-9]{6}-[A-Za-z0-9]{4}-[A-Za-z0-9]{5}-[A-Za-z0-9]{4}-[A-Za-z0-9]{6}/.test(aceCode)
            if(!isValid){
                 alert("Invalid Card. License Code is too small")
                 setIsACEChecked(false)
                 return false;
             }
            var response = await isValidCard(aceCode,LicenseTier.ACE)
            if(response.value == undefined){
                setIsACEChecked(false)
                alert("Invalid Card. License Code is Already Used or is not valid for ACE License Type")
                return false;
            }
            
            setIsACEChecked(true)
        }else if(licenseType == "User Count"){
            var isValid = userCountCode.length == 29 && /[A-Za-z0-9]{6}-[A-Za-z0-9]{4}-[A-Za-z0-9]{5}-[A-Za-z0-9]{4}-[A-Za-z0-9]{6}/.test(userCountCode)
            if(!isValid){
                 alert("Invalid Card. License Code is too small")
                 setIsUserCountChecked(false)
                 return false;
             }
            var responseCode =  await isValidCard(userCountCode, LicenseTier.USERCOUNT)
            if(responseCode.value == undefined){
                setIsUserCountChecked(false)
                alert("Invalid Card. License Code is Already Used or is not valid for User Count License Type")
                return false;
            }else if(responseCode.value < tenant?.numOfUsers!){
                setIsUserCountChecked(false)
                setUserCount(responseCode.value)
                alert("This card set number of users lesser than current user count ")
                return false;
            }
            setUserCount(responseCode.value)
            setIsUserCountChecked(true);
        }else{
            var isValid = prepaidCode.length == 29 && /[A-Za-z0-9]{6}-[A-Za-z0-9]{4}-[A-Za-z0-9]{5}-[A-Za-z0-9]{4}-[A-Za-z0-9]{6}/.test(prepaidCode)
            if(!isValid){
                 alert("Invalid Card. License Code is too small")
                 setIsPrepaidChecked(false)
                 return false;
             }
            var responseCode = await isValidCard(prepaidCode, tierType == 0 ? LicenseTier.ProtectorNetCloud : tierType == 1 ? LicenseTier.OdysseyCloud  : LicenseTier.ProtectorNet)
            if(responseCode.value == undefined){
                setIsPrepaidChecked(false)
                alert("Invalid Card")
                return false;
            }
            setPanelCount(responseCode.value)
            setIsPrepaidChecked(true)
        }
        return true;
    }

    const ActivateLicense = async(tier:LicenseTier) => {
        var object = {
            UserId:userIdentifier,
            LicenseCard: tier == LicenseTier.ProtectorNetCloud ? prepaidCode : "",
            ACECard: tier == LicenseTier.ACE ? aceCode : "",
            UserCountCard: LicenseTier.USERCOUNT  ? userCountCode : "",
            UserCount: userCount
        }

        try{
            var response :any = await API.post(`api/v1/hosteddeployment/activate/addons/${deploymentId}/licensecard`,object,auth)
            if(response != null && response.id != null){
                if(response.status == 1)
                    setHostedStatus("Active")
                 navigate(`/deployment/${deploymentId}`)
            }else
                console.log("error")
        }catch(e:any){
            alert(e)
        }
    }

    const UpdateDevices = async() =>{
        console.log("UPDATE DEVICES")

        setLoading(true)
        try {
            setShowUpdateDevicesModal(false)
            var devices:any[] = []
            if (doorsAmount != 0 && updateDoors != null)
                devices.push({Device: HostedDeviceType.Doors, Amount: doorsAmount, Add: updateDoors })
            if (elevatorsAmount != 0 && updateElevators != null)
                devices.push({Device: HostedDeviceType.Elevators, Amount: elevatorsAmount, Add: updateElevators })
            if (iOExpandersAmount != 0 && updateIOExpanders != null)
                devices.push({Device: HostedDeviceType.IOExpanders, Amount: iOExpandersAmount, Add: updateIOExpanders })

            var object = { DeploymentId: deploymentId, Devices: devices }
            const response: Invoice | null |string = await API.post(`api/v1/hosteddeployment/invoice/updateHostedDevice`, object, auth)
            
            if (response != "No Invoice" )
            {
                setInvoice(response as Invoice)
                setLoading(false)
                setSection(t('updateDevicesPayment'))
            }
            else
            {
                refresh()
                setLoading(false)
            }
        }
        catch (e: any) {
            setLoading(false)
            alert(e.errors?.Error!)
        }

        setDoorsAmount(0)
        setElevatorsAmount(0)
        setIOExpandersAmount(0)
        setUpdateDoors(null)
        setUpdateElevators(null)
        setUpdateIOExpanders(null)
    }

    const PostUpdateDevicesFunction = async (isInvoice?: boolean) => {
        console.log("Payment Update Devices")

        if (isInvoice == true) {
            try {
                var object = { DeploymentId: deploymentId, InvoiceId: invoice?.invoiceNumber }
                const response: Invoice | null = await API.post(`api/v1/hosteddeployment/updateDevicesCount`, object, auth)
            }
            catch (e: any) {
                alert(e.errors?.Error!)
            }
        }

        setSection("details")
        setLoading(true)
        await refresh()
        setLoading(false)
    }

    return (
        <div className=" mb-4">
            <ModalComponent actionFunction={AddPanelsInvoice} modalTitle={`${t("add")} ${t("deployment:panels")}`} modalText={`${t("deployment:addPanelsMessage")}`}
                buttonText={t("add")} showModal={showAddModal} setShowModal={setShowAddModal} />
            <ModalComponent actionFunction={DeletePanelsPending} modalTitle={`${t("delete")} ${t("deployment:panels")}`} modalText={`${t("deployment:deletePanelsMessage")}`}
                buttonText={t("delete")} showModal={showDeleteModal} setShowModal={setShowDeleteModal} />
            <ModalComponent actionFunction={UndoPendingDeletion} modalTitle={`${t("deployment:undoPanelDeletion")}`} modalText={`${t("deployment:undoDeletePanelsMessage")}`}
                buttonText={t("deployment:undo")} showModal={showUndoDeleteModal} setShowModal={setUndoShowDeleteModal} />
            <ModalComponent actionFunction={UpdateSchedule} modalTitle={`${t("deployment:updateSchedule")}`} modalText={`${t("deployment:updateScheduleMessage")}`}
                buttonText={t("deployment:updateSchedule")} showModal={showScheduleModal} setShowModal={setShowScheduleModal} />
            <ModalComponent actionFunction={UpdateDevices} modalTitle={`${t("deployment:updateDevices")}`} modalText={`${t("deployment:updateDevicesMessage")}`}
                buttonText={t("deployment:updateDevices")} showModal={showUpdateDevicesModal} setShowModal={setShowUpdateDevicesModal} />
            {(loading) && <LoadingPageComponent />}
            {(loading == false && section == "details") &&
                <div className="tab-content">
                    <div role="tabpanel" className="tab-pane active show">
                        <div className="row">
                            <div className="card col-xxl-4 bg-transparent shadow-none">
                                <div className=" card-header pb-0 text-center">
                                    <h3 className='mb-4 header-text '><FontAwesomeIcon icon="cube" className="fs-3 me-2" />{t('deployment:packageDetails')}</h3>
                                    <div className={`text-center`}>
                                        <h4 className={`${(tierType) == 1 || (tierType) == 3 ? 'text-dark' : 'text-info'}`}>{(tierType) == 1 || (tierType) == 3 ? "ODYSSEY" : "PROTECTOR.Net"}</h4>
                                    </div>
                                    {((tierType) == 0 && companyFeatureGate.allowedOdyssey) && <div className={`text-center`}>
                                        <button className="btn btn-secondary" onClick={() => setSection("updateToOdyssey")}>
                                            <span >{`${t('updateToOdyssey')}`}</span>
                                        </button>
                                    </div>}
                                </div>
                                {
                                    !noHostedPackage &&
                                    <div className="card-body bg-white">
                                        <TextInputComponent smallLabel labelClass={"col-5 col-form-label text-end"} inputClass={"col-6"} label={t("deployment:schedule")} value={`${hostedPackage?.packageTerm == 3 ? "Quarterly" : "Yearly"}`} noEdit />
                                        {pending != null && pending.pendingSchedule != null &&
                                            <TextInputComponent smallLabel labelColor={"text-warning"} inputColor={"text-warning"} labelClass={"col-5 col-form-label text-end"} inputClass={"col-6"}
                                                label={t("deployment:pendingSchedule")} value={pending.pendingSchedule == 3 ? "Quarterly" : "Yearly"} noEdit />}
                                        {/* <TextInputComponent smallLabel value={hostedPackage?.multiCardEnabled ? "Yes" : "No"} label={t('license:multiCard')} noEdit labelClass="col-5 col-form-label text-end" /> */}
                                        <div className="row">
                                            <div className="col-1 d-none d-sm-block"></div>
                                            <label className="col-5 col-form-label text-end" >
                                                <h3 className="d-none d-sm-block fs-5">{t('license:multiCard')}</h3>
                                                <h3 className="d-block d-sm-none fs-4 ">{t('license:multiCard')}</h3>
                                            </label>
                                            <div className="col-5 form-check form-switch ps-0">
                                                <input className="align-bottom form-check-input mt-2 ms-4 " type="checkbox" id="flexSwitchCheckDefault" checked={multicard}
                                                    onChange={() => ToggleMulticard()} />
                                            </div>
                                        </div>

                                        {tierType == LicenseTier.ProtectorNet && 
                                        <div>
                                            <TextInputComponent smallLabel labelClass={"col-5 col-form-label text-end"} inputClass={"col-6"} label={t("deployment:numberOfPanels")} 
                                                value={panelCount > 0 ? panelCount.toString() : `${hostedPackage?.numOfPanels.toString()!}`} noEdit />
                                            {pending != null && pending.pendingDeletion && pending.pendingNumOfPanels != null &&
                                            <TextInputComponent smallLabel labelColor={"text-danger"} inputColor={"text-danger"} labelClass={"col-5 col-form-label text-end"} inputClass={"col-6"}
                                                label={t("deployment:pendingDeletion")} value={pending.pendingNumOfPanels.toString()!} noEdit />}
                                        </div>
                                        }
                                        {tierType == LicenseTier.Odyssey && 
                                        <div>
                                            {hostedPackage?.numberOfDoors != null && 
                                                <TextInputComponent smallLabel value={hostedPackage.numberOfDoors.toString()} 
                                                    label={t('deployment:doors')} noEdit labelClass="col-5  col-form-label text-end" />}
                                            {hostedPackage?.pendingDoors != null && hostedPackage.pendingDoors < hostedPackage.numberOfDoors! && 
                                                <TextInputComponent labelColor={"text-danger"} inputColor={"text-danger"} smallLabel value={hostedPackage.pendingDoors.toString()} 
                                                    label={t('deployment:pendingDoors')} noEdit labelClass="col-5  col-form-label text-end" />}
                                            {hostedPackage?.numberOfElevators != null && 
                                                <TextInputComponent smallLabel value={hostedPackage.numberOfElevators.toString()} 
                                                    label={t('deployment:elevators')} noEdit labelClass="col-5  col-form-label text-end" />}
                                            {hostedPackage?.pendingElevators != null && hostedPackage.pendingElevators < hostedPackage.numberOfElevators! && 
                                                <TextInputComponent labelColor={"text-danger"} inputColor={"text-danger"} smallLabel value={hostedPackage.pendingElevators.toString()} 
                                                    label={t('deployment:pendingElevators')} noEdit labelClass="col-5  col-form-label text-end" />}
                                            {hostedPackage?.numberOfIOExpanders != null && 
                                                <TextInputComponent smallLabel value={hostedPackage.numberOfIOExpanders.toString()} 
                                                    label={t('deployment:iOExpanders')} noEdit labelClass="col-5  col-form-label text-end" />}
                                            {hostedPackage?.pendingIOExpanders != null && hostedPackage.pendingIOExpanders < hostedPackage.numberOfIOExpanders! &&  
                                                <TextInputComponent labelColor={"text-danger"} inputColor={"text-danger"} smallLabel value={hostedPackage.pendingIOExpanders.toString()} 
                                                    label={t('deployment:pendingIOExpanders')} noEdit labelClass="col-5  col-form-label text-end" />}
                                        </div>
                                        }
       
                                        <TextInputComponent smallLabel value={userCount > 0 ? userCount.toString() : tenant?.numOfUsers!.toString()!} label={t('deployment:numberOfUsers')} noEdit labelClass="col-5  col-form-label text-end" />
                                        {(tierType == 1 || tierType == 3) && showACE && <TextInputComponent smallLabel value={isACEEnabled.toString() } label={t('deployment:isACEEnabled')} noEdit labelClass="col-5  col-form-label text-end" />}
                                    </div>
                                }
                                {
                                    pending != null && pending.pendingDeletion && pending.pendingNumOfPanels != null &&
                                    <div className="card-footer pt-0 ">
                                        <div className="mb-1 pe-3 float-end ">
                                            <button className="btn float-end btn-secondary add-btn-text " onClick={() => setUndoShowDeleteModal(true)} >
                                                <span >{`${t('deployment:undoPanelDeletion')}`}</span>
                                            </button>
                                        </div>
                                    </div>
                                }
                            </div>
                            {prepaid && <div className="card col-xxl-6 bg-transparent shadow-none">
                                 <div className=" card-header pb-2 text-center">
                                    <h3 className='mb-4 header-text d-inline '><FontAwesomeIcon icon="credit-card" className="fs-3 me-2" />{t('license:addAddOns')}</h3>
                                </div>
                                <div className="card-body bg-white">
                                    <LicenseAddOnTextComponent maxLength={29} label={"Panel Count"} value={prepaidCode}
                                        setText={setPrepaidCode} placeholder={t('license:enterPrepaidCode')} upperCase
                                        buttonLabel={"Apply"} buttonClick={ ()=> ActivateLicense(LicenseTier.ProtectorNetCloud) } smallLabel = {true}  showAdd={true}
                                        defaultValue={hostedPackage?.numOfPanels.toString()!}
                                        licenseValue={panelCount > 0 ? panelCount.toString() : ""} setLicenseValue={setPanelCount} tierType={tierType} type={type}/>
                                    {status != t("deployment:pastdue") && <LicenseAddOnTextComponent maxLength={29} label={"User Count"} value={userCountCode}
                                        setText={setUserCountCode} placeholder={t('license:enterPrepaidCode')} upperCase
                                        buttonLabel={"Apply"} buttonClick={()=> ActivateLicense(LicenseTier.USERCOUNT) } smallLabel = {true}  showAdd={true}
                                        defaultValue={tenant?.numOfUsers!.toString()!}
                                        licenseValue={userCount.toString()} setLicenseValue={setUserCount} tierType={tierType} type={type} />}
                                       
                                     {(tierType == 1 || tierType == 3) && showACE && <LicenseAddOnTextComponent maxLength={29} label={"ACE"} value={aceCode}
                                            setText={setAceCode} placeholder={t('license:enterPrepaidCode')} upperCase
                                            defaultValue={(tenant?.extraOptions == 1).toString()}
                                            buttonLabel={"Apply"} buttonClick={()=> ActivateLicense(LicenseTier.ACE) } smallLabel={true} showAdd={true}
                                            licenseValue={isACEEnabled.toString()} setLicenseValue={setIsACEEnabled} tierType={tierType} type={type}/>}
                                </div>
                            </div>}
                            {!prepaid && <div className="card col-xxl-4 bg-transparent shadow-none">
                                <div className=" card-header pb-2 text-center">
                                    <h3 className='mb-4 header-text d-inline '><FontAwesomeIcon icon="clock" className="fs-3 me-2" />{t('deployment:updateSchedule')}</h3>
                                </div>
                                <div className="card-body bg-white">
                                    <div className="">
                                        <RadioComponent smallLabel labelClass={"col-5 col-sm-12 col-form-label text-center"} label={t('deployment:schedule')}
                                            options={hostedTermOptions} currentVal={hostedTerm} setCurrentVal={setHostedTerm} radioClass={"col-6 col-sm-12 col-form-label text-center"} />
                                    </div>
                                </div>
                                <div className="card-footer pt-0 ">
                                    <div className="mb-1 pe-3 float-end ">
                                        <button disabled={disableButtons} className="btn float-end btn-dribbble add-btn-text " onClick={() => setShowScheduleModal(true)} >
                                            <span >{`${t('deployment:updateSchedule')}`}</span>
                                        </button>
                                    </div>
                                </div>
                            </div>}
                            {!prepaid && <div className="card col-xxl-4 bg-transparent shadow-none">
                                <div className=" card-header pb-2 text-center" >
                                    {tierType == LicenseTier.ProtectorNet && <h3 className='mb-4 header-text d-inline '><FontAwesomeIcon icon="wrench" className="fs-3 me-2" />{t('deployment:updatePanels')}</h3>}
                                    {tierType == LicenseTier.Odyssey && <h3 className='mb-4 header-text d-inline '><FontAwesomeIcon icon="wrench" className="fs-3 me-2" />{t('deployment:updateDevices')}</h3>}
                                </div>
                                <div className="card-body bg-white">
                                    {tierType == LicenseTier.ProtectorNet && 
                                        <div className="form-group row">
                                            <label className="col-5 col-sm-12 col-form-label text-center" >
                                                <h3 className="d-none d-sm-block fs-5">{t('invoice:quantity')}</h3>
                                                <h3 className="d-block d-sm-none fs-4">{t('invoice:quantity')}</h3>
                                            </label>
                                            <div className="col-5 col-sm-12">
                                                <div className="text-center d-none d-sm-block w-50 ms-auto me-auto">
                                                    <input className="align-bottom form-control  " min={1} type="number" id="flexSwitchCheckDefault" value={amount} onChange={(e) => setAmount(Number(e.target.value))} />
                                                </div>
                                                <div className="text-center d-sm-none ms-auto me-auto">
                                                    <input className="align-bottom form-control  " min={1} type="number" id="flexSwitchCheckDefault" value={amount} onChange={(e) => setAmount(Number(e.target.value))} />
                                                </div>
                                            </div>
                                            <div className="form-group mt-2" style={{ display: (amount + hostedPackage?.numOfPanels!) > 2000 ? 'block' : 'none' }} >
                                                <p className="text-danger text-center">{t('deployment:maxPanels')}</p>
                                            </div>
                                        </div>
                                    }
                                    {tierType == LicenseTier.Odyssey &&
                                        <div className="pt-3">
                                            <div className="form-group row align-items-center">
                                                <label className="col-4 col-form-label text-end mt-1" >
                                                    <h3 className="d-none d-sm-block fs-5">{t('deployment:doors')}</h3>
                                                    <h3 className="d-block d-sm-none fs-4">{t('deployment:doors')}</h3>
                                                </label>
                                                <div className="col-4">
                                                    <div className="text-center  ms-auto me-auto">
                                                        <input className="align-bottom form-control  " min={1} type="number" id="flexSwitchCheckDefault" value={doorsAmount} onChange={(e) => setDoorsAmount(Number(e.target.value))} />
                                                    </div>
                                                </div>
                                                <div className="col-1 ms-3">
                                                    <div className="text-center">
                                                        <button className="btn btn-sm float-end btn-bitbucket " disabled={doorsAmount == 0} onClick={() => setUpdateDoors(true)} >
                                                            <FontAwesomeIcon icon="plus" className="fs-5" />
                                                        </button>
                                                    </div>
                                                </div>
                                                <div className="col-1 ms-2">
                                                    <div className="text-center">
                                                        <button className="btn btn-sm float-end btn-pinterest add-btn-text " disabled={doorsAmount > hostedPackage?.numberOfDoors!|| doorsAmount == 0 } onClick={() => setUpdateDoors(false)} >
                                                            <FontAwesomeIcon icon="minus" className="fs-5" />
                                                        </button>
                                                    </div>
                                                </div>
                                                {updateDoors != null && 
                                                    <div className="col-12 text-center mt-2">
                                                        {updateDoors == true && 
                                                            <h5 className="d-block fs-5 text-primary">
                                                                {`${t('deployment:adding')} ${doorsAmount} ${t('deployment:doors')}`}
                                                                <FontAwesomeIcon icon="multiply" className="fs-5 ms-3" onClick={() => setUpdateDoors(null)} />
                                                            </h5>}
                                                        {updateDoors == false && 
                                                            <h5 className="d-block fs-5 text-danger">
                                                                {`${t('deployment:removing')} ${doorsAmount} ${t('deployment:doors')}`}
                                                                <FontAwesomeIcon icon="multiply" className="fs-5 ms-3" onClick={() => setUpdateDoors(null)} />
                                                            </h5>}
                                                    </div>
                                                }
                                            </div> 
                                            <div className="form-group row pt-2 align-items-center">
                                                <label className="col-4 col-form-label text-end mt-1" >
                                                    <h3 className="d-none d-sm-block fs-5">{t('deployment:elevators')}</h3>
                                                    <h3 className="d-block d-sm-none fs-4">{t('deployment:elevators')}</h3>
                                                </label>
                                                <div className="col-4">
                                                    <div className="text-center  ms-auto me-auto">
                                                        <input className="align-bottom form-control  " min={1} type="number" id="flexSwitchCheckDefault" value={elevatorsAmount} onChange={(e) => setElevatorsAmount(Number(e.target.value))} />
                                                    </div>
                                                </div>
                                                <div className="col-1 ms-3">
                                                    <div className="text-center">
                                                        <button className="btn btn-sm float-end btn-bitbucket " disabled={elevatorsAmount == 0} onClick={() => setUpdateElevators(true)} >
                                                            <FontAwesomeIcon icon="plus" className="fs-5" />
                                                        </button>
                                                    </div>
                                                </div>
                                                <div className="col-1 ms-2">
                                                    <div className="text-center">
                                                        <button className="btn btn-sm float-end btn-pinterest add-btn-text" 
                                                            disabled={elevatorsAmount > hostedPackage?.numberOfElevators!|| elevatorsAmount == 0 } onClick={() => setUpdateElevators(false)}  >
                                                            <FontAwesomeIcon icon="minus" className="fs-5" />
                                                        </button>
                                                    </div>
                                                </div>
                                                {updateElevators != null && 
                                                    <div className="col-12 text-center mt-2">
                                                        {updateElevators == true && 
                                                            <h5 className="d-block fs-5 text-primary">
                                                                {`${t('deployment:adding')} ${elevatorsAmount} ${t('deployment:elevators')}`}
                                                                <FontAwesomeIcon icon="multiply" className="fs-5 ms-3" onClick={() => setUpdateElevators(null)} />
                                                            </h5>}
                                                        {updateElevators == false && 
                                                            <h5 className="d-block fs-5 text-danger">
                                                                {`${t('deployment:removing')} ${elevatorsAmount} ${t('deployment:elevators')}`}
                                                                <FontAwesomeIcon icon="multiply" className="fs-5 ms-3" onClick={() => setUpdateElevators(null)} />
                                                            </h5>}
                                                    </div>
                                                }
                                            </div> 
                                            <div className="form-group row pt-2 align-items-center">
                                                <label className="col-4 col-form-label text-end mt-1" >
                                                    <h3 className="fs-5">{t('deployment:iOExpanders')}</h3>
                                                </label>
                                                <div className="col-4">
                                                    <div className="text-center  ms-auto me-auto">
                                                        <input className="align-bottom form-control  " min={1} type="number" id="flexSwitchCheckDefault" value={iOExpandersAmount} onChange={(e) => setIOExpandersAmount(Number(e.target.value))} />
                                                    </div>
                                                </div>
                                                <div className="col-1 ms-3">
                                                    <div className="text-center">
                                                        <button className="btn btn-sm float-end btn-bitbucket " disabled={iOExpandersAmount == 0} onClick={() => setUpdateIOExpanders(true)} >
                                                            <FontAwesomeIcon icon="plus" className="fs-5" />
                                                        </button>
                                                    </div>
                                                </div>
                                                <div className="col-1 ms-2">
                                                    <div className="text-center">
                                                        <button className="btn btn-sm float-end btn-pinterest add-btn-text "
                                                            disabled={iOExpandersAmount > hostedPackage?.numberOfIOExpanders!|| iOExpandersAmount == 0 } onClick={() => setUpdateIOExpanders(false)}   >
                                                            <FontAwesomeIcon icon="minus" className="fs-5" />
                                                        </button>
                                                    </div>
                                                </div>
                                                {updateIOExpanders != null && 
                                                    <div className="col-12 text-center mt-2">
                                                        {updateIOExpanders == true && 
                                                            <h5 className="d-block fs-5 text-primary">
                                                                {`${t('deployment:adding')} ${iOExpandersAmount} ${t('deployment:iOExpanders')}`}
                                                                <FontAwesomeIcon icon="multiply" className="fs-5 ms-3" onClick={() => setUpdateIOExpanders(null)} />
                                                            </h5>}
                                                        {updateIOExpanders == false && 
                                                            <h5 className="d-block fs-5 text-danger">
                                                                {`${t('deployment:removing')} ${iOExpandersAmount} ${t('deployment:iOExpanders')}`}
                                                                <FontAwesomeIcon icon="multiply" className="fs-5 ms-3" onClick={() => setUpdateIOExpanders(null)} />
                                                            </h5>}
                                                    </div>
                                                }
                                            </div> 
                                        </div> 
                                    }
                                </div>
                                {tierType == LicenseTier.ProtectorNet && <div className="card-footer pt-0 ">
                                    <div className="mb-1 pe-3 float-end ">
                                        <button className="btn float-end btn-bitbucket add-btn-text " disabled={amount < 1 || (amount + hostedPackage?.numOfPanels!) > 2000 || disableButtons} onClick={() => setShowAddModal(true)} >
                                            <span >{`${t('deployment:addPanels')}`}</span>
                                        </button>
                                        <button className="btn float-end btn-pinterest add-btn-text me-3 " disabled={amount > hostedPackage?.numOfPanels! || amount < 1 || disableButtons} onClick={() => setShowDeleteModal(true)}  >
                                            <span >{`${t('deployment:deletePanels')}`}</span>
                                        </button>
                                    </div>
                                </div>}
                                {tierType == LicenseTier.Odyssey && <div className="card-footer pt-0 ">
                                    <div className="mb-1 pe-3 float-end ">
                                        <button className="btn float-end btn-twitter add-btn-text " disabled={updateDoors == null && updateElevators == null && updateIOExpanders == null} onClick={() => setShowUpdateDevicesModal(true)} >
                                            <span >{`${t('deployment:updateDevices')}`}</span>
                                        </button>
                                    </div>
                                </div>}
                            </div>}
                        </div>
                    </div>

                </div>
            }
            {
                section == t('payment') &&
                <div className=" mb-4 mt-2 ms-auto me-auto card shadow border-2" >
                    <div className=" card-header border-0 ">
                        <h2 className='mb-4 header-text'><FontAwesomeIcon icon="credit-card" className="fs-3 me-2" />{t('payment')}</h2>
                    </div>
                    <div className="card-body bg-opacity-75 ">
                        <Payment invoice={invoice} postFunction={PostFunction} hostedPayment={true} />
                    </div>
                </div>
            }
            {
                section == "updateDevicesPayment" &&
                <div className=" mb-4 mt-2 ms-auto me-auto card shadow border-2" >
                    <div className=" card-header border-0 ">
                        <h2 className='mb-4 header-text'><FontAwesomeIcon icon="credit-card" className="fs-3 me-2" />{t('payment')}</h2>
                    </div>
                    <div className="card-body bg-opacity-75 ">
                        <Payment invoice={invoice} postFunction={PostUpdateDevicesFunction} hostedPayment={true} />
                    </div>
                </div>
            }
            {
                section == t('upgradePayment') &&
                <div className=" mb-4 mt-2 ms-auto me-auto card shadow border-2" >
                    <div className=" card-header border-0 ">
                        <h2 className='mb-4 header-text'><FontAwesomeIcon icon="credit-card" className="fs-3 me-2" />{t('upgradePayment')}</h2>
                    </div>
                    <div className="card-body bg-opacity-75 ">
                        <Payment invoice={invoice} postFunction={PostUpgradeFunction} hostedPayment={true} />
                    </div>
                </div>
            }
            {
                section == "updateToOdyssey" &&
                <div className=" mb-4 mt-2 ms-auto me-auto card shadow border-2" >
                    <div className=" card-header border-0 ">
                        <h2 className='mb-4 header-text'><FontAwesomeIcon icon="cube" className="fs-3 me-2" />{`${t('updateToOdyssey')}`}</h2>
                    </div>
                    <div className="card-body bg-opacity-75 ">
                        <div className="form-group row mb-4">
                            <div className="col-sm-1"></div>
                            <div className="col-12 col-sm-10 ">
                                <div className="ps-3 mb-2">
                                    <p className="h4 lh-base">{`${t("deployment:upgradeToOdysseyInfo")}`}</p>
                                </div>
                            </div>
                        </div>
                        <div className="">
                            <div className="form-group row align-items-center">
                                <div className="col-1 col-sm-3"></div>
                                <label className="col-6 col-sm-2 col-form-label text-begin mt-1" >
                                    <h3 className="d-none d-sm-block">{t('deployment:doors')}</h3>
                                    <h3 className="d-block d-sm-none fs-4">{t('deployment:doors')}</h3>
                                </label>
                                <div className="col-4 col-sm-2">
                                    <div className="text-center  ms-auto me-auto">
                                        <input className="align-bottom form-control  " min={1} type="number" id="flexSwitchCheckDefault" value={doorsAmount} onChange={(e) => setDoorsAmount(Number(e.target.value))} />
                                    </div>
                                </div>
                            </div> 
                            <div className="form-group row pt-2 align-items-center">
                                <div className="col-1 col-sm-3"></div>
                                <label className="col-6 col-sm-2 col-form-label text-begin mt-1" >
                                    <h3 className="d-none d-sm-block">{t('deployment:elevators')}</h3>
                                    <h3 className="d-block d-sm-none fs-4">{t('deployment:elevators')}</h3>
                                </label>
                                <div className="col-4 col-sm-2">
                                    <div className="text-center  ms-auto me-auto">
                                        <input className="align-bottom form-control  " min={1} type="number" id="flexSwitchCheckDefault" value={elevatorsAmount} onChange={(e) => setElevatorsAmount(Number(e.target.value))} />
                                    </div>
                                </div>
                            </div> 
                            <div className="form-group row pt-2 align-items-center">
                                <div className="col-1 col-sm-3"></div>
                                <label className="col-6 col-sm-2 col-form-label text-begin mt-1" >
                                    <h3 className="d-none d-sm-block">{t('deployment:iOExpanders')}</h3>
                                    <h3 className="d-block d-sm-none fs-4">{t('deployment:iOExpanders')}</h3>
                                </label>
                                <div className="col-4 col-sm-2">
                                    <div className="text-center  ms-auto me-auto">
                                        <input className="align-bottom form-control  " min={1} type="number" id="flexSwitchCheckDefault" value={iOExpandersAmount} onChange={(e) => setIOExpandersAmount(Number(e.target.value))} />
                                    </div>
                                </div>
                            </div> 
                        </div> 
                    </div>
                    <div className="card-footer pt-0 ">
                        <div className="mb-1 pe-3 float-end ">
                            <button className="btn float-end btn-bitbucket add-btn-text " disabled={(doorsAmount + elevatorsAmount + iOExpandersAmount) < 1 } onClick={() => GetUpdateInvoice()} >
                                <span >{`${t('updateToOdyssey')}`}</span>
                            </button>
                        </div>
                    </div>
                </div>    
            }
        </div>
    )
}