import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Action } from "history";
import { features } from "process";
import { CompanyFeatures, Features } from "../../common/store";
import { RootState, AppThunk } from "../reduxStore";


export interface ConfigState {
    publish: boolean;
    name: string;
    sysAdmin: boolean;
    addDeployments: boolean;
    features: Features;
    companyFeatures: CompanyFeatures;
    hostedDomain: string,
    hostedOdysseyDomain: string,
    showACECard: boolean,
  }
  
  const initialState: ConfigState = {
    publish: false,
    name: "",
    sysAdmin:false,
    addDeployments:false,
    features: {backups: false, onlineDeployments: false, devPayment:true, hostedDeployments: false},
    companyFeatures: {allowedLicenseCard:false, allowedLocalLicensePayment: false, oem: "Hartmann", language:"", timezone:"", 
      allowedOdyssey: false, allowedInvoice:false, allowedHostedLicenseCard: false, allowedHostedPayment: false, 
      allowedCameraLicensing: false, allowPayBeforeActivate: false},
    hostedDomain: ".myprotectornet.com",
    hostedOdysseyDomain: ".hc-odyssey.com",
    showACECard: true //Change this value to allow dealer portal to show ACE Card
  };

export const configSlice = createSlice({
  name:"config",
  initialState,
  reducers:{
    setPublish: (state) => {
      state.publish = true;
    },
    setDevelopment:(state) => {
      state.publish = false;
    },
    setPublishConfig:(state, action: PayloadAction<boolean>) => {
      state.publish = action.payload
    },
    setName:(state, action: PayloadAction<string>) => {
      state.name = action.payload
    },
    setSysAdmin:(state, action: PayloadAction<boolean>) => {
      state.sysAdmin = action.payload
    },
    setAddDeployment:(state, action: PayloadAction<boolean>) => {
      state.addDeployments = action.payload
    },
    setFeatures: (state, action: PayloadAction<Features>) => {
      state.features = action.payload
    },
    setCompanyFeatures: (state, action: PayloadAction<CompanyFeatures>) => {
      state.companyFeatures = action.payload
    },
    setHostedDomain:(state, action: PayloadAction<string>) => {
      state.hostedDomain = action.payload
    },
    setHostedOdysseyDomain:(state, action: PayloadAction<string>) => {
      state.hostedOdysseyDomain = action.payload
      },
    setShowACECard: (state, action: PayloadAction<boolean>) => {
        state.showACECard = action.payload
    }
  }
})

export const { setPublish, setDevelopment, setShowACECard, setPublishConfig, setName, setSysAdmin, setAddDeployment, setFeatures,setCompanyFeatures, setHostedDomain, setHostedOdysseyDomain } = configSlice.actions;

export const selectConfig = (state: RootState) => state.config.publish;
export const selectName = (state: RootState) => state.config.name;
export const isAdmin = (state: RootState) => state.config.sysAdmin;
export const canAddDeployment = (state: RootState) => state.config.addDeployments;
export const getFeatureGate = (state: RootState) => state.config.features;
export const getCompanyFeatureGate = (state: RootState) => state.config.companyFeatures;
export const getHostedDomain = (state: RootState) => state.config.hostedDomain;
export const getHostedOdysseyDomain = (state: RootState) => state.config.hostedOdysseyDomain;
export const showACECard = (state: RootState) => state.config.showACECard;
export default configSlice.reducer;