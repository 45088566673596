import { t } from "i18next";
import React from "react";  
import { AuthContextProps } from "react-oidc-context";
import API from "../API";

export default async function(auth:AuthContextProps, accountNumber:number, registrationKey:string, licenseId:number, setLoading:Function, setSection:Function, setResponseKey:Function ){
    setLoading(true)
    var object = {
        AccountNumber: accountNumber,
        RequestKey:  registrationKey
    }
    try {
        const response: any = await API.post(`api/v1/license/activate/${licenseId}`, object, auth)
        setResponseKey(response.responseKey)
        setLoading(false)
        setSection( "response")
    }
    catch (e: any) {
        setLoading(false)
        alert(e.errors?.Error!)
    }
}