import { WebStorageStateStore } from "oidc-client-ts"
import { AuthProvider, AuthProviderProps, useAuth } from "react-oidc-context"
import { BrowserRouter } from "react-router-dom"
import App from "./App"
import { CompanyFeatures } from "./common/store"
import useAppDispatch from "./hooks/useAppDispatch"
import useAppSelector from "./hooks/useAppSelector"
import Middleware from "./Middleware"
import { selectConfig, setCompanyFeatures, setDevelopment, setHostedDomain, setPublishConfig, setShowACECard } from "./redux/slices/config"

export default function () {
    const dispatch = useAppDispatch()
    dispatch(setPublishConfig(true))
    dispatch(setShowACECard(false))
    const releaseMode = useAppSelector(selectConfig)
    const hartmannDev = false;

    const companyFeatures: CompanyFeatures = { allowedLocalLicensePayment: false, oem: "Aiphone", language: "en-US", timezone: "America/Detroit",
     allowedOdyssey: false, allowedInvoice: false, allowedLicenseCard: false, 
     allowedHostedLicenseCard: false, allowedHostedPayment: false, allowedCameraLicensing:false, allowPayBeforeActivate:false }
    dispatch(setCompanyFeatures(companyFeatures))

    let portalURL: string = releaseMode ? companyFeatures.oem == "Aiphone" ? "https://portal.acnio-license.aiphone.com" : "https://portal.hartmann-controls.com" : "https://localhost:44410"
    let authURL: string = releaseMode ? companyFeatures.oem == "Aiphone" ? "https://auth.acnio-license.aiphone.com" : "https://auth.hartmann-controls.com" : "https://localhost:7113"

    if (hartmannDev) {
        portalURL = "https://dev-dealer.hartmann-controls.com"
        authURL = "https://obsidianauth.hartmann-controls.com"
        dispatch(setHostedDomain(".securemybuilding.com"))
    }

    const logo = document.querySelector(".tab-icon");
    logo!.setAttribute("href", `/${companyFeatures.oem}-icon.png`);

    const oidcConfig: AuthProviderProps = {
        authority: authURL,
        client_id: "react-app",
        client_secret: "react-app-secret",
        redirect_uri: `${portalURL}/callback`,
        post_logout_redirect_uri: `${portalURL}/callback`,
        response_type: "code",
        scope: "profile openid api roles",
        metadataUrl: `${authURL}/.well-known/openid-configuration`,
        userStore: new WebStorageStateStore({ store: window.localStorage })
    };

    return (
        <AuthProvider {...oidcConfig}>
            <BrowserRouter>
                <Middleware />
            </BrowserRouter>
        </AuthProvider>
    )
}