import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { t } from "i18next";
import { type } from "os";
import React, { useEffect, useState } from "react";
import { useAuth } from "react-oidc-context";
import { useNavigate, useParams } from "react-router-dom";
import API from "../../common/API";
import { Invoice, InvoiceItem, OptionType, ViewInvoiceDTO } from "../../common/store";
import LoadingPageComponent from "../../components/shared/LoadingPageComponent";
import TextInputComponent from "../../components/shared/TextInputComponent";
import createBlob from "../../scripts/createBlob";
import download from "../../scripts/download";

export default function ({ iId, showPayStatus, updatePurchaseOrder }: { iId?: number, showPayStatus?: boolean, updatePurchaseOrder?: boolean }) {
    const { id } = useParams();
    const auth = useAuth()
    const navigate = useNavigate()

    const [invoice, setInvoice] = useState<Invoice>()
    const [invoiceItems, setInvoiceItems] = useState<InvoiceItem[]>()
    const [loading, setLoading] = useState<boolean>(true)
    const [purchaseOrder, setPurchaseOrder] = useState<string>('')

    const getPDF = async () => {
        try {
            const url: string = `/api/v1/invoice/pdf/${id}`
            const response: any = await API.get(url, auth)
            download(createBlob(response, "application/pdf"), `${t("invoice:invoice")} #${invoice?.id}.pdf`)

        }
        catch (e: any) {
            alert(e.errors?.Error!)
        }
    }

    const updatePO = async () => {
        try {
            const Id = iId != null ? iId : id
            const url: string = `api/v1/invoices/UpdatePurchaseOrder/${Id}/${purchaseOrder}`
            const response: any = await API.put(url, {}, auth)
        }
        catch (e: any) {
            alert(e.errors?.Error!)
        }

    }

    useEffect(() => {
        if (updatePurchaseOrder == true)
            updatePO()
    }, [updatePurchaseOrder])

    useEffect(() => {
        const Id = iId != null ? iId : id
        const getInvoice = async () => {
            try {
                setLoading(true)
                const url: string = `/api/v1/invoices/${Id}`
                const response: ViewInvoiceDTO | null = await API.get(url, auth)
                setLoading(false)

                setInvoice(response?.invoice)
                setInvoiceItems(response?.invoiceItems)
                setPurchaseOrder(response?.invoice.purchaseOrder ?? '')
            }
            catch (e: any) {
                alert(e.errors?.Error!)
                navigate('/notFound')
            }
        }
        getInvoice()
    }, [])

    return (
        <div>
            {loading && <LoadingPageComponent />}
            {!loading &&
                <div className={iId != null ? "" : "card"}>
                    {iId == null && <div className="card-header">
                        <h2 className='mb-4 header-text '>
                            <FontAwesomeIcon icon="file-invoice-dollar" className="fs-3 me-2" />{`${t("invoice:invoice")} #${invoice?.id}`}
                            <button className="btn btn-dribbble float-end rounded-3" onClick={() => getPDF()}>
                                <FontAwesomeIcon icon="download" className="fs-3" />
                            </button>
                        </h2>
                    </div>}
                    <div className={iId != null ? "" : "card-body"}>
                        <div className="row">
                            <div className="col-sm-12 ms-auto ">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th className="h5">{`${t("item")}`}</th>
                                            <th className="h5">{`${t("invoice:quantity")}`}</th>
                                            <th className="h5 d-none d-sm-table-cell">{`${t("invoice:price")}`}</th>
                                            <th className="h5">{`${t("total")}`}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            invoiceItems?.map((e: InvoiceItem) => (
                                                <tr>
                                                    <th><h5>{e.description}</h5></th>
                                                    <th><h5>{e.quantity}</h5></th>
                                                    <th className="d-none d-sm-table-cell"><h5>{"$" + e.amount.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</h5></th>
                                                    <th><h5>{"$" + (e.quantity * e.amount).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</h5></th>
                                                </tr>
                                                /* <TextInputComponent label={e.description} value={e.amount.toString()} noEdit  labelClass="col-sm-6 col-form-label mb-4 text-center"  /> */
                                            ))
                                        }
                                    </tbody>
                                    <tbody>
                                        <tr >
                                            <th className="d-none d-sm-table-cell"></th><th></th>
                                            <th className="h5"> {`${t("subtotal")}`} </th>
                                            <th className="h5"> {`$${invoice?.subTotal.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}`} </th>
                                        </tr>
                                        <tr>
                                            <th className="d-none d-sm-table-cell"></th><th></th>
                                            <th className="h5"> {`${t("invoice:taxes")}`}  </th>
                                            <th className="h5"> {`$${invoice?.taxes.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}`} </th>
                                        </tr>
                                        <tr>
                                            <th className="d-none d-sm-table-cell"></th><th></th>
                                            <th className="h5"> {`${t("invoice:discount")}`}  </th>
                                            <th className="h5"> {`$${invoice?.discount.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}`} </th>
                                        </tr>
                                        <tr>
                                            <th className="d-none d-sm-table-cell"></th><th></th>
                                            <th className="h5"> {`${t("total")}`}  </th>
                                            <th className="h5"> {`$${invoice?.total.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}`} </th>
                                        </tr>
                                    </tbody>
                                </table>
                                {invoice?.paidOn != null &&
                                    <div className="mb-4 me-3 float-end">
                                        <h5>{`${t("invoice:paidOn")}: ${new Date(invoice?.paidOn!.toString()!).toLocaleDateString() + " " + new Date(invoice?.paidOn!.toString()!).toLocaleTimeString()}`}</h5>
                                    </div>
                                }
                                {invoice?.paidOn == null && invoice?.exported == true &&
                                    <div className="mb-4 me-3 float-end">
                                        <h5>{`Invoiced at ${new Date(invoice?.createdOn!.toString()!).toLocaleDateString()}`}</h5>
                                    </div>
                                }
                                {showPayStatus != null && showPayStatus == true && invoice?.paidOn == null && invoice?.exported == false &&
                                    <div className="mb-4 me-3 float-end">
                                        <h5>{`Not Paid`}</h5>
                                    </div>
                                }
                                <div className="col-12 col-sm-6 mb-3 mb-sm-0">
                                    {updatePurchaseOrder == null && <TextInputComponent noLabelPadding smallLabel labelClass={"col-4 col-sm-2 c col-form-label text-end mt-1 mt-sm-2 px-0"} inputClass={"col-8 col-sm-8"}
                                        value={purchaseOrder} label={t('purchaseOrder')} setText={setPurchaseOrder}
                                        onClick={() => updatePO()} append={"circle-check"}
                                        appendClass={" form-control form-control-lg border-0 btn btn-outline-secondary "} inputColor={'w-75 d-inline'} />}
                                    {updatePurchaseOrder != null && <TextInputComponent noLabelPadding smallLabel labelClass={"col-4 col-sm-2 c col-form-label text-end mt-1 mt-sm-2 px-0"} inputClass={"col-8 col-sm-8"}
                                        value={purchaseOrder} label={t('purchaseOrder')} setText={setPurchaseOrder} />}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>}
        </div>

    )

}