import { t } from "i18next";
import React from "react";
import { AuthContextProps } from "react-oidc-context";
import { NavigateFunction } from "react-router-dom";
import { LicenseTier } from "../../components/shared/Enums";
import API from "../API";
import { CompanyFeatures, LicenseDTO, OptionType } from "../store";

export default async function(auth:AuthContextProps, id:string, navigate: NavigateFunction, setSection:Function, 
    setInvoice:Function,setActivatingLicense:Function,setLicenseId:Function, setPackageType:Function, setLicenseTerm:Function, setLicenseTier:Function, renew: string,
    setFivePartitions:Function, setTenPartitions:Function, setTwentyPartitions:Function,setMulticard:Function, companyFeatureGate:CompanyFeatures){

    const licenseOption:OptionType[] = [{i1: 50, i2: 1, label: t("package:essentials"), value:''}, {i1: 100, i2: 2, label: t("package:small"), value:''}, {i1: 250, i2: 4, label: t("package:mid"), value:''},
    {i1: 400, i2: 256, label: t("package:large"), value:''}, {i1: 750, i2: 8, label: t("package:enterprise"), value:''}]
    
    try {
        const response: LicenseDTO | null = await API.get(`/api/v1/Deployment/${id}/activeLicense` + (renew == null ? "" : "/renew") , auth)
        if (response == null)
            return
        else if (renew != null)
        {
            setLicenseTerm(response?.duration! )
            setPackageType(response?.package)
            setLicenseTier(response?.licenseTier)
            setMulticard(response?.multicard ? 16:0)
            setFivePartitions(response?.additional5Partitions ? 32: 0)
            setTenPartitions(response?.additional10Partitions ? 64: 0)
            setTwentyPartitions(response?.additional20Partitions ? 128: 0)
        }
        else if (response?.activated) {
            alert("License Is Already Activated. Changes To License Cannot Be Made")
            navigate("/deployment")
        }
        else if (response?.invoice != null)
        {
            if(response?.invoice.paidOn != null || response.invoice.exported == true)
            {
                setLicenseId(response.id)
                setInvoice(response.invoice!)
                setSection("activate")
                setActivatingLicense(true)
                setLicenseTerm(response?.duration! )
                setPackageType(response?.package)
                setLicenseTier(response?.licenseTier)
            }
            else
            {
                setLicenseId(response.id)
                setInvoice(response.invoice!)
                setSection("payment")
                setLicenseTerm(response?.duration! )
                setPackageType(response?.package)
                setLicenseTier(response?.licenseTier)
            }
        }

        if (!companyFeatureGate.allowedOdyssey && response.licenseTier == LicenseTier.Odyssey)
        {
            setLicenseTier(LicenseTier.ProtectorNet)
            setPackageType(1)
        }
    }
    catch (e: any) {
        alert(e.errors?.Error!)
        if (renew)
            if (e.errors.Error != null && e.errors.Error[0] != null && e.errors.Error[0].includes("License History"))
                navigate("/deployment/" + id + "/licenseHistory" )
            else
                navigate("/deployment/" + id)
        else
            navigate("/deployment")
    }
}