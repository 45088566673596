import { faCropSimple } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import React, { MouseEventHandler, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useAuth } from 'react-oidc-context';
import TextInputComponent from '../shared/TextInputComponent';

type Props = {
  addPanel:(macAddress:string)=>void
}

export default function AddPanel(props: Props) {
  let auth = useAuth();
  const [macAddress, setMacAddress] = useState<string>('');
  const [macAddressLength, setMacAddressLength] = useState<number>(0)
  const [errorMessage, setErrorMessage] = useState<string>("")
  const { t } = useTranslation()

  const validateMacAddress = () => {

    const newMacAddress = macAddress.replaceAll(":", "")
    let alphanumericCheck = /[^0-9a-fA-F]/.test(newMacAddress)
    return newMacAddress.length == 12 && !alphanumericCheck
  }

  useEffect(() => {
    const newMacAddress = (macAddress.replaceAll(":", "")).replace(/..\B/g, '$&:').toUpperCase()
    setMacAddress(newMacAddress)
  }, [macAddress])

  return (
    <div className=''>
      <button className="btn btn-primary d-inline ms-2 float-end " disabled={!validateMacAddress()} onClick={()=>props.addPanel(macAddress.replaceAll(":", ""))}>
        <h6 className="text-white d-inline">{t('addPanel')}</h6>
        <FontAwesomeIcon icon="plus" className=" ms-2 mt-1" />
      </button>
      <input
        className="mb-1 w-25 float-end form-control input-text d-inline"
        placeholder={"mac address"}
        type="text"
        maxLength={17}
        value={macAddress}
        onChange={(e) => setMacAddress(e.target.value)} />
      {(!validateMacAddress() && macAddress.length > 0) && <p className="mt-2 text-danger float-end me-3">{`${t("error:invalidMacAddress")}`}</p>}
    </div>
  )
}