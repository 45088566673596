import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { t } from "i18next";
import React, { useState } from "react";
import { useAuth } from "react-oidc-context";
import { LinkContainer } from "react-router-bootstrap";
import API from "../../common/API";
import { OptionType } from "../../common/store";
import { ModalComponent } from "./ModalComponent";

type Props = {
    headerValues: OptionType[],
    list: any[],
    isEditable?: boolean,
    isRemovable?: boolean,
    editLink?: string,
    deleteString?: string,
    label?: string,
    repopulate?: Function,
    setOrderBy?: Function,
    orderBy?: string,
    setOrderDirection?: Function,
    orderDirection?: string
}

export default function PaginationListComponent({ headerValues, list, isEditable = true, isRemovable = true, editLink, deleteString, label, repopulate, setOrderBy, orderBy, setOrderDirection, orderDirection }: Props) {

    const auth = useAuth()
    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
    const [deleteId, setDeleteId] = useState<number>(0)

    const orderDirections: OptionType[] = [{ value: 'Asc', label: t('Asc') }, { value: 'Desc', label: t('Desc') }]

    const deleteModal = (id: number) => {
        setDeleteId(id)
        setShowDeleteModal(true)
    }

    const deleteItem = async () => {
        setShowDeleteModal(false)
        try {
            const response = await API.delete(`${deleteString}${deleteId}`, {}, auth)
            if (repopulate != null)
                repopulate()
        }
        catch (e: any) {
            alert(e.errors?.Error!)
        }
    }

    const handleOrder = async (value: string | undefined) => {
        if (orderBy == value && setOrderDirection != null && orderDirection != null) {
            if (orderDirection == "Asc")
                setOrderDirection("Desc")
            else
                setOrderDirection("Asc")
        }
        else if (setOrderBy != null) {
            setOrderBy(value!)
        }


    }

    return (
        <div>
            <ModalComponent actionFunction={deleteItem} modalTitle={`${t('delete')} ${label != null ? label : 'Item'} `} modalText={`${t('deleteMessage')} ${label != null ? label : 'Item'}?`}
                buttonText={t("delete")} showModal={showDeleteModal} setShowModal={setShowDeleteModal} />
            <table className="table  table-sm  fs--1 mb-0 mt-4 table-hover ">
                <thead className="border-bottom">
                    <tr className='text-center table-header-text'>
                        {isEditable != false && <th className="col-1"></th>}
                        {headerValues.map((e: OptionType) => (
                            <th className="col-1" key={Math.random()}>
                                <div>
                                    {(e.i1 != null && setOrderBy != null) &&
                                        <a onClick={() => handleOrder(e.value)}>
                                            <h4>{e.label}
                                                {orderBy == e.value && <FontAwesomeIcon className={`ms-2 ${orderBy == e.value ? 'text-primary' : ''} `} icon={`${orderDirection == "Desc" ? 'arrow-up' : 'arrow-down'}`} />}
                                            </h4>
                                        </a>
                                    }
                                    {(e.i1 == null ) && <h4>{e.label}</h4>}
                                </div> </th>

                        ))}
                        {isRemovable != false && <th className="col-1"></th>}
                    </tr>
                </thead>
                <tbody className="">
                    {list.map((e: any) => (
                        <tr className="table-text text-center" key={Math.random()}>
                            {isEditable != false &&
                                <td className="text-center">
                                    <LinkContainer to={editLink != null ? editLink + `${e.id}` : ''}>
                                        <button className="border-0  btn btn-outline-primary" >
                                            <FontAwesomeIcon icon="cog" />
                                        </button>
                                    </LinkContainer>
                                </td>
                            }
                            {headerValues.map((a: OptionType) => (
                                <td key={Math.random()}>
                                    {typeof (e[a.value!]) != 'boolean' && a.s1 != null && a.s1 != "subdomain" &&
                                        <LinkContainer to={a.s2 + e[a.s1]}>
                                            <a className="h5">{e[a.value!]}</a>
                                        </LinkContainer>
                                    }
                                    {typeof (e[a.value!]) != 'boolean' && a.s2 != null && a.s1 == "subdomain" &&
                                        <div>
                                            {e[a.s1!] != null && <a onClick={() => window.open("https://" + e[a.s1!])} className="h5 text-decoration-underline">{e[a.value!]}</a>}
                                            {e[a.s1!] == null && <h5>{e[a.value!]}</h5>}
                                        </div>
                                    }
                                    {typeof (e[a.value!]) != 'boolean' && a.s1 == null && a.s2 == null &&
                                        <h5>{e[a.value!]}</h5>
                                    }
                                    {typeof (e[a.value!]) == 'boolean' && a.func == null &&
                                        <input className="align-bottom " type="checkbox" checked={e[a.value!]} readOnly />
                                    }
                                     {typeof (e[a.value!]) == 'boolean' && a.func != null &&
                                        <input className="align-bottom " type="checkbox" checked={e[a.value!]} onChange={() => a.func!(e)} />
                                    }
                                </td>
                            ))}
                            {isRemovable != false &&
                                <td className="text-center">
                                    <button className="border-0  btn btn-outline-danger" onClick={() => deleteString != null ? deleteModal(e.id) : null}  >
                                        <FontAwesomeIcon icon="delete-left" />
                                    </button>
                                </td>
                            }
                        </tr>
                    ))}
                </tbody>
            </table>

        </div>
    )
}