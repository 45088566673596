import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { CSSProperties, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { PasswordComponent } from "../../components/shared/PasswordComponent";
import Select, { StylesConfig } from 'react-select'
import { SelectStyle } from "../../common/SelectStyle";
import { CompanyFeatures, CompanyRegisterInfo, GetUserRequest, OptionType, PhoneNumberType, WizardOption } from "../../common/store";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import { Console, time } from "console";
import TextInputComponent from "../../components/shared/TextInputComponent";
import { validate as EmailValidate } from 'email-validator';
import TimezoneSelect from 'react-timezone-select'
import ReactDOM from 'react-dom'
import PhoneNumberComponent from "../../components/shared/PhoneNumberComponent";
import RadioComponent from "../../components/shared/RadioComponent";
import API from "../../common/API";
import CountryRegionComponent from "../../components/shared/CountryRegionComponent";
import AddUserComponent from "../../components/register/AddUserComponent";
import LoadingPageComponent from "../../components/shared/LoadingPageComponent";
import { changeLanguage } from "i18next";
import { availableLanguages } from "../../languages/lang";
import logo from '../../assets/images/hartmannDark.png';
import aiphone from '../../assets/images/Aiphone-Logo-Light.svg';
import aiphoneDark from '../../assets/images/Aiphone-Logo-Dark.svg';
import SelectComponent from "../../components/shared/SelectComponent";
import { useTranslation } from "react-i18next";
import { ProgressWizard } from "../../components/shared/ProgressComponent";
import PostalCodeValidator from "../../common/Functions/PostalCodeValidator";
import useAppSelector from "../../hooks/useAppSelector";
import { getCompanyFeatureGate } from "../../redux/slices/config";
import RequiredFields from "../../components/shared/RequiredFields";
export function Register() {

    const { t } = useTranslation();
    const navigate = useNavigate()
    const companyFeatureGate: CompanyFeatures = useAppSelector(getCompanyFeatureGate)
    const { token } = useParams();
    const [langCheck, setLangCheck] = useState<string>(t('admin'))
    const [currentLang, setCurrentLang] = useState<string>('en')
    const [section, setSection] = useState<string>("company")
    const sectionsWizard:WizardOption[] =  [{label:t('register:companyDetails'), icon:"building", value:"company"}, 
    {label:t('contactDetails'), icon:"address-book", value:"contact"},{label:t('register:administrator'), icon:"user", value:"admin"}, 
    {label:t('register:addUsers'), icon:"users", value:"addUsers"}]
    const languageOptions: OptionType[] = [{ value: 'es-ES', label: 'Spanish' }, { value: 'en-US', label: 'English' }, { value: 'fr-FR', label: 'French' }]
    const companyTypeOptions: OptionType[] = companyFeatureGate.oem == "Aiphone" ?[{ value: 'Dealer', label: t('dealer') }]
    :[{ value: 'Dealer', label: t('dealer') }, { value: 'User', label: t('endUser') }, { value: 'Distributor', label: t('distributor') }]
    const [loading, setLoading] = useState<boolean>(true)

    //Company Details
    const [companyName, setCompanyName] = useState<string>('')
    const [companyType, setCompanyType] = useState<string>('')

    //Contact Info
    const [website, setWebsite] = useState<string>('')
    const [email, setEmail] = useState<string>('')
    const [phoneNumbers, setPhoneNumbers] = useState<PhoneNumberType[]>([])
    const [currentPhoneTypeOption, setCurrentTypeOption] = useState<string | undefined>('Work')
    const [currentPhoneNumber, setCurrentPhoneNumber] = useState<string>('')
    const [currentExtension, setCurrentExtension] = useState<string>('')
    const [address, setAddress] = useState<string>('')
    const [city, setCity] = useState<string>('')
    const [postalCode, setPostalCode] = useState<string>('')
    const [country, setCountry] = useState<string>('')
    const [region, setRegion] = useState<string>('')
    const [emailSubscription, setEmailSubscription] =  useState<number>(0)

    // Admin Details
    const [firstName, setFirstName] = useState<string>('')
    const [lastName, setLastName] = useState<string>('')
    const [currentAdminPhoneTypeOption, setCurrentAdminTypeOption] = useState<string | undefined>('Work')
    const [currentAdminPhoneNumber, setCurrentAdminPhoneNumber] = useState<string>('')
    const [currentAdminExtension, setCurrentAdminExtension] = useState<string>('')
    const [language, setLanguage] = useState<string | undefined>("en-US")
    const [adminEmail, setAdminEmail] = useState<string>("")
    const [password, setPassword] = useState<string>("")
    const [confirmPassword, setConfirmPassword] = useState<string>("")
    const [timezone, setTimezone] = useState<string>("")

    // AddUsers Details
    const [addUserList, setAddUsersList] = useState<GetUserRequest[]>([])

    const emailFlags: OptionType[] = [{ value: '1', label: t('deployment:billing') }, { value: '2', label: t('deployment:pricing') }, { value: '4', label: t('deployment:newsletter') },
    { value: '8', label: t('deployment:outages') }, { value: '16', label: t('deployment:softwareUpdates') }, { value: '32', label: t('deployment:licensing') }, 
    { value: '64', label: t('deployment:systemHealth') }]

    const handleEmailSubscription = (i: number) => {
        if ((emailSubscription! & i) != 0)
            setEmailSubscription!(emailSubscription! - i)
        else
            setEmailSubscription!(emailSubscription! + i)
    }

    useEffect(() => {
        const checkToken = async () => {
            try {
                const response: CompanyRegisterInfo | null = await API.get(`/api/v1/checkRegisterToken/${token}`)
                setCompanyName(response?.companyName!)
                setCompanyType(response?.companyType!)
                setLoading(false)
            }
            catch (e: any) {
                setLoading(false)
                alert(e.errors?.Error!)
                navigate('/notFound')
            }
        }
        checkToken()
    }, [])

    useEffect(() => {
        if (langCheck != t('admin')) {
            // setSection(t('register:companyDetails'))
            setLangCheck(t('admin'))
        }
    }, [sectionsWizard])


    const RegisterCompany = async () => {
        const AdminPhoneNumber: PhoneNumberType = { type: currentAdminPhoneTypeOption, number: currentAdminPhoneNumber, extension: currentAdminExtension }
        if (phoneNumbers.length == 0) {
            if (currentAdminPhoneTypeOption != '' && currentPhoneNumber != '') {
                const PhoneNumber: PhoneNumberType = { type: currentAdminPhoneTypeOption, number: currentPhoneNumber, extension: currentExtension }
                phoneNumbers.push(PhoneNumber)
            }
        }
        const object = {
            CompanyName: companyName, CompanyType: companyType,
            Website: website, Email: email,
            PhoneNumbers: phoneNumbers,
            Address: address, City: city, PostalCode: postalCode,
            Country: country, Region: region,
            //Admin Details
            FirstName: firstName, LastName: lastName, AdminPhoneNumber: AdminPhoneNumber, Language: language,
            TimeZone: timezone, AdminEmail: adminEmail, Password: password, Token: token,
            Users: addUserList, EmailSubscriptions: emailSubscription
        }
        try {
            setLoading(true)
            const response = await API.post("/api/v1/Register/Company", object)
            setLoading(false)
            setSection('Finish')
        }
        catch (e: any) {
            alert(e.errors?.Error!)
            setLoading(false)
        }
    }

    return (
        <div>
            <header>
                <nav className="navbar-expand  navbar-toggleable mb-3" >
                    <div className='navbar-brand ms-3'>
                        {companyFeatureGate.oem == "Hartmann" &&<div className="text-center d-block d-sm-none">
                            <img className="border-0 me-1" src={logo}></img>
                            </div>
                        }
                        {companyFeatureGate.oem == "Aiphone" &&<div className="text-center d-block d-sm-none">
                            <img className="border-0 me-1 w-50 mb-3" src={aiphone}></img>
                            </div>
                        }
                        {companyFeatureGate.oem == "Hartmann" && <img className="border-0 me-1 d-none d-sm-block" src={logo}></img>}
                        {companyFeatureGate.oem == "Aiphone" && <img className="border-0 me-1 d-none d-sm-block " style={{width : "15%"}} src={aiphone}></img>}
                        {section != 'Finish' && <div>
                            <h1 className="text-center text-dark">{`${t("companyRegistration")}`}</h1>
                            <select className="float-end mb-2 bg-white border-light" defaultValue={'en'} value={currentLang} onChange={(e) => { changeLanguage(e.target.value); setCurrentLang(e.target.value) }}>
                                {availableLanguages.map((language) => (
                                    <option key={language}>{language}</option>
                                ))}
                            </select>
                        </div>}
                    </div>
                </nav>
            </header>
            {!loading &&
                <div className="w-100">
                    {section != "Finish" && <ProgressWizard sections={sectionsWizard} currentSection={section} />}
                    {section == "company" && <div className="mb-4 mt-2 ms-auto me-auto card shadow border-2" >
                        <div className=" card-header border-0 ">
                            <h5 className="float-end">{t(companyType.toLocaleLowerCase())}</h5>
                            <h2 className='mb-4 header-text'><FontAwesomeIcon icon="building" className="fs-3 me-2" />{t('companyDetails')}</h2>
                            <RequiredFields />
                        </div>
                        <div className="card-body bg-opacity-75 ">
                            <TextInputComponent mandatory value={companyName} label={t('companyName')} setText={setCompanyName} />
                            <RadioComponent label={t('companyType')} options={companyTypeOptions} currentVal={companyType} setCurrentVal={setCompanyType} noEdit />
                        </div>
                        <div className=" mb-4 pe-5">
                            <button className="btn float-end btn-github add-btn-text " disabled={companyName == '' || companyType == ''} onClick={() => setSection("contact")}>
                                <span >{t('next')}</span>
                                <FontAwesomeIcon icon="chevron-right" className="ms-2" />
                            </button>
                        </div>
                    </div>}
                    {section == "contact" && <div className="mb-4 mt-2 ms-auto me-auto card shadow border-2" >
                        <div className=" card-header border-0 ">
                            <h5 className="float-end">{t(companyType.toLocaleLowerCase())}</h5>
                            <h2 className='mb-4 header-text'><FontAwesomeIcon icon="address-book" className="fs-3 me-2" />{t('contactDetails')}</h2>
                            <RequiredFields />
                        </div>
                        <div className="card-body bg-opacity-75 ">
                            <TextInputComponent value={website} label={t('website')} setText={setWebsite} />
                            <TextInputComponent mandatory value={email} label={t('email')} setText={setEmail} validator={EmailValidate} validateText={t('user:emailValidationError')} />
                            <TextInputComponent mandatory value={address} label={t('address')} setText={setAddress} />
                            <CountryRegionComponent mandatory country={country} region={region} setCountry={setCountry} setRegion={setRegion} />
                            <TextInputComponent mandatory value={city} label={t('city')} setText={setCity} />
                            <TextInputComponent validator={(e:string) => PostalCodeValidator(e, country)} validateText={t("invalidPostalCode")} mandatory value={postalCode} label={t('postalCode')} setText={setPostalCode} />
                            <div className="form-group row mb-4 ">
                                <div className="col-1 d-none d-sm-block"></div>
                                <label className="col-5 col-sm-2 col-form-label text-begin ms-3 mb-2" >
                                    <h3 className="d-none d-sm-block">{t('deployment:emailSubscription')}</h3>
                                    <h3 className="d-block d-sm-none fs-4">{t('deployment:emailSubscription')}</h3>
                                </label>
                                <div className=" col-6 col-sm-7 col-form-label">
                                    {emailFlags.map((e: OptionType) => (
                                        <div className="d-inline me-4 text-nowrap" key={e.value}>
                                            <input onChange={() => { }} className="ms-2" type="checkbox" checked={(emailSubscription! & Number(e.value)) != 0} value={0} onClick={() => handleEmailSubscription(Number(e.value))} />
                                            <label className="text-center mt-1 ms-2" ><h4>{e.label}</h4></label>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            
                            
                            
                            <PhoneNumberComponent  phoneNumber={currentPhoneNumber} extension={currentExtension} typeOption={currentPhoneTypeOption} setPhoneNumber={setCurrentPhoneNumber}
                                setExtension={setCurrentExtension} setTypeOption={setCurrentTypeOption} phoneNumbers={phoneNumbers} setPhoneNumbers={setPhoneNumbers} />
                        </div>
                        <div className=" mb-4 pe-5">
                            <button className="btn float-end btn-github add-btn-text " disabled={((!EmailValidate(email)) || (address == '') || (city == '') || (postalCode == '')
                                || (country == '') || (region == '')) || (!PostalCodeValidator(postalCode, country))} onClick={() => setSection("admin")}>
                                <span >{t('next')}</span>
                                <FontAwesomeIcon icon="chevron-right" className="ms-2" />
                            </button>
                            <button className="btn float-start btn-github add-btn-text ms-4 " onClick={() => setSection("company")}>
                                <FontAwesomeIcon icon="chevron-left" className="me-2" />
                                <span >{t('previous')}</span>
                            </button>
                        </div>
                    </div>}
                    {section == "admin" && <div className=" mb-4 mt-2 ms-auto me-auto card shadow border-2" >
                        <div className=" card-header border-0 ">
                            <h2 className='mb-4 header-text'><FontAwesomeIcon icon="user" className="fs-3 me-2" />{t('register:adminDetails')}</h2>
                            <RequiredFields />
                        </div>
                        <div className="card-body bg-opacity-75 ">
                            <TextInputComponent mandatory value={firstName} label={t('user:firstName')} setText={setFirstName} />
                            <TextInputComponent mandatory value={lastName} label={t('user:lastName')} setText={setLastName} />
                            <PhoneNumberComponent phoneNumber={currentAdminPhoneNumber} extension={currentAdminExtension} typeOption={currentAdminPhoneTypeOption} setPhoneNumber={setCurrentAdminPhoneNumber}
                                setExtension={setCurrentAdminExtension} setTypeOption={setCurrentAdminTypeOption} />
                            <div className="form-group row mb-4">
                                <div className="col-1 d-none d-sm-block"></div>
                                <label className="col-4 col-sm-2  col-form-label text-begin ms-3" >
                                    <h3 className="d-none d-sm-block">{t('user:language')}</h3>
                                    <h3 className="d-block d-sm-none fs-4">{t('user:language')} <FontAwesomeIcon icon="star-of-life" className="fs-5 mt-2 d-inline d-sm-none text-center" /></h3>
                                </label>
                                <div className="col-7 col-sm-5">
                                    <Select placeholder={t("selectLanguage")} onChange={(e) => setLanguage(e?.value)} className="form-select p-0" styles={SelectStyle({ border: 'none' })} options={languageOptions} />
                                </div>
                                <div className="col-1 d-none d-sm-block">
                                    <FontAwesomeIcon icon="star-of-life" className="fs-5 mt-2" />
                                </div>
                            </div>
                            <div className="form-group row mb-4">
                                <div className="col-1 d-none d-sm-block"></div>
                                <label className="col-4 col-sm-2  col-form-label text-begin ms-3" >
                                    <h3 className="d-none d-sm-block">{t('user:timeZone')}</h3>
                                    <h3 className="d-block d-sm-none fs-4">{t('user:timeZone')} <FontAwesomeIcon icon="star-of-life" className="fs-5 mt-2 d-inline d-sm-none text-center" /></h3>
                                </label>
                                <div className="col-7 col-sm-5">
                                    <TimezoneSelect placeholder={t("selectTimezone")} value={timezone} onChange={(e) => setTimezone(e.value)} />
                                </div>
                                <div className="col-1 d-none d-sm-block">
                                    <FontAwesomeIcon icon="star-of-life" className="fs-5 mt-2" />
                                </div>
                            </div>
                            <TextInputComponent mandatory value={adminEmail} label={t('user:emailAddress')} setText={setAdminEmail} validator={EmailValidate} validateText={"This is not a valid e-mail address."} />
                            <PasswordComponent mandatory  labelText={t('user:newPassword')} password={password} setNewPassword={setPassword} />
                            <PasswordComponent mandatory labelText={t('user:confirmPassword')} password={confirmPassword} setNewPassword={setConfirmPassword} />
                            <div className="form-group" style={{ display: confirmPassword != password ? 'block' : 'none' }} >
                                <p className="text-danger text-center">{t('user:passwordNotMatch')}</p>
                            </div>
                            <div className="form-group" style={{ display: confirmPassword == password &&  password != "" &&  password.length < 6 ? 'block' : 'none' }} >
                                <p className="text-danger text-center">{t('user:passwordLengthError')}</p>
                            </div>
                        </div>
                        <div className=" mb-4 pe-5">
                            <button className="btn float-end btn-github add-btn-text " disabled={(password == '') || (timezone == '') || (confirmPassword != password) 
                            || (!EmailValidate(adminEmail)) || language == "" || firstName == "" || lastName == "" || password.length < 6}
                                onClick={() => setSection("addUsers")}>
                                <span >{t('next')}</span>
                                <FontAwesomeIcon icon="chevron-right" className="ms-2" />
                            </button>
                            <button className="btn float-start btn-github add-btn-text ms-4 " onClick={() => setSection("contact")}>
                                <FontAwesomeIcon icon="chevron-left" className="me-2" />
                                <span >{t('previous')}</span>
                            </button>
                        </div>
                    </div>}
                    {
                        section == "addUsers" &&
                        <div className=" mb-4 mt-2 ms-auto me-auto card shadow border-2">
                            <div className=" card-header border-0 ">
                                <h2 className='mb-4 header-text'><FontAwesomeIcon icon="users" className="fs-3 me-2" />{t('register:addUsers')}</h2>
                            </div>
                            <div className="card-body bg-opacity-75 ">
                                <AddUserComponent addUsersList={addUserList} setAddUsersList={setAddUsersList} language={language!} timezone={timezone} adminEmail={adminEmail} />
                            </div>
                            <div className=" mb-4 pe-5">
                                <button className="btn float-end btn-flickr add-btn-text " onClick={() => RegisterCompany()}>
                                    <span >{t('register')}</span>
                                </button>
                                <button className="btn float-start btn-github add-btn-text ms-4 " onClick={() => setSection('admin')}>
                                    <FontAwesomeIcon icon="chevron-left" className="me-2" />
                                    <span >{t('previous')}</span>
                                </button>
                            </div>
                        </div>
                    }
                    {
                        section == 'Finish' &&
                        <div className=" w-25 ms-auto me-auto">
                            <div className="card-header align-middle row">
                                <h1 className="text-center col-12">{t('register:registrationComplete')}</h1>
                                <div className="col-12 text-center">
                                    <button className="btn btn-flickr add-btn-text mt-3" onClick={() => navigate("/")}>
                                        <span >{t('logIn')}</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            }
            {loading && <LoadingPageComponent />}
        </div>


    )
}