import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react'
import API from '../../common/API';
import { CompanyFeatures, DeploymentDTO, Features, OptionType, Roles } from '../../common/store';
import LoadingPageComponent from '../../components/shared/LoadingPageComponent';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAuth } from 'react-oidc-context';
import PaginationListComponent from '../../components/shared/PaginatedListComponent';
import { PaginationComponent } from '../../components/shared/PaginationComponent';
import { LinkContainer } from 'react-router-bootstrap';
import { listenerCount } from 'process';
import { useTranslation } from 'react-i18next';
import Select, { StylesConfig } from 'react-select'
import { SelectStyle } from '../../common/SelectStyle';
import SelectComponent from '../../components/shared/SelectComponent';
import useAppSelector from '../../hooks/useAppSelector';
import { getCompanyFeatureGate, getFeatureGate, getHostedDomain, getHostedOdysseyDomain } from '../../redux/slices/config';

function DeploymentList() {

  const [deployList, setDeployList] = useState<DeploymentDTO[]>([]);
  const { t, i18n } = useTranslation()
  const auth = useAuth()
  const userIdentifier = auth.user?.profile.client_id
  const sysAdmin: boolean = auth.user?.profile.role == "SysAdmin"
  const [loading, setLoading] = useState<boolean>(true)
  const companyFeatureGate: CompanyFeatures = useAppSelector(getCompanyFeatureGate)
  const featureGate: Features = useAppSelector(getFeatureGate)

  const hostedDomain: string = useAppSelector(getHostedDomain)
  const hostedOdysseyDomain: string = useAppSelector(getHostedOdysseyDomain)

  const [manageDeployment, setManageDeployment] = useState<boolean>(false)

  const licenseOption: OptionType[] = [{ i1: 50, i2: 1, label: t("package:essentials"), value: '' }, { i1: 100, i2: 2, label: t("package:small"), value: '' }, { i1: 250, i2: 4, label: t("package:mid"), value: '' },
  { i1: 400, i2: 256, label: t("package:large"), value: '' }, { i1: 750, i2: 8, label: t("package:enterprise"), value: '' }]

  const header: OptionType[] = [
    { value: "name", label: t('name'), s2: ".myprotectornet.com", s1: "subdomain", i1: 1 },
    { value: "type", label: t('license:type'), i1: 1 },
    { value: "licenseTier", label: t('system'), i1: 1 },
    { value: "status", label: t('status'), i1: 1 },
    { value: "createdTime", label: t('createdOn'), i1: 1 },
  ]

  const hostedheader: OptionType[] = [
    { value: "name", label: t('name'), s2: ".myprotectornet.com", s1: "subdomain", i1: 1 },
    { value: "type", label: t('license:type'), i1: 1 },
    { value: "licenseTier", label: t('system'), i1: 1 },
    { value: "status", label: t('status'), i1: 1 },
    { value: "createdTime", label: t('createdOn'), i1: 1 },
    { value: "nextPaymentTime", label: t('deployment:renewalDate'), i1: 1 },
  ]

  const licenseheader: OptionType[] = [
    { value: "name", label: t('name'), s2: ".myprotectornet.com", s1: "subdomain", i1: 1 },
    { value: "accountNumber", label: t("license:accountNumber"), i1: 1 },
    { value: "type", label: t('license:type'), i1: 1 },
    { value: "licenseTier", label: t('system'), i1: 1 },
    { value: "status", label: t('status'), i1: 1 },
    { value: "createdTime", label: t('createdOn'), i1: 1 },
    { value: "expirationDate", label: t('license:expirationDate'), i1: 1 },
  ]

  const header_sm: OptionType[] = [
    { value: "name", label: t('name'), s2: ".myprotectornet.com", s1: "subdomain", i1: 1 },
    { value: "type", label: t('license:type'), i1: 1 },
    { value: "status", label: t('status'), i1: 1 },
  ]

  const [perPage, setPerPage] = useState<number>(10)
  const [pageNumber, setPageNumber] = useState<number>(1)
  const [maxPageNumber, setMaxPageNumber] = useState<number>(0)
  const [pages, setPages] = useState<number[]>([])
  const [searchValue, setSearchValue] = useState<string>('')
  const [orderBy, setOrderBy] = useState<string>('createdTime')
  const [orderDirection, setOrderDirection] = useState<string>('Desc')

  const buildFilterString = (filter: string) => {
    if (searchType == 'AccountNumber' && filterType == "Licensed")
      return 'AccountNumber.ToString().Contains(' + encodeURI('"' + filter.toLowerCase() + '"') + ')'
    return 'Name.ToLower().Contains(' + encodeURI('"' + filter.toLowerCase() + '"') + ')'
  }
  const filterOptions: OptionType[] = featureGate.onlineDeployments ?
    featureGate.hostedDeployments ?
      [{ value: 'All', label: t('all') }, { value: 'Licensed', label: t('licensed') }, { value: 'Hosted', label: t('hosted') }, { value: 'Online', label: t('online') }]
      : [{ value: 'All', label: t('all') }, { value: 'Licensed', label: t('licensed') }, { value: 'Online', label: t('online') }]
    : featureGate.hostedDeployments ?
      [{ value: 'All', label: t('all') }, { value: 'Licensed', label: t('licensed') }, { value: 'Hosted', label: t('hosted') }]
      : [{ value: 'All', label: t('all') }]

  const orderDirections: OptionType[] = [{ value: 'Asc', label: t('Asc') }, { value: 'Desc', label: t('Desc') }]

  const [filterType, setFilterType] = useState<string | undefined>("All")

  const searchOptions: OptionType[] = [{ value: 'Name', label: t("name") }, { value: 'AccountNumber', label: t("account") + " #" }]
  const [searchType, setSearchType] = useState<string>("Name")

  useEffect(() => {
    if (searchType == "AccountNumber") {
      if (filterType == "All" || filterType == "Hosted")
        setFilterType('Licensed')
    }

  }, [searchType])

  useEffect(() => {
    const getRoles = async () => {
      try {
        const response: any = await API.get(`/api/v1/user/roles/${userIdentifier}`, auth)
        const data: Roles = response.result
        setManageDeployment(data.manageDeployments || data.manageHostedDeployments)
      }
      catch (e: any) {
        alert(e.errors?.Error!)
      }
    }
    getRoles()
  }, [])


  useEffect(() =>{
    getDeployments()
  }, [pageNumber])

  useEffect(() => {
    try {
      if (filterType == "All" || filterType == "Hosted")
        setSearchType("Name")
      if (pageNumber == 1)
        getDeployments()
      else
        setPageNumber(1)
    }
    catch (e: any) {
      alert(e.errors?.Error!)
    }
  }, [ searchValue, filterType, orderBy, orderDirection])

  const getDeployments = async () => {
    try {
      const url = filterType == "All" ? "/api/v1/deployment" : "/api/v1/deployment/type/" + filterType
      const response: any = await API.get(`${url}?PerPage=${perPage}&PageNumber=${pageNumber}&Filter=${buildFilterString(searchValue)}&OrderBy=${orderBy} ${orderDirection}`, auth);
      setMaxPageNumber(Math.ceil(response.totalRecords / perPage))
      const pageArray: number[] = []
      for (let index = 1; index <= Math.ceil(response.totalRecords / perPage); index++) {
        pageArray.push(index)
      }
      setPages(pageArray)
      setLoading(false)
      if (response.results == null)
        response.results = []
      response.results.map((e: DeploymentDTO) => {
        e.createdTime = e.createdOn
        e.nextPaymentTime = e.nextPayment
        e.package = licenseOption.find(x => x.i2 == e.package)?.label!
        e.type = t(`deployment:${e.type.toLowerCase()}`)
        e.status = t(`deployment:${e.status.toLowerCase()}`)
        e.licenseTier = e.licenseTier == "Unactivated" ? t(`deployment:${e.licenseTier.toLowerCase()}`) : e.licenseTier
        if (e.subdomain != null) {
          e.subdomain = e.subdomain + (e.licenseTier == "Odyssey" ? hostedOdysseyDomain : hostedDomain)
        }
      })
      setDeployList(response.results)
    }
    catch (e: any) {
      alert(e.errors?.Error!)
    }
  };

  const firstUpdate = useRef(true);
  useEffect(() => {
    if (i18n.language == "undefined")
      return
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    getDeployments()
  }, [i18n.language])

  return (
    <div>
      <div>
        <div className="w-100 ms-auto me-auto " >
          <div className=" pb-0 ">
            {manageDeployment && <LinkContainer to="/deployment/add">
              <button className="btn btn-primary float-end">
                <h6 style={{ color: "white" }} className="d-inline">{t('deployment:addDeployment')}</h6>
                <FontAwesomeIcon icon="plus" className=" ms-2 mt-1" />
              </button>
            </LinkContainer>}
            <h1 className=' mb-4 ms-1  header-text'><FontAwesomeIcon icon="tablet-screen-button" className="fs-3 me-2" />{t('deployments')}</h1>

          </div>
        </div>
        {loading && <LoadingPageComponent />}
        {!loading &&
          <div className='card w-100 ms-auto me-auto '>
            <div className="card-header bg-transparent row pb-0">
              <div className="col-12 col-sm-2">
                <SelectComponent className="form-select mb-3" options={filterOptions} onChange={(e: any) => { setFilterType(e.target.value);; setPageNumber(1) }} value={filterOptions.find(e => e.value == filterType)?.value} />
              </div>
              <div className="col-1">
                {/* <SelectComponent className="form-select mb-3" options={orderDirections} onChange={(e:any) => {setOrderDirection(e.target.value);;setPageNumber(1)}} value={orderDirections.find(e => e.value == orderDirection)?.value} /> */}
              </div>
              <div className="col-5 col-sm-5"></div>
              <form className="col-12 col-sm-4">
                <div className=" row" style={{ border: "1px solid white" }}>
                  <div className=' col-3 pe-0 ps-0 '>
                    <div className='input-group-navbar input-group border-2 border-dark' style={{ minWidth: '0px' }}>
                      <select style={{ height: 'calc(2.24375rem + 2px)', fontSize: "0.75rem" }} value={searchOptions.find(e => e.value == searchType)?.value} className={"form-select "} onChange={(e: any) => { setSearchType(e.target.value);; setPageNumber(1) }} >
                        {
                          searchOptions.map((e: OptionType) => (
                            <option value={e.value} label={e.label} key={Math.random()}></option>
                          ))
                        }
                      </select>

                    </div>
                  </div>
                  <div className='col-9 ps-0 pe-0'>
                    <div className='input-group-navbar input-group'>
                      <input className=" float-end form-control input-text" placeholder={t("deployment:deploymentSearch")} type="text" value={searchValue} onChange={(e) => setSearchValue(e.target.value)} />
                      <button className="btn">
                        <FontAwesomeIcon icon="magnifying-glass" className=" ms-2" />
                      </button>
                    </div>
                  </div>

                </div>
              </form>
            </div>
            <div className='d-none d-sm-block'>
              {filterType == "All" && <PaginationListComponent
                isEditable={manageDeployment}
                isRemovable={manageDeployment}
                list={deployList}
                headerValues={header}
                editLink={"/deployment/"}
                repopulate={getDeployments}
                deleteString={"/api/v1/deployment/unregister/"}
                label={t('deployment')}
                setOrderBy={setOrderBy}
                orderBy={orderBy}
                orderDirection={orderDirection}
                setOrderDirection={setOrderDirection} />}
              {filterType == "Hosted" && <PaginationListComponent
                isEditable={manageDeployment}
                isRemovable={manageDeployment}
                list={deployList}
                headerValues={hostedheader}
                editLink={"/deployment/"}
                repopulate={getDeployments}
                deleteString={"/api/v1/deployment/unregister/"}
                label={t('deployment')}
                setOrderBy={setOrderBy}
                orderBy={orderBy}
                orderDirection={orderDirection}
                setOrderDirection={setOrderDirection} />}
              {(filterType == "Licensed" || filterType == "Online") && <PaginationListComponent
                isEditable={manageDeployment}
                isRemovable={manageDeployment}
                list={deployList}
                headerValues={licenseheader}
                editLink={"/deployment/"}
                repopulate={getDeployments}
                deleteString={"/api/v1/deployment/unregister/"}
                label={t('deployment')}
                setOrderBy={setOrderBy}
                orderBy={orderBy}
                orderDirection={orderDirection}
                setOrderDirection={setOrderDirection} />}
            </div>
            <div className='d-sm-none'>
              <PaginationListComponent
                isEditable={manageDeployment}
                isRemovable={manageDeployment}
                list={deployList}
                headerValues={header_sm}
                editLink={"/deployment/"}
                repopulate={getDeployments}
                deleteString={"/api/v1/deployment/unregister/"}
                label={t('deployment')} />
            </div>

            <PaginationComponent
              pageNumber={pageNumber}
              pages={pages}
              maxPageNumber={maxPageNumber}
              setPageNumber={setPageNumber} />
          </div>
        }
      </div>
    </div>
  )
}

export default DeploymentList