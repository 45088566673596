import React, { useState } from "react";
import { useAuth } from "react-oidc-context";
import { THEME } from "../../constants";
import useTheme from "../../hooks/useTheme";

export default function({setThemeVal}: {setThemeVal: (value: React.SetStateAction<string>) => void}){
    const { theme, setTheme } = useTheme();
    const themeOptions = [
        {
          name: "Default",
          value: THEME.DEFAULT,
        },
        {
          name: "Colored",
          value: THEME.COLORED,
        },
        {
          name: "Dark",
          value: THEME.DARK,
        },
        {
          name: "Light",
          value: THEME.LIGHT,
        },
      ];
    const auth = useAuth()
    const [themeDropdown, setThemeDropdown] = useState<boolean>(false)

    const handleOutsideClick = (event:any) => {
        if(event.target.id != "themeDropdown")
            setThemeDropdown(false)
    }
    document.addEventListener("click", handleOutsideClick);
    return(
        <div id='themeDropdown' className='ms-2 nav-item dropdown'>
            <a id='themeDropdown' className='nav-link nav-flag dropdown-toggle' aria-expanded="false" onClick={() => setThemeDropdown(!themeDropdown)}>
                {theme == themeOptions[0].value && <div id='themeDropdown' className="settings-scheme-theme settings-scheme-theme-default col-sm-6 mt-2" style={{width:'25px', height:'25px'}}></div>}
                {theme == themeOptions[3].value && <div id='themeDropdown' className="settings-scheme-theme settings-scheme-theme-light col-sm-6 mt-2" style={{width:'25px', height:'25px'}}></div>}
                {theme == themeOptions[2].value && <div id='themeDropdown' className="settings-scheme-theme settings-scheme-theme-dark col-sm-6 mt-2" style={{width:'25px', height:'25px'}}></div>}
            </a>
            <div id='themeDropdown' className={themeDropdown ?'dropdown-menu dropdown-menu-end show ' : 'dropdown-menu dropdown-menu-end'}>
                <a id='themeDropdown' className='dropdown-item' role="button" onClick={() => {setTheme(themeOptions[0].value); setThemeDropdown(!themeDropdown); setThemeVal(themeOptions[0].name)}}>
                    <div className="settings-scheme-theme settings-scheme-theme-default col-sm-6" style={{width:'25px', height:'25px'}}>
                        <span id='themeDropdown' className="align-middle ms-5">{themeOptions[0].name}</span>
                    </div>
                </a> 
                <a id='themeDropdown' className='dropdown-item ' role="button" onClick={() => {setTheme(themeOptions[3].value); setThemeDropdown(!themeDropdown); setThemeVal(themeOptions[3].name)}}>
                    <div className="settings-scheme-theme settings-scheme-theme-light col-sm-6" style={{width:'25px', height:'25px'}}>
                        <span id='themeDropdown' className="align-middle ms-5">{themeOptions[3].name}</span>
                    </div>
                </a> 
                <a id='themeDropdown' className='dropdown-item' role="button" onClick={() => {setTheme(themeOptions[2].value); setThemeDropdown(!themeDropdown); setThemeVal(themeOptions[2].name)}}>
                    <div className="settings-scheme-theme settings-scheme-theme-dark col-sm-6" style={{width:'25px', height:'25px'}}>
                        <span id='themeDropdown' className="align-middle ms-5">{themeOptions[2].name}</span>
                    </div>
                </a> 
            </div>
        </div>
    )
}