import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { t } from "i18next";
import React from "react";
import { useNavigate } from "react-router-dom";
import { OnlineStatusInfo } from "../../common/store";

export default function({onlineStatusInfo, showArray, setShowArray, allOnlineStatusInfo}:
    {onlineStatusInfo:OnlineStatusInfo[], showArray:boolean[], setShowArray: Function, allOnlineStatusInfo?:OnlineStatusInfo[]}){
    const navigate = useNavigate();
    
    const clickHandler = (statusInfo:OnlineStatusInfo) => {
        const index:number|undefined = allOnlineStatusInfo != null? allOnlineStatusInfo?.indexOf(statusInfo) :onlineStatusInfo?.indexOf(statusInfo)
        const value = !showArray[index!]
        const allItems:boolean[] = JSON.parse(JSON.stringify(showArray));
        allItems[index!] = value
        setShowArray(allItems)
        return
    }
    return(
        <div>
            {onlineStatusInfo?.map((statusInfo:OnlineStatusInfo) => {
                return (
                    <div key={statusInfo.deploymentName} className="accordion-item" onClick={() => clickHandler(statusInfo)}>
                        <div className="accordion-header">
                            <button type="button" className={showArray[allOnlineStatusInfo != null ? allOnlineStatusInfo.indexOf(statusInfo) :onlineStatusInfo.indexOf(statusInfo)] ? "accordion-button bg-light " : "accordion-button collapsed "}>
                                <a className="fs-4 d-none d-sm-block col-6 text-black"  >
                                    <FontAwesomeIcon icon="tablet-screen-button" className="fs-4 me-2" onClick={() => navigate(`/deployment/${statusInfo.odysseyInfo.deploymentId}`)} />
                                    <span onClick={() => navigate(`/deployment/${statusInfo.odysseyInfo.deploymentId}`)}>{statusInfo.deploymentName}</span>
                                </a>
                                <a className="fs-5 col-6 text-black d-block d-sm-none"  >
                                    <FontAwesomeIcon icon="tablet-screen-button" className="fs-4 me-2" onClick={() => navigate(`/deployment/${statusInfo.odysseyInfo.deploymentId}`)} />
                                    <span onClick={() => navigate(`/deployment/${statusInfo.odysseyInfo.deploymentId}`)}>{statusInfo.deploymentName}</span>
                                </a>
                                <div className="col-sm-4"></div>
                                {allOnlineStatusInfo == null && 
                                <div>
                                    <span className={`float-start badge mt-1 fs-5 disabled   d-none d-sm-block ${statusInfo.currentlyConnected ? 'btn-vimeo' : 'btn-youtube'} `}>
                                        {statusInfo.currentlyConnected ? `${t("connected")}` : `${t("disconnected")}`}
                                    </span>
                                    <span className={`float-end ms-3 d-block d-sm-none badge mt-1  disabled  col-sm-1 ${statusInfo.currentlyConnected ? 'btn-vimeo' : 'btn-youtube'} `}>
                                        {statusInfo.currentlyConnected ? `${t("connected")}` : `${t("disconnected")}`}
                                    </span>
                                </div>
                                
                                }
                            </button>
                        </div>
                        <div className={showArray[allOnlineStatusInfo != null ? allOnlineStatusInfo.indexOf(statusInfo) :onlineStatusInfo.indexOf(statusInfo)] ? "accordion-collapse collapse show" : "accordion-collapse collapse"}>
                            <div className="accordion-body bg-white">
                                <div className="row">
                                    <div className="col-sm-2 text-center">
                                        <FontAwesomeIcon className="stat ms-2" style={{width:'24px', height:'24px'}} icon="tablet-button" />
                                        <div className="flex-grow-1">
                                            <h4 className="mt-3">{`${statusInfo.odysseyInfo.panelCount} ${t("deployment:panels")}`}</h4>
                                        </div>
                                    </div>
                                    <div className="col-sm-2 text-center">
                                        <FontAwesomeIcon className="stat ms-2" style={{width:'24px', height:'24px'}} icon="users" />
                                        <div className="flex-grow-1">
                                            <h4 className="mt-3">{`${statusInfo.odysseyInfo.userCount} ${t("users")}`}</h4>
                                        </div>
                                    </div>
                                    <div className="col-sm-2 text-center">
                                        <FontAwesomeIcon className="stat ms-2" style={{width:'24px', height:'24px'}} icon="door-closed" />
                                        <div className="flex-grow-1">
                                            <h4 className="mt-3">{`${statusInfo.odysseyInfo.doorCount} ${t("license:doors")}`}</h4>
                                        </div>
                                    </div>
                                    <div className="col-sm-2 text-center">
                                        <FontAwesomeIcon className="stat " style={{width:'24px', height:'24px'}} icon="person-shelter" />
                                        <h4 className="mt-3 " >{`${statusInfo.odysseyInfo.partitionCount} ${t("license:partitions")}`}</h4>
                                    </div>
                                    <div className="col-sm-4 text-center ">
                                        <h4 className="mt-5">{`${t("lastUpdatedOn")} : ${new Date(statusInfo.odysseyInfo.lastUpdatedOn.toString()!).toLocaleString()!}`}</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            })}
        </div>
    )
}