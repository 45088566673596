import { t } from "i18next";
import React from "react";
import { AuthContextProps } from "react-oidc-context";
import API from "../API";
import { Invoice, OptionType } from "../store";
import { GetFeatures } from "./GetFeatures";


export default async function(auth:AuthContextProps, deploymentId:number, packageType:number, licenseTerm:string, multicard:number,
    twentyPartitions:number, fivePartitions:number, tenPartitions:number, renew:string|undefined, online: string|undefined, registrationKey:string,
    setPaymentDetails:Function, setInvoice:Function, setLoading:Function, setSection:Function, licenseTier:number
    ){
    var object = {
        Method: 4,
        DeploymentId: deploymentId,
        Package: packageType,
        Duration: Number(licenseTerm),
        Features: multicard + twentyPartitions + fivePartitions + tenPartitions,
        Demo: false,
        Description: '',
        LicenseId: null,
        Renew: renew != null,
        RequestKey: online != null ? "" : registrationKey,
        TierType: licenseTier,
        OnlineLicensing: online != null ? true : false
    }
    
    const licenseOption:OptionType[] = [{i1: 50, i2: 1, label: t("package:essentials"), value:''}, {i1: 100, i2: 2, label: t("package:small"), value:''}, {i1: 250, i2: 4, label: t("package:mid"), value:''},
    {i1: 400, i2: 256, label: t("package:large"), value:''}, {i1: 750, i2: 8, label: t("package:enterprise"), value:''}]

    try {
        const response: Invoice|null = await API.post(`api/v1/license/invoice`,object, auth)
        const packageName:string|undefined = licenseOption.find(e => e.i2 == packageType)?.label!

        var details:OptionType[] = [
            {label:t('license:package'), value: packageName!},
            {label:t('license:licenseFeatures'), value: GetFeatures(object.Features, packageType)},
            {label: t('license:licenseTerm'), value: licenseTerm == '12' ? `1 ${t('years')}`:`5 ${t('years')}`}, 
            {label: t('license:multiCard'), value: (multicard == 16 ).toString()},
        ]
        setPaymentDetails({title: 'License Purchase', details: details })
        setInvoice(response)
        setLoading(false)
        setSection("payment")
    }
    catch (e: any) {
        setLoading(false)
        alert(e.errors?.Error!)
    }
}