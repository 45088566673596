declare const window: any;

export const loadConverge = (callback: any) => {
    const existingScript = document.getElementById('payWithConverge');
    if (!existingScript) {
        const script = document.createElement('script');
        script.src = 'https://api.convergepay.com/hosted-payments/PayWithConverge.js';
        script.id = 'payWithConverge';
        script.async = true
        document.body.appendChild(script);
        script.onload = () => {
            if (callback) callback();

        };
    }
    if (existingScript && callback) callback();
}

export const loadConvergeDemo = (callback: any) => {
    const existingScript = document.getElementById('payWithConverge');
    if (!existingScript) {
        const script = document.createElement('script');
        script.src = 'https://demo.convergepay.com/hosted-payments/PayWithConverge.js';
        script.id = 'payWithConverge';
        script.async = true
        document.body.appendChild(script);
        script.onload = () => {
            if (callback) callback();

        };
    }
    if (existingScript && callback) callback();
}

