import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Features, OptionType } from "../../common/store";
import { getFeatureGate } from "../../redux/slices/config";
import useAppSelector from "../../hooks/useAppSelector";
import { useNavigate } from "react-router-dom";
import TextInputComponent from "../../components/shared/TextInputComponent";
import { useAuth } from "react-oidc-context";
import RadioComponent from "../../components/shared/RadioComponent";
import API from "../../common/API";

export default function RegistrationKey() {
    const { t } = useTranslation();
    const navigate = useNavigate()
    const auth = useAuth()
    
    const featureGate: Features = useAppSelector(getFeatureGate)

    
    const [initRegistrationKey, setInitRegistrationKey] = useState<string>("")
    const [initAccountNumber, setInitAccountNumber] = useState<string>("")

    const [responseKey, setResponseKey] = useState<string>("")
    const [registrationKey, setRegistrationKey] = useState<string>("")
    const [accountNumber, setAccountNumber] = useState<string>("")
    const [licenseTier, setLicenseTier] = useState<number>(0)

    const _licenseTiers:OptionType[] =[{label:"ProtectorNet", value:"0"}, {label:"Odyssey", value:"1"}]

    useEffect(() => {
        if (featureGate.devPayment == false)
        {
            navigate(`/*`)
        }  
    },[])


    const getRegistrationKey = async () => {
        try {
            const response: any = await API.get(`/api/v1/registrationKey/${initAccountNumber}/${licenseTier}`, auth)
            setInitRegistrationKey(response)
        }
        catch (e: any) {
          alert(e.errors?.Error!)
        }
    }

    const getResponseKey = async () => {
        try {
            const response: any = await API.get(`/api/v1/responseKey/${accountNumber}/${licenseTier}/${registrationKey}`, auth)
            setResponseKey(response)
        }
        catch (e: any) {
          alert(e.errors?.Error!)
        }
    }

    return(
        <div>
            <div className="w-100 ms-auto me-auto card" >
                <div className="card-header align-middle">
                    <h2 className='mb-4 header-text'><FontAwesomeIcon icon="key" className="fs-3 me-2" />{"Get Registration Key"}</h2>
                </div>
                <div className="card-body">
                    <RadioComponent radioClass={"col-7 col-sm-4 col-form-label mb-4"} labelClass={"col-2 col-form-label"} label={t("system")} options={_licenseTiers} 
                    currentVal={licenseTier.toString()} setCurrentVal={setLicenseTier} />

                    <TextInputComponent value={initAccountNumber} label={t('license:accountNumber')} setText={setInitAccountNumber} />
                    <TextInputComponent appendClass="btn btn-outline-secondary" onClick={() => {navigator.clipboard.writeText(initRegistrationKey)}} append={"copy"} 
                                    value={initRegistrationKey} label={t('license:registrationKey')} labelClass={"col-8 col-sm-2 col-form-label  mb-4 ms-3"} inputClass={"col-12 col-sm-5"} noEdit />
                </div>
                <div className="card-footer" >
                    <button className="btn btn-dribbble float-end ms-2" disabled={initAccountNumber == ""} onClick={() => getRegistrationKey()}>Get Registration Key</button>
                </div>
            </div>
            <div className="w-100 ms-auto me-auto card" >
                <div className="card-header align-middle">
                    <h2 className='mb-4 header-text'><FontAwesomeIcon icon="key" className="fs-3 me-2" />{"Get Response Key"}</h2>
                </div>
                <div className="card-body">
                    
                    <RadioComponent radioClass={"col-7 col-sm-4 col-form-label mb-4"} labelClass={"col-2 col-form-label"} label={t("system")} options={_licenseTiers} 
                    currentVal={licenseTier.toString()} setCurrentVal={setLicenseTier} />
                    <TextInputComponent value={accountNumber} label={t('license:accountNumber')} setText={setAccountNumber} />
                    <TextInputComponent mandatory value={registrationKey} label={t('license:registrationKey')} setText={setRegistrationKey}
                        validator={() => /[A-Za-z0-9]{5}-[A-Za-z0-9]{5}-[A-Za-z0-9]{5}-[A-Za-z0-9]{5}-[A-Za-z0-9]{5}/.test(registrationKey)} validateText={"Invalid Request Key"} upperCase/>
                    <TextInputComponent appendClass="btn btn-outline-secondary" onClick={() => {navigator.clipboard.writeText(responseKey)}} append={"copy"} 
                                    value={responseKey} label={t('license:responseKey')} labelClass={"col-8 col-sm-2 col-form-label  mb-4 ms-3"} inputClass={"col-12 col-sm-5"} noEdit />
                </div>
                <div className="card-footer" >
                    <button className="btn btn-success float-end ms-2" disabled={ registrationKey == "" || !(/[A-Za-z0-9]{5}-[A-Za-z0-9]{5}-[A-Za-z0-9]{5}-[A-Za-z0-9]{5}-[A-Za-z0-9]{5}/.test(registrationKey))}
                        onClick={() => getResponseKey()}>Get Response Key</button>
                </div>
            </div>
        </div>
    )
}