import { t } from 'i18next';
import React, { Component,ReactElement, useEffect, useState } from 'react';
import { useAuth } from 'react-oidc-context';
import { LinkContainer } from 'react-router-bootstrap';
import { useLocation } from 'react-router-dom';
import Footer from './Footer';
import { NavMenu } from './NavMenu';
import Sidebar from './Sidebar';


export function Layout({children}: {children: ReactElement}) {
  
    const [toggle, setToggle] = useState<boolean>(true)
    const themeData:string|null = localStorage.getItem('theme')
    const [theme, setTheme] = useState<string>(themeData == null ? "Default" :themeData!.substring(1, themeData!.length-1))

    return (
      <div className='wrapper'>
        <Sidebar theme={theme} setTheme={setTheme} toggle={toggle}/>
          <div className='main' >
            <NavMenu toggle={toggle} setToggle={setToggle} setTheme={setTheme}  />
            <div className="content">
              {children}
            </div>
            <Footer/>
          </div>
        {/* {toggle && <Sidebar/>} */}
      </div>
    );
  
}
export interface IStateProps{
  children: ReactElement
}

export const LayoutProvider = ({children} : IStateProps): ReactElement => (
  <Layout children={children} />
)
