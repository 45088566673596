import React, { useEffect, useRef, useState } from "react";
import { useAuth } from "react-oidc-context";
import API from "../common/API";
import { Activity, DashboardInfo, Features, LatestStatusUpdate } from "../common/store";
import customerPic from '../assets/images/CustomerSupport.png'
import { t } from "i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DeploymentTypesChart from "../components/dashboard/DeploymentTypesChart";
import { stat } from "fs";
import LoadingPageComponent from "../components/shared/LoadingPageComponent";
import InfoCard from "../components/dashboard/InfoCard";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import useAppSelector from "../hooks/useAppSelector";
import { getFeatureGate } from "../redux/slices/config";


export default function Dashboard(){

    const auth = useAuth()
    const [dashboardInfo, setDashboardInfo] = useState<DashboardInfo|null>()
    const [loading, setLoading] = useState<boolean>(true)
    const {i18n} = useTranslation()
    const navigate = useNavigate()
    const featureGate: Features = useAppSelector(getFeatureGate)

    useEffect(() =>{
        setLoading(true)
        getDashboardData() 
    }, [])

    const getDashboardData = async () => {
        try{
             setDashboardInfo(await API.get(`/api/v1/dashboardInfo?language=${i18n.language == "undefined" ? "en" : i18n.language}`, auth))
             setLoading(false)
        }
        catch(e:any){
            alert(e.errors?.Error!)
        }
    }
    const firstUpdate = useRef(true);
    useEffect(() => {
        if (i18n.language ==  "undefined")
          return
        if (firstUpdate.current) {
          firstUpdate.current = false;
          return;
        }
        getDashboardData()
      }, [i18n.language])

    return(
        <div className="">
            {loading && <LoadingPageComponent />}
            {!loading && <div className=" p-0">
                <div className="row">
                    <div className="d-flex col-xl-3 col-sm-6 col-md-6 ">
                        <div className="illustration flex-fill card ">
                            <div className="p-0 d-flex flex-fill card-body">
                                <div className="g-0 w-100 row">
                                    <div className="col-6">
                                        <div className="illustration-text p-3 m-1">
                                            <h4 className="illustration-text">{`${t("dashboard:welcomeBack")}, ${dashboardInfo?.userName}!`}</h4>
                                        </div>
                                    </div>
                                    <div className="align-self-end text-end col-6">
                                        <img className="img-fluid illustration-img" src={customerPic} ></img>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex col-xl-3 col-sm-6 col-md-6 ">
                        <InfoCard info={`${dashboardInfo?.numberOfTotalDeployments} ${t('deployments')}`} label={t("dashboard:totalDeployments")} icon={"tablet-screen-button"}/>
                    </div>
                    <div className="d-flex col-xl-3 col-sm-6 col-md-6 ">
                        <InfoCard info={`${dashboardInfo?.numberOfTotalDeployedDeployments} ${t('deployments')}`} label={t("dashboard:activeDeployments")} icon={"tablet-button"}/>
                    </div>
                    <div className="d-flex col-xl-3 col-sm-6 col-md-6 ">
                        <InfoCard info={`${dashboardInfo?.numberOfUsers} ${t('users')}`} label={t('dashboard:totalUsers')} icon={"users"}/>
                    </div>
                </div>
                {featureGate.onlineDeployments && <div className="flex-fill w-100 card border-bottom ">
                    <div className="card-header">
                        <h4>{`${t('dashboard:onlineDeploymentStatistics')}`}</h4>
                    </div>
                    <table className="my-0 table">
                        <thead>
                            <tr>
                                <th></th>
                                <th  className="d-table-cell h5">{`${t('dashboard:totalPanels')}`}</th>
                                <th className="d-table-cell h5">{`${t('dashboard:totalUsers')}`}</th>
                                <th className="d-table-cell h5">{`${t('dashboard:totalDoors')}`}</th>
                                <th className="d-table-cell h5">{`${t('dashboard:totalPartitions')}`}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr className="">
                                <td></td>
                                <td className="d-xl-table-cell"><h5>{`${dashboardInfo?.odysseyData.totalPanels} ${t("panel")}(s)`}</h5></td>
                                <td className="d-xl-table-cell"><h5>{`${dashboardInfo?.odysseyData.totalUsers} ${t('user')}(s)`}</h5></td>
                                <td className="d-xl-table-cell"><h5>{`${dashboardInfo?.odysseyData.totalDoors} ${t("door")}(s)`}</h5></td>
                                <td className="d-xl-table-cell"><h5>{`${dashboardInfo?.odysseyData.totalPartitions} ${t("partition")}(s)`}</h5></td>
                            </tr>
                        </tbody>
                    </table>
                </div>}
                <div className="row">
                    <div className={`d-flex ${featureGate.onlineDeployments ? "col-xxl-3" : "col-xxl-4"} col-xl-4 col-lg-6`}>
                        <DeploymentTypesChart dashboardInfo={dashboardInfo!} featureGate={featureGate} />
                    </div>
                    {featureGate.onlineDeployments && <div className="d-flex col-xl-4 col-lg-6">
                        <div className="flex-fill w-100 card">
                            <div className="card-header">
                                <h4>{`${t('dashboard:latestStatusUpdates')}`}</h4>
                            </div>
                            <div className="d-flex card-body w-100">
                                <ul className="timeline w-100">
                                    {dashboardInfo?.latestStatusUpdates.map((status:LatestStatusUpdate) =>(
                                            <li key={status.deploymentName} className="timeline-item mb-4">
                                                <div className=" text-danger float-end">
                                                    <FontAwesomeIcon className=" text-danger text-lg-center stat" style={{width:'24px', height:'24px'}} icon="heart-pulse" />
                                                </div>
                                                <a className="h4" onClick={() => navigate(`/deployment/${status.id}`)}>{status.deploymentName}</a>
                                                <p>{new Date(status.time.toString()!).toLocaleString()!}</p>
                                                <hr />
                                            </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>}
                    <div className={`d-flex ${featureGate.onlineDeployments ? "col-lg-5" : "col-lg-8"}`}>
                        <div className="flex-fill w-100 card">
                            <div className="card-header">
                                <span className="float-end badge bg-info">{`${t("dashboard:latest")}`}</span>
                                <h4>{`${t("dashboard:recentActivity")}`}</h4>
                            </div>
                            <div className="card-body">
                                {dashboardInfo?.latestActivities.map((activity:Activity) => (
                                    <div key={activity.id}>
                                        <div className="d-flex">
                                            <div className="flex-grow-1">
                                                <div className="float-end">
                                                    <FontAwesomeIcon className=" text-info text-lg-center stat" style={{width:'20px', height:'20px'}} icon="bolt" />
                                                </div>
                                                <small className="text-muted">{activity.time}</small>
                                                <h5>{activity.message}</h5>
                                            </div>
                                        </div>
                                        <hr></hr>
                                    </div>
                                    
                                ))
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>}
        </div>
    )
}