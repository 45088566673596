import { CreateSigninRequestArgs, User } from "oidc-client-ts";
import { useAuth } from "react-oidc-context";
import { Navigate, useLocation, useSearchParams } from "react-router-dom";

const arg: CreateSigninRequestArgs = {
  prompt: "login"
}

export function RequireAuth({ children }: { children: JSX.Element }) {
  let auth = useAuth();
  let location = useLocation();
  const [searchParams] = useSearchParams(); 
  console.log (location)
  if (!auth.isAuthenticated) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.

    
    var returnUrl = searchParams.get("ReturnUrl")
    if (returnUrl != null)
    {
      var referrer = document.referrer;
      if (returnUrl?.startsWith("#system/licensing"))
      {
        returnUrl = referrer + returnUrl
        location.search = "?ReturnUrl=" + encodeURIComponent(returnUrl)
      }
    }

    console.log(location)

    arg.state = location.pathname
    if(location.search != '')
      arg.state = arg.state + location.search
    console.log(arg)
    auth.signinRedirect(arg)
    
    return (
      <div>
      </div>
    );

  }
  return children;
}