import React from "react";
import { AuthContextProps } from "react-oidc-context";
import { NavigateFunction } from "react-router-dom";
import API from "../API";

export default async function(auth:AuthContextProps, deploymentId:number, numberOfPanels:number, hostedTerm:string, multicard:number,
    setLoading:Function, navigate:NavigateFunction, licenseTier:number, 
    numberOfDoors: number, numberOfElevators: number, numberOfIOExpanders: number, isInvoice?: boolean ){
        
    var object = { Id: deploymentId, NumOfPanels: numberOfPanels == 0 ? 1 : numberOfPanels, PackageTerm: Number(hostedTerm), MultiCardEnabled: multicard == 16, TierType:licenseTier
        , NumberOfDoors: numberOfDoors, NumberOfElevators: numberOfElevators, NumberOfIOExpanders: numberOfIOExpanders }
    setLoading(true)
    try {
        const url = isInvoice != null ? "api/v1/hosteddeployment/package/"+ isInvoice : "api/v1/hosteddeployment/package/"
        const response: any = await API.post(url, object, auth)
        setLoading(false)
        navigate(`/deployment/${deploymentId}`)
    }
    catch (e: any) {
        setLoading(false)
        alert(e.errors?.Error!)
    }
}