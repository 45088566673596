import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAuth } from "react-oidc-context";
import { LinkContainer } from "react-router-bootstrap";
import API from "../../common/API";
import {  GetUserRequest, OptionType} from "../../common/store";
import LoadingPageComponent from "../../components/shared/LoadingPageComponent";
import { ModalComponent } from "../../components/shared/ModalComponent";
import PaginationListComponent from "../../components/shared/PaginatedListComponent";
import { PaginationComponent } from "../../components/shared/PaginationComponent";
import useAppSelector from "../../hooks/useAppSelector";
import { isAdmin } from "../../redux/slices/config";

export default function Users(){

    const auth = useAuth()
    const {t, i18n} = useTranslation()
    const sysAdmin:boolean = useAppSelector(isAdmin)
    const [userList, setUserList] = useState<GetUserRequest[]>([])
    const values:OptionType[] = [{value: "firstName", label:t("user:firstName")}, {value: "lastName", label:t("user:lastName")},
    {value: "email", label:t("email")}, {value: "language", label:t("user:language")}, {value: "isAdmin", label:t("user:systemAdmin")}]

    const values_sm:OptionType[] = [{value: "firstName", label:t("user:firstName")}, {value: "lastName", label:t("user:lastName")},{value: "isAdmin", label:t("user:systemAdmin")}]

    const [loading, setLoading] = useState<boolean>(true)

    const [perPage, setPerPage] = useState<number>(20)
	const [pageNumber, setPageNumber] = useState<number>(1)
	const [maxPageNumber, setMaxPageNumber] = useState<number>(0)
	const [pages, setPages] = useState<number[]>([])
    const [searchValue, setSearchValue] = useState<string>('')


    useEffect(() => {
        getUsers() 
    }, [pageNumber])

    useEffect(() => {
        if (pageNumber == 1)
            getUsers() 
        else
            setPageNumber(1)
    }, [searchValue])

    const buildFilterString = (filter: string) => {
        return 'FirstName.ToLower().Contains('+encodeURI('"' + filter.toLowerCase() + '"') + ')'
    }
    
    const getUsers = async () => {
        try{
            const response:any = await API.get(`/api/v1/users?PerPage=${perPage}&PageNumber=${pageNumber}&Filter=${buildFilterString(searchValue)}`, auth)
            setLoading(false)
            setMaxPageNumber(Math.ceil(response.totalRecords/ perPage))
            const pageArray:number[] = []
				for (let index = 1; index <= Math.ceil(response.totalRecords/ perPage); index++){
					pageArray.push(index)
				}
				setPages(pageArray)
            setUserList(response.results)
        }
        catch(e:any){
            alert(e.errors?.Error!)
        } 
    }
    return(
        <div>
            {loading && <LoadingPageComponent/>}
            {!loading &&
            <div>
                <div className="w-100 ms-auto me-auto ">
                    <div className=" pb-0">
                        {sysAdmin && <LinkContainer to={'/user/add'}>
                            <button className="btn btn-primary float-end">
                                <h6 style={{color:"white"}} className="d-inline">{t('user:addUsers')}</h6>
                                <FontAwesomeIcon icon="plus"  className=" ms-2 mt-1"/>
                            </button>
                        </LinkContainer>}
                        <h1 className='mb-4 ms-1  header-text'><FontAwesomeIcon icon="users" className="fs-3 me-2" />{t('users')}</h1>
                    </div>
                </div>
                <div className="card w-100 ms-auto me-auto">
                    <div className="card-header bg-transparent row pb-0">
                        <div className="col-9"></div>
                        <form className="col-3">
                            <div className="float-end input-group-navbar input-group" style={{border:"1px solid white"}}>
                                <input className="float-end form-control input-text" placeholder={t("user:userSearch")} type="text" value={searchValue} onChange={(e) => setSearchValue(e.target.value)} />
                                <button className="btn">
                                    <FontAwesomeIcon icon="magnifying-glass"  className=" ms-2 mt-1"/>
                                </button>
                            </div>
                        </form>
                    </div>
                    <div className="d-none d-sm-block">
                        <PaginationListComponent label={t('user')} isEditable={sysAdmin} isRemovable={sysAdmin} list={userList} headerValues={values} 
                        editLink={"/user/edit/"} deleteString={`/api/v1/user/`} repopulate={getUsers} />
                    </div>
                    <div className="d-block d-sm-none">
                        <PaginationListComponent label={t('user')} isEditable={sysAdmin} isRemovable={sysAdmin} list={userList} headerValues={values_sm} 
                        editLink={"/user/edit/"} deleteString={`/api/v1/user/`} repopulate={getUsers} />
                    </div>
                    <PaginationComponent pageNumber={pageNumber} pages={pages} maxPageNumber={maxPageNumber} setPageNumber={setPageNumber} />
                </div>
            </div>}
        </div>
    )
}