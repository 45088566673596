import React, { useState } from "react";
import PhoneNumberComponent from "./PhoneNumberComponent";
import TextInputComponent from "./TextInputComponent";
import { validate as EmailValidate, validate } from 'email-validator';
import { useTranslation } from "react-i18next";
import { OptionType, PhoneNumberType } from "../../common/store";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import RequiredFields from "./RequiredFields";
import { ModalComponent } from "./ModalComponent";
import { faQuestion } from "@fortawesome/free-solid-svg-icons";

type Props = {
    website?: string,
    contactName?: string,
    email: string,
    currentPhoneNumber: string,
    currentPhoneTypeOption?: string | undefined,
    currentExtension?: string,
    phoneNumbers?: PhoneNumberType[],
    setWebsite?: (value: React.SetStateAction<string>) => void,
    setContactName?: (value: React.SetStateAction<string>) => void,
    setEmail: (value: React.SetStateAction<string>) => void,
    setCurrentExtension?: (value: React.SetStateAction<string>) => void,
    setCurrentTypeOption?: (value: React.SetStateAction<string | undefined>) => void,
    setCurrentPhoneNumber: (value: React.SetStateAction<string>) => void,
    setPhoneNumbers?: (value: React.SetStateAction<PhoneNumberType[]>) => void,
    onlyBody?: boolean,
    card?: boolean,
    emailSubscription?: number,
    setEmailSubscription?: (value: React.SetStateAction<number>) => void,
    addPage?: boolean,
    company: boolean,
    hosted: boolean,
    info?: string
}

export function ContactDetailsComponent(props: Props) {
    const { t } = useTranslation();
    const handleEmailSubscription = (i: number) => {
        if ((props.emailSubscription! & i) != 0)
            props.setEmailSubscription!(props.emailSubscription! - i)
        else
            props.setEmailSubscription!(props.emailSubscription! + i)
    }

    const [showEmailInfoModal, setShowEmailInfoModal] = useState<boolean>(false)

    const emailFlags: OptionType[] = props.company ? [{ value: '1', label: t('deployment:billing') }, { value: '2', label: t('deployment:pricing') }, { value: '4', label: t('deployment:newsletter') },
    { value: '8', label: t('deployment:outages') }, { value: '16', label: t('deployment:softwareUpdates') }, { value: '32', label: t('deployment:licensing') }, 
    { value: '64', label: t('deployment:systemHealth') }] : props.hosted == false ? [{value:'128', label: t('deployment:licenseExpiry')}] : [{value:'128', label: t('deployment:hostedPayment')}]

    return (
        <div className={props.card != null ? "card h-100 mb-0 p-0" : ""}>
            {props.onlyBody == null && <div className=" card-header border-0 pb-0">
                <h2 className='mb-4 header-text '>
                    <FontAwesomeIcon icon="address-book" className="fs-3 me-2" />
                    {props.info != null ? t('endUserContactDetails') :t('contactDetails')}
                    {props.info != null && <FontAwesomeIcon icon={faQuestion} type="button" className="ms-2 align-middle fs-3 float-end btn btn-secondary" onClick={() => setShowEmailInfoModal(true)}/>}
                </h2>
                {props.addPage && <RequiredFields />}
                <ModalComponent textSize={"fs-4"} modalTitle={``} modalText={`${props.info}`} 
                             showModal={showEmailInfoModal} setShowModal={setShowEmailInfoModal}/>
            </div>}
            <div className="card-body bg-opacity-75">
                {props.website != null && <TextInputComponent value={props.website} label={t('website')} setText={props.setWebsite} />}
                {props.contactName != null && <TextInputComponent mandatory={props.addPage} value={props.contactName} label={t('name')} setText={props.setContactName} />}
                <TextInputComponent mandatory={props.addPage}  value={props.email} label={t('email')} setText={props.setEmail} validator={EmailValidate} validateText={"This is not a valid e-mail address."} />              
                {(props.emailSubscription != null && props.company) && <div className="form-group row mb-4 ">
                    <div className="col-1 d-none d-sm-block"></div>
                    <label className="col-5 col-sm-2 col-form-label text-begin ms-3 mb-2" >
                        <h3 className="d-none d-sm-block">{t('deployment:emailSubscription')}</h3>
                        <h3 className="d-block d-sm-none fs-4">{t('deployment:emailSubscription')}</h3>
                    </label>
                    <div className=" col-6 col-sm-7 col-form-label">
                        {emailFlags.map((e: OptionType) => (
                            <div className="d-inline me-4 text-nowrap" key={e.value}>
                                <input onChange={() => { }} className="ms-2" type="checkbox" checked={(props.emailSubscription! & Number(e.value)) != 0} value={0} onClick={() => handleEmailSubscription(Number(e.value))} />
                                <label className="text-center mt-1 ms-2" ><h4>{e.label}</h4></label>
                            </div>
                        ))}
                    </div>
                </div>}
                
                <PhoneNumberComponent phoneNumber={props.currentPhoneNumber} extension={props.currentExtension} typeOption={props.currentPhoneTypeOption} setPhoneNumber={props.setCurrentPhoneNumber}
                    setExtension={props.setCurrentExtension} setTypeOption={props.setCurrentTypeOption} phoneNumbers={props.phoneNumbers} setPhoneNumbers={props.setPhoneNumbers} />

                {(props.emailSubscription != null && !props.company) && <div className="form-group row mb-4 ">
                    <div className="col-1 d-none d-sm-block"></div>
                    <label className="col-5 col-sm-2 col-form-label text-begin ms-3 mb-2" >
                        <h3 className="d-none d-sm-block">{t('deployment:emailSubscription')}</h3>
                        <h3 className="d-block d-sm-none fs-4">{t('deployment:emailSubscription')}</h3>
                    </label>
                    <div className=" col-6 col-sm-7 col-form-label">
                        {emailFlags.map((e: OptionType) => (
                            <div className="d-inline me-4 text-nowrap" key={e.value}>
                                <input onChange={() => { }} className="ms-2" type="checkbox" checked={(props.emailSubscription! & Number(e.value)) != 0} value={0} onClick={() => handleEmailSubscription(Number(e.value))} />
                                <label className="text-center mt-1 ms-2" ><h4>{e.label}</h4></label>
                            </div>
                        ))}
                    </div>
                </div>}
            </div>
        </div>
    )
}