import { t } from "i18next";
import React, { useState } from "react";
import { AuthContextProps } from "react-oidc-context";
import { NavigateFunction } from "react-router-dom";
import useAppSelector from "../../hooks/useAppSelector";
import { getCompanyFeatureGate, getFeatureGate } from "../../redux/slices/config";
import API from "../API";
import {  CompanyFeatures, Features, GetDeployment, HostedDeviceType, HostedPackage, LicenseDTO, OptionType } from "../store";
export async function GetDeploymentById( 
    auth:AuthContextProps, id:string , prepaid:boolean, isACEPaid:boolean,isUserCountPaid: boolean, setName:Function, setLabel:Function, setAccountNumber:Function, 
    setType:Function, setDescription:Function,setIsOnline:Function, setPayExpiry:Function, setPayReference:Function,
    setNotLicense:Function, setLicense:Function, setLicenseTerm:Function, setPackageType:Function, setRearm:Function,
    setOdysseyInfo:Function, setIsOnlineConnected:Function, setOnlinePanels:Function, setHeaderOption:Function, setHostedPackage:Function, 
    setAddress:Function, setCity:Function, setPostalCode:Function, setCountry:Function, setRegion:Function, setContactName:Function, setEmail:Function, 
    setLoading:Function, setEmailSubscription:Function, setPhoneNumber:Function, navigate: NavigateFunction, setLicenseTier:Function, setLicenseAddOns:Function, 
    setHostedStatus:Function, setTenant:Function,setNextPayment:Function, setLatestInvoiceId:Function, setPending: Function, featureGate: Features, setIsHosted:Function, setNeedsToBeUpdated: Function , setACEPaid: Function, setUserCountPaid : Function,
    companyFeatureGate: CompanyFeatures, setPrepaid: Function ) {
    
    const deploymentTypes:OptionType[] = [{ value: '0', label: t('deployment:licensed') }, { value: '1', label: t('deployment:hosted') }]
    const HostedStatusOptions:OptionType[] =[{value: '0', label: t('deployment:pending')},{value: '1', label: t('deployment:active')},{value: '2', label: t('deployment:pastdue')},
    {value: '3', label: t('deployment:disabled')},{value: '4', label: t('deployment:incomplete')}]
    const licenseOption: OptionType[] = [{ i1: 50, i2: 1, label: t("package:essentials"), value: '' }, { i1: 100, i2: 2, label: t("package:small"), value: '' }, { i1: 250, i2: 4, label: t("package:mid"), value: '' },
    { i1: 400, i2: 256, label: t("package:large"), value: '' }, { i1: 750, i2: 8, label: t("package:enterprise"), value: '' },
    { i1: 750, i2: 512, label: t("package:basic"), value: '' }, { i1: 1024, i2: 1024, label: t("package:standard"), value: '' }]


    try{
        const response:GetDeployment|null = await API.get(`/api/v1/Deployment/${id}`, auth)
        if(response == null)
        {
            alert("Deployment Does Not Exist")
            return
        }
        const address = response.address
        const contact = response.contact
        setLatestInvoiceId(response.latestInvoiceId)

        
        let headerOption:OptionType[] = []
        headerOption = ([{value:"deployment", label:t('deployment:deploymentDetails')},{value : 'licenseDetails', label: t('license:licenseDetails')},
        { value: 'licenseHistory', label:t('license:licenseHistory')}, { value: 'paymentInfo', label:t('paymentInformation')}, { value: 'paymentHistory', label:t('paymentHistory')}])
        
        if (response.deployment != null)
        {
            const deployment = response.deployment  
            setName(deployment.name)
            setLabel(deployment.name)
            setAccountNumber(deployment.accountNumber)
            setType(deploymentTypes.find(x => x.value == deployment.type.toString())?.label!)
            setDescription(deployment.description)
            setIsOnline(deployment.online)
            setPayExpiry(deployment.paymentExpiry!)
            setPayReference(deployment.cardNumber!)
            setLicenseAddOns(deployment.licenseAddOns)
            if (deployment.cardNumber == null)
                headerOption = ([{value:"deployment", label:t('deployment:deploymentDetails')},{value : 'licenseDetails', label: t('license:licenseDetails')},
                { value: 'licenseHistory', label:t('license:licenseHistory')}])

            const license:LicenseDTO|null = deployment.activeLicense!;
            if(license == null)
                setNotLicense(true)
            else
            {
                setLicense(license)
                setLicenseTerm(license?.duration! == 60 ? `5 ${t('years')}` : `1 ${t('years')}` )
                setPackageType(licenseOption.find(x => x.i2 == license?.package)?.label!)
                setRearm(license?.rearm!)
                setLicenseTier(license.licenseTier.toString())
            }
            setIsOnlineConnected(response.isConnected)
            if (response.odysseyInfo != null && featureGate.onlineDeployments)
            {
                setOdysseyInfo(response.odysseyInfo)
                if (featureGate.backups)
                {
                    headerOption = ([{value:"deployment", label:t('deployment:deploymentDetails')},{value : 'licenseDetails', label: t('license:licenseDetails')},{ value: 'licenseHistory', label:t('license:licenseHistory')},
                    { value: 'odysseyInfo', label:t('status')},{ value: 'backups', label:t('backups')} , { value: 'paymentInfo', label:t('paymentInformation')}, { value: 'paymentHistory', label:t('paymentHistory')} ])      
                }
                else
                {
                    headerOption = ([{value:"deployment", label:t('deployment:deploymentDetails')},{value : 'licenseDetails', label: t('license:licenseDetails')},{ value: 'licenseHistory', label:t('license:licenseHistory')},
                    { value: 'odysseyInfo', label:t('status')} , { value: 'paymentInfo', label:t('paymentInformation')}, { value: 'paymentHistory', label:t('paymentHistory')} ])        
                }
                if (deployment.cardNumber == null)
                {
                    if (featureGate.backups){
                        headerOption = ([{value:"deployment", label:t('deployment:deploymentDetails')},{value : 'licenseDetails', label: t('license:licenseDetails')},
                        { value: 'licenseHistory', label:t('license:licenseHistory')}, { value: 'odysseyInfo', label:t('status')},{ value: 'backups', label:t('backups')} ])
                    }
                    else{
                        console.log(headerOption)
                        headerOption = ([{value:"deployment", label:t('deployment:deploymentDetails')},{value : 'licenseDetails', label: t('license:licenseDetails')},
                        { value: 'licenseHistory', label:t('license:licenseHistory')}, { value: 'odysseyInfo', label:t('status')} ])
                    }
                }
            }
            if (license != null && license.licenseTier == 1 && companyFeatureGate.allowedCameraLicensing)
            {
                headerOption.push({ value: 'cameraLicensing', label:t('cameraLicensing')})
            }
            setHeaderOption(headerOption)
        }
        else if(response.hostedDeployment != null)
        {
            const deployment = response.hostedDeployment
            console.log(deployment)
            setIsHosted(true)
            setPending(deployment.pending)  
            setName(deployment.name)
            setLabel(deployment.name)
            setType(deploymentTypes.find(x => x.value == deployment.type.toString())?.label!)
            setDescription(deployment.description)
            setPayExpiry(deployment.paymentExpiry)
            setPayReference(deployment.cardNumber!)
            setHostedStatus(HostedStatusOptions.find( x => x.value == deployment.status.toString())?.label)
            setLicenseTier(deployment.tierType!.toString())
            setACEPaid(deployment.aceCardPaid)
            setUserCountPaid(deployment.userCardPaid)
            setPrepaid(companyFeatureGate.allowedHostedPayment? false : true )
            if(companyFeatureGate.allowedHostedPayment)
                 setHeaderOption([{value:"deployment", label:t('deployment:deploymentDetails')},{value : 'packageDetails', label: t('deployment:packageDetails')}, 
                     { value: 'panels', label:t('deployment:panels')}, { value: 'paymentInfo', label:t('paymentInformation')}, { value: 'paymentHistory', label:t('paymentHistory')}])
              else
                setHeaderOption([{value:"deployment", label:t('deployment:deploymentDetails')},{value : 'packageDetails', label: t('deployment:packageDetails')}, 
                 { value: 'panels', label:t('deployment:panels')}])

            setTenant(deployment.tenant!)
            setNeedsToBeUpdated(deployment.needsToBeUpgraded)
            // if (deployment.tenant.activated)
            //     setHostedStatus(t("deployment:activated"))
            setNextPayment(deployment.nextPayment!)
            const hPackage:HostedPackage = {multiCardEnabled: deployment.multicard, packageTerm: deployment.schedule, numOfPanels: deployment.numOfPanels}
            if (deployment.hostedDevices != null){
                deployment.hostedDevices.forEach(element => {
                    if (element.device == HostedDeviceType.Doors)
                    {
                        hPackage.numberOfDoors = element.amount
                        hPackage.pendingDoors = element.pendingAmount
                    }
                    else if (element.device == HostedDeviceType.Elevators)
                    {
                        hPackage.numberOfElevators = element.amount
                        hPackage.pendingElevators = element.pendingAmount
                    }
                    else if (element.device == HostedDeviceType.IOExpanders)
                    {
                        hPackage.numberOfIOExpanders = element.amount
                        hPackage.pendingIOExpanders = element.pendingAmount
                    }
                });
            }
            if(deployment.status == 4)
                navigate(`/package/${id}/hosted`,{state:{licenseTierType:deployment.tierType}})
            else
                setHostedPackage(hPackage)
        }
        setAddress(address.street)
        setCity(address.city)
        setPostalCode(address.postalCode)
        setCountry(address.country)
        setRegion(address.state)
        setContactName(contact.name)
        setEmail(contact.emailAddress)
        setPhoneNumber(contact.phoneNumber)
        setEmailSubscription(contact.emailSubscriptions)
        setLoading(false)
        
    }
    catch(e:any){
        console.log(e)
        alert(e.errors?.Error!)
        navigate("/deployment")
    }
}