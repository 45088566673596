import { changeLanguage } from "i18next";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAuth } from "react-oidc-context";
import englishIcon from '../../assets/images/englishIcon.png'
import frenchIcon from '../../assets/images/frenchIcon.png'
import spanishIcon from '../../assets/images/spanishIcon.png'

export default function(){
    const auth = useAuth()
    const [currentLang, setCurrentLang] = useState<string>(String(auth.user?.profile.locality!).split('-')[0])
    const [langDropdown, setLangDropdown] = useState<boolean>(false)
    const { t } = useTranslation();
    const handleOutsideClick = (event:any) => {
        if(event.target.id != "langDropdown")
            setLangDropdown(false)
    }
    document.addEventListener("click", handleOutsideClick);

    const { i18n } = useTranslation();
    useEffect(() => {
       setCurrentLang(i18n.language)
    }, [i18n.language])
    return(
        <div id='langDropdown' className='ms-2 nav-item dropdown'>
            <a id='langDropdown' className='nav-link nav-flag dropdown-toggle me-2' aria-expanded="false" onClick={() => setLangDropdown(!langDropdown)}>
                <img id='langDropdown' className="mt-1" style={{width: "23px" , height: "23px"}} src={currentLang == 'en' ? englishIcon : currentLang == 'fr' ? frenchIcon : spanishIcon} />
            </a>
            <div id='langDropdown' className={langDropdown ?'dropdown-menu dropdown-menu-end show ' : 'dropdown-menu dropdown-menu-end'}>
                <a id='langDropdown' className='dropdown-item' role="button" aria-expanded="false" onClick={(e) => {changeLanguage('en'); setCurrentLang('en');setLangDropdown(!langDropdown)}}>
                    <img id='langDropdown' width={"20"} className="align-middle me-1" src={englishIcon}  />
                    <span id='langDropdown' className="align-middle">English</span>
                </a>
                <a id='langDropdown' className='dropdown-item' role="button" aria-expanded="false" onClick={(e) => {changeLanguage('es'); setCurrentLang('es');setLangDropdown(!langDropdown)}}>
                    <img id='langDropdown' width={"20"} className="align-middle me-1" src={spanishIcon} />
                    <span id='langDropdown' className="align-middle">Spanish</span>
                </a>
                <a id='langDropdown' className='dropdown-item' role="button" aria-expanded="false" onClick={(e) => {changeLanguage('fr'); setCurrentLang('fr');setLangDropdown(!langDropdown)}}>
                    <img id='langDropdown' width={"20"} className="align-middle me-1" src={frenchIcon} />
                    <span id='langDropdown' className="align-middle">French</span>
                </a>
            </div>
        </div>
    )
}