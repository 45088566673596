import { count } from "console";


export default function(postalCode:string, country:string):boolean {
    let postalCodeRegex:RegExp;
    switch (country) {
        case "United States":
            postalCodeRegex = /^([0-9]{5})(?:[-\s]*([0-9]{4}))?$/;
            break;
        case "Canada":
            postalCode = postalCode.toUpperCase()
            postalCodeRegex = /^([A-Z][0-9][A-Z])\s*([0-9][A-Z][0-9])$/;
            break;
        default:
            postalCode = postalCode.toUpperCase()
            postalCodeRegex = /^(?:[A-Z0-9]+([- ]?[A-Z0-9]+)*)?$/;
    }
    return postalCodeRegex.test(postalCode);
}