import React, { useEffect, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import { Layout, LayoutProvider } from './layout/Layout';
import { useAuth } from "react-oidc-context";
import Callback from './layout/api-authorization/Callback';
import { ReactElement } from 'react-markdown/lib/react-markdown';
import { RequireAuth } from './layout/api-authorization/RequireAuth';
import Weatherforecast from './Weatherforecast';
import { Register } from './views/Company/Register';

import Company from './views/Company/Company';
import AccountInfo from './views/Users/Account';
import Users from './views/Users/Users';
import AddUser from './views/Users/AddUser';
import SetNewUserPassword from './views/Users/SetPassword';
import EditUser from './views/Users/EditUser';
import InvalidPage from './views/Miscellaneous/InvalidPage';
import RegisterLink from './views/Miscellaneous/SendRegisterLink';
import Activity from './views/Reports/Activity';
import DeploymentList from './views/Deployments/DeploymentList';
import AddDeployment from './views/Deployments/AddDeployment';
import EditDeployment from './views/Deployments/EditDeployment';
import './languages/lang'
import {useTranslation} from "react-i18next";
import OdysseyConnect from './layout/api-authorization/OdysseyConnect';
import Dashboard from './views/Dashboard';
import OnlineStatus from './views/Deployments/OnlineStatus';
import LoadingPageComponent from './components/shared/LoadingPageComponent';
import { ThemeProvider } from './contexts/ThemeContext';
import Payment from './views/Payment/ElavonPayment';
import Invoices from './views/Payment/Invoices';
import ViewInvoice from './views/Payment/ViewInvoice';
import OdysseyInvoicePayment from './views/Payment/OdysseyInvoicePayment';
import Support from './views/Support';
import TermsOfService from './views/TermsOfService';
import useAppDispatch from './hooks/useAppDispatch';
import { getCompanyFeatureGate, getFeatureGate, selectConfig, selectName, setAddDeployment, setCompanyFeatures, setDevelopment, setFeatures, setName, setSysAdmin } from './redux/slices/config';
import useAppSelector from './hooks/useAppSelector';
import API from './common/API';
import { CompanyFeatures, Features, GetUserRequest } from './common/store';
import SoftwareDownloads from './views/Resources/SoftwareDownloads';
import AcPortalInfo from './views/Company/AcPortalInfo';
import RegistrationKey from './views/Miscellaneous/RegistrationKey';

function App() {
  const auth = useAuth();
  const featureGate: Features = useAppSelector(getFeatureGate)
  const companyFeatureGate: CompanyFeatures = useAppSelector(getCompanyFeatureGate)
  
  const{t, i18n} = useTranslation()
  useEffect( () => {
    const language = String(auth.user?.profile.locality!).split('-')[0]
    i18n.changeLanguage(language)
  },[auth])


  switch (auth.activeNavigator) {
    case "signinSilent":
      return <div>Signing you in...</div>;
    case "signoutRedirect":
      return <div>Signing you out...</div>;
  }
  if (auth.isLoading) {
    return <div style={{marginTop: window.screen.height / 3}}><LoadingPageComponent /></div>;
  }

    if (auth.error) {
        auth.signinRedirect()
        return <div>Oops... {auth.error.message}</div>;
    }


  return (
    <div  className="App">
      <header className="App-header">
      </header>
      <ThemeProvider>
        <LayoutProvider>
          <div className="container-fluid">
            <Routes>
              <Route path='/' element={<RequireAuth><Dashboard/></RequireAuth>} />
              {featureGate?.onlineDeployments && <Route path='/onlineStatus' element={<RequireAuth><OnlineStatus/></RequireAuth>} />}
              {featureGate?.devPayment && <Route path='/registrationKey' element={<RequireAuth><RegistrationKey/></RequireAuth>} />}
              {companyFeatureGate.oem == "Aiphone" && <Route path='/acPortalInfo' element={<AcPortalInfo/>} />}
              <Route path='/register/:token' element={<Register/>}/>
              <Route path='/notFound' element={<InvalidPage/>} />
              <Route path='/registerLink' element={<RegisterLink/>} />
              <Route path='/callback' element={<Callback />} />
              {/* <Route path='/OdysseyConnect' element= {<RequireAuth><OdysseyConnect/></RequireAuth>} /> */}
              <Route path='/company' element= {<RequireAuth><Company/></RequireAuth>} />
              <Route path='/manage' element= {<RequireAuth><AccountInfo/></RequireAuth>} />
              <Route path='/user/list' element={<RequireAuth><Users/></RequireAuth>}/>
              <Route path='/user/add' element={<RequireAuth><AddUser/></RequireAuth>}/>
              <Route path='/user/edit/:id' element={<RequireAuth><EditUser/></RequireAuth>}/>
              <Route path='/activity' element={<RequireAuth><Activity/></RequireAuth>}/>
              <Route path='/deployment' element={<RequireAuth><DeploymentList /></RequireAuth>} />
              <Route path='/deployment/:id' element={<RequireAuth><EditDeployment /></RequireAuth>} />
              <Route path='/deployment/:id/:licenseHistory' element={<RequireAuth><EditDeployment /></RequireAuth>} />
              <Route path='/deployment/add' element={<RequireAuth><AddDeployment /></RequireAuth>} />
              {featureGate?.onlineDeployments && <Route path='/deployment/add/:online' element={<RequireAuth><AddDeployment /></RequireAuth>} />}
              <Route path='/license/create/:id/:accNumber' element={<RequireAuth><AddDeployment /></RequireAuth>} />
              <Route path='/license/:id/:accNumber/:renew' element={<RequireAuth><AddDeployment /></RequireAuth>} />
              <Route path='/license/:id/:accNumber/:renew/:online' element={<RequireAuth><AddDeployment /></RequireAuth>} />
              <Route path='/package/:id/:hosted' element={<RequireAuth><AddDeployment /></RequireAuth>} />
              <Route path='/invoices' element={<RequireAuth><Invoices /></RequireAuth>} />
              <Route path='/invoice/:id' element={<RequireAuth><ViewInvoice /></RequireAuth>} />
              {featureGate?.onlineDeployments && <Route path='/odysseypayment/:id' element={<RequireAuth><OdysseyInvoicePayment /></RequireAuth>} />}
              <Route path='/support' element={<RequireAuth><Support /></RequireAuth>} />
              {/*<Route path='/tos' element={<RequireAuth><TermsOfService /></RequireAuth>} />*/}
              <Route path='/software/Downloads' element={<RequireAuth><SoftwareDownloads /></RequireAuth>} />
              <Route path='*' element={<InvalidPage/>} />
            </Routes>
            
          </div>
        </LayoutProvider>
      </ThemeProvider>
      
      </div>
    );
}

export default App;
