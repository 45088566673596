import React, { useEffect, useState } from "react";
import { useAuth } from "react-oidc-context";
import TimezoneSelect from "react-timezone-select";
import { PasswordComponent } from "../../components/shared/PasswordComponent";
import PhoneNumberComponent from "../../components/shared/PhoneNumberComponent";
import TextInputComponent from "../../components/shared/TextInputComponent";
import Select from 'react-select';
import { validate as EmailValidate } from 'email-validator';
import { SelectStyle } from "../../common/SelectStyle";
import { GetUserRequest, languageOptions, OptionType, PhoneNumberType } from "../../common/store";
import API from "../../common/API";
import HeaderSectionsComponent from "../../components/shared/HeaderSectionsComponent";
import SuccessErrorMessage from "../../components/shared/SuccessErrorMessage";
import LoadingPageComponent from "../../components/shared/LoadingPageComponent";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SelectComponent from "../../components/shared/SelectComponent";
import { changeLanguage } from "i18next";
import useAppDispatch from "../../hooks/useAppDispatch";
import { setName } from "../../redux/slices/config";

export default function () {
    const { t } = useTranslation();
    const auth = useAuth();
    const sysAdmin: boolean = auth.user?.profile.role == "SysAdmin"
    const userIdentifier = auth.user?.profile.client_id;
    const [showSuccessMessage, setShowSuccessMessage] = useState<boolean>(false)
    const [showErrorMessage, setShowErrorMessage] = useState<boolean>(false)
    const [errorMessage, setErrorMessage] = useState<string>("")
    const [successMessage, setSuccessMessage] = useState<string>("")
    const [loading, setLoading] = useState<boolean>(true)

    const headerOptions: OptionType[] = [{ label: t('user:adminDetails'), value: 'adminDetails' }, { label: t('user:changePassword'), value: 'changePassword' }]
    const [currentSection, setCurrentSection] = useState<string>('adminDetails')

    // Admin Details
    const [firstName, setFirstName] = useState<string>('')
    const [lastName, setLastName] = useState<string>('')
    const [currentAdminPhoneTypeOption, setCurrentAdminTypeOption] = useState<string | undefined>('')
    const [currentAdminPhoneNumber, setCurrentAdminPhoneNumber] = useState<string>('')
    const [currentAdminExtension, setCurrentAdminExtension] = useState<string>('')
    const [language, setLanguage] = useState<string | undefined>("")
    const [originalLanguage, setOriginalLanaguage] = useState<string | undefined>("")
    const [adminEmail, setAdminEmail] = useState<string>("")
    const [currentPassword, setCurrentPassword] = useState<string>("")
    const [newPassword, setNewPassword] = useState<string>("")
    const [confirmPassword, setConfirmPassword] = useState<string>("")
    const [isAdmin, setIsAdmin] = useState<boolean>(false)
    const [timezone, setTimezone] = useState<string>("")
    const [id, setId] = useState<number>(0)
    const [manageCompany, setManageCompany] = useState<boolean>(false)
    const [manageDeployments, setManageDeployments] = useState<boolean>(false)
    const [manageHostedDeployments, setManageHostedDeployments] = useState<boolean>(false)
    const [manageLicenses, setManageLicenses] = useState<boolean>(false)
    const [managePayments, setManagePayments] = useState<boolean>(false)

    const dispatch = useAppDispatch()
    
    useEffect(() => {
        const getUser = async () => {
            try {
                const response: any = await API.get(`/api/v1/User/${userIdentifier}`, auth)
                setLoading(false)
                const data: GetUserRequest = response.result
                setFirstName(data.firstName)
                setLastName(data.lastName)
                setLanguage(data.language)
                setOriginalLanaguage(data.language)
                setTimezone(data.timezone)
                setAdminEmail(data.email)
                setCurrentAdminExtension(data.phoneNumber?.extension!)
                setCurrentAdminPhoneNumber(data.phoneNumber?.number!)
                setCurrentAdminTypeOption(data.phoneNumber?.type!)
                setIsAdmin(data.isAdmin)
                setId(data.id)
                setManageCompany(data.manageCompany)
                setManageDeployments(data.manageDeployments)
                setManageHostedDeployments(data.manageHostedDeployments)
                setManageLicenses(data.manageLicenses)
                setManagePayments(data.managePayments)
            }
            catch (e: any) {
                alert(e.errors?.Error!)
            }
        }
        getUser()
    }, [])

    const UpdateUser = async () => {
        const phoneNumber: PhoneNumberType = {
            type: currentAdminPhoneTypeOption == null ? 'Work' : currentAdminPhoneTypeOption,
            number: currentAdminPhoneNumber == null ? "" : currentAdminPhoneNumber, extension: currentAdminExtension == null ? "" : currentAdminExtension
        }
        const object: GetUserRequest = {
            firstName: firstName, lastName: lastName,
            language: language!, timezone: timezone,
            email: adminEmail, id: id, phoneNumber: phoneNumber,
            isAdmin: isAdmin, lastLoggedIn: '',
            manageCompany: manageCompany, manageDeployments: manageDeployments,
            manageHostedDeployments: manageHostedDeployments, manageLicenses: manageLicenses,
            managePayments: managePayments
        }
        try {
            const response = await API.put("/api/v1/User", object, auth)
            setSuccessMessage(t("user:updateMessage"))
            setShowSuccessMessage(true)
            setShowErrorMessage(false)
            dispatch(setName(firstName))
            if (originalLanguage != language) {
                alert(t("user:languageChangeMessage"))
                auth.removeUser()
            }
        }
        catch (e: any) {
            setErrorMessage(e.errors?.Error!)
            setShowErrorMessage(true)
            setShowSuccessMessage(false)
        }
    }

    const ChangePassword = async () => {
        const Password = { CurrentPassword: currentPassword, NewPassword: newPassword, UserId: id }
        try {
            const response = await API.put("/api/v1/User/ChangePassword", Password, auth)
            setSuccessMessage(t("user:updatedPassword"))
            setShowSuccessMessage(true)
            setShowErrorMessage(false)
            setCurrentPassword("")
            setConfirmPassword("")
            setNewPassword("")
        }
        catch (e: any) {
            setErrorMessage(t("user:incorrectPassword"))
            setShowErrorMessage(true)
            setShowSuccessMessage(false)
        }

    }

    return (

        <div>
            {loading && <LoadingPageComponent />}
            {!loading &&
                <div className="">
                    <h1 className="h3 mb-3 ms-1"><FontAwesomeIcon icon="user" className="fs-3 me-2" />{`${firstName} ${lastName}`}</h1>
                    <div className="row">
                        <SuccessErrorMessage showErrorMessage={showErrorMessage} showSuccessMessage={showSuccessMessage} setShowErrorMessage={setShowErrorMessage}
                            setShowSuccessMessage={setShowSuccessMessage} errorMessage={errorMessage} successMessage={successMessage} />
                        <div className="col-sm-3">
                            <HeaderSectionsComponent label={`${t("user:accountSettings")}`}  currentSection={currentSection} setCurrentSection={setCurrentSection} sections={headerOptions} />
                        </div>
                        <div className="col-sm-9">
                            {currentSection == 'adminDetails' &&
                                <div>
                                    <div className="card">
                                        <div className=" card-header border-0 ">
                                            <h2 className='mb-4 header-text'><FontAwesomeIcon icon="circle-info" className="fs-3 me-2" />{t('user:adminDetails')}</h2>
                                        </div>
                                        <div className="card-body bg-opacity-75 ">
                                            <TextInputComponent noEdit={true} value={adminEmail} label={t('user:emailAddress')} setText={setAdminEmail} validator={EmailValidate} validateText={"This is not a valid e-mail address."} />
                                            <TextInputComponent value={firstName} label={t('user:firstName')} setText={setFirstName} />
                                            <TextInputComponent value={lastName} label={t('user:lastName')} setText={setLastName} />
                                            <div className="form-group row mb-4">
                                                <div className="col-1 d-none d-sm-block"></div>
                                                <label className="col-4 col-sm-2 col-form-label text-begin ms-3" >
                                                    <h3 className="d-none d-sm-block">{t('user:language')}</h3>
                                                    <h3 className="d-block d-sm-none fs-4">{t('user:language')}</h3>
                                                </label>
                                                <div className="col-7 col-sm-5">
                                                    <SelectComponent className="form-select form-control-lg" options={languageOptions} onChange={(e: any) => setLanguage(e.target.value)} value={languageOptions.find(x => x.value == language)?.value} />
                                                </div>
                                            </div>
                                            <div className="form-group row mb-4">
                                                <div className="col-1 d-none d-sm-block"></div>
                                                <label className="col-4 col-sm-2 col-form-label text-begin ms-3" >
                                                    <h3 className="d-none d-sm-block">{t('user:timeZone')}</h3>
                                                    <h3 className="d-block d-sm-none fs-4">{t('user:timeZone')}</h3>
                                                </label>
                                                <div className="col-7 col-sm-5">
                                                    <TimezoneSelect value={timezone} onChange={(e) => setTimezone(e.value)} />
                                                </div>
                                            </div>
                                            <PhoneNumberComponent phoneNumber={currentAdminPhoneNumber} extension={currentAdminExtension} typeOption={currentAdminPhoneTypeOption} setPhoneNumber={setCurrentAdminPhoneNumber}
                                                setExtension={setCurrentAdminExtension} setTypeOption={setCurrentAdminTypeOption} />
                                        </div>
                                        <div className=" mb-4 pe-5">
                                            <button className="btn float-end btn-twitter add-btn-text " onClick={() => UpdateUser()}>
                                                <span >{t('update')}</span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            }
                            {currentSection == 'changePassword' &&
                                <div>
                                    <div className="card">
                                        <div className=" card-header border-0 ">
                                            <h2 className='mb-4 header-text'><FontAwesomeIcon icon="key" className="fs-3 me-2" />{t('user:changePassword')}</h2>
                                        </div>
                                        <div className="card-body bg-opacity-75 ">
                                            <PasswordComponent labelText={t('user:currentPassword')} password={currentPassword} setNewPassword={setCurrentPassword} />
                                            <PasswordComponent labelText={t('user:newPassword')} password={newPassword} setNewPassword={setNewPassword} />
                                            <PasswordComponent labelText={t('user:confirmPassword')} password={confirmPassword} setNewPassword={setConfirmPassword} />
                                            <div className="form-group" style={{ display: confirmPassword != newPassword ? 'block' : 'none' }} >
                                                <p className="text-danger text-center">{t('user:passwordNotMatch')}</p>
                                            </div>
                                            <div className="form-group" style={{ display: confirmPassword == newPassword &&  newPassword != "" &&  newPassword.length < 6 ? 'block' : 'none' }} >
                                                <p className="text-danger text-center">{t('user:passwordLengthError')}</p>
                                            </div>
                                        </div>
                                        <div className=" mb-4 pe-5">
                                            <button className="btn float-end btn-twitter add-btn-text " 
                                                onClick={() => ChangePassword()} disabled={confirmPassword != newPassword || newPassword == '' || newPassword.length < 6}>
                                                <span >{t('user:changePassword')}</span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>

                    </div>
                </div>
            }
        </div>

    )
}