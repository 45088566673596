import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import Select from "react-select";
import { SelectStyle } from "../../common/SelectStyle";
import { OptionType, PhoneNumberType } from "../../common/store";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { useTranslation } from "react-i18next";
import SelectComponent from "./SelectComponent";
import { t } from "i18next";

export default function PhoneNumberComponent({phoneNumber, extension, typeOption, setPhoneNumber, setTypeOption, setExtension, phoneNumbers, setPhoneNumbers }:
    {phoneNumber:string, extension?:string, typeOption?:string|undefined, setPhoneNumber:(value: React.SetStateAction<string>) => void, setTypeOption?:(value: React.SetStateAction<string|undefined>) => void, 
        setExtension?:(value: React.SetStateAction<string>) => void, phoneNumbers?:PhoneNumberType[],setPhoneNumbers?:(value: React.SetStateAction<PhoneNumberType[]>) => void }){
    
    const {t} = useTranslation();
    const phoneOptions:OptionType[] = [{ value: 'Work', label: t('phoneNumber:work') },{ value: 'Cell', label: t('phoneNumber:cell') },{ value: 'Fax', label: t('phoneNumber:fax') },
        { value: 'Toll Free', label: t('phoneNumber:tollFree') },{ value: 'Home', label: t('phoneNumber:home') }]
    
    
    const handleAddPhoneNumber =() => {
        if (phoneNumber.length < 8)
        {
            alert("Invalid Phone Number")
            return
        }
        const newPhoneNumber:PhoneNumberType = {type: typeOption, number:phoneNumber, extension:extension! }
        phoneNumbers?.push(newPhoneNumber)
        setExtension!('')
        setPhoneNumber('')
    }
    const handleDeletePhoneNumber = (index:number) => {
        if (phoneNumbers != null && setPhoneNumbers!=null)
        {
            const newPhoneArray = [...phoneNumbers]
            newPhoneArray.splice(index, 1)
            setPhoneNumbers(newPhoneArray)
        }
    }
    return (
        <div>
            <div className={phoneNumbers == null ? "form-group row mb-4 " : "form-group row mb-1 "}>
                <div className="col-1 d-none d-sm-block"></div>
                {typeOption == null && <label className="col-4 col-sm-2 col-form-label text-begin ms-3" >
                    <h3 className="d-none d-sm-block">{t('phoneNumber')}</h3>
                    <h3 className="d-block d-sm-none fs-4">{t('phoneNumber')}</h3>
                </label>}
                {typeOption != null && <label className="col-12 col-sm-2 col-form-label text-begin  ms-sm-3 mb-3" >
                    <h3 className="d-none d-sm-block">{t('phoneNumber')}</h3>
                    <h3 className="d-block d-sm-none fs-4">{t('phoneNumber')}</h3>
                </label>}
                {typeOption != null && phoneNumbers == null && <div className="col-1 d-sm-none"></div>}
                {typeOption != null && <div className="col-12 mb-3 mb-sm-0 col-sm-2 ">
                    <SelectComponent placeholder={t('deployment:deploymentType')}  className="form-select form-control-lg py-0" options={phoneOptions} onChange={(e:any) => setTypeOption!((e.target.value))} value={phoneOptions.find(e => (e.value) == typeOption)?.value } />
                </div>}
                {typeOption == null && <div className={ "col-7 col-sm-5" }>
                    <PhoneInput  placeholder="" country={'ca'} preferredCountries={['ca', 'us', 'gb']} containerClass="form-control  ps-0 py-0 d-none d-sm-block " inputClass="border-0 w-100 bg-white" buttonClass="border-0 bg-white "  regions={['north-america', 'carribean', 'europe']}  value={phoneNumber}  onChange={(e) => setPhoneNumber(e)}/>
                    <PhoneInput  placeholder="" country={'ca'} preferredCountries={['ca', 'us', 'gb']} containerClass="form-control  ps-0 py-0 d-block d-sm-none" inputClass="border-0 w-100 bg-white fs-5" buttonClass="border-0 bg-white "  regions={['north-america', 'carribean', 'europe']}  value={phoneNumber}  onChange={(e) => setPhoneNumber(e)}/>
                </div>}
                {typeOption != null && <div className={ "col-8 col-sm-3" }>
                    <PhoneInput  placeholder="" country={'ca'} preferredCountries={['ca', 'us', 'gb']} containerClass="form-control  ps-0 py-0 " inputClass="border-0 w-100 bg-white" buttonClass="border-0 bg-white "  regions={['north-america', 'carribean', 'europe']}  value={phoneNumber}  onChange={(e) => setPhoneNumber(e)}/>
                </div>}
                { extension != null && <div className="col-3 col-sm-2 ">
                    <input className="form-control-lg form-control  input-text py-0" type="number" placeholder="Ext" value={extension} onChange={(e) => setExtension!(e.target.value)}  />
                </div>}
                {phoneNumbers != null && <div className="col-1">
                    <button className="btn p-0 mt-2 "disabled ={(phoneNumber == "" || typeOption == "")} onClick={handleAddPhoneNumber}>
                        <FontAwesomeIcon className="text-info fs-2" icon="plus" />
                    </button>
                </div>}
            </div>
            {phoneNumbers != null && <div className="mt-4 mb-4">
                { phoneNumbers.map ((e:PhoneNumberType) => (
                    <div className="form-group row mb-3  " key={e.number+e.type}>
                        <div className="col-0 col-sm-3  col-form-label text-center ms-3" ></div>
                        <div className="col-3 col-sm-2 border-bottom pb-0">
                            <p className="text-begin ms-2">{phoneOptions.find(f => f.value == e.type )?.label}</p>
                        </div>
                        <div className="col-5 col-sm-3  border-bottom">
                            <p className="text-begin ms-2">{e.number}</p>
                        </div>
                        <div className="col-3 col-sm-2 border-bottom">
                            <p className="text-begin align-bottom ms-1">{e.extension}</p>
                        </div>
                        <div className="col-1 ">
                            <button className="btn p-0 ms-2" onClick={() => handleDeletePhoneNumber(phoneNumbers.indexOf(e))}>
                                <FontAwesomeIcon className="text-danger" icon="delete-left" />
                            </button>
                        </div>
                </div>))}
            </div>}
        </div>
    )
}