import React, { useEffect, useLayoutEffect, useState } from 'react';
import useAppDispatch from './hooks/useAppDispatch';
import { selectConfig, selectName, setAddDeployment, setCompanyFeatures, setDevelopment, setFeatures, setName, setSysAdmin } from './redux/slices/config';
import useAppSelector from './hooks/useAppSelector';
import { useAuth } from 'react-oidc-context';
import { CompanyFeatures, Features, GetUserRequest } from './common/store';
import API from './common/API';
import App from './App';
import LoadingPageComponent from './components/shared/LoadingPageComponent';
import { render } from 'sass';
import { RequireAuth } from './layout/api-authorization/RequireAuth';
import { useNavigate } from 'react-router-dom';

export default function()
{
    const auth = useAuth();
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const [loading, setLoading] = useState<boolean>(true)
    useEffect(() => {
        const id = auth.user?.profile.client_id
        const state = auth.user?.state
        console.log(state)
        if (id != null && auth.isAuthenticated)
        {
            if (state != undefined && state != null)
              navigate(`${state}`)
            middleware()
        }
        else{
            unloggedMiddleware()
        }
    },[auth])

    const unloggedMiddleware = async () => {
      await getFeatures()
      if (auth.isLoading == false)
          setLoading(false)
    }
    
    const middleware = async () => {
        await getUser()
        await getFeatures()
        await getCompanyFeatures()
       setLoading(false)
    }


  const getUser = async () => {
    try {
        const response: any = await API.get(`/api/v1/User/${auth.user?.profile.client_id}`, auth)
        const data: GetUserRequest = response.result
        dispatch(setName(data.firstName))
        dispatch(setSysAdmin(data.isAdmin))
        dispatch(setAddDeployment(data.manageDeployments || data.manageHostedDeployments))
    }
    catch (e: any) {
      alert(e.errors?.Error!)
    }
  }
  const getFeatures = async () => {
    try {
      const response: Features|null = await API.get(`/api/v1/features`)
      if (response != null)
      {
        dispatch(setFeatures(response))
      }
    }
    catch (e: any) {
      console.log(e)
    }
  }

  const getCompanyFeatures = async () => {
    try {
      const response: CompanyFeatures|null = await API.get(`/api/v1/companyFeatures`, auth)
      if (response != null)
      {
        dispatch(setCompanyFeatures(response))
      }
    }
    catch (e: any) {
      alert(e.errors?.Error!)
    }
  }
    return( 
        <div>
            {loading && 
                <div style={{marginTop: window.screen.height / 3}}>
                    <LoadingPageComponent/>
                </div>
                
            }
            {!loading && <App/>}
        </div>
    
    )

}