import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import React, { useState } from "react";
import TextInputComponent from "../shared/TextInputComponent";
import { CompanyFeatures } from "../../common/store";
import { getCompanyFeatureGate } from "../../redux/slices/config";
import useAppSelector from "../../hooks/useAppSelector";

// Tier = 1 => Odyssey, Tier = 0 => ProtectorNet
export default function ({ currentChoice, setCurrentChoice, value, display, tier }:
    { currentChoice?: number, setCurrentChoice?: (value: React.SetStateAction<number>) => void, value: number, display?: boolean, tier: number }) {

    const companyFeatureGate: CompanyFeatures = useAppSelector(getCompanyFeatureGate)
    const oem = companyFeatureGate.oem
    var label = ''
    var Door = 0
    var Camera = 0
    var Partitions = 0
    var Elevator = false
    var IO = false
    var Ldap = false
    var Unmanaged = false

    const { t } = useTranslation();
    if (value == 1) {
        label = t("package:essentials")
        Door = tier == 1 ? 40 : (40)
        Camera = tier == 1 ? 40 : (40)
        Partitions = tier == 1 ? oem == "Aiphone" ? 1 :  10000000 : (1)
        Elevator = tier == 1 ? true : (false)
        IO = (true)
        Ldap = tier == 1 ? true : (false)
        Unmanaged = tier == 1 ? true : (false)
    }
    else if (value == 2) {
        label = t("package:small")
        Door = tier == 1 ? oem == "Aiphone" ? 80 : 125 : (80)
        Camera = tier == 1 ? oem == "Aiphone" ? 80 : 125 : (80)
        Partitions = tier == 1 ? oem == "Aiphone" ? 5 : 10000000 : (5)
        Elevator = (true)
        IO = (true)
        Ldap = (true)
        Unmanaged = (true)
    }
    else if (value == 4) {
        label = t("package:mid")
        Door = tier == 1 ? oem == "Aiphone" ? 120 : 500 : (120)
        Camera = tier == 1 ? oem == "Aiphone" ? 120 : 500 : (120)
        Partitions = tier == 1 ? oem == "Aiphone" ? 20 : 10000000 : (20)
        Elevator = (true)
        IO = (true)
        Ldap = (true)
        Unmanaged = (true)
    }
    else if (value == 256) {
        label = t("package:large")
        Door = tier == 1 ? oem == "Aiphone" ? 240 : 1000 : (240)
        Camera = tier == 1 ? oem == "Aiphone" ? 240 :1000 : (240)
        Partitions = tier == 1 ? oem == "Aiphone" ? 40 : 10000000 : (40)
        Elevator = (true)
        IO = (true)
        Ldap = (true)
        Unmanaged = (true)
    }
    else if (value == 8) {
        label = t("package:enterprise")
        Door = (10000000)
        Camera = (10000000)
        Partitions = (10000000)
        Elevator = (true)
        IO = (true)
        Ldap = (true)
        Unmanaged = (true)
    }
    else if (value == 512) {
        label = t("package:basic")
        Door = (20)
        Camera = (20)
        Partitions = (5)
        Elevator = (true)
        IO = (true)
        Ldap = (true)
        Unmanaged = (true)
    }
    else if (value == 1024) {
        label = t("package:standard")
        Door = (250)
        Camera = (250)
        Partitions = (10000000)
        Elevator = (true)
        IO = (true)
        Ldap = (true)
        Unmanaged = (true)
    }
    return (

        <div className={`mb-3 ${display == null ? 'col-12 col-sm' : ''}  `}>
            {display == null && <div className="card border-1 p-0 mb-1">
                <div className="card-header  border-0">
                    <h3 className=' text-center header-text'>{label}</h3>
                </div>
                <div className="card-body bg-opacity-75 border-0 py-0 px-0 ">
                    <ul className={"list-group fw-bold list-group-flush list-group-striped"}>
                        <li className={"list-group-item text-center"} >{t('license:doors')}:{Door == 10000000 ? t('unlimited') : Door}</li>
                        <li className={"list-group-item text-center"} >{t('license:cameras')}:{Camera == 10000000 ? t('unlimited') : Camera}</li>
                        <li className={"list-group-item text-center"} >{t('license:partitions')}:{Partitions == 10000000 ? t('unlimited') : Partitions}</li>
                        <li className={"list-group-item text-center"} >{t('license:elevators')}:{Elevator ? t('true') : "False"}</li>
                        <li className={"list-group-item text-center"} >{t('license:IO')}:{IO ? t('true') : t('false')}</li>
                        <li className={"list-group-item text-center"} >{t('license:LDAP')}:{Ldap ? t('true') : t('false')}</li>
                        <li className={"list-group-item text-center"} >{t('license:unmanagedDoors')}:{Unmanaged ? t('true') : t('false')}</li>
                    </ul>
                </div>
            </div>}
            {display == null && <button className={currentChoice == value && currentChoice != null ? "btn btn-success col-12 mt-2" : "btn btn-secondary col-12 mt-2"}
                onClick={() => setCurrentChoice != null ? setCurrentChoice(value) : {}}>{t('select')}</button>}


            {display != null &&
                <div className="">
                    <div className=" card-header pb-0 text-center">
                        <h3 className='mb-4 header-text ms-1'><FontAwesomeIcon icon="cube" className="fs-3 me-2" />{`${label} ${t('license:package')}`}</h3>
                    </div>
                    <div className="card-body p-0 pb-4">
                        <TextInputComponent smallLabel inputClass='col-6 col-sm-5' labelClass="col-6 col-sm-5 col-form-label text-end" label={t('license:doors')} value={Door == 10000000 ? t('unlimited') : Door.toString()} noEdit />
                        <TextInputComponent smallLabel inputClass='col-6 col-sm-5' labelClass="col-6 col-sm-5 col-form-label text-end" label={t('license:cameras')} value={Camera == 10000000 ? t('unlimited') : Camera.toString()} noEdit />
                        <TextInputComponent smallLabel inputClass='col-6 col-sm-5' labelClass="col-6 col-sm-5 col-form-label text-end" label={t('license:partitions')} value={Partitions == 10000000 ? t('unlimited') : Partitions.toString()} noEdit />
                        <TextInputComponent smallLabel inputClass='col-6 col-sm-5' labelClass="col-6 col-sm-5 col-form-label text-end" label={t('license:elevators')} value={Elevator ? t('true') : t('false')} noEdit />
                        <TextInputComponent smallLabel inputClass='col-6 col-sm-5' labelClass="col-6 col-sm-5 col-form-label text-end" label={t('license:IO')} value={IO ? t('true') : t('false')} noEdit />
                        <TextInputComponent smallLabel inputClass='col-6 col-sm-5' labelClass="col-6 col-sm-5 col-form-label text-end" label={t('license:LDAP')} value={Ldap ? t('true') : t('false')} noEdit />
                        <TextInputComponent smallLabel inputClass='col-6 col-sm-5' labelClass="col-6 col-sm-5 col-form-label text-end" label={t('license:unmanagedDoors')} value={Unmanaged ? t('true') : t('false')} noEdit />
                    </div>
                </div>

            }
        </div>
    )
}