import { useEffect } from "react";
import { CompanyFeatures } from "../common/store";
import { getCompanyFeatureGate } from "../redux/slices/config";
import useAppSelector from "./useAppSelector";
import useLocalStorage from "./useLocalStorage";

function useSettingsState(key: any, initialValue: string) {
  const [value, setValue] = useLocalStorage(key, initialValue);
  const companyFeatureGate: CompanyFeatures = useAppSelector(getCompanyFeatureGate)
  useEffect(() => {
    // Set data attribute on body element
    document.body.dataset[key] = value;

    // Replace style sheet if dark theme gets toggled
    if (key === "theme") {
      const theme = value === "dark" ? "dark" : "light";
      const stylesheet = document.querySelector(".js-stylesheet");
      stylesheet!.setAttribute("href", `/css/${theme}.css`);
    }
  }, [value, key]);

  return [value, setValue];
}

export default useSettingsState;
