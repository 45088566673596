import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { t } from "i18next";
import React from "react";
import internal from "stream";

export type OptionType = {
    label: string;
    value?: string;
    i1? : number;
    i2?: number;
    s1?: string;
    s2?: string;
    func?: Function
};

export type WizardOption = {
    label: string;
    icon: IconProp;
    value: string;
};

export type PhoneNumberType = {
    type: string|undefined,
    number: string,
    extension: string,
    id?: number,
};

export interface Features {
    backups: boolean;
    onlineDeployments: boolean;
    devPayment: boolean;
    hostedDeployments: boolean;
}

export interface CompanyFeatures {
    allowedLocalLicensePayment: boolean;
    oem: string;
    timezone:string;
    language: string;
    allowedOdyssey:boolean,
    allowedInvoice: boolean,
    allowedLicenseCard: boolean;
    allowedHostedPayment: boolean;
    allowedHostedLicenseCard:boolean;
    allowedCameraLicensing:boolean;
    allowPayBeforeActivate:boolean
}

export interface Config {
    type: string;
    prod: boolean;
}

export interface SoftwareDownload {
    productName: string;
    file: string;
    version:string;
}

export interface Company{
    companyId:    number;
    companyName:  string;
    companyType:  string;
    website:      string;
    email:        string;
    phoneNumbers: PhoneNumberType[];
    address:      AddressInfo,
}
export type CompanyInfo=Omit<Company,"companyId">

export interface CompanyEmail{
    emailAddress: string;
    attachInvoice: boolean;
    emailSendType?: number;
    id?: number;
}

export interface GetCompanyRequest {
    companyId:    number;
    companyName:  string;
    companyType:  string;
    website:      string;
    email:        string;
    phoneNumbers: PhoneNumberType[];
    street:       string;
    city:         string;
    postalCode:   string;
    country:      string;
    region:       string;
    emailSubscriptions: number;
    companyEmails?: CompanyEmail[];
    cardNumber?: string;
    paymentExpiry?: Date;
}

export interface GetUserRequest {
    firstName:   string;
    lastName:    string;
    phoneNumber: PhoneNumberType;
    language:    string;
    timezone:    string;
    email:       string;
    id:          number;
    isAdmin:     boolean;
    lastLoggedIn: string;
    manageCompany: boolean;
    manageDeployments: boolean;
    manageHostedDeployments: boolean;
    manageLicenses: boolean;
    managePayments: boolean
}

export interface Roles{
    isAdmin: boolean;
    manageCompany: boolean;
    manageDeployments: boolean;
    manageHostedDeployments: boolean;
    manageLicenses: boolean;
    managePayments: boolean
}

export interface Activity{
    id: number,
    message: string, 
    time: string,
    userName: string
}

export interface DeploymentDTO  {
    name: string,
    accountNumber: number,
    type: string,
    package:number|string,
    licenseTerm: number|string, 
    activationDate: string,
    expirationDate: string
    subdomain:string,
    status: string, 
    licenseTier: string,
    createdTime?: string,
    createdOn?: string,
    nextPayment?: string,
    nextPaymentTime?: string
}

export interface LicenseDTO {
    id:                     number;
    type:                   number;
    package:                number;
    accountNumber:          number;
    features:               number;
    duration:               number;
    description:            string;
    demo:                   boolean;
    rearm:                  boolean;
    createdOn:              Date;
    activationDate:         Date;
    expirationDate:         Date;
    responseKey:            string;
    requestMac:             string;
    method:                 number;
    multicard:              boolean;
    additional5Partitions:  boolean;
    additional10Partitions: boolean;
    additional20Partitions: boolean;
    activated:              boolean;
    deploymentId:           number;
    licenseTier:            number;
    invoice?: Invoice;
}
export type LicenseInfo =Omit<LicenseDTO,"id">

export type Deployment = {
    id: number,
    title: string,
    createdDate: string,
    modifiedDate?: string,
    author: string,
    text: string,
    image?: string,
}

interface BaseDeploymentReponse{
    id:            number;
    name:          string;
    type:          number; 
    description:   string;
    demo:          boolean;
    createdOn:     Date;
    updatedOn:     Date;
    contactId:     number;
    addressId:     number;
    companyId:     number;
    cardNumber?: string;
    paymentExpiry?: Date;
}


export interface DeploymentResponse extends BaseDeploymentReponse{
    accountNumber: number;
    activeLicense?: LicenseDTO;
    online? : boolean;
    licenseAddOns?: LicenseAddOn[] 
}
export interface HostedDeploymentResponse extends BaseDeploymentReponse{
    numOfPanels: number;
    multicard: boolean;
    schedule: number;
    status: number;
    tierType: number;
    tenant: Tenant;
    nextPayment: Date; 
    pending: Pending;
    needsToBeUpgraded?: number,
    aceCardPaid : boolean,
    userCardPaid: boolean,
    hostedDevices?: HostedDevicesResponse[]
}

export interface HostedDevicesResponse {
    device: HostedDeviceType,
    amount: number
    pendingAmount?: number
}

export interface Pending{
    id:number;
    pendingDeletion: boolean;
    pendingNumOfPanels? : number;
    pendingSchedule?: number;
}

export interface Tenant {
    tenantId:         string;
    subdomain:        string;
    connectionString: null;
    numOfUsers:       number;
    activated:        boolean;
    deletedOn:        null;
    deleted:          boolean;
    deploymentId:     null;
    deployment:       null;
    id:               number;
    extraOptions:     number;
}

export interface LicenseAddOn 
{
    count: number;
    feature:number;
}

export type DeploymentInfo = {
  name:string,
  label:string,
  accountNumber:number,
  type:string,
  description:string,
  deploymentTypes:OptionType[],
}

export interface Address {
    id:         number;
    street:     string;
    city:       string;
    postalCode: string;
    state:      string;
    country:    string;
}
export type AddressInfo=Omit<Address,"id">

export interface Contact {
    id:                 number;
    name:               string;
    emailAddress:       string;
    emailSubscriptions: number;
    phoneNumber:        string;
    companyId:          number;
    deploymentId:       number|null;
}
export type ContactInfo=Omit<Contact,"id"|"companyId"|"deploymentId">

export interface CompanyRegisterInfo{
    companyName: string;
    companyType: string;
}

export interface GetDeployment {
    deploymentId: number;
    address:      Address;
    contact:      Contact;
    deployment?:   DeploymentResponse;
    hostedDeployment?: HostedDeploymentResponse;
    odysseyInfo?:    OdysseyInfo;
    isConnected?:      boolean;
    panels?:           PanelDTO[];
    latestInvoiceId?: number;
}

export interface OdysseyInfo {
    id:             number;
    deploymentId:   number;
    doorCount:      number;
    partitionCount: number;
    panelCount:     number;
    userCount:      number;
    lastUpdatedOn:  Date;
}

export interface BackupInfo {
    deploymentId:   number;
    lastBackedUpOn?: Date;
    currentlyBackingUp?:boolean;
    backupType?:number;
    pendingBackupType?:number;
    currentBackupsSize?:number;
    currentBackupsSizeString?: string;
    backupLimit?: number;
    disabled?:boolean;
    schedule?: number;
    pendingSchedule?: number;
    nextPayment?: Date;
    addOnSize?:number;
    pendingAddOnSize?: number;
}

export interface OnlineClientInfo{
    odysseyClientId: number;
    currentlyConnected: boolean;
    onlineStatuses: OnlineStatusInfo[];
}

export interface OnlineStatusInfo{
    odysseyInfo: OdysseyInfo;
    currentlyConnected: boolean;
    deploymentName: string;
}


export interface HostedPackage{
    multiCardEnabled: boolean,
    packageTerm: number,
    numOfPanels: number,
    numberOfDoors?: number,
    numberOfElevators?: number,
    numberOfIOExpanders?: number
    pendingDoors?: number;
    pendingElevators?: number;
    pendingIOExpanders?: number;
}


export type PanelInfo={
    macAddress:string,
    createdOn:Date|string
}


export type PanelDTO= PanelInfo&{
    id:number,
    deploymentId:number,
    panelType?:    string;
}

export interface DashboardInfo {
    numberOfUsers:                     number;
    numberOfTotalDeployments:          number;
    numberOfTotalDeployedDeployments:  number;
    numberOfHostedDeployments:         number;
    numberOfLicensedDeployments:       number;
    numberOfOnlineLicensedDeployments: number;
    latestActivities:                  Activity[];
    latestStatusUpdates:               LatestStatusUpdate[];
    odysseyData:                       OdysseyData;
    companyName:                       string;
    userName:                          string;
}

export interface LatestStatusUpdate {
    deploymentName: string;
    time:           Date;
    id: number;
}

export interface OdysseyData {
    totalUsers:      number;
    totalDoors:      number;
    totalPanels:     number;
    totalPartitions: number;
}

export interface Invoice {
    id:           number;
    subTotal:     number;
    taxRate:      number;
    taxes:        number;
    discountRate: number;
    discount:     number;
    total:        number;
    createdOn:    Date;
    dueDate:      Date;
    paidOn?:      Date;
    invoiceNumber: string;
    billingAddress: Address;
    currency: string;
    exported?: boolean;
    purchaseOrder?: string;
}

export interface InvoiceItem{
    description: string;
    quantity: number;
    amount: number;
}

export interface ViewInvoiceDTO{
    invoice: Invoice;
    invoiceItems: InvoiceItem[]
}

export interface HostedPaymentDetailsDTO{
    invoice: Invoice;
    package: HostedPackage;
}

export interface PaymentDetails{
    title: string;
    details: OptionType[]
}

export interface AuthorizeCardInfo{
    id: number
}

export interface Transaction {
    id:                number;
    type:              number;
    state:             number;
    provider:          number;
    orderId:           string;
    amount:            number;
    authCode:          null | string;
    message:           null | string;
    payerReference:    string;
    paymentReference:  string;
    messageId:         null | string;
    invoiceId:         number;
    responseTimestamp: string;
}

export interface ProductPricing {
    partNumber: string;
    price:      number;
}

export interface Backup {
    id:        number;
    name:      string;
    timeStamp: string;
    size:      string;
}
export enum HostedDeviceType{
    Doors,
    Elevators,
    IOExpanders
}
export interface HostedDeviceCost{
    DeviceType: HostedDeviceType,
    Subtotal: number,
    Total: number,
    Amount: number,
    DiscountAmount?: number,
    LargestDiscountAmount?: number
}

export interface CreateCameraLicense{
    Package: number,
    Feature:number,
    Duration: number,
    RequestKey:string
}

export interface CameraLicense {
    type:                 number;
    package:              number;
    feature:              number;
    recordingServerCount: number;
    cameraCount:          number;
    duration:             number;
    description:          string;
    rearm:                boolean;
    createdOn:            Date;
    activationDate:       Date;
    expirationDate:       Date;
    responseKey:          string;
    requestMac:           string;
    serverType:           number;
    deploymentId:         number;
}

export interface PaymentRefDTO
{
    cardNumber: string;
    paymentExpiry: Date;
    id: number;
    isDeployment: boolean;
}

export interface DeploymentPaymentInfo
{
    id: number;
    name:string ;
    samePayment: boolean;
}


// export const licenseTiers:OptionType[] = [{label:"ProtectorNet", value:"0"}, {label:"Odyssey", value:"1"}]
export const licenseTiers:OptionType[] = [{label:"ProtectorNet", value:"0"}]
export const aiphoneLicenseTiers:OptionType[] = [ {label:"AC NIO", value:"1"}]
export const languageOptions: OptionType[] = [{ value: 'es-ES', label: 'Spanish' }, { value: 'en-US', label: 'English' }, { value: 'fr-FR', label: 'French' }]
