import { faL } from "@fortawesome/free-solid-svg-icons";
import { t } from "i18next";
import React, { useEffect, useState } from "react";
import { useAuth } from "react-oidc-context";
import API from "../../common/API";
import { AuthorizeCardInfo, CompanyFeatures, Features, Invoice, OptionType, PaymentDetails, PaymentRefDTO } from "../../common/store";
import EULAModalComponent from "../../components/shared/EULAModalComponent";
import LoadingPageComponent from "../../components/shared/LoadingPageComponent";
import TextInputComponent from "../../components/shared/TextInputComponent";
import useAppSelector from "../../hooks/useAppSelector";
import { getCompanyFeatureGate, getFeatureGate, selectConfig } from "../../redux/slices/config";
import { loadConverge, loadConvergeDemo } from "../../scripts/loadConverge";
import ViewInvoice from "./ViewInvoice";
import PaymentInfo from "./PaymentInfo";
import Cards from 'react-credit-cards-2'
declare const window: any;
export default function ({ invoice, postFunction, paymentDetails, authorize, showEula, licensePayment, hostedPayment, companyAuthorize }: {
    invoice?: Invoice | null, postFunction: Function, paymentDetails?: PaymentDetails,
    authorize?: AuthorizeCardInfo, showEula?: boolean, licensePayment?: boolean, hostedPayment?: boolean, companyAuthorize?: boolean
}) {

    const [token, setToken] = useState<string>('')
    const [loading, setLoading] = useState<boolean>(false)
    const [loadingScript, setLoadingScript] = useState<boolean>(false)
    const [formHtml, setFormHtml] = useState<string>("")
    const [status, setStatus] = useState<string>("")
    const [message, setMessage] = useState<string>("")
    const invoiceNumber = invoice == null ? "12312" : invoice.invoiceNumber
    const auth = useAuth()
    const [verifyPaymentCounter, setVerifyPaymentCounter] = useState<number>(0)
    const [paymentResponse, setPaymentResponse] = useState<any>(null)
    const [updatePurchaseOrder, setUpdatePurchaseOrder] = useState<boolean>(false)

    const [showEulaModal, setShowEulaModal] = useState<boolean>(false)
    const [showEulaModalExistingPayment, setShowEulaModalExistingPayment] = useState<boolean>(false)

    const releaseMode = useAppSelector(selectConfig)
    const featureGate: Features = useAppSelector(getFeatureGate)
    const companyFeatureGate: CompanyFeatures = useAppSelector(getCompanyFeatureGate)

    const [paymentReference, setPaymentReference] = useState<PaymentRefDTO|null>(null)
    const [showExisiting, setShowExisiting] = useState<boolean>(false)
    const [payExp, setPayExp] = useState<string>('')

    const getPaymentReference = async () => {
        try{
            let response: PaymentRefDTO|undefined|null = await API.get("api/v1/payment/paymentReference/" + `${invoiceNumber}`, auth)
            console.log("Response" + response?.cardNumber)
            if (response == null || response == undefined)
                return
            setPayExp(response.paymentExpiry!.toString().split("-")[1] + "/" + response.paymentExpiry!.toString().split("-")[0].slice(-2))
            setPaymentReference(response!)
        }
        catch (e: any) {
            console.log (e)
        }
    }

    const getToken = async () => {
        setLoading(true)
        try {
            const amount = invoice != null ? invoice.total : 123
            const response: any = await API.get("api/v1/payment/paymentToken/" + `${invoiceNumber}`, auth)
            setShowExisiting(false);
            setToken(response)
            setLoading(false)
        }
        catch (e: any) {
            setLoading(false)
            alert(e.errors?.Error!)
        }
    }

    const getAuthToken = async (invNumber: string) => {
        setLoading(true)
        try {
            const amount = invoice != null ? invoice.total : 123
            const response: any = await API.get(`api/v1/payment/authToken/${invNumber}/${!companyAuthorize}`, auth)
            setToken(response)
            setLoading(false)
        }
        catch (e: any) {
            setLoading(false)
            alert(e.errors?.Error!)
        }
    }

    const createAuthInvoice = async () => {
        setLoading(true)
        try {
            let url = "api/v1/payment/authInvoice/deployment/"
            if (companyAuthorize)
                url = "api/v1/payment/authInvoice/company/"
            const response: Invoice | null = await API.post(`${url}${authorize?.id}`, {}, auth)
            if (response != null)
                getAuthToken(response.invoiceNumber)
        }
        catch (e: any) {
            setLoading(false)
            alert(e.errors?.Error!)
        }
    }

    const verifyPayment = async (transactionResponse: any) => {
        setLoading(true)
        try {
            var object = { ResultCode: Number(transactionResponse.ssl_result), AuthCode: transactionResponse.ssl_approval_code, CardNumber: transactionResponse.ssl_card_number, CardToken: transactionResponse.ssl_token, Amount: Number(transactionResponse.ssl_amount), ExpiryString: transactionResponse.ssl_exp_date }
            const response: any = await API.post(`api/v1/payment/verifyPayment/${transactionResponse.ssl_invoice_number}`, object, auth)

            if (response != null && response.startsWith("Error:")) {
                setLoading(false)
                setVerifyPaymentCounter(0)
                setStatus("")
                alert(response)
                return
            }

            setStatus("")
            setVerifyPaymentCounter(0)
            if (postFunction != null) {
                if (authorize != null) {
                    await postFunction(transactionResponse.ssl_card_number, transactionResponse.ssl_exp_date)
                    setLoading(false)
                }
                else {
                    await postFunction()
                }
            }
        }
        catch (e: any) {
            if (verifyPaymentCounter == 10) {
                setLoading(false)
                setVerifyPaymentCounter(0)
                setStatus("")
                alert(t("payment:paymentTimeError"))
                return;
            }
            setTimeout(
                () => {
                    setVerifyPaymentCounter(verifyPaymentCounter + 1)
                }, 5000
            )
        }
    }

    const openLightbox = async () => {
        var paymentFields = {
            ssl_txn_auth_token: token
        };
        var callback = {
            onError: function (error: any) {
                showResult(t("payment:error"), error);
            },
            onCancelled: function () {
                showResult(t("payment:cancelled"), "");
            },
            onDeclined: function (response: any) {
                showResult(t("payment:declined"), JSON.stringify(response, null, '\t'));
            },
            onApproval: function (response: any) {
                showResult(t("payment:approved"), JSON.stringify(response, null, '\t'));
                if (postFunction != null) {
                    setPaymentResponse(response)
                    setVerifyPaymentCounter(verifyPaymentCounter + 1)
                }
            }
        };
        window.PayWithConverge.open(paymentFields, callback);
        return false;
    }

    const payWithExisting = async () => {
        console.log ("Paying With Existing")
        try{
            setLoading(true)
            setShowExisiting(false);
            const response: any = await API.post(`api/v1/payment/verifyPayment/existing/${invoiceNumber}`,paymentReference!, auth)
            await postFunction()
            //setLoading(false)
        }
        catch (e: any) {
            setLoading(false)
            alert(e.errors?.Error!)
        }
    }

    const showResult = (status: string, message: string) => {
        setStatus(releaseMode ? "" : status)
        setMessage(message)
    }

    useEffect(() => {
        if (!featureGate.devPayment) {
            loadConverge(() => {
                setLoadingScript(true)
            })
        }
        else {
            loadConvergeDemo(() => {
                setLoadingScript(true)
            })
        }
        getPaymentReference()
    }, [])

    useEffect(() => {
        if (token != '') {
            openLightbox()
        }
    }, [token])

    useEffect(() => {
        if (verifyPaymentCounter == 0)
            return
        if (paymentResponse == null) {
            setVerifyPaymentCounter(verifyPaymentCounter + 1)
        }
        else {
            verifyPayment(paymentResponse)
        }
    }, [verifyPaymentCounter])

    useEffect(() => {
        setShowExisiting(false)
    },[showEulaModalExistingPayment, showEulaModal])

    return (
        <div>
            <div className="">
                {!loading && <div className="card-body py-0">
                    {invoice != null &&
                        <ViewInvoice iId={invoice.id} updatePurchaseOrder={updatePurchaseOrder} />
                    }
                    {/* {token != '' && <TextInputComponent label={"Token"} value={token} noEdit  />} */}
                    {/* {authorize == null && <button className="btn btn-flickr float-end" onClick={() => getToken()}>{`${t("pay")}`}</button>} */}
                    {authorize == null &&
                        <div onClick={() => setUpdatePurchaseOrder(true)} >
                            {(hostedPayment != null && hostedPayment == true && companyFeatureGate.allowedHostedPayment && paymentReference == null ) && 
                                <button className="btn btn-flickr float-end ms-2" onClick={() => showEula == null ? getToken() : setShowEulaModal(true)}>{`${t("pay")}`}</button>
                                
                            }
                            {(licensePayment != null && licensePayment == true && companyFeatureGate.allowedLocalLicensePayment && paymentReference == null ) &&
                                <button className="btn btn-flickr float-end ms-2" onClick={() => showEula == null ? getToken() : setShowEulaModal(true)}>{`${t("pay")}`}</button>
                             }
                            {(hostedPayment != null && hostedPayment == true && companyFeatureGate.allowedHostedPayment && paymentReference != null ) && 
                                <button className="btn btn-flickr float-end ms-2" onClick={() =>setShowExisiting(true)}>{`${t("pay")}`}</button>
                                
                            }
                            {(licensePayment != null && licensePayment == true && companyFeatureGate.allowedLocalLicensePayment && paymentReference != null) &&
                                <button className="btn btn-flickr float-end ms-2" onClick={() =>setShowExisiting(true)}>{`${t("pay")}`}</button>
                             }
                            {(licensePayment != null && licensePayment == true && companyFeatureGate.allowedInvoice) && <button className="btn btn-flickr float-end ms-2" onClick={() => postFunction(true)!}>{`${t("invoice:invoice")}`}</button>}
                            {(hostedPayment != null && hostedPayment == true && companyFeatureGate.allowedInvoice) && <button className="btn btn-flickr float-end ms-2" onClick={() => postFunction(true)!}>{`${t("invoice:invoice")}`}</button>}
                        </div>}
                    {authorize != null && <button className="btn btn-facebook float-end" onClick={() => createAuthInvoice()}>{`${t("payment:updateCard")}`}</button>}
                </div>}
                {loading && <LoadingPageComponent />}
            </div>
            {showEulaModal == true &&
                <EULAModalComponent showModal={showEulaModal} setShowModal={setShowEulaModal} actionFunction={getToken} />
            }
            {showEulaModalExistingPayment == true &&
                <EULAModalComponent showModal={showEulaModalExistingPayment} setShowModal={setShowEulaModalExistingPayment} actionFunction={payWithExisting} />
            }
            { showExisiting && 
                
                <div className={"modal fade show"} style={{ display: "block", background: " rgba(0, 0, 0, 0.6)" }}>
                    <div className="modal-dialog modal-dialog-centered" style={{ maxWidth: "900px" }}>
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">{`${t("payExisting")}`}</h5>
                                <button type="button" className="btn-close" onClick={e => setShowExisiting(false)}></button>
                            </div>
                            <div className="modal-body">
                                <Cards expiry={payExp} number={paymentReference?.cardNumber} name={" "} cvc={""} focused={"number"}/>
                            </div>
                            <div className="modal-footer" >
                                <button type="button" className="btn btn-dribbble float-end ms-2"  onClick={() =>  showEula == null ? getToken() : setShowEulaModal(true)}>{`${t("payNew")}`}</button>
                                <button className="btn btn-flickr float-end ms-2" onClick={() => showEula == null ? payWithExisting() : setShowEulaModalExistingPayment(true)}>{`${t("pay")}`}</button>
                            </div>
                        </div>
                    </div>
                </div>
            }
            {(status != "") &&
                <div className="">
                    <div className="card-body">
                        <div dangerouslySetInnerHTML={{ __html: status }}></div>
                        <div dangerouslySetInnerHTML={{ __html: message }}></div>
                    </div>
                </div>

            }
        </div>
    )
}