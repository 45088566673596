import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { t } from "i18next";
import React, { useEffect, useState } from "react";
import { useAuth } from "react-oidc-context";
import { useNavigate } from "react-router-dom";
import API from "../../common/API";
import { OnlineClientInfo, OnlineStatusInfo } from "../../common/store";
import { OdysseyInfoComponent } from "../../components/deployment/OdysseyInfo";
import OdysseyOnlineStatus from "../../components/deployment/OdysseyOnlineStatus";
import LoadingPageComponent from "../../components/shared/LoadingPageComponent";
import { PaginationComponent } from "../../components/shared/PaginationComponent";
import TextInputComponent from "../../components/shared/TextInputComponent";

export default function(){
    const auth = useAuth()
    const navigate = useNavigate()
    const [loading, setLoading] = useState<boolean>(false)
    const [onlineStatusInfo, setOnlineStatusInfo] = useState<OnlineStatusInfo[]|null>()
    const [onlineClientInfo, setOnlineClientInfo] = useState<OnlineClientInfo[]|null>()

    const [perPage, setPerPage] = useState<number>(5)
	const [pageNumber, setPageNumber] = useState<number>(1)
	const [maxPageNumber, setMaxPageNumber] = useState<number>(0)
	const [pages, setPages] = useState<number[]>([])
    
    
    const [showArray, setShowArray] = useState<boolean[]>([])
    const [showClientArray, setShowClientArray] = useState<boolean[]>([])
    const [selection, setSelection] = useState<string>("Clients")

    
    const getOnlineStatus = async() =>{
        try{
            const response:any = await API.get(`/api/v1/onlineStatus?PerPage=${perPage}&PageNumber=${pageNumber}`, auth)
            const results:OnlineStatusInfo[]|null = response.results
            const array:boolean[] = []
            results?.forEach(e => {
                array.push(false)
            })
            setShowArray(array)
            setOnlineStatusInfo(results)

            setMaxPageNumber(Math.ceil(response.totalRecords/ perPage))
            const pageArray:number[] = []
            for (let index = 1; index <= Math.ceil(response.totalRecords/ perPage); index++){
            pageArray.push(index)
            }
            setPages(pageArray)
            setLoading(false)
        }
        catch(e:any){
            alert(e.errors?.Error!)
        }
    }

    const getOnlineClientStatus = async() =>{
        try{
            const response:any = await API.get(`/api/v1/onlineClientStatus?PerPage=${perPage}&PageNumber=${pageNumber}`, auth)
            const results:OnlineClientInfo[]|null = response.results
            const array:boolean[] = []
            const childArray:boolean[] = []
            const statusInfoArray:OnlineStatusInfo[] = []
            results?.forEach((e:OnlineClientInfo) => {
                array.push(false)
                e.onlineStatuses.forEach( f => {
                    childArray.push(false)
                    statusInfoArray.push(f)
                })
            })
            setShowClientArray(array)
            setShowArray(childArray)
            setOnlineClientInfo(results)
            setOnlineStatusInfo(statusInfoArray!)

            setMaxPageNumber(Math.ceil(response.totalRecords/ perPage))
            const pageArray:number[] = []
            for (let index = 1; index <= Math.ceil(response.totalRecords/ perPage); index++){
            pageArray.push(index)
            }
            setPages(pageArray)
            setLoading(false)
        }
        catch(e:any){
            alert(e.errors?.Error!)
        }
    }

    useEffect(() => {
        setLoading(true)
        selection == "Deployments" ? getOnlineStatus() : getOnlineClientStatus()
    }, [pageNumber])

    useEffect(() => {
        setLoading(true)
        if (pageNumber == 1)
            selection == "Deployments" ? getOnlineStatus() : getOnlineClientStatus()
        setPageNumber(1)
    }, [selection])

    const clickHandler = (clientInfo:OnlineClientInfo) => {
        const index:number|undefined = onlineClientInfo?.indexOf(clientInfo)
        const value = !showClientArray[index!]
        const allItems:boolean[] = JSON.parse(JSON.stringify(showClientArray));
        allItems[index!] = value
        setShowClientArray(allItems)
        return
    }


    return(
        <div className="p-0 container-fluid row">
            <h1 className=" mb-3 d-inline col-12 col-sm-6"><FontAwesomeIcon icon="heart-pulse" className="fs-3 me-2" />{`${t("onlineStatusOverview")}`}</h1>
            <div className="d-block d-sm-inline mt-3 mt-sm-0 col-12 col-sm-6">
                <button  onClick={() => setSelection("Deployments")} className={`ms-1 btn d-inline float-end  ${selection == "Deployments" ? "btn-instagram" : "bg-white border-1 border-dark"} `}>
                    <span style={{color:"white"}} className={` ${selection == "Deployments" ? "" : "text-dark"}`}>{`${t('deployments')}`}</span>
                </button>
                <button  onClick={() => setSelection("Clients")} className={`btn d-inline float-end  ${selection == "Clients" ? "btn-instagram" : "bg-white border-1 border-dark"}`}>
                    <span style={{color:"white"}} className={` ${selection == "Clients" ? "" : "text-dark"}`} >{`${t("onlineClients")}`}</span>
                </button>
            </div>
            {loading && <LoadingPageComponent />}
            {!loading && selection == "Deployments" && <div className="card mt-4 mt-sm-2">
                <div className="card-body">
                    <div className="accordion">
                        <OdysseyOnlineStatus onlineStatusInfo={onlineStatusInfo!} showArray={showArray} setShowArray={setShowArray} />
                    </div>
                </div>
                <PaginationComponent 
                    pageNumber={pageNumber} 
                    pages={pages} 
                    maxPageNumber={maxPageNumber} 
                    setPageNumber={setPageNumber} />
            </div>}
            {!loading && selection == "Clients" && <div className="card mt-4 mt-sm-2">
                <div className="card-body">
                    <div className="accordion">
                        {onlineClientInfo?.map((clientInfo:OnlineClientInfo) => {
                            return (
                                <div key={clientInfo.odysseyClientId} className="accordion-item" >
                                    <div className="accordion-header" onClick={() => clickHandler(clientInfo)}>
                                        <button type="button" className={showClientArray[onlineClientInfo.indexOf(clientInfo)] ? "accordion-button bg-white" : "accordion-button collapsed bg-white "}>
                                            <a className="fs-4 col-6 text-black"  >
                                                <span className="" >{`${t("onlineClient")} #${clientInfo.odysseyClientId}`}</span>
                                            </a>
                                            <div className="col-sm-4"></div>
                                            <span className={`float-start badge mt-1 fs-5 disabled  col-sm-1 d-none d-sm-block ${clientInfo.currentlyConnected ? 'btn-vimeo' : 'btn-youtube'} `}>
                                                {clientInfo.currentlyConnected ? `${t("connected")}` : `${t("disconnected")}`}
                                            </span>
                                            <span className={`float-end ms-3 d-block d-sm-none badge mt-1  disabled  col-sm-1 ${clientInfo.currentlyConnected ? 'btn-vimeo' : 'btn-youtube'} `}>
                                                {clientInfo.currentlyConnected ? `${t("connected")}` : `${t("disconnected")}`}
                                            </span>
                                        </button>
                                    </div>
                                    <div className={showClientArray[onlineClientInfo.indexOf(clientInfo)] ? "accordion-collapse collapse show" : "accordion-collapse collapse"}>
                                        <div className="accordian">
                                            <OdysseyOnlineStatus allOnlineStatusInfo={onlineStatusInfo!} onlineStatusInfo={clientInfo.onlineStatuses} showArray={showArray} setShowArray={setShowArray} />
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
                <PaginationComponent 
                    pageNumber={pageNumber} 
                    pages={pages} 
                    maxPageNumber={maxPageNumber} 
                    setPageNumber={setPageNumber} />
            </div>}
        </div>
    )
}