import React from 'react'
import Oidc, { UserManagerSettings } from 'oidc-client-ts'
import { Navigate, useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { useAuth } from 'react-oidc-context';
type Props = {}

export default function Callback(prop: any) {
    let navigate =useNavigate();
    let auth = useAuth();
    const [searchParams] = useSearchParams(); 
    searchParams.forEach((v,k)=>{
    })
    if (typeof auth.user?.state! === 'string')
    {
      const location:string = auth.user?.state!
      return (
        <Navigate to={location} replace></Navigate>
      )
    }

  return (
    <Navigate to='/' replace></Navigate>
  )
}
