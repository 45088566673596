import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { NavDropdown } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useAuth } from "react-oidc-context";
import { LinkContainer } from "react-router-bootstrap";
import { useLocation } from "react-router-dom";
import logo from '../assets/images/hartmannLogo.png'
import hartmann from '../assets/images/hartmann.png'
import hartmannDark from '../assets/images/hartmannDark.png'
import aiphone from '../assets/images/Aiphone-Logo-Light.svg'
import aiphoneDark from '../assets/images/Aiphone-Logo-Dark.svg'
import useLocalStorage from "../hooks/useLocalStorage";
import useAppSelector from "../hooks/useAppSelector";
import { canAddDeployment, getCompanyFeatureGate, getFeatureGate, isAdmin, selectConfig } from "../redux/slices/config";
import { CompanyFeatures, Features } from "../common/store";

export default function Sidebar({theme, setTheme, toggle}:{theme:string, setTheme: (value: React.SetStateAction<string>) => void, toggle: boolean}) {
    const { t } = useTranslation();
    //scrollbar Variables
    const auth = useAuth();
    const sysAdmin:boolean = auth.user?.profile.role == "SysAdmin"
    const releaseMode = useAppSelector(selectConfig)
    const canAddDeployments = useAppSelector(canAddDeployment)
    const isSysAdmin = useAppSelector(isAdmin)
    const featureGate: Features = useAppSelector(getFeatureGate)
    const companyFeatureGate: CompanyFeatures = useAppSelector(getCompanyFeatureGate)
    useEffect(() => {
        const themeData:string|null = localStorage.getItem('theme')
        if (theme == undefined)
            {
                setTheme(themeData == null ? "Default" :themeData!.substring(1, themeData!.length-1))
            }
      }, [theme])

    if (!auth.isAuthenticated)
        return(
            <div></div>
        )
    return(
        <nav className={`sidebar  ${companyFeatureGate.oem == "Aiphone" ? (theme.toLowerCase() == 'dark') ? 'bg-white' : (theme.toLowerCase() == 'default') ?  'bg-primary-dark' : '' : '' }
        ${!toggle ? "collapse" : ''}`} style={{"marginLeft": "0"}}>
            <div className="sidbar-content">
                <div className="scrollbar-container ps ps--active-y">
                    {companyFeatureGate.oem == "Hartmann" && <a href={`${ releaseMode  != true ? "/registerLink" : '/'}`} className="sidebar-brand px-0">
                        { (theme != "Light" && theme != "light") &&<img className="border-0 me-1" src={hartmann}></img>}
                        { (theme == "Light" || theme == "light") &&<img className="border-0 me-1" src={hartmannDark}></img>}
                    </a>}
                    {companyFeatureGate.oem == "Aiphone" && <a href={`${'/'}`} className="sidebar-brand px-0">
                        { (theme != "Light" && theme != "light") &&<img className="border-0 me-1 w-75" src={aiphoneDark}></img>}
                        { (theme == "Light" || theme == "light") &&<img className="border-0 me-1 w-75" src={aiphone}></img>}
                    </a>}
                    <ul className="sidebar-nav">
                    <li className="sidebar-header fs-7 row pt-1 pb-1">
                            <span className='mt-1 col-2'>{`${t('pages')}`}</span>
                            <hr className="mt-3 ms-3 col-9 "></hr>
                        </li>
                        <li className="sidebar-item">
                            <LinkContainer to="/">
                            <a className="sidebar-link fs-5 pt-1">
                                <FontAwesomeIcon icon="table-columns" />
                                <span >{`${t('dashboard')}`}</span></a>
                            </LinkContainer>
                        </li>
                        <li className="sidebar-item">
                            <LinkContainer to="/company">
                            <a className="sidebar-link fs-5  pt-1">
                                <FontAwesomeIcon icon="building" />
                                <span >{t('company')}</span></a>
                            </LinkContainer>
                        </li>
                        <li className="sidebar-header fs-7 row pt-2 pb-1">
                            <span className='mt-1 col-4'>{t('deployments')}</span>
                            <hr className="mt-3 ms-3 col-7 "></hr>
                        </li>
                        <li className="sidebar-item">
                            <LinkContainer to="deployment" >
                                <a className="sidebar-link fs-5 pt-1">
                                    <FontAwesomeIcon icon="bars" />
                                    <span >{`${t('deployments')}`}</span>
                                </a>
                            </LinkContainer>
                        </li>
                        { canAddDeployments && <li className="sidebar-item">
                            <LinkContainer to="/deployment/add">
                                <a className="sidebar-link fs-5 pt-1">
                                    <FontAwesomeIcon icon="plus" />
                                    <span >{t('deployment:addDeployment')}</span>
                                </a>
                            </LinkContainer>
                        </li>}
                        {featureGate.onlineDeployments && <li className="sidebar-item">
                            <LinkContainer to="/onlineStatus">
                                <a className="sidebar-link fs-5 pt-1">
                                    <FontAwesomeIcon icon="heart-pulse" />
                                    <span >{`${t('onlineStatus')}`}</span>
                                </a>
                            </LinkContainer>
                        </li>}
                        <li className="sidebar-header fs-7 row pt-2 pb-1">
                            <span className='mt-1 col-2'>{t('users')}</span>
                            <hr className="mt-3 ms-3 col-9 "></hr>
                        </li>
                        <li className="sidebar-item">
                            <LinkContainer to="/user/list">
                                <a className="sidebar-link fs-5 pt-1">
                                    <FontAwesomeIcon icon="bars" />
                                    <span >{`${t('users')}`}</span>
                                </a>
                            </LinkContainer>
                        </li>
                        { isSysAdmin && <li className="sidebar-item">
                            <LinkContainer to="/user/add">
                                <a className="sidebar-link fs-5 pt-1">
                                    <FontAwesomeIcon icon="plus" />
                                    <span>{t('user:addUsers')}</span>
                                </a>
                            </LinkContainer>
                        </li>}
                        {isSysAdmin && 
                            <div>
                                <li className="sidebar-header fs-7 row pt-2 pb-1">
                                    <span className='mt-1 col-3 me-1'>{t('reports')}</span>
                                    <hr className="mt-3 ms-2 col-8 "></hr>
                                </li>
                                <li className="sidebar-item">
                                    <LinkContainer to="/activity">
                                        <a className="sidebar-link fs-5 pt-1">
                                            <FontAwesomeIcon icon="bolt" />
                                            <span>{t('activities')}</span>
                                        </a>
                                    </LinkContainer>
                                </li>
                                {(featureGate.hostedDeployments || companyFeatureGate.allowedLocalLicensePayment) && <li className="sidebar-item">
                                    <LinkContainer to="/invoices">
                                        <a className="sidebar-link  fs-5 pt-1">
                                            <FontAwesomeIcon icon="file-invoice-dollar" />
                                            <span >{t('invoices')}</span>
                                        </a>
                                    </LinkContainer>
                                </li>}
                            </div>
                        }
                        <div>
                                <li className="sidebar-header fs-7 row pt-2 pb-1">
                                    <span className='mt-1 col-3 me-1'>{t('resources')}</span>
                                    <hr className="mt-3 ms-2 col-8 "></hr>
                                </li>
                                <li className="sidebar-item">
                                    <LinkContainer to="/software/Downloads">
                                        <a className="sidebar-link fs-5 pt-1">
                                            <FontAwesomeIcon icon="cloud-arrow-down" />
                                            <span>{t('software')}</span>
                                        </a>
                                    </LinkContainer>
                                </li>
                                {(featureGate.devPayment ) && <li className="sidebar-item">
                                    <LinkContainer to="/registrationKey">
                                        <a className="sidebar-link  fs-5 pt-1">
                                            <FontAwesomeIcon icon="key" />
                                            <span >License Keys</span>
                                        </a>
                                    </LinkContainer>
                                </li>}
                            </div>
                    </ul>
                </div>
            </div>
        </nav>
    )
    
}