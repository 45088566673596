import React, { useEffect, useState } from "react";
import PhoneNumberComponent from "../../components/shared/PhoneNumberComponent";
import TextInputComponent from "../../components/shared/TextInputComponent";
import Select, { StylesConfig } from 'react-select'
import TimezoneSelect from "react-timezone-select";
import { PasswordComponent } from "../../components/shared/PasswordComponent";
import { validate as EmailValidate } from 'email-validator';
import { SelectStyle } from "../../common/SelectStyle";
import { CompanyFeatures, GetUserRequest, languageOptions, OptionType, PhoneNumberType, WizardOption } from "../../common/store";
import API from "../../common/API";
import { useAuth } from "react-oidc-context";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ProgressWizard } from "../../components/shared/ProgressComponent";
import { getCompanyFeatureGate } from "../../redux/slices/config";
import useAppSelector from "../../hooks/useAppSelector";
import RequiredFields from "../../components/shared/RequiredFields";

export default function () {

    const { t } = useTranslation();
    const auth = useAuth();
    const userIdentifier = auth.user?.profile.client_id;
    const sysAdmin: boolean = auth.user?.profile.role == "SysAdmin"
    const navigate = useNavigate()
    const companyFeatureGate: CompanyFeatures = useAppSelector(getCompanyFeatureGate)

    const [section, setSection] = useState<string>("user")
    const optionSections: WizardOption[] = [{icon:'address-book', label: t('user:userDetails'), value: "user"}, {icon:'id-card', label: t('user:userRoles'), value: "roles"}]

    const [firstName, setFirstName] = useState<string>('')
    const [lastName, setLastName] = useState<string>('')
    const [currentAdminPhoneTypeOption, setCurrentAdminTypeOption] = useState<string | undefined>('Work')
    const [currentAdminPhoneNumber, setCurrentAdminPhoneNumber] = useState<string>('')
    const [currentAdminExtension, setCurrentAdminExtension] = useState<string>('')
    const [language, setLanguage] = useState<string | undefined>(companyFeatureGate.language!)
    const [adminEmail, setAdminEmail] = useState<string>("")
    const [timezone, setTimezone] = useState<string>(companyFeatureGate.timezone)
    const [isAdmin, setIsAdmin] = useState<boolean>(false)
    const [manageCompany, setManageCompany] = useState<boolean>(false)
    const [manageDeployments, setManageDeployments] = useState<boolean>(false)
    const [manageHostedDeployments, setManageHostedDeployments] = useState<boolean>(false)
    const [manageLicenses, setManageLicenses] = useState<boolean>(false)
    const [managePayments, setManagePayments] = useState<boolean>(false)

    useEffect(() => {
        if (!sysAdmin)
            navigate('/user/list')
    }, [])

    const [langCheck, setLangCheck] = useState<string>(t('admin'))
    useEffect(() => {
        if (langCheck != t('admin')) {
            setLangCheck(t('admin'))
        }
    }, [optionSections])
    
    const AddUser = async () => {
        const phoneNumber: PhoneNumberType = { type: currentAdminPhoneTypeOption, number: currentAdminPhoneNumber, extension: currentAdminExtension }
        const object: GetUserRequest = {
            firstName: firstName, lastName: lastName,
            language: language!, timezone: timezone,
            email: adminEmail, id: 0, phoneNumber: phoneNumber,
            isAdmin: isAdmin, lastLoggedIn: '',
            manageCompany: manageCompany, manageDeployments: manageDeployments,
            manageHostedDeployments: manageHostedDeployments, manageLicenses: manageLicenses, managePayments: managePayments
        }
        try {
            const response = await API.post("api/v1/user/add", object, auth)
            navigate("/user/list")
        }
        catch (e: any) {
            alert(e.errors?.Error!)
        }
    }
    return (
        <div className="">
            <div className="mb-4  ms-auto me-auto " >
                <div className=" border-0">
                    <h1 className=' ms-1 header-text d-inline'><FontAwesomeIcon icon="user" className="fs-3 me-2" />{t('user:addUsers')}</h1>
                </div>
                <ProgressWizard sections={optionSections} currentSection={section} />
                {section == "user" && <div className="card bg-opacity-75 mt-2">
                    <div className=" card-header border-0  ">
                        <h2 className='mb-4 header-text'><FontAwesomeIcon icon="circle-info" className="fs-3 me-2" />{t('user:userDetails')}</h2>
                        <RequiredFields />
                    </div>
                    <div className="card-body">
                        <TextInputComponent mandatory value={firstName} label={t('user:firstName')} setText={setFirstName} />
                        <TextInputComponent mandatory value={lastName} label={t('user:lastName')} setText={setLastName} />
                        <div className="form-group row mb-4">
                            <div className="col-1 d-none d-sm-block"></div>
                            <label className="col-4 col-sm-2  col-form-label text-begin ms-3" >
                                <h3 className="d-none d-sm-block">{t('user:language')}</h3>
                                <h3 className="d-block d-sm-none fs-4">{t('user:language')} <FontAwesomeIcon icon="star-of-life" className="mt-2 d-inline d-sm-none text-center" style={{"fontSize": "0.65rem"}}/></h3>
                            </label>
                            <div className="col-7 col-sm-5">
                                <Select placeholder={t("selectLanguage")} onChange={(e) => setLanguage(e?.value)} value={languageOptions.find(e => e.value == language)} className="form-select p-0" styles={SelectStyle({ border: 'none' })} options={languageOptions} />
                            </div>
                            <div className="col-1 d-none d-sm-block">
                                <FontAwesomeIcon icon="star-of-life" className=" mt-2" style={{"fontSize": "0.65rem"}} />
                            </div>
                        </div>
                        <div className="form-group row mb-4">
                            <div className="col-1 d-none d-sm-block"></div>
                            <label className="col-4 col-sm-2  col-form-label text-begin ms-3" >
                                <h3 className="d-none d-sm-block">{t('user:timeZone')}</h3>
                                <h3 className="d-block d-sm-none fs-4">{t('user:timeZone')} <FontAwesomeIcon icon="star-of-life" className="mt-2 d-inline d-sm-none text-center" style={{"fontSize": "0.65rem"}}/></h3>
                            </label>
                            <div className="col-7 col-sm-5">
                                <TimezoneSelect placeholder={t("selectTimezone")} value={timezone} onChange={(e) => setTimezone(e.value)} />
                            </div>
                            <div className="col-1 d-none d-sm-block">
                                <FontAwesomeIcon icon="star-of-life" className="mt-2" style={{"fontSize": "0.65rem"}} />
                            </div>
                        </div>
                        <TextInputComponent mandatory value={adminEmail} label={t('user:emailAddress')} setText={setAdminEmail} validator={EmailValidate} validateText={t('user:emailValidationError')} />
                        <PhoneNumberComponent phoneNumber={currentAdminPhoneNumber} extension={currentAdminExtension} typeOption={currentAdminPhoneTypeOption} setPhoneNumber={setCurrentAdminPhoneNumber}
                            setExtension={setCurrentAdminExtension} setTypeOption={setCurrentAdminTypeOption} />
                    </div>
                    <div className=" mb-4 pe-5">
                        <button className="mb-4 btn float-end btn-github add-btn-text " disabled={firstName == '' || lastName == '' || adminEmail == '' || timezone == '' || language == ''|| (!EmailValidate(adminEmail))}
                         onClick={() => setSection("roles")}>
                            <span >{t('next')}</span>
                            <FontAwesomeIcon icon="chevron-right" className="ms-2" />
                        </button>
                    </div>
                </div>}
                {section == "roles" && <div className="card mt-2">
                    <div className=" card-header border-0 ">
                        <h2 className='mb-4 header-text'><FontAwesomeIcon icon="address-book" className="fs-3 me-2" />{t('user:userRoles')}</h2>
                    </div>
                    <div className="card-body">
                        <div className="form-group row mb-4 ">
                            <div className="col-1 d-none d-sm-block"></div>
                            <label className="col-7 col-sm-3 col-form-label text-begin" >
                                <h3 className="d-none d-sm-block">{t('user:systemAdmin')}</h3>
                                <h3 className="d-block d-sm-none fs-4">{t('user:systemAdmin')}</h3>
                            </label>
                            <div className="col-5 form-check form-switch">
                                <input className="align-bottom form-check-input mt-2 " type="checkbox" id="flexSwitchCheckDefault" checked={isAdmin} onChange={(e) => setIsAdmin(!isAdmin)} />
                            </div>
                        </div>
                        <div className="form-group row mb-4 mt-4">
                            <div className="col-1 d-none d-sm-block"></div>
                            <label className="col-7 col-sm-3 col-form-label text-begin" >
                                <h3 className="d-none d-sm-block">{t('user:manageCompany')}</h3>
                                <h3 className="d-block d-sm-none fs-4">{t('user:manageCompany')}</h3>
                            </label>
                            <div className="col-5 form-check form-switch">
                                <input className="align-bottom form-check-input mt-2 " type="checkbox" id="flexSwitchCheckDefault" checked={manageCompany} onChange={(e) => setManageCompany(!manageCompany)} />
                            </div>
                        </div>
                        <div className="form-group row mb-4 mt-4">
                            <div className="col-1 d-none d-sm-block"></div>
                            <label className="col-7 col-sm-3 col-form-label text-begin" >
                                <h3 className="d-none d-sm-block">{t('user:manageDeployments')}</h3>
                                <h3 className="d-block d-sm-none fs-4">{t('user:manageDeployments')}</h3>
                            </label>
                            <div className="col-5 form-check form-switch">
                                <input className="align-bottom form-check-input mt-2 " type="checkbox" id="flexSwitchCheckDefault" checked={manageDeployments} onChange={(e) => setManageDeployments(!manageDeployments)} />
                            </div>
                        </div>
                        <div className="form-group row mb-4 mt-4">
                            <div className="col-1 d-none d-sm-block"></div>
                            <label className="col-7 col-sm-3 col-form-label text-begin" >
                                <h3 className="d-none d-sm-block">{t('user:manageHostedDeployments')}</h3>
                                <h3 className="d-block d-sm-none fs-4">{t('user:manageHostedDeployments')}</h3>
                            </label>
                            <div className="col-5 form-check form-switch">
                                <input className="align-bottom form-check-input mt-2 " type="checkbox" id="flexSwitchCheckDefault" checked={manageHostedDeployments} onChange={(e) => setManageHostedDeployments(!manageHostedDeployments)} />
                            </div>
                        </div>
                        <div className="form-group row  mt-4">
                            <div className="col-1 d-none d-sm-block"></div>
                            <label className="col-7 col-sm-3 col-form-label text-begin" >
                                <h3 className="d-none d-sm-block">{t('user:manageLicenses')}</h3>
                                <h3 className="d-block d-sm-none fs-4">{t('user:manageLicenses')}</h3>
                            </label>
                            <div className="col-5 form-check form-switch">
                                <input className="align-bottom form-check-input mt-2 " type="checkbox" id="flexSwitchCheckDefault" checked={manageLicenses} onChange={(e) => setManageLicenses(!manageLicenses)} />
                            </div>
                        </div>
                        <div className="form-group row  mt-4">
                            <div className="col-1 d-none d-sm-block"></div>
                            <label className="col-7 col-sm-3 col-form-label text-begin" >
                                <h3 className="d-none d-sm-block">{t('user:manageLicenses')}</h3>
                                <h3 className="d-block d-sm-none fs-4">{t('user:managePayments')}</h3>
                            </label>
                            <div className="col-5 form-check form-switch">
                                <input className="align-bottom form-check-input mt-2 " type="checkbox" id="flexSwitchCheckDefault" checked={managePayments} onChange={(e) => setManagePayments(!managePayments)} />
                            </div>
                        </div>
                    </div>
                    <div className=" mb-4 pe-5 mt-3">
                        <button className="mb-4 btn float-start btn-github add-btn-text ms-4 " onClick={() => setSection("user")}>
                            <FontAwesomeIcon icon="chevron-left" className="me-2" />
                            <span >{t('previous')}</span>
                        </button>
                        <button className="mb-4 btn float-end btn-flickr add-btn-text " disabled={firstName == '' || lastName == '' || adminEmail == '' || timezone == '' || language == ''}
                            onClick={() => AddUser()}>
                            <span >{t('user:addUsers')}</span>
                        </button>
                    </div>
                </div>}
            </div>
        </div>
    )

}