import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { aiphoneLicenseTiers, CompanyFeatures, LicenseAddOn, LicenseDTO, OptionType } from '../../common/store'
import PackageComponent from './PackageComponent'
import TextInputComponent from '../shared/TextInputComponent'
import { t } from 'i18next'
import { getCompanyFeatureGate } from '../../redux/slices/config'
import useAppSelector from '../../hooks/useAppSelector'

type Props = {
    noLicence: boolean,
    license: LicenseDTO | undefined,
    packageType: string,
    accountNumber: number,
    id: string | undefined,
    licenseTerm: string,
    rearm: boolean,
    setCurrentSection: (value: React.SetStateAction<string>) => void,
    isOnline: boolean,
    tier: number,
    licenseAddOns: LicenseAddOn[]
}

function LicenseDetails(props: Props) {
    const navigate = useNavigate()
    const { t } = useTranslation();

    const companyFeatureGate: CompanyFeatures = useAppSelector(getCompanyFeatureGate)
    const _licenseTiers = companyFeatureGate.oem == "Aiphone" ? aiphoneLicenseTiers : companyFeatureGate.allowedOdyssey ? [{ label: "ProtectorNet", value: "0" }, { label: "Odyssey", value: "1" }] : [{ label: "ProtectorNet", value: "0" }];

    var licenseOption: OptionType[] = [{ i1: 50, i2: 1, label: t("package:essentials"), value: 'essentials' }, { i1: 100, i2: 2, label: t("package:small"), value: 'small' }, { i1: 250, i2: 4, label: t("package:mid"), value: 'mid' },
    { i1: 400, i2: 256, label: t("package:large"), value: 'large' }, { i1: 750, i2: 8, label: t("package:enterprise"), value: 'enterprise' },
    { i1: 750, i2: 512, label: t("package:basic"), value: 'basic' }, { i1: 1024, i2: 1024, label: t("package:standard"), value: 'standard' }]

    const AddOnTypes: OptionType[] = [{ value: '0', label: t("deployment:panels") }, { value: '1', label: t("license:cameras") }, { value: '2', label: t("license:doors") }, { value: '3', label: t("license:partitions") },
    { value: '4', label: t("license:elevators") }, { value: '5', label: t("addOn:inputs") }, { value: '6', label: t("addOn:outputs") }, { value: '7', label: t("addOn:maps") },
    { value: '8', label: t("users") }, { value: '9', label: t("addOn:locksets") }]

    const [createdOn, setCreatedOn] = useState<string>("")

    useEffect(() => {
        var date = props.license?.activationDate == null ? new Date(props.license?.createdOn.toString()!) : new Date(props.license?.activationDate.toString()! + "T17:09:30.6225241")
        setCreatedOn(date.getFullYear() + "-" + (date.getMonth() + 1).toString().padStart(2, "0") + "-" + date.getDate().toString().padStart(2, "0"))
    }, [])

    return (
        <div className="tab-content">

            {!props.noLicence && props.license?.activated &&
                <div role="tabpanel" className="tab-pane active show">
                    <div className="row">
                        <div className={"card  col-12 col-sm-4 bg-transparent shadow-none"}>
                            <div className=" card-header pb-0">
                                <h3 className='mb-4 header-text text-center '><FontAwesomeIcon icon="circle-info" className="fs-3 me-2" />{t('license:licenseDetails')}</h3>
                                <div className={`text-center`}>
                                    <h4 className={'text-dark'}>{_licenseTiers.find((e) => e.value == props.tier.toString())?.label!}</h4>
                                </div>
                            </div>
                            <div className=" card-body p-0 bg-white">
                                <div className='mt-4'>
                                    <TextInputComponent smallLabel value={props.accountNumber.toString()} label={t('license:accountNumber')} noEdit labelClass="col-6 col-sm-5 col-form-label text-end " inputClass='col-6 col-sm-5' />
                                    <TextInputComponent smallLabel value={props.packageType} label={t('license:package')} noEdit labelClass="col-6 col-sm-5 col-form-label text-end" inputClass='col-6 col-sm-5' />
                                    <TextInputComponent smallLabel value={props.licenseTerm} label={t('license:licenseTerm')} noEdit labelClass="col-6 col-sm-5 col-form-label text-end" inputClass='col-6 col-sm-5' />
                                    <TextInputComponent smallLabel value={createdOn} label={t('license:activationDate')} noEdit labelClass="col-6 col-sm-5 col-form-label text-end" inputClass='col-6 col-sm-5' />
                                    <TextInputComponent smallLabel value={props.license?.expirationDate.toString()} label={t('license:expirationDate')} noEdit labelClass="col-6 col-sm-5 col-form-label text-end" inputClass='col-6 col-sm-5' />
                                </div>
                            </div>
                        </div>
                        <div className="card col-12 col-sm-4 bg-transparent shadow-none">
                            <div className="bg-white">
                                <PackageComponent tier={props.tier} value={licenseOption.find(e => e.label == props.packageType)?.i2!} display />
                            </div>
                        </div>
                        <div className="card col-12 col-sm-4 bg-transparent shadow-none" >
                            <div className="card-header pb-0">
                                <h3 className='mb-4 header-text text-center'><FontAwesomeIcon icon="screwdriver-wrench" className="fs-3 me-2" />{t('license:licenseFeatures')}</h3>
                                {props.tier == 1 &&
                                    <div className='text-center'>
                                        <button className="btn btn-primary add-btn-text" onClick={() => props.setCurrentSection('addOns')} >
                                            <FontAwesomeIcon icon="screwdriver-wrench" className="fs-5 me-2" />
                                            <span className="fs-5 me-1" >{`${t('license:addOns')}`}</span>
                                        </button>
                                    </div>
                                }
                            </div>
                            <div className="card-body p-0 bg-white ">
                                <div className="mt-4">
                                    <TextInputComponent smallLabel value={props.license?.multicard ? t('yes') : t('no')} label={t('license:multiCard')} noEdit labelClass="col-6 col-form-label text-end" inputClass='col-6 col-sm-5' />
                                    {props.tier != 1 &&
                                        <div>
                                            <TextInputComponent smallLabel value={props.license?.additional5Partitions ? t('yes') : licenseOption.find(e => e.label == props.packageType)?.i2! == 8 ? t('unlimited') : t('no')} label={t('license:5Partitions')} noEdit labelClass="col-6 col-form-label text-end" inputClass='col-6 col-sm-5' />
                                            <TextInputComponent smallLabel value={props.license?.additional10Partitions ? t('yes') : licenseOption.find(e => e.label == props.packageType)?.i2! == 8 ? t('unlimited') : t('no')} label={t('license:10Partitions')} noEdit labelClass="col-6 col-form-label text-end" inputClass='col-6 col-sm-5' />
                                            <TextInputComponent smallLabel value={props.license?.additional20Partitions ? t('yes') : licenseOption.find(e => e.label == props.packageType)?.i2! == 8 ? t('unlimited') : t('no')} label={t('license:20Partitions')} noEdit labelClass="col-6 col-form-label text-end" inputClass='col-6 col-sm-5' />
                                        </div>
                                    }
                                    {props.licenseAddOns.map((e: LicenseAddOn) => (
                                        <TextInputComponent smallLabel value={e.count.toString() + ` ${AddOnTypes.find(f => f.value == e.feature.toString())?.label}(s)`} label={AddOnTypes.find(f => f.value == e.feature.toString())?.label!} noEdit labelClass="col-6 col-form-label text-end" inputClass='col-6 col-sm-5' />
                                    ))}
                                </div>
                            </div>
                            <div className="card-footer ">
                                <div className=" pe-3 float-end mt-4">
                                    <button className="btn float-end btn-bitbucket add-btn-text " disabled={!props.rearm || props.isOnline} onClick={() => props.setCurrentSection('Rearm License')} >
                                        <span >{t('license:rearm')}</span>
                                    </button>
                                </div>
                                <div className="pe-3 float-end mt-4">
                                    {!props.isOnline && <button className="btn float-end btn-bitbucket add-btn-text " onClick={() => navigate(`/license/${props.id}/${props.accountNumber}/renew`)} >
                                        <span >{t('license:renew')}</span>
                                    </button>}
                                    {props.isOnline && <button className="btn float-end btn-bitbucket add-btn-text " onClick={() => navigate(`/license/${props.id}/${props.accountNumber}/renew/online`)} >
                                        <span >{t('license:renew')}</span>
                                    </button>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
            {props.noLicence &&
                <div className={!props.noLicence && props.license?.activated ? "card p-0" : "card p-0"}>
                    <div className=" card-header pb-0">
                        <h2 className=' mt-4 mb-4 text-center  header-text'>{t('license:licenseDetails')}</h2>
                    </div>
                    <div className="card-body">
                        <TextInputComponent value={props.accountNumber.toString()} label={t('license:accountNumber')} noEdit labelClass="col-5 col-form-label text-end" />
                        <TextInputComponent value={t('unactivated')} label={t('license:package')} noEdit labelClass="col-5 col-form-label text-end" />
                        <button className="btn float-end btn-info add-btn-text " onClick={() => navigate(`/license/create/${props.id}/${props.accountNumber}`)}>
                            <span >{t('license:createLicense')}</span>
                        </button>
                    </div>
                </div>
            }
            {!props.noLicence && !props.license?.activated &&
                <div className={!props.noLicence && props.license?.activated ? "card p-0" : "card p-0"}>
                    <div className=" card-header pb-0">
                        <h2 className=' mt-4 mb-4 text-center  header-text'>{t('license:licenseDetails')}</h2>
                    </div>
                    <div className="card-body">
                        <TextInputComponent value={props.accountNumber.toString()} label={t('license:accountNumber')} noEdit labelClass="col-5 col-form-label text-end" />
                        <TextInputComponent value={props.packageType} label={t('license:package')} noEdit labelClass="col-5 col-form-label text-end" />
                        <button className="btn float-end btn-info add-btn-text " onClick={() => navigate(`/license/create/${props.id}/${props.accountNumber}`)}>
                            <span >{t('license:activateLicense')}</span>
                        </button>
                    </div>
                </div>
            }
        </div>
    )
}

export default LicenseDetails

