import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { t } from "i18next";
import React, { MouseEventHandler, useState } from "react";
import API from "../../common/API";
import { useAuth } from "react-oidc-context";
import { faVcard } from "@fortawesome/free-solid-svg-icons";
import { DeploymentType, LicenseTier } from "./Enums";
import { Spinner } from "react-bootstrap";
import { CompanyFeatures } from "../../common/store";
import useAppSelector from "../../hooks/useAppSelector";
import { getCompanyFeatureGate } from "../../redux/slices/config";

type Props = {
    label: string,
    value: string,
    setText?: (value: React.SetStateAction<string>) => void,
    noEdit?: boolean,
    labelClass?: string,
    inputClass?: string,
    maxLength?: number,
    minLength?: number,
    alphanumeric?: boolean,
    onClick?: MouseEventHandler<HTMLInputElement>
    link?: boolean
    smallLabel?: boolean,
    labelColor?: string,
    inputColor?: string,
    mandatory?: boolean,
    placeholder?: string,
    append?: IconProp,
    appendClass?: string,
    upperCase?: boolean,
    noLabelPadding?: boolean,
    buttonLabel: string,
    buttonClick: MouseEventHandler<HTMLButtonElement>,
    showAdd: boolean,
    licenseValue: string,
    setLicenseValue:(value: React.SetStateAction<any>) => void,
    defaultValue: string,
    tierType: number,
    type:number,
}

export default function LicenseAddonTextComponent({ label, value, setText, noEdit, labelClass, inputClass, defaultValue,
    maxLength, minLength, alphanumeric, onClick, link, smallLabel, labelColor, inputColor, mandatory, placeholder, append, appendClass, licenseValue, setLicenseValue,
    upperCase, noLabelPadding, buttonLabel,buttonClick,showAdd , tierType,type}: Props) {
   
    const [validate,setValidate] = useState<boolean>();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>("");
    const companyFeatureGate: CompanyFeatures = useAppSelector(getCompanyFeatureGate)

    const alphanumericCheck = alphanumeric == null ? false : /[^0-9a-zA-Z]/.test(value)
    if (value == null)
        value = ""
        
    var auth = useAuth();
    const validateCard = async () => {
        setIsLoading(true);
        console.log("check");
        var valid = value != undefined && value.length == 29 && /[A-Za-z0-9]{6}-[A-Za-z0-9]{4}-[A-Za-z0-9]{5}-[A-Za-z0-9]{4}-[A-Za-z0-9]{6}/.test(value)
        
        if(!valid ){
           //  alert("Invalid Card. License Code is too small")
            setIsLoading(false);
            setValidate(false)
            value.length > 0 ? setErrorMessage("License Card is too small") : setErrorMessage("");
            setLicenseValue(defaultValue)
            return false;
         }
         var object = { Code: value }
         try {
            var response: any = await API.post(`api/v1/license/IsValidCard`, object, auth)
            setIsLoading(false);

            var responseTier = response.tier;

            var cardTier = LicenseTier[responseTier];
            var currentTier = LicenseTier[tierType];
             if(label != "ACE" && label != "User Count"){
                if(type == DeploymentType.Hosted && tierType == 0 && (responseTier == LicenseTier.OdysseyCloud || responseTier == LicenseTier.Odyssey)){
                    alert("Invalid Card. Current License Card is not supported for "+ currentTier+ " Hosted Deployment")
                    return;
                }

                if(type == DeploymentType.Hosted && tierType == 1 && (responseTier == LicenseTier.ProtectorNetCloud || responseTier == LicenseTier.ProtectorNet)){
                    alert("Invalid Card. Current License Card is not supported for "+ currentTier+ " Hosted Deployment")
                    return;
                }

                if (type == DeploymentType.Hosted && (response.tier == LicenseTier.OdysseyCloud || response.tier == LicenseTier.ProtectorNetCloud)) {
                    //setlicenseAddonValue(response.value);
                } else if( type == DeploymentType.Local && (response.tier == LicenseTier.Odyssey || response.tier == LicenseTier.ProtectorNet)){
                    
                    
                }else{
                    alert("Invalid Card. Current License Card is not supported ")
                    return;
                }
                if (response.tier == LicenseTier.Odyssey && !companyFeatureGate.allowedOdyssey)
                {
                    alert("Invalid Card")
                    return;
                }
            }
            console.log(response,label,LicenseTier.ACE)
             if(response.value == undefined){
                setErrorMessage("License Card is not valid")
                setLicenseValue(defaultValue)
                setValidate(false)
            }
            if(LicenseTier.ACE == response.tier && label == "ACE"){
                setValidate(true);
                setErrorMessage("")
                setLicenseValue("True")!!
                return;
            }else if(LicenseTier.USERCOUNT == response.tier && label == 'User Count'){
                setValidate(true);
                setLicenseValue(response.value)!
                setErrorMessage("")
                return;
            }else if(LicenseTier.OdysseyCloud == response.tier && label == 'Panel Count'){
                setLicenseValue(response.value)!
                setValidate(true);
                setErrorMessage("")
                return;
            }else if(LicenseTier.ProtectorNetCloud == response.tier && label == 'Panel Count'){
                setLicenseValue(response.value)!
                setValidate(true);
                setErrorMessage("")
                return;
            }else {
                console.log("Invalid")
                setValidate(false);
                setLicenseValue(defaultValue)
                setErrorMessage("License Card is not valid. License Card is not valid for current license type");
                return;
            }
         }catch (e: any) {
            setIsLoading(false);
            setErrorMessage(e.errors?.Error)
            setLicenseValue(defaultValue)
            console.log(e)
            return;
        }
    }
    return (
        <div className="row">
            {/* <input className={"form-check-input col-1"} type="checkbox" checked={isChecked} onChange={(e) => setIsChecked(label,!isChecked)} />  */}
           
            <label className={"col-5 col-sm-3 mb-4 ms-3"} >
                <h3 className={` d-sm-block ${smallLabel ? 'fs-6' : ''} ${labelColor ?? ''}`}>{label}</h3>
            </label>
            <div onClick={noEdit == null ? () => { } : onClick!} className={"col-5 col-lg-6 mb-4"}>
                
                <input placeholder={placeholder} maxLength={maxLength!} minLength={minLength!} className={`form-control lg col-5 col-lg-12 input-text ${inputColor ?? ''}`}
                    style={{ 'border': noEdit ? 'none' : '' }} type="text" value={value} onBlur={validateCard} onChange={(e) => setText != null ? upperCase ? setText(e.target.value.toUpperCase()) : setText(e.target.value) : {}} disabled={noEdit} />
              
                {append && <div className={`d-inline ${appendClass != null ? appendClass : ''}`} onClick={onClick!}>
                    <FontAwesomeIcon icon={append} className="fs-4 d-inline" />
                </div>}
               
                {(!validate && errorMessage.length > 0) &&
                    <div className="col-12 mt-2">
                        <p className="" style={{ "color": "#FF0000" }}>{errorMessage}</p>
                    </div>}
                {(minLength != null && value != '' && value.length < minLength!) && <div className="col-12 mt-2">
                    <p className="" style={{ "color": "#FF0000" }}>{`${t("error:invalidLengthOfInput").replace('$$', minLength.toString())}`}</p>
                </div>}
                {(alphanumericCheck && value != '' && (value.length >= minLength! || minLength == null)) && <div className="col-12 mt-2">
                    <p className="" style={{ "color": "#FF0000" }}>{`${t("error:inputNotAlphaNumeric")}`}</p>
                </div>}
                
            </div>
            {isLoading && <Spinner animation="border" />}
        
            {showAdd && <button className={"col-2 col-xs-4 mb-4 btn float-end btn-twitter add-btn-text"}
                    disabled={!validate} 
                    onClick={buttonClick}>{buttonLabel}</button> }
        </div>
      

    )
}

