import React, { CSSProperties } from "react";
import { StylesConfig } from "react-select";
import { OptionType } from "./store";

export function SelectStyle(customControlStyles:CSSProperties ){

    const selectStyle: StylesConfig<OptionType, false> = {
        control: (provided, state) => {
          return {
            ...provided,
            ...customControlStyles
          };
        }
    };
    return selectStyle;
}