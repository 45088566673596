import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

export default function({info, label, icon}: {info:any, label:string, icon:IconProp}){

    return(
        <div className="card flex-fill" style={{"zIndex" : "-3"}}>
            <div className="card-body py-4">
                <div className="d-flex align-items-start">
                    <div className="flex-grow-1">
                        <h4 className="mb-3">{info}</h4>
                        <h6 className="mb-2">{label}</h6>
                    </div>
                    <div className="d-inline ms-3">
                        <div className="stat">
                            <FontAwesomeIcon icon={icon}  />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}