import { t } from "i18next";
import React from "react";
import { AuthContextProps } from "react-oidc-context";
import API from "../API";
import { HostedPaymentDetailsDTO, OptionType } from "../store";

export default async function(
    auth:AuthContextProps, id:string , setInvoice:Function, setNumberOfPanels:Function, setHostedTerm:Function,setMulticard:Function,
    setPaymentDetails:Function, setSection:Function 
){
    try {
        const response: HostedPaymentDetailsDTO|null = await API.get(`/api/v1/hosteddeployment/paymentDetails/${id}`, auth)
        if (response == null)
            return
        else if (response?.invoice != null) {
            setInvoice(response?.invoice)
            setNumberOfPanels(response?.package.numOfPanels)
            setHostedTerm(response?.package.packageTerm.toString())
            setMulticard(response?.package.multiCardEnabled ? 16: 0)
            var details:OptionType[] = [
                {label: t('deployment:numberOfPanels'), value: response?.package.numOfPanels.toString()},
                {label: t('deployment:schedule'), value: response?.package.packageTerm.toString() == '12' ? t('deployment:yearly'):t('deployment:quarterly')}, 
                {label: t('license:multiCard'), value: (response?.package.multiCardEnabled ).toString()},
            ]
            setPaymentDetails({title: 'Hosted Deployment Purchase', details: details })
            setSection("package")
        }
    }
    catch (e: any) {
        alert(e.errors?.Error!)
    }
}