import { t } from "i18next";
import React from "react";
import { AuthContextProps } from "react-oidc-context";
import { NavigateFunction, useNavigate } from "react-router-dom";
import API from "../API";
import { GetDeployment, HostedPackage, LicenseDTO, OptionType } from "../store";

export function GetFeatures(features:number, packageType:number){

    const multicard:string = (features & 16) == 16 ? "Multicard," : ''
    const fivePartitions:string = (features & 32) == 32 ? "Five Partitions," : ""
    const tenPartitions:string = (features & 64) == 64 ? "Ten Partitions,": ""
    const twentyPartitions:string = (features & 128) == 128 ? "Twenty Partitions " : ''
    
    const result: string = packageType == 8 ? "Multicard, Unlimited Partitions ": multicard + fivePartitions  + tenPartitions + twentyPartitions
    return result.slice(0,-1)
}


