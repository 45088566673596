import React, { useEffect, useRef, useState } from "react";
import { useAuth } from "react-oidc-context";
import API from "../../common/API";
import {
  Activity,
  CompanyFeatures,
  Features,
  OptionType,
} from "../../common/store";
import LoadingPageComponent from "../../components/shared/LoadingPageComponent";
import PaginationListComponent from "../../components/shared/PaginatedListComponent";
import { PaginationComponent } from "../../components/shared/PaginationComponent";
import Select, { StylesConfig } from "react-select";
import { SelectStyle } from "../../common/SelectStyle";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SelectComponent from "../../components/shared/SelectComponent";
import useAppSelector from "../../hooks/useAppSelector";
import {
  getCompanyFeatureGate,
  getFeatureGate,
} from "../../redux/slices/config";

export default function () {
  const navigate = useNavigate();
  const auth = useAuth();
  const { t } = useTranslation();
  const [perPage, setPerPage] = useState<number>(20);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [maxPageNumber, setMaxPageNumber] = useState<number>(0);
  const [pages, setPages] = useState<number[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [activities, setActivities] = useState<Activity[]>([]);
  const [type, setType] = useState<number>(-1);

  const values: OptionType[] = [
    { value: "time", label: t("time") },
    { value: "userName", label: t("user") },
    { value: "message", label: t("invoice:message") },
  ];

  const { i18n } = useTranslation();
  const featureGate: Features = useAppSelector(getFeatureGate);
  const companyFeatureGate: CompanyFeatures = useAppSelector(
    getCompanyFeatureGate
  );

  const filterOptions: OptionType[] = [
    { value: "-1", label: t("activities:all") },
    { value: "0", label: t("activities:loggedIn") },
    { value: "1", label: t("activities:companyRegistered") },
    { value: "2", label: t("activities:companyUpdated") },
    { value: "4", label: t("activities:addedUser") },
    { value: "5", label: t("activities:updatedUser") },
    {
      value: "6",
      label: t("activities:deletedUser"),
    },
    { value: "9", label: t("activities:licensed") },
    { value: "10", label: t("activities:addedDeployment") },
    { value: "12", label: t("activities:updatedDeployment") },
    { value: "14", label: t("activities:deletedDeployment") },
    { value: "18", label: t("activities:renewLicense") },
    { value: "17", label: t("activities:rearmLicense") },
    { value: "16", label: t("activities:activatedLicense") },
    { value: "34", label: t("activities:addedLicenseAddOn") },
    { value: "35", label: t("activities:licenseExpiryEmail") },
  ];
  if (featureGate.hostedDeployments) {
    filterOptions.push(
      { value: "11", label: t("activities:addedHostedDeployment") },
      { value: "13", label: t("activities:updatedHostedDeployment") },
      { value: "15", label: t("activities:deletedHostedDeployment") },
      { value: "19", label: t("activities:addedHostedDeploymentPackage") },
      { value: "30", label: t("activities:updatedHostedPanelCountAdd") },
      { value: "31", label: t("activities:updatedHostedPanelCountDelete") },
      { value: "32", label: t("activities:undoUpdatedHostedPanelCountDelete") },
      { value: "33", label: t("activities:upadtedHostedSchedule") },
      { value: "27", label: t("activities:panelCreated") },
      { value: "28", label: t("activities:panelDeleted") },
      { value: "29", label: t("activities:pendingInvoiceCreated") }
    );
  }
  if (
    featureGate.hostedDeployments ||
    companyFeatureGate.allowedLocalLicensePayment
  ) {
    filterOptions.push(
      { value: "20", label: t("activities:invoiceCreated") },
      { value: "21", label: t("activities:invoicePaid") },
      { value: "22", label: t("activities:changedCard") },
      { value: "23", label: t("activities:recurringInvoice") },
      { value: "24", label: t("activities:recurringChargeCard") },
      { value: "25", label: t("activities:recurringChargeCardFail") },
      { value: "26", label: t("activities:expiredCardEmail") }
    );
  }
  if (featureGate.backups) {
    filterOptions.push(
      { value: "36", label: t("activities:upgradeBackupTier") },
      { value: "37", label: t("activities:downgradeBackupTier") },
      { value: "38", label: t("activities:undoDowngradeBackup") },
      { value: "39", label: t("activities:backupCompleted") },
      { value: "40", label: t("purgeBackups") }
    );
  }
  if (companyFeatureGate.allowedOdyssey) {
    filterOptions.push({ value: "41", label: t("updateToOdyssey") });
  }

  useEffect(() => {
    setLoading(true);
    getActivities();
  }, [pageNumber, type]);

  const getActivities = async () => {
    try {
      const url: string =
        type == -1
          ? `/api/v1/activities?PerPage=${perPage}&PageNumber=${pageNumber}&Language=${i18n.language}`
          : `/api/v1/activities/${type}?PerPage=${perPage}&PageNumber=${pageNumber}&Language=${i18n.language}`;
      const response: any = await API.get(url, auth);
      setLoading(false);
      setMaxPageNumber(Math.ceil(response.totalRecords / perPage));
      const pageArray: number[] = [];
      for (
        let index = 1;
        index <= Math.ceil(response.totalRecords / perPage);
        index++
      ) {
        pageArray.push(index);
      }
      setPages(pageArray);
      setActivities(response.results);
    } catch (e: any) {
      alert(e.errors?.Error!);
      navigate("/notFound");
    }
  };
  const firstUpdate = useRef(true);
  useEffect(() => {
    if (i18n.language == "undefined") return;
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    getActivities();
  }, [i18n.language]);

  return (
    <div>
      <div>
        <div className="w-100 ms-auto me-auto ">
          <div className="pb-0">
            <h1 className=" mb-4 ms-1 header-text">
              <FontAwesomeIcon icon="bolt" className="fs-3 me-2" />
              {t("activities")}
            </h1>
          </div>
        </div>
        {loading && <LoadingPageComponent />}
        {!loading && (
          <div className="card w-100 ms-auto me-auto">
            <div className="card-header bg-transparent row pb-0">
              <div className="col-5 col-sm-2">
                <SelectComponent
                  className={"form-select mb-3 float-end"}
                  options={filterOptions}
                  onChange={(e: any) => {
                    setType(Number(e.target.value));
                    setPageNumber(1);
                  }}
                  value={
                    filterOptions.find((e) => e.value == type.toString())?.value
                  }
                />
              </div>
            </div>
            <PaginationListComponent
              isEditable={false}
              isRemovable={false}
              list={activities}
              headerValues={values}
            />
            <PaginationComponent
              pageNumber={pageNumber}
              pages={pages}
              maxPageNumber={maxPageNumber}
              setPageNumber={setPageNumber}
            />
          </div>
        )}
      </div>
    </div>
  );
}
