import { t } from "i18next";
import React from "react";
import { useAuth } from "react-oidc-context";
import { LinkContainer } from "react-router-bootstrap";
import { CompanyFeatures } from "../common/store";
import useAppSelector from "../hooks/useAppSelector";
import { getCompanyFeatureGate } from "../redux/slices/config";

export default function(){
    const auth = useAuth();
    const companyFeatureGate: CompanyFeatures = useAppSelector(getCompanyFeatureGate)
    return(
        <footer className="footer">
            <div className="container-fluid">
                <div className="row text-muted">
                    {auth.isAuthenticated && 
                    <div className="text-start col-6">
                        <ul className="list-inline">
                        <li className="list-inline-item">
                            <LinkContainer to="support">
                                <a type='button' className="text-muted" href="#support">{`${t("support")}`}</a>
                            </LinkContainer>
                        </li>
                        {/*<li className="list-inline-item">*/}
                        {/*    <LinkContainer to="TOS">*/}
                        {/*        <a type='button' className="text-muted" href="#support">{`${t("termsOfService")}`}</a>*/}
                        {/*    </LinkContainer>*/}
                        {/*</li>*/}
                        </ul>
                    </div>}
                <div className={`text-end ${auth.isAuthenticated ? "col-6" : "col-12"} float-end`}>
                    <p className="mb-0">© 2022 - <span  className="text-muted">{companyFeatureGate.oem == "Aiphone" ? "Aiphone": "Hartmann Controls"}</span></p>
                </div>
            </div>
            </div>
        </footer>
    )
}