import React, { useEffect, useRef, useState } from "react";
import { useAuth } from "react-oidc-context";
import { useNavigate, useParams } from "react-router-dom";
import API from "../../common/API";
import { aiphoneLicenseTiers, CompanyFeatures, DeploymentDTO, DeploymentResponse, Features, GetDeployment, HostedPackage, Invoice, LicenseAddOn, LicenseDTO, OdysseyInfo, OptionType, PanelDTO, Pending, Roles, Tenant } from "../../common/store";
import LoadingPageComponent from "../../components/shared/LoadingPageComponent";
import TextInputComponent from "../../components/shared/TextInputComponent";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SuccessErrorMessage from "../../components/shared/SuccessErrorMessage";
import HeaderSectionsComponent from "../../components/shared/HeaderSectionsComponent";
import { useTranslation } from "react-i18next";
import PanelList from "./PanelList";
import LicenseDetails from "../../components/deployment/LicenseDetails";
import { OdysseyInfoComponent } from "../../components/deployment/OdysseyInfo";
import { ResponseComponent } from "../../components/deployment/ResponseComponent";
import { RearmComponent } from "../../components/deployment/RearmComponent";
import { HostedLicenseComponent } from "../../components/deployment/HostedLicense";
import { AdressDetailsComponent } from "../../components/shared/AddressDetails";
import { ContactDetailsComponent } from "../../components/shared/ContactDetails";
import LicenseHistoryComponent from "../../components/deployment/LicenseHistory";
import PaymentInfo from "../Payment/PaymentInfo";
import AddAddOns from "../../components/deployment/AddAdd-Ons";
import { GetDeploymentById } from "../../common/Functions/GetDeployment";
import PaymentHistory from "../Payment/PaymentHistory";
import useAppSelector from "../../hooks/useAppSelector";
import { getCompanyFeatureGate, getFeatureGate, getHostedDomain, getHostedOdysseyDomain, selectConfig, showACECard } from "../../redux/slices/config";
import Backups from "../../components/deployment/Backups";
import CameraLicensingComponent from "../../components/deployment/CameraLicensing";

export default function () {
    const auth = useAuth()
    const { t } = useTranslation()
    const userIdentifier = auth.user?.profile.client_id;
    const [isHosted, setIsHosted] = useState<boolean>(false)
    const navigate = useNavigate()
    const releaseMode = useAppSelector(selectConfig)
    const showACE = useAppSelector(showACECard)
    const { id, hosted, licenseHistory } = useParams();

    const [loading, setLoading] = useState<boolean>(true)
    const [showSuccessMessage, setShowSuccessMessage] = useState<boolean>(false)
    const [successMessage, setSuccessMessage] = useState<string>("")
    const [headerOptions, setHeaderOption] = useState<OptionType[]>([])
    const [currentSection, setCurrentSection] = useState<string>(licenseHistory != null ? "licenseHistory" :"deployment")

    //Deployment Info
    const [name, setName] = useState<string>('')
    const [label, setLabel] = useState<string>('')
    const [accountNumber, setAccountNumber] = useState<number>(0)
    const [type, setType] = useState<string>('')
    const [description, setDescription] = useState<string>('')

    //Address Info
    const [address, setAddress] = useState<string>('')
    const [city, setCity] = useState<string>('')
    const [postalCode, setPostalCode] = useState<string>('')
    const [country, setCountry] = useState<string>('')
    const [region, setRegion] = useState<string>('')


    //Contact Info
    const [contactName, setContactName] = useState<string>('')
    const [email, setEmail] = useState<string>('')
    const [phoneNumber, setPhoneNumber] = useState<string>('')
    const [emailSubscription, setEmailSubscription] = useState<number>(0)

    //License Info
    const [license, setLicense] = useState<LicenseDTO>()
    const [deploymentId, setDeploymentId] = useState<number>(id != null ? Number(id) : 0)
    const [licenseTerm, setLicenseTerm] = useState<string>("12")
    const [packageType, setPackageType] = useState<string>('')
    const [noLicence, setNotLicense] = useState<boolean>(false)
    const [responseKey, setResponseKey] = useState<string>('')
    const [rearm, setRearm] = useState<boolean>(false)
    const [licenseTier, setLicenseTier] = useState<string>("0")
    const [licenseAddOns, SetLicenseAddOns] = useState<LicenseAddOn[]>()

    //Hosted Deployment Variables
    const [hostedPackage, setHostedPackage] = useState<HostedPackage>()
    const [noHostedPackage, setNoHostedPackage] = useState<boolean>(false)
    const [hostedStatus, setHostedStatus] = useState<string>("")
    const [tenant, setTenant] = useState<Tenant>()
    const [nextPayment, setNextPayment] = useState<Date>()
    const [pending, setPending] = useState<Pending>()
    const [needsToBeUpdated, setNeedsToBeUpdated] = useState<Number | null>()

    //Online License Variables
    const [isOnline, setIsOnline] = useState<boolean>()
    const [odysseyInfo, setOdysseyInfo] = useState<OdysseyInfo>()
    const [isOnlineConnected, setIsOnlineConnected] = useState<boolean>()
    const [onlinePanels, setOnlinePanels] = useState<PanelDTO[]>()

    //Payment Variables
    const [payReference, setPayReference] = useState<string>('')
    const [payExpiry, setPayExpiry] = useState<Date>()
    const [latestInvoiceId, setLatestInvoiceId] = useState<number>()
    const [canPayHosted, setCanPayHosted] = useState<boolean>(false)
    const [canPrepaidHosted, setCanPrepaidHosted] = useState<boolean>(false);

    const deploymentTypes: OptionType[] = [{ value: '0', label: t('deployment:licensed') }, { value: '1', label: t('deployment:hosted') }]
    const featureGate: Features = useAppSelector(getFeatureGate)
    const companyFeatureGate: CompanyFeatures = useAppSelector(getCompanyFeatureGate)
    const hostedDomain: string = useAppSelector(getHostedDomain)
    const hostedOdysseyDomain: string = useAppSelector(getHostedOdysseyDomain)
    const [prepaid, setPrepaid] = useState<boolean>(false)
    const [acePaid, setAcePaid] = useState<boolean>(false);
    const [userPaid, setUserPaid] = useState<boolean>(false);

    const _licenseTiers = companyFeatureGate.oem == "Aiphone" ? aiphoneLicenseTiers : companyFeatureGate.allowedOdyssey ?
        [{ label: "ProtectorNet", value: "0" }, { label: "Odyssey", value: "1" }] : [{ label: "ProtectorNet", value: "0" }];;

    const getDeployment = async () => {
        try{
        GetDeploymentById(auth, id!,prepaid, acePaid, userPaid, setName, setLabel, setAccountNumber, setType, setDescription, setIsOnline,
            setPayExpiry, setPayReference, setNotLicense, setLicense, setLicenseTerm, setPackageType, setRearm, setOdysseyInfo,
            setIsOnlineConnected, setOnlinePanels, setHeaderOption, setHostedPackage, setAddress, setCity, setPostalCode, setCountry,
            setRegion, setContactName, setEmail, setLoading, setEmailSubscription, setPhoneNumber, navigate, setLicenseTier, SetLicenseAddOns, setHostedStatus,
            setTenant, setNextPayment, setLatestInvoiceId, setPending, featureGate, setIsHosted, setNeedsToBeUpdated, setAcePaid, setUserPaid, companyFeatureGate, setPrepaid)

        }
        catch(err)
        {
            console.log(err)
        }
    }

    const chargeStoredCard = async () => {
        try {
            const response: any = await API.get(`/api/v1/payment/chargeStoredCard/${id}`, auth)
        }
        catch (e: any) {
            alert(e.errors?.Error!)
        }
    }

    useEffect(() => {
        const getRoles = async () => {
            try {
                const response: any = await API.get(`/api/v1/user/roles/${userIdentifier}`, auth)
                const data: Roles = response.result
                if (!data.manageDeployments)
                    navigate('/deployment')
                getDeployment()
            }
            catch (e: any) {
                navigate('/deployment')
            }
        }
        getRoles()
    }, [])

    const updateDeployment = async () => {
        var deployment = { Name: name, Type: Number(deploymentTypes.find(x => x.label == type)?.value!), Description: description, Demo: false }
        var contact = { Name: contactName ?? "", EmailAddress: email == '' ? null : email, EmailSubscriptions: emailSubscription, PhoneNumber: phoneNumber == '' ? null : phoneNumber }
        var addressInfo = { Street: address ?? "", City: city ?? "", PostalCode: postalCode ?? "", StateName: region, CountryName: country }
        var object = { UserId: userIdentifier, Contact: contact, Address: addressInfo, Deployment: deployment }
        try {
            const response: DeploymentResponse | null = await API.put(`api/v1/deployment/${deploymentId}`, object, auth)
            setLabel(name)
            window.scrollTo(0, 0)
            setSuccessMessage(t('deployment:updatedMessage'))
            setShowSuccessMessage(true)
        }
        catch (e: any) {
            alert(e.errors?.Error!)
        }
    }

    const { i18n } = useTranslation()
    const firstUpdate = useRef(true);
    useEffect(() => {
        if (i18n.language == "undefined")
            return
        if (firstUpdate.current) {
            firstUpdate.current = false;
            return;
        }
        getDeployment()
    }, [i18n.language])

    useEffect(() => {
        if (nextPayment != null) {
            if(prepaid && (new Date(nextPayment?.toString()!).getTime() > new Date().getTime())
                    && (new Date(nextPayment?.toString()!).getTime() - new Date().getTime() <= 691200000)){
                setCanPrepaidHosted(prepaid && (new Date(nextPayment?.toString()!).getTime() > new Date().getTime())
                                            && (new Date(nextPayment?.toString()!).getTime() - new Date().getTime() <= 691200000))
            }else{
                setCanPayHosted(!prepaid && (new Date(nextPayment?.toString()!).getTime() > new Date().getTime())
                                            && (new Date(nextPayment?.toString()!).getTime() - new Date().getTime() <= 691200000))
            }

            console.log(prepaid && (new Date(nextPayment?.toString()!).getTime() > new Date().getTime())
                && (new Date(nextPayment?.toString()!).getTime() - new Date().getTime() <= 691200000))
        }
    }, [nextPayment])

    // console.log(new Date(nextPayment?.toString()!).getTime() > new Date().getTime())
    // console.log(new Date(nextPayment?.toString()!).getTime() - new Date().getTime())
    // console.log((new Date(nextPayment?.toString()!).getTime() - new Date().getTime() <= 691200000))
    // console.log((new Date(nextPayment?.toString()!).getTime() > new Date().getTime())
    //     && (new Date(nextPayment?.toString()!).getTime() - new Date().getTime() <= 691200000))

    return (
        <div className="">
            {(loading) && <LoadingPageComponent />}
            {!loading &&
                <div className="row" >
                    {(currentSection != 'Rearm License' && currentSection != 'Response') && <div className="row" >
                        <div className=" pb-0 col-12">
                            <h5 className="float-end">{type}</h5>
                            {isOnline != null && <h5 className="float-end me-2">{"Online"}</h5>}
                            <h1 className=' ms-1  mb-3 mb-sm-4 float-start  header-text'><FontAwesomeIcon icon="tablet-button" className="fs-3 me-2" />{label}</h1>
                            {isOnlineConnected != null && <h2 className={`float-start ms-4 mb-3 mt-1 badge disabled fs-4 d-none d-sm-block ${isOnlineConnected ? 'btn-vimeo' : 'btn-youtube'} `}>{isOnlineConnected ? `${t("connected")}` : `${t("disconnected")}`}</h2>}
                            {hostedStatus != null && hostedStatus != "" && <h2 className={`float-start ms-4 mb-3 mt-1 badge disabled d-none d-sm-block ${hostedStatus == t("deployment:disabled") || hostedStatus == t("deployment:pastdue") ? 'btn-youtube' :
                                hostedStatus == t("deployment:pending") ? 'bg-warning' : hostedStatus == t("deployment:activated") ? 'btn-twitter' : 'btn-vimeo'} fs-4`}>{hostedStatus}</h2>}
                        </div>
                        <div className="col-12">
                            {isOnlineConnected != null && <h2 className={`float-start mb-3  badge disabled fs-4 d-sm-none ${isOnlineConnected ? 'btn-vimeo' : 'btn-youtube'} `}>{isOnlineConnected ? `${t("connected")}` : `${t("disconnected")}`}</h2>}
                            {hostedStatus != null && <h2 className={`float-start mb-3  badge disabled d-sm-none ${hostedStatus == t("deployment:disabled") || hostedStatus == t("deployment:pastdue") ? 'btn-youtube' :
                                hostedStatus == t("deployment:pending") ? 'bg-warning' : hostedStatus == t("deployment:activated") ? 'btn-twitter' : 'btn-vimeo'} fs-4`}>{hostedStatus}</h2>}
                        </div>
                    </div>}
                    {((hostedStatus != null) && canPayHosted) &&
                        <div>
                            {hostedStatus == t("deployment:pastdue") && <SuccessErrorMessage showErrorMessage={hostedStatus == t("deployment:pastdue")} errorMessage={t("pastDueInvoiceMessage")} errorOnClick={() => setCurrentSection("paymentHistory")} />
                            }
                            {(hostedStatus != t("deployment:pastdue") && canPayHosted) && <SuccessErrorMessage showWarningMessage={canPayHosted} warningMessage={t("soonExpiringDeployment")} warningOnClick={() => setCurrentSection("paymentHistory")} />
                            }
                        </div>
                    }
                    {((hostedStatus != null) && canPrepaidHosted) &&
                        <div>
                            {hostedStatus == t("deployment:pastdue") && <SuccessErrorMessage showErrorMessage={hostedStatus == t("deployment:pastdue")} errorMessage={t("licenseExpiredMessage")} errorOnClick={() => setCurrentSection("packageDetails")} />
                            }
                            {(hostedStatus != t("deployment:pastdue") && canPrepaidHosted) && <SuccessErrorMessage showWarningMessage={canPrepaidHosted} warningMessage={t("soonExpiringDeploymentLicenseCode")} warningOnClick={() => setCurrentSection("packageDetails")} />
                            }
                        </div>
                    }
                    
                    {(hostedStatus != null && prepaid) && <div>
                        {tenant?.numOfUsers! > 2000 && !userPaid && <SuccessErrorMessage showErrorMessage={hostedStatus == t("deployment:pastdue") || hostedStatus == t("deployment:active")} errorMessage={t("activities:renewUserCard")} errorOnClick={() => setCurrentSection("packageDetails")} />
                        }  
                        {(tenant?.extraOptions == 1 && !acePaid) && <SuccessErrorMessage showErrorMessage={hostedStatus == t("deployment:pastdue") || hostedStatus == t("deployment:active")} errorMessage={t("activities:renewACECard")} errorOnClick={() => setCurrentSection("packageDetails")} />
                        }
                    </div>}
                     
                    <SuccessErrorMessage showSuccessMessage={showSuccessMessage} setShowSuccessMessage={setShowSuccessMessage} successMessage={successMessage} />
                    <div className="col-sm-3">
                        <HeaderSectionsComponent label={`${t("deployment:settings")}`} currentSection={currentSection} setCurrentSection={setCurrentSection} sections={headerOptions} />
                        {payReference != null && !releaseMode && <button className="btn btn-primary mb-4" onClick={() => chargeStoredCard()}>Charge Stored Card</button>}
                    </div>
                    <div className="col-sm-9">

                        {currentSection == 'deployment' &&
                            <div>
                                <div className="row">
                                    <div className=" col-sm-12 mb-4 h-100">
                                        <div className="card col-sm-12 pb-0">
                                            <div className=" card-header pb-0">
                                                <h2 className='mb-4 header-text d-inline '><FontAwesomeIcon icon="circle-info" className="fs-3 me-2" />{t('deployment:deploymentDetails')}</h2>
                                                <div className={`float-sm-end mt-sm-0 mt-2`}>
                                                    <h4 className={'text-dark'}>{_licenseTiers.find((e) => e.value == licenseTier)?.label!}</h4>
                                                </div>
                                            </div>
                                            <div className="card-body bg-opacity-75 p-0 mt-3">
                                                <TextInputComponent value={name} label={t('name')} setText={setName} />
                                                <TextInputComponent value={description} label={t('description')} setText={setDescription} />
                                                {(tenant != null) &&
                                                    <div>

                                                        {(needsToBeUpdated == null || needsToBeUpdated == 0 || needsToBeUpdated == 4) &&
                                                            <TextInputComponent link onClick={() => window.open("https://" + tenant.subdomain + (licenseTier == "0" ? hostedDomain : hostedOdysseyDomain), '_blank', 'noreferrer')}
                                                                value={tenant.subdomain + (licenseTier == "0" ? hostedDomain : hostedOdysseyDomain)} label={t("deployment:subdomain")} noEdit />
                                                        }
                                                        {(needsToBeUpdated == 1 || needsToBeUpdated == 2 || needsToBeUpdated == 3) &&
                                                            <TextInputComponent value={"Updating"} label={t("deployment:subdomain")} noEdit />
                                                        }
                                                    </div>
                                                }
                                                {(nextPayment != null) && <TextInputComponent value={new Date(nextPayment?.toString()!).toDateString()!} label={t("deployment:renewalDate")} noEdit />}
                                            </div>
                                        </div>
                                        <div className="card pb-0">
                                            < ContactDetailsComponent info={t('contactInfo')} hosted={isHosted} company={false} contactName={contactName} email={email} currentPhoneNumber={phoneNumber} setCurrentPhoneNumber={setPhoneNumber}
                                                setEmail={setEmail} setContactName={setContactName} card={true} emailSubscription={emailSubscription} setEmailSubscription={setEmailSubscription} />
                                        </div>
                                        <div className="card pb-0">
                                            <AdressDetailsComponent region={region} country={country} city={city} postalCode={postalCode} address={address}
                                                setAddress={setAddress} setRegion={setRegion} setCity={setCity} setCountry={setCountry} setPostalCode={setPostalCode} />
                                            <div className=" card-footer mb-3 mt-3 pe-5">
                                                <button className="btn float-end btn-twitter add-btn-text " onClick={() => updateDeployment()} >
                                                    <span >{t('update')}</span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                        {
                            currentSection == "licenseDetails" &&
                            <div>
                                <LicenseDetails tier={Number(licenseTier)} noLicence={noLicence} setCurrentSection={setCurrentSection} license={license} id={id}
                                    accountNumber={accountNumber} rearm={rearm} licenseTerm={licenseTerm} packageType={packageType} isOnline={isOnline!} licenseAddOns={licenseAddOns!} />
                            </div>

                        }
                        {
                            currentSection == "packageDetails" &&
                            <HostedLicenseComponent prepaid={prepaid} status={hostedStatus!} pending={pending} refresh={getDeployment} 
                                                    deploymentId={deploymentId} hostedPackage={hostedPackage} noHostedPackage={noHostedPackage} tierType={Number(licenseTier)} 
                                                    tenant={tenant} userIdentifier={userIdentifier!} setHostedStatus={setHostedStatus} acePaid={acePaid} userPaid={userPaid} type={Number(deploymentTypes.find(x => x.label == type)?.value!)}/>
                        }
                        {
                            currentSection == 'licenseHistory' &&
                            <LicenseHistoryComponent id={id} auth={auth} />
                        }
                        {
                            currentSection == "panels" &&
                            <PanelList deploymentId={id!}></PanelList>
                        }
                        {currentSection == 'Rearm License' &&
                            <RearmComponent multicard={license?.multicard!} auth={auth} activationDate={license?.activationDate} accountNumber={accountNumber} setLoading={setLoading}
                                setCurrentSection={setCurrentSection} setResponseKey={setResponseKey} packageType={packageType} licenseTerm={licenseTerm} licenseTier={Number(licenseTier)} />
                        }
                        {
                            currentSection == 'Response' &&
                            <ResponseComponent responseKey={responseKey} loading={loading} setResponseKey={setResponseKey} />
                        }
                        {
                            currentSection == "odysseyInfo" &&
                            <OdysseyInfoComponent onlinePanels={onlinePanels} deploymentId={deploymentId} />
                        }
                        {
                            currentSection == "paymentInfo" &&
                            <PaymentInfo payExpiry={payExpiry} payReference={payReference} id={Number(id)} setPayReference={setPayReference} />
                        }
                        {
                            currentSection == "addOns" &&
                            <AddAddOns licenseId={license?.id!} setSection={setCurrentSection} setResponseKey={setResponseKey} />
                        }
                        {
                            currentSection == "paymentHistory" &&
                            <PaymentHistory latestInvoiceId={latestInvoiceId!} deploymentId={deploymentId} pastDue={hostedStatus == t("deployment:pastdue") || canPayHosted} />
                        }
                        {
                            currentSection == "backups" &&
                            <Backups DeploymentId={deploymentId} />
                        }
                        {
                            currentSection == "cameraLicensing" &&
                            <CameraLicensingComponent deploymentId={deploymentId} />
                        }
                    </div>

                </div>
            }
        </div>
    )
}