import React, { useEffect, useState } from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import API from "../../common/API";
import { GetUserRequest, languageOptions, OptionType, PhoneNumberType } from "../../common/store";
import PhoneNumberComponent from "../../components/shared/PhoneNumberComponent";
import SuccessErrorMessage from "../../components/shared/SuccessErrorMessage";
import TextInputComponent from "../../components/shared/TextInputComponent";
import Select from 'react-select';
import { SelectStyle } from "../../common/SelectStyle";
import TimezoneSelect from "react-timezone-select";
import { useAuth } from "react-oidc-context";
import LoadingPageComponent from "../../components/shared/LoadingPageComponent";
import { useTranslation } from "react-i18next";
import HeaderSectionsComponent from "../../components/shared/HeaderSectionsComponent";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SelectComponent from "../../components/shared/SelectComponent";
import { isAdmin, setAddDeployment, setSysAdmin } from "../../redux/slices/config";
import useAppDispatch from "../../hooks/useAppDispatch";
import useAppSelector from "../../hooks/useAppSelector";

export default function () {
    const { t } = useTranslation();
    const auth = useAuth()
    const navigate = useNavigate()
    const sysAdmin: boolean = auth.user?.profile.role == "SysAdmin"
    const isSysAdmin = useAppSelector(isAdmin)
    const loggedInId = auth.user?.profile.client_id
    const { id } = useParams();
    const [loading, setLoading] = useState<boolean>(true)
    const headerOptions: OptionType[] = isSysAdmin ? [{ label: t("user:userDetails"), value: 'userDetails' }, { label: t("user:userRoles"), value: 'userRoles' }] : [{ label: t("user:userDetails"), value: 'userDetails' }]
    const [currentSection, setCurrentSection] = useState<string>('userDetails')

    const [showSuccessMessage, setShowSuccessMessage] = useState<boolean>(false)
    const [showErrorMessage, setShowErrorMessage] = useState<boolean>(false)
    const [errorMessage, setErrorMessage] = useState<string>("")
    const [successMessage, setSuccessMessage] = useState<string>("")
    // Admin Details
    const [firstName, setFirstName] = useState<string>('')
    const [lastName, setLastName] = useState<string>('')
    const [currentAdminPhoneTypeOption, setCurrentAdminTypeOption] = useState<string | undefined>('')
    const [currentAdminPhoneNumber, setCurrentAdminPhoneNumber] = useState<string>('')
    const [currentAdminExtension, setCurrentAdminExtension] = useState<string>('')
    const [language, setLanguage] = useState<string | undefined>("")
    const [adminEmail, setAdminEmail] = useState<string>("")
    const [_isAdmin, setIsAdmin] = useState<boolean>(false)
    const [timezone, setTimezone] = useState<string>("")
    const [manageCompany, setManageCompany] = useState<boolean>(false)
    const [manageDeployments, setManageDeployments] = useState<boolean>(false)
    const [manageHostedDeployments, setManageHostedDeployments] = useState<boolean>(false)
    const [manageLicenses, setManageLicenses] = useState<boolean>(false)
    const [managePayments, setManagePayments] = useState<boolean>(false)
    const [lastLoginTime, setLastLoginTime] = useState<string>('')

    const dispatch = useAppDispatch()

    useEffect(() => {
        const getUser = async () => {
            try {
                const response: any = await API.get(`/api/v1/User/${id}`, auth)
                setLoading(false)
                const data: GetUserRequest = response.result
                setFirstName(data.firstName)
                setLastName(data.lastName)
                setLanguage(data.language)
                setAdminEmail(data.email)
                setCurrentAdminExtension(data.phoneNumber?.extension!)
                setCurrentAdminPhoneNumber(data.phoneNumber?.number!)
                setCurrentAdminTypeOption(data.phoneNumber?.type)
                setIsAdmin(data.isAdmin)
                setLastLoginTime(data.lastLoggedIn)
                setTimezone(data.timezone)
                setManageCompany(data.manageCompany)
                setManageDeployments(data.manageDeployments)
                setManageHostedDeployments(data.manageHostedDeployments)
                setManageLicenses(data.manageLicenses)
                setManagePayments(data.managePayments)
            }
            catch (e: any) {
                alert(e.errors?.Error!)
                navigate('/user/list')
            }
        }
        getUser()
    }, [])

    const UpdateUser = async () => {
        const phoneNumber: PhoneNumberType = { type: currentAdminPhoneTypeOption , number: currentAdminPhoneNumber, extension: currentAdminExtension }
        const object: GetUserRequest = {
            firstName: firstName, lastName: lastName,
            language: language!, timezone: timezone,
            email: adminEmail, id: +id!, phoneNumber: phoneNumber,
            isAdmin: _isAdmin, lastLoggedIn: lastLoginTime,
            manageCompany: manageCompany, manageDeployments: manageDeployments,
            manageHostedDeployments: manageHostedDeployments, manageLicenses: manageLicenses,
            managePayments: managePayments
        }
        try {
            const response = await API.put("/api/v1/User", object, auth)
            window.scrollTo(0, 0)
            setSuccessMessage(t("user:updateMessage"))
            setShowSuccessMessage(true)
            setShowErrorMessage(false)
            if (loggedInId == id)
            {
                dispatch(setSysAdmin(_isAdmin))
                dispatch(setAddDeployment(manageDeployments || manageHostedDeployments))
            }
        }
        catch (e: any) {
            setErrorMessage(e.errors?.Error!)
            setShowErrorMessage(true)
            setShowSuccessMessage(false)
            console.log(e)
        }
    }

    const resendPasswordLink = async () => {
        try {
            const response = await API.post(`/api/v1/resendPasswordLink/${id}`, {}, auth)
            setSuccessMessage(t("user:resentPasswordMessage"))
            setShowSuccessMessage(true)
            setShowErrorMessage(false)
        }
        catch (e: any) {
            setErrorMessage(e.errors?.Error!)
            setShowErrorMessage(true)
            setShowSuccessMessage(false)
        }
    }

    return (

        <div className="">
            {loading && <LoadingPageComponent />}
            {!loading &&
                <div>
                    <div className=" border-0">
                        {lastLoginTime == "Not Registered" &&
                            <button className="float-end btn btn-primary" onClick={() => resendPasswordLink()}>
                                <span>{`${t("user:resendPassword")}`}</span>
                            </button>}
                        <h1 className='mb-4 header-text'><FontAwesomeIcon icon="user" className="fs-3 me-2" />{`${firstName} ${lastName}`}</h1>
                    </div>
                    <div className="row" >
                        <SuccessErrorMessage showErrorMessage={showErrorMessage} showSuccessMessage={showSuccessMessage} setShowErrorMessage={setShowErrorMessage}
                            setShowSuccessMessage={setShowSuccessMessage} errorMessage={errorMessage} successMessage={successMessage} />
                        <div className="col-sm-3">
                            <HeaderSectionsComponent label={`${t("user:profileSettings")}`} currentSection={currentSection} setCurrentSection={setCurrentSection} sections={headerOptions} />
                        </div>
                        <div className=" col-sm-9 mb-4 card shadow border-2 p-0">
                            {currentSection == 'userDetails' && <div>
                                <div className=" card-header border-0 ">
                                    <h2 className='mb-4 header-text'><FontAwesomeIcon icon="circle-info" className="fs-3 me-2" />{`${t("user:userDetails")}`}</h2>
                                </div>
                                <div className="card-body bg-opacity-75 pt-3 ">
                                    <TextInputComponent noEdit={true} value={adminEmail} label={t('user:emailAddress')} setText={setAdminEmail} validateText={"This is not a valid e-mail address."} />
                                    <TextInputComponent noEdit={true} value={firstName} label={t('user:firstName')} setText={setFirstName} />
                                    <TextInputComponent noEdit={true} value={lastName} label={t('user:lastName')} setText={setLastName} />
                                    <TextInputComponent value={lastLoginTime} label={t('user:lastLoggedIn')} noEdit={true} setText={setLastLoginTime} />
                                    <div className="form-group row ">
                                        <div className="col-1 d-none d-sm-block"></div>
                                        <label className="col-4 col-sm-2  col-form-label text-begin mb-4 ms-3" >
                                            <h3 className="d-none d-sm-block">{t('user:language')}</h3>
                                            <h3 className="d-block d-sm-none fs-4">{t('user:language')}</h3>
                                        </label>
                                        <div className="col-7 col-sm-5">
                                            <SelectComponent className="form-select form-control-lg" options={languageOptions} onChange={(e: any) => setLanguage(e.target.value)} value={languageOptions.find(x => x.value == language)?.value} />
                                        </div>
                                    </div>
                                    <div className="form-group row mb-4">
                                        <div className="col-1 d-none d-sm-block"></div>
                                        <label className="col-4 col-sm-2  col-form-label text-begin ms-3" >
                                            <h3 className="d-none d-sm-block">{t('user:timeZone')}</h3>
                                            <h3 className="d-block d-sm-none fs-4">{t('user:timeZone')}</h3>
                                        </label>
                                        <div className="col-7 col-sm-5">
                                            <TimezoneSelect value={timezone} onChange={(e) => setTimezone(e.value)} />
                                        </div>
                                    </div>
                                    <PhoneNumberComponent phoneNumber={currentAdminPhoneNumber} extension={currentAdminExtension ?? ""} typeOption={currentAdminPhoneTypeOption ?? "Work"} setPhoneNumber={setCurrentAdminPhoneNumber}
                                        setExtension={setCurrentAdminExtension} setTypeOption={setCurrentAdminTypeOption} />
                                </div>
                            </div>}
                            {currentSection == "userRoles" &&
                                <div>
                                    <div className=" card-header border-0 ">
                                        <h2 className='mb-4 header-text'><FontAwesomeIcon icon="address-book" className="fs-3 me-2" />{`${t("user:userRoles")}`}</h2>
                                    </div>
                                    <div className="card-body">
                                        <div className="form-group row mb-4">
                                            <div className="col-1 d-none d-sm-block"></div>
                                            <label className="col-5 col-sm-3 col-form-label text-begin" ><h3>{t('user:systemAdmin')}</h3></label>
                                            <div className="col-5 form-check form-switch">
                                                <input className="align-bottom form-check-input mt-2 " type="checkbox" id="flexSwitchCheckDefault" disabled={!isSysAdmin} checked={_isAdmin} onChange={(e) => setIsAdmin(!_isAdmin)} />
                                            </div>
                                        </div>
                                        <div className="form-group row mb-4 mt-4">
                                            <div className="col-1 d-none d-sm-block"></div>
                                            <label className="col-5 col-sm-3 col-form-label text-begin" ><h3>{t('user:manageCompany')}</h3></label>
                                            <div className="col-5 form-check form-switch">
                                                <input className="align-bottom form-check-input mt-2 " type="checkbox" id="flexSwitchCheckDefault" disabled={!isSysAdmin} checked={manageCompany} onChange={(e) => setManageCompany(!manageCompany)} />
                                            </div>
                                        </div>
                                        <div className="form-group row mb-4 mt-4">
                                            <div className="col-1 d-none d-sm-block"></div>
                                            <label className="col-5 col-sm-3 col-form-label text-begin" ><h3>{t('user:manageDeployments')}</h3></label>
                                            <div className="col-5 form-check form-switch">
                                                <input className="align-bottom form-check-input mt-2 " type="checkbox" id="flexSwitchCheckDefault" disabled={!isSysAdmin} checked={manageDeployments} onChange={(e) => setManageDeployments(!manageDeployments)} />
                                            </div>
                                        </div>
                                        <div className="form-group row mb-4 mt-4">
                                            <div className="col-1 d-none d-sm-block"></div>
                                            <label className="col-5 col-sm-3 col-form-label text-begin" ><h3>{t('user:manageHostedDeployments')}</h3></label>
                                            <div className="col-5 form-check form-switch">
                                                <input className="align-bottom form-check-input mt-2 " type="checkbox" id="flexSwitchCheckDefault" disabled={!isSysAdmin} checked={manageHostedDeployments} onChange={(e) => setManageHostedDeployments(!manageHostedDeployments)} />
                                            </div>
                                        </div>
                                        <div className="form-group row mb-4 mt-4">
                                            <div className="col-1 d-none d-sm-block"></div>
                                            <label className="col-5 col-sm-3 col-form-label text-begin" ><h3>{t('user:manageLicenses')}</h3></label>
                                            <div className="col-5 form-check form-switch">
                                                <input className="align-bottom form-check-input mt-2 " type="checkbox" id="flexSwitchCheckDefault" disabled={!isSysAdmin} checked={manageLicenses} onChange={(e) => setManageLicenses(!manageLicenses)} />
                                            </div>
                                        </div>
                                        <div className="form-group row mb-4 mt-4">
                                            <div className="col-1 d-none d-sm-block"></div>
                                            <label className="col-5 col-sm-3 col-form-label text-begin" ><h3>{t('user:managePayments')}</h3></label>
                                            <div className="col-5 form-check form-switch">
                                                <input className="align-bottom form-check-input mt-2 " type="checkbox" id="flexSwitchCheckDefault" disabled={!isSysAdmin} checked={managePayments} onChange={(e) => setManagePayments(!managePayments)} />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            }
                            <div className=" mb-4 pe-5">
                                <button className="btn float-end btn-twitter add-btn-text " onClick={() => UpdateUser()}>
                                    <span >{t('update')}</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>

    )
}

