import axios from 'axios'
import React, { useEffect, useReducer, useState } from 'react'
import { useParams } from 'react-router-dom'
import { OptionType, PanelDTO, PanelInfo } from '../../common/store'
import { FetchResponse, GenericPagedResponse } from '../../common/Type'
import { useTranslation } from "react-i18next";
import { useAuth } from 'react-oidc-context'
import { useMemo } from 'react'
import PaginationListComponent from '../../components/shared/PaginatedListComponent'
import { LinkContainer } from 'react-router-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import AddPanel from '../../components/panel/AddPanel'
import API from '../../common/API'
type Props = {
  deploymentId: string
}

//TODO:use web socket to update list of panels once adding and deleting performed 
function PanelList(props: Props) {
  //const { deploymentId } = useParams();
  let auth = useAuth();
  const [panels, setPanel] = useState<PanelDTO[]>([]);
  const { t } = useTranslation()
  const [searchValue, setSearchValue] = useState<string>('')
  const header: OptionType[] = [
    { value: "macAddress", label: t('panel:macAddress') },
    { value: "createdOn", label: t('panel:createdOn') },
  ]
  const addPanel = async (macAddress:string) => {
    
    try{
      const response = await API.post(`/api/v1/hosteddeployment/${props.deploymentId}/panels`,{ macAddress},auth)
      getPanels();
    }
    catch(e:any){
      alert(e.errors?.Error!)
    }
  }

  const getPanels = async () => {
   try{
    const response: any = await API.get(`/api/v1/hosteddeployment/${props.deploymentId}/panels`, auth)
    const results: PanelDTO[] = response.results;
    results.forEach(e => {
      e.createdOn = new Date(e.createdOn?.toString()!).toUTCString()!
      e.macAddress = (e.macAddress.replaceAll(":", "")).replace(/..\B/g, '$&:').toUpperCase()
    });
    setPanel(results);
   } 
   catch(e:any){
      alert(e.errors?.Error!)
    }
  }

  useEffect(() => {
    getPanels();
  }, [])

  return (
    <div>
      
      <div className="card">
        <div className="card-header">
          <h2 className='mb-4 header-text '><FontAwesomeIcon icon="box" className="fs-3 me-2" />{t('deployment:panels')}</h2>
            <AddPanel addPanel={addPanel}></AddPanel>
        </div>
        <PaginationListComponent
          isEditable={false}
          isRemovable={true}
          list={panels!}
          headerValues={header}
          editLink={"/deployment/"}
          repopulate={getPanels}
          deleteString={`api/v1/hosteddeployment/${props.deploymentId}/panels/`} />
      </div>
    </div>
  )
}

export default PanelList