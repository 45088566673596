import { t } from "i18next";
import React from "react";
import { AuthContextProps } from "react-oidc-context";
import { NavigateFunction } from "react-router-dom";
import API from "../API";
import { DeploymentResponse, HostedDeploymentResponse } from "../store";
import { LicenseTier } from "../../components/shared/Enums";

export default async function (auth:AuthContextProps, name:string, type:number, description:string, contactName:string,
    email:string, emailSubscription:number, phoneNumber:string, address:string, city:string, postalCode:string, region:string, country:string,
    subdomain:String, userIdentifier:unknown, isOnline:boolean, prepaid : boolean, tierType:LicenseTier, setLoading:Function, setDeploymentId:Function, setAccountNumber:Function, setSection:Function,
    navigate:NavigateFunction,setLicenseTierType:Function){
        
    setLoading(true)
        var deployment = { Name: name, Type: Number(type), Description: description, EULA: true, TierType: tierType }
        var contact = { Name: contactName, EmailAddress: email, EmailSubscriptions: emailSubscription, PhoneNumber: phoneNumber == "" ? null : phoneNumber }
        var addressInfo = { Street: address, City: city, PostalCode: postalCode, StateName: region, CountryName: country }
        var tenant = { Subdomain: subdomain }
        var object = { UserId: userIdentifier, Contact: contact, Address: addressInfo, Deployment: deployment, Tenant: type == 1 ? tenant : null }
        try {
            const response: any = await API.post(`${type == 1 ? "api/v1/hosteddeployment" : "api/v1/deployment"}`, object, auth)
            setDeploymentId(response?.id!)
            setLoading(false)
            if (type != 1) {
                setAccountNumber(response?.accountNumber!)
            }else{
                setLicenseTierType(response.tierType!);
            }
            if (isOnline) {
                navigate('/deployment')
            }
            console.log(prepaid)
           
            setSection("method")
            
        }
        catch (e: any) {
            setLoading(false)
            alert(e.errors?.Error!)
        }
}