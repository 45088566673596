import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from 'react-i18next';
import React from "react";
import CountryRegionComponent from "./CountryRegionComponent";
import TextInputComponent from "./TextInputComponent";
import PostalCodeValidator from "../../common/Functions/PostalCodeValidator";
import RequiredFields from "./RequiredFields";

type Props = {
    address: string,
    region: string,
    country: string,
    postalCode: string,
    city: string,
    setAddress: (value: React.SetStateAction<string>) => void,
    setCountry: (value: React.SetStateAction<string>) => void,
    setRegion: (value: React.SetStateAction<string>) => void,
    setPostalCode: (value: React.SetStateAction<string>) => void,
    setCity: (value: React.SetStateAction<string>) => void,
    onlyBody?: boolean,
    card?: boolean,
    addPage?:boolean
}
export function AdressDetailsComponent(props: Props) {
    const { t } = useTranslation();
    return (
        <div className={props.card != null ? "card h-100  mb-0 p-0" : undefined}>
            {props.onlyBody == null && <div className=" card-header pb-0">
                <h2 className='mb-4 header-text '><FontAwesomeIcon icon="location-dot" className="fs-3 me-2" />{t('addressDetails')}</h2>
                {props.addPage && <RequiredFields />}
            </div>}
            <div className="card-body bg-opacity-75">
                <TextInputComponent mandatory={props.addPage} value={props.address} label={t('address')} setText={props.setAddress} />
                <CountryRegionComponent mandatory={props.addPage} country={props.country} region={props.region} setCountry={props.setCountry} setRegion={props.setRegion} />
                <TextInputComponent mandatory={props.addPage} value={props.city} label={t('city')} setText={props.setCity} />
                <TextInputComponent validator={(e:string) => PostalCodeValidator(e, props.country)} validateText={t("invalidPostalCode")} mandatory={props.addPage} value={props.postalCode} label={t('postalCode')} setText={props.setPostalCode} />
            </div>
        </div>
    )
}