import React, { useEffect, useRef, useState } from "react";
import { useAuth } from "react-oidc-context";
import API from "../../common/API";
import { Activity, OptionType } from "../../common/store";
import LoadingPageComponent from "../../components/shared/LoadingPageComponent";
import PaginationListComponent from "../../components/shared/PaginatedListComponent";
import { PaginationComponent } from "../../components/shared/PaginationComponent";
import Select, { StylesConfig } from 'react-select'
import { SelectStyle } from "../../common/SelectStyle";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SelectComponent from "../../components/shared/SelectComponent";

export default function () {
    const navigate = useNavigate()
    const auth = useAuth()
    const { t } = useTranslation()
    const [perPage, setPerPage] = useState<number>(20)
    const [pageNumber, setPageNumber] = useState<number>(1)
    const [maxPageNumber, setMaxPageNumber] = useState<number>(0)
    const [pages, setPages] = useState<number[]>([])
    const [loading, setLoading] = useState<boolean>(true)
    const [invoices, setInvoices] = useState<any[]>([])
    const [searchValue, setSearchValue] = useState<string>('')
    const [orderBy, setOrderBy] = useState<string>('createdOn')
    const [orderDirection, setOrderDirection] = useState<string>('Desc')

    const values: OptionType[] = [{ value: "deployment", label: t("deployment"), s1: "deploymentId", s2: "/deployment/", i1: 1 }, { value: "status", label: t("status"), i1: 1 },
    { value: "subTotal", label: t("subtotal"), i1: 1 }, { value: "taxes", label: t("invoice:taxes"), i1: 1 }, { value: "total", label: t("total"), i1: 1 }, { value: "createdOn", label: t("createdOn"), i1: 1 }
        , { value: "purchaseOrder", label: t("purchaseOrder"), i1: 1 }
    ]

    const values_sm: OptionType[] = [{ value: "deployment", label: t("deployment"), s1: "deploymentId", s2: "/deployment/" }, { value: "status", label: t("status") },
    { value: "total", label: t("total") }
    ]
    const InvoiceStatus: OptionType[] = [{ value: '0', label: t("invoice:created") }, { value: '1', label: t("invoice:paid") }, { value: '2', label: t("invoice:exported") }, { value: '3', label: t("invoice:void") }]

    useEffect(() => {
        setLoading(true)
        getInvoices()
    }, [pageNumber])

    useEffect(() => {
        if (pageNumber == 1)
            getInvoices()
        else
            setPageNumber(1)
    }, [searchValue, orderBy, orderDirection])

    const buildFilterString = (filter: string) => {
        return 'Deployment.Name.ToLower().Contains(' + encodeURI('"' + filter.toLowerCase() + '"') + ')'
    }
    const getInvoices = async () => {
        try {
            var ordering: string = orderBy == 'deployment' ? 'deployment.Name' : orderBy
            const url: string = `/api/v1/invoices?PerPage=${perPage}&PageNumber=${pageNumber}&Filter=${buildFilterString(searchValue)}&OrderBy=${ordering} ${orderDirection}`
            const response: any = await API.get(url, auth)
            setLoading(false)

            response.results.map((e: any) => {
                e.deployment = e.deployment.name
                e.createdOn = new Date(e?.createdOn!.toString()!).toLocaleDateString() + " " + new Date(e?.createdOn!.toString()!).toLocaleTimeString()
                if (e.paidOn != null)
                    e.paidOn = new Date(e?.paidOn!.toString()!).toLocaleDateString() + " " + new Date(e?.paidOn!.toString()!).toLocaleTimeString()
            })

            setMaxPageNumber(Math.ceil(response.totalRecords / perPage))
            const pageArray: number[] = []
            for (let index = 1; index <= Math.ceil(response.totalRecords / perPage); index++) {
                pageArray.push(index)
            }
            response.results.map((e: any) => {
                e.subTotal = '$' + e.subTotal.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
                e.taxes = '$' + e.taxes.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
                e.total = '$' + e.total.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
                e.status = InvoiceStatus.find(f => f.value == e.status.toString())?.label
            })
            setPages(pageArray)
            setInvoices(response.results)
        }
        catch (e: any) {
            alert(e.errors?.Error!)
            navigate('/notFound')
        }
    }

    const { i18n } = useTranslation()
    const firstUpdate = useRef(true);
    useEffect(() => {
        if (i18n.language == "undefined")
            return
        if (firstUpdate.current) {
            firstUpdate.current = false;
            return;
        }
        getInvoices()
    }, [i18n.language])
    return (
        <div>
            <div>
                <div className="w-100 ms-auto me-auto ">
                    <div className="pb-0">
                        <h1 className=' mb-4 ms-1 header-text'><FontAwesomeIcon icon="file-invoice-dollar" className="fs-3 me-2" />{t('invoices')}</h1>
                    </div>
                </div>
                {loading && <LoadingPageComponent />}
                {!loading &&
                    <div className="card w-100 ms-auto me-auto">
                        <div className="card-header bg-transparent row pb-0">
                            <div className="col-0 col-sm-9"></div>
                            <form className="col-12 col-sm-3 ">
                                <div className="float-end input-group-navbar input-group" style={{ border: "1px solid white" }}>
                                    <input className=" float-end form-control input-text" placeholder={t("deployment:deploymentSearch")} type="text" value={searchValue} onChange={(e) => setSearchValue(e.target.value)} />
                                    <button className="btn">
                                        <FontAwesomeIcon icon="magnifying-glass" className=" ms-2" />
                                    </button>
                                </div>
                            </form>
                        </div>
                        <div className="d-none d-sm-block">
                            <PaginationListComponent
                                isEditable={true}
                                isRemovable={false}
                                list={invoices}
                                headerValues={values}
                                editLink={"/invoice/"}
                                setOrderBy={setOrderBy}
                                orderBy={orderBy}
                                orderDirection={orderDirection}
                                setOrderDirection={setOrderDirection} />
                        </div>
                        <div className="d-block d-sm-none">
                            <PaginationListComponent isEditable={true} isRemovable={false} list={invoices} headerValues={values_sm} editLink={"/invoice/"} />
                        </div>
                        <PaginationComponent pageNumber={pageNumber} pages={pages} maxPageNumber={maxPageNumber} setPageNumber={setPageNumber} />
                    </div>
                }
            </div>
        </div>
    )
}