import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { AuthContextProps } from "react-oidc-context";
import API from "../../common/API";
import { LicenseDTO } from "../../common/store";
import TextInputComponent from "../shared/TextInputComponent";

type Props = {
    activationDate: Date|undefined,
    packageType:string,
    accountNumber:number,
    licenseTerm:string,
    setCurrentSection:(value: React.SetStateAction<string>) => void,
    setResponseKey:(value: React.SetStateAction<string>) => void,
    setLoading: (value: React.SetStateAction<boolean>) => void,
    auth: AuthContextProps,
    licenseTier: number,
    multicard: boolean
  }

export function RearmComponent(props:Props){
    const { t } = useTranslation();
    const [requestKey, setRequestKey] = useState<string>('')
    const [registrationKeyCopy, setRegistrationKeyCopy] = useState<string>("")
    const rearmLicense = async () => {
        const object = {AccountNumber: props.accountNumber, ActivationDate: props.activationDate, RequestKey: requestKey, Description: '', TierType:props.licenseTier, Multicard: multicard == 16}
        try{
            props.setCurrentSection('Response')
            props.setLoading(true)
            const response:any = await API.post(`api/v1/license/rearm`, object, props.auth)
            props.setResponseKey(response.responseKey)
            props.setLoading(false)
        }
        catch (e: any){ 
            alert(e.errors?.Error!)
            props.setCurrentSection('deployment')
            props.setLoading(false)
        }
    }
    const [multicard, setMulticard] = useState<number>(props.multicard ? 16 : 0)

    useEffect(() =>{
        if (requestKey.length == 29)
            return
        if (requestKey.length > 29)
            setRequestKey(requestKey.substring(0,29))
        if (requestKey.endsWith("-") && requestKey.length % 6 != 0)
            setRequestKey(requestKey.substring(0,requestKey.length - 1))
        if (requestKey.length % 6 == 0)
        {
            if (!requestKey.endsWith("-") && requestKey.length != 0)
            {
                const temp = requestKey[requestKey.length - 1]
                setRequestKey(requestKey.substring(0, requestKey.length - 1) + "-" + temp)
                setRegistrationKeyCopy(requestKey.substring(0, requestKey.length - 1) + "-" + temp)
            }
            return
        }
        var newKey = requestKey.replaceAll("-", "")
        if (newKey.length % 5 == 0)
        {    
            if (registrationKeyCopy.length < requestKey.length)
            {   
                setRequestKey(requestKey + "-")
                setRegistrationKeyCopy(requestKey + "-")
            }
        }
        else
            setRegistrationKeyCopy(requestKey)

    }, [requestKey])
    return (

        <div className="card">
            <div className="card-header">
                <h2 className='header-text'><FontAwesomeIcon icon="edit" className="fs-3 me-2" />{t('license:rearmLicense')}</h2>
            </div>
            <div className="card-body">
                <div className="card-body bg-opacity-75 ">
                    <TextInputComponent value={props.accountNumber.toString()} label={t('license:accountNumber')} noEdit />
                    <TextInputComponent value={props.licenseTerm} label={t('license:licenseTerm')} noEdit />
                    <TextInputComponent value={props.packageType} label={t('license:package')} noEdit />
                    <div className="form-group row mb-4">
                        <div className="col-1 d-none d-sm-block"></div>
                        <label className="col-4 col-sm-2 col-form-label text-begin ms-3" >
                            <h3 className={`d-none d-sm-block`}>{t('license:multiCard')}</h3>
                            <h3 className="d-block d-sm-none fs-5">{t('license:multiCard')}</h3>
                        </label>
                        <div className="col-7 col-sm-5  form-check form-switch ps-0 mt-2 text-center">
                            <input className="align-bottom form-check-input ms-4" type="checkbox" id="flexSwitchCheckDefault" checked={multicard == 16} onChange={(e) => setMulticard(multicard == 16? 0 : 16)}  />
                        </div>
                    </div>
                    <TextInputComponent value={requestKey} label={t('license:registrationKey')} setText={setRequestKey} upperCase />
                </div>
            </div>
            <div className="mb-4 pe-5 float-end">
                <button className="btn float-start btn-secondary ms-5  " onClick={() => props.setCurrentSection('deployment')} >
                    <FontAwesomeIcon icon="chevron-left" className="me-2" />
                    <span >{t('back')}</span>
                </button>
                <button className="btn float-end btn-secondary " disabled={requestKey == ''} onClick={() => rearmLicense()} >
                    <span >{t('license:rearm')}</span>
                </button>
            </div>
        </div>
    )
}