
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { t } from "i18next";
import React from "react";
export default function() {

    return(
        <div className="row fs-5">
            <div className="col-sm-3"></div>
            <div className="col-12 col-sm-4 ms-3">
                <span className="fs-5 text-black-50 fw-bold">{`${t("mandatoryFields")}`} </span>
                <FontAwesomeIcon icon="star-of-life" className="text-black-50 ms-2 mt-1" style={{"fontSize": "0.65rem"}} />
            </div>
        </div>
    )
}