import React from "react";
import { Pie, Doughnut, Chart } from 'react-chartjs-2';
import 'chart.js/auto'
import { DashboardInfo, Features } from "../../common/store";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import 'chart.js/auto'
import { t } from "i18next";

export default function ({ dashboardInfo, featureGate}: { dashboardInfo: DashboardInfo, featureGate: Features }) {

    const state = {
        labels: featureGate.onlineDeployments ? 
        featureGate.hostedDeployments? [t('online'), t('hosted'), t('licensed')] :  [t('online'), t('licensed')] :
        featureGate.hostedDeployments ?[t('hosted'), t('licensed')] : [t('licensed')] ,
        datasets: [
            {
                label: 'Deployment Types',
                backgroundColor: featureGate.onlineDeployments ? featureGate.hostedDeployments? ['#1E90FF','#ebd619','rgb(217, 83, 79)']: ['#1E90FF','rgb(217, 83, 79)']:
                featureGate.hostedDeployments? ['#ebd619','rgb(217, 83, 79)']: ['rgb(217, 83, 79)'],

                hoverBackgroundColor:  featureGate.onlineDeployments ? featureGate.hostedDeployments? ['#000080','#FFA500','#800000']: ['#000080','#800000']:
                featureGate.hostedDeployments? ['#FFA500','#800000']: ['#800000'],

                data:  featureGate.onlineDeployments ? 
                featureGate.hostedDeployments
                ?[dashboardInfo?.numberOfOnlineLicensedDeployments, dashboardInfo?.numberOfHostedDeployments, dashboardInfo?.numberOfLicensedDeployments] 
                :[dashboardInfo?.numberOfOnlineLicensedDeployments, dashboardInfo?.numberOfLicensedDeployments] 
                : featureGate.hostedDeployments
                ?[ dashboardInfo?.numberOfHostedDeployments, dashboardInfo?.numberOfLicensedDeployments]
                :[ dashboardInfo?.numberOfLicensedDeployments]
            }
        ]
    }
    const noApp: boolean = dashboardInfo?.numberOfOnlineLicensedDeployments + dashboardInfo?.numberOfHostedDeployments + dashboardInfo?.numberOfLicensedDeployments == 0
    return (
        <div className="flex-fill w-100 card">
            <div className="card-header">
                <div className="mb-0 card-title h5">
                    <h4>{`${t("dashboard:deploymentTypes")}`}</h4>
                </div>
            </div>
            <div className="card-body">
                <div className="align-self-center w-100">
                    <div className="py-2">
                        <div className="chart chart-xs">
                            {!noApp && <Doughnut
                                data={state}
                                options={{
                                    radius: "85%",
                                    cutout: "60%",
                                    responsive: true,
                                    maintainAspectRatio: true,
                                    plugins: {
                                        legend: {
                                            display: true,
                                            position: 'bottom',
                                            fullSize: true,
                                            labels: {
                                                font: {
                                                    size: 15
                                                },
                                                padding: 20
                                            }
                                        }
                                    }
                                }}
                            />}
                            {noApp &&
                                <div className=" w-75 ms-auto me-auto">
                                    <FontAwesomeIcon className=" w-100 h-75 text-center text-light" icon="circle-exclamation" />
                                    <h3 className="text-center mt-4">No Applications Exist</h3>
                                </div>}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}