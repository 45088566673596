import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { t } from "i18next";
import React, { MouseEventHandler } from "react";

type Props = {
    label: string,
    value: string,
    setText?: (value: React.SetStateAction<string>) => void,
    validator?: Function,
    validateText?: string,
    noEdit?: boolean,
    labelClass?: string,
    inputClass?: string,
    maxLength?: number,
    minLength?: number,
    alphanumeric?: boolean,
    onClick?: MouseEventHandler<HTMLInputElement>
    link?: boolean
    smallLabel?: boolean,
    labelColor?: string,
    inputColor?: string,
    mandatory?: boolean,
    placeholder?: string,
    append?: IconProp,
    appendClass?: string,
    upperCase?: boolean,
    noLabelPadding?: boolean
}

export default function TextInputComponent({ label, value, setText, validator, validateText, noEdit, labelClass, inputClass,
    maxLength, minLength, alphanumeric, onClick, link, smallLabel, labelColor, inputColor, mandatory, placeholder, append, appendClass, upperCase, noLabelPadding }: Props) {
    const validate = validator == null ? true : validator(value)
    const alphanumericCheck = alphanumeric == null ? false : /[^0-9a-zA-Z]/.test(value)
    if (value == null)
        value = ""
    return (
        <div className="form-group row ">
            {(labelClass != "d-none" && noLabelPadding == null) && <div className="col-1 d-none d-sm-block"></div>}
            <label className={labelClass != null ? labelClass : "col-4 col-sm-2 col-form-label text-begin mb-4 ms-3"} >
                <h3 className={`${smallLabel ? 'd-none d-sm-block fs-5 ' : 'd-none d-sm-block'} ${labelColor ?? ''}`}>{label}</h3>
                <h3 className={`d-block d-sm-none fs-4 ${labelColor ?? ''}`}>{label} {mandatory != null &&
                    <FontAwesomeIcon icon="star-of-life" className=" mt-2 d-inline d-sm-none text-center" style={{ "fontSize": "0.65rem" }} />}</h3> 
            </label>
            <div onClick={noEdit == null ? () => { } : onClick!} className={inputClass == null ? "col-7 col-sm-5" : inputClass}>
                {link == null && <input size={value.length + 4} placeholder={placeholder} maxLength={maxLength!} minLength={minLength!} className={noEdit ? ` ${append == null ? 'form-control' : 'w-auto text-center'} bg-white fw-bolder fs-5 pt-0 mt-1  ${link != null ? "nav-link" : ""} ${inputColor ?? ''}`
                    : `${append == null ? 'form-control form-control-lg' : 'form-control form-control-lg'} input-text ${inputColor ?? ''}`}
                    style={{ 'border': noEdit ? 'none' : '' }} type="text" value={value} onChange={(e) => setText != null ? upperCase ? setText(e.target.value.toUpperCase()) : setText(e.target.value) : {}} disabled={noEdit}  onClick={onClick!}/>}
                {link != null && <span className="bg-white fw-bolder fs-5 pt-0 mt-1 nav-link" style={{cursor:"pointer"}}>{value}</span>}
                
                {append && <div className={`d-inline ${appendClass != null ? appendClass : ''}`} onClick={onClick!}>
                    <FontAwesomeIcon icon={append} className="fs-4 d-inline" />
                </div>}
                {(!validate && value != '') &&
                    <div className="col-12 mt-2">
                        <p className="" style={{ "color": "#FF0000" }}>{validateText}</p>
                    </div>}
                {(minLength != null && value != '' && value.length < minLength!) && <div className="col-12 mt-2">
                    <p className="" style={{ "color": "#FF0000" }}>{`${t("error:invalidLengthOfInput").replace('$$', minLength.toString())}`}</p>
                </div>}
                {(alphanumericCheck && value != '' && (value.length >= minLength! || minLength == null)) && <div className="col-12 mt-2">
                    <p className="" style={{ "color": "#FF0000" }}>{`${t("error:inputNotAlphaNumeric")}`}</p>
                </div>}

            </div>

            {mandatory != null &&
                <div className="col-1 d-none d-sm-block">
                    <FontAwesomeIcon icon="star-of-life" className=" mt-2 d-inline text-black-50" style={{ "fontSize": "0.65rem" }} />
                </div>
            }


        </div>


    )
}