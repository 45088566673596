import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { aiphoneLicenseTiers, CompanyFeatures, OptionType } from "../../common/store";
import PackageComponent from "./PackageComponent";
import RadioComponent from "../shared/RadioComponent";
import useAppSelector from "../../hooks/useAppSelector";
import { getCompanyFeatureGate } from "../../redux/slices/config";
import { LicenseTier } from "../shared/Enums";

type Props =  {
    licenseTerm: string,
    setLicenseTerm: (value: React.SetStateAction<string>) => void,
    packageType: number,
    setPackageType: (value: React.SetStateAction<number>) => void,
    fivePartitions: number,
    tenPartitions: number,
    twentyPartitions: number,
    setFivePartitions: (value: React.SetStateAction<number>) => void,
    setTenPartitions: (value: React.SetStateAction<number>) => void,
    setTwentyPartitions: (value: React.SetStateAction<number>) => void,
    price: number,
    tier: number,
    licenseTier: number,
    setLicenseTier: (value: React.SetStateAction<number>) => void,
    subtotal: number,
    price5Part:number,
    price10Part:number,
    price20Part:number
}

export function LocalLicensePackage(props: Props){
    const { t } = useTranslation();
    const licenseTermOptions:OptionType[] = [{ value: '12', label: `1 ${t('years')}` },{ value: '60', label: `5 ${t('years')}` }]
    const licenseOption: OptionType[] = [{ i1: 50, i2: 1, label: t("package:essentials"), value: '' }, { i1: 100, i2: 2, label: t("package:small"), value: '' }, { i1: 250, i2: 4, label: t("package:mid"), value: '' },
    { i1: 400, i2: 256, label: t("package:large"), value: '' }, { i1: 750, i2: 8, label: t("package:enterprise"), value: '' },
        { i1: 750, i2: 512, label: t("package:basic"), value: '' }, { i1: 1024, i2: 1024, label: t("package:standard"), value: '' }]
    const [numberOfDoors, setNumberOfDoors] = useState<number>(0)
    const companyFeatureGate: CompanyFeatures = useAppSelector(getCompanyFeatureGate)
    const _licenseTiers = companyFeatureGate.oem == "Aiphone" ? aiphoneLicenseTiers : companyFeatureGate.allowedOdyssey ?  [{label:"ProtectorNet", value:"0"}, {label:"Odyssey", value:"1"}] :[{label:"ProtectorNet", value:"0"}];


    useEffect(() => {
        if (props.packageType == 8)
            setNumberOfDoors(2)
        else if (props.packageType == 256 || props.packageType == 1024 || props.packageType == 4)
            setNumberOfDoors(1)
    }, [props.packageType])

    useEffect(() => {
        if(props.tier == LicenseTier.ProtectorNet)
        {
            if (props.packageType == 1024 || props.packageType == 512)
            {
                props.setPackageType(1)
                setNumberOfDoors(0)
            }
        }
    }, [props.tier])

   return(

        <div className="card-body bg-opacity-75 ">
            <div className="row">
                <div className="col-12 col-sm-4 text-end">
                    <RadioComponent radioClass={"col-12 col-sm-7 col-form-label"}  options={companyFeatureGate.oem == "Aiphone" ? aiphoneLicenseTiers : _licenseTiers} currentVal={props.licenseTier.toString()} setCurrentVal={props.setLicenseTier} padding />
                </div>
                <div className="col-12 col-sm-4 text-end">
                    <RadioComponent radioClass={"col-12 col-sm-7 col-form-label"} options={licenseTermOptions} currentVal={props.licenseTerm} setCurrentVal={props.setLicenseTerm}  />
                </div>

            </div>
           {props.tier != 1 &&
               <div className="row">
                   <PackageComponent currentChoice={props.packageType} setCurrentChoice={props.setPackageType} value={1} tier={props.tier} />
                   <PackageComponent currentChoice={props.packageType} setCurrentChoice={props.setPackageType} value={2} tier={props.tier} />
                   <PackageComponent currentChoice={props.packageType} setCurrentChoice={props.setPackageType} value={4} tier={props.tier} />
                   <PackageComponent currentChoice={props.packageType} setCurrentChoice={props.setPackageType} value={256} tier={props.tier} />
                   <PackageComponent currentChoice={props.packageType} setCurrentChoice={props.setPackageType} value={8} tier={props.tier} />
               </div>}
           {props.tier == 1 &&
               <div className="row">
                   <div className="mt-2 tab-primary mb-3">
                       <div className="nav nav-tabs justify-content-center border-0" role="tablist">
                           <div className="nav-item">
                               <a role="tab" className={`nav-link  ${numberOfDoors == 0 ? 'active' : 'text-dark'}`} onClick={() => setNumberOfDoors(0)}>0-100 Doors</a>
                           </div>
                           <div className="nav-item">
                               <a role="tab" className={`nav-link ${numberOfDoors == 1 ? 'active' : 'text-dark'}`} onClick={() => setNumberOfDoors(1)}>100-1000 Doors</a>
                           </div>
                           <div className="nav-item">
                               <a role="tab" className={`nav-link ${numberOfDoors == 2 ? 'active' : 'text-dark'}`} onClick={() => setNumberOfDoors(2)}>Unlimited Doors</a>
                           </div>
                       </div>
                   </div>
                   <div className="justify-content-center row">
                       <div className="row col-8">
                           {(numberOfDoors == 0 && companyFeatureGate.oem == "Hartmann") && 
                                <PackageComponent currentChoice={props.packageType} setCurrentChoice={props.setPackageType} value={512} tier={props.tier} />}
                           {numberOfDoors == 0 && <PackageComponent currentChoice={props.packageType} setCurrentChoice={props.setPackageType} value={1} tier={props.tier} />}
                           {numberOfDoors == 0 && <PackageComponent currentChoice={props.packageType} setCurrentChoice={props.setPackageType} value={2} tier={props.tier} />}
                           {(numberOfDoors == 1 && companyFeatureGate.oem == "Hartmann") && 
                                <PackageComponent currentChoice={props.packageType} setCurrentChoice={props.setPackageType} value={1024} tier={props.tier} />}
                           {numberOfDoors == 1 && <PackageComponent currentChoice={props.packageType} setCurrentChoice={props.setPackageType} value={4} tier={props.tier} />}
                           {numberOfDoors == 1 && <PackageComponent currentChoice={props.packageType} setCurrentChoice={props.setPackageType} value={256} tier={props.tier} />}
                           {numberOfDoors == 2 && <PackageComponent currentChoice={props.packageType} setCurrentChoice={props.setPackageType} value={8} tier={props.tier} />}
                       </div>
                   </div>
               </div>}
            {props.packageType != 1 && props.packageType != 8 && props.tier != 1 &&  <div className="form-group row mb-4 mt-5">
                <label className="col-sm-3 col-form-label text-center" ></label>
                <div className="col-sm-2 form-check form-switch">
                    <label className="text-center float-start" ><h4>{t('license:5Partitions')}</h4></label>
                    <input className="form-check-input mt-1 ms-2 " type="checkbox" id="flexSwitchCheckDefault" checked={props.fivePartitions == 32} onChange={(e) => props.setFivePartitions(props.fivePartitions == 32? 0 : 32)}  />
                </div>
                <div className="col-sm-2 form-check form-switch">
                    <label className="text-center float-start" ><h4>{t('license:10Partitions')}</h4></label>
                    <input className=" form-check-input mt-1 ms-2 " type="checkbox" id="flexSwitchCheckDefault" checked={props.tenPartitions == 64} onChange={(e) => props.setTenPartitions(props.tenPartitions == 64? 0 : 64)}  />
                </div>
                <div className="col-sm-2 form-check form-switch">
                    <label className="text-center float-start" ><h4>{t('license:20Partitions')}</h4></label>
                    <input className="form-check-input mt-1 ms-2 " type="checkbox" id="flexSwitchCheckDefault" checked={props.twentyPartitions == 128} onChange={(e) => props.setTwentyPartitions(props.twentyPartitions == 128? 0 : 128)}  />
                </div>
            </div>}
            <div className="w-75 ms-auto me-auto mb-4 mt-2">
                <table className="table table-sm  fs--1 mb-0 mt-4">
                    <thead className="border-bottom" >
                        <tr className="text-center table-header-text ">
                            <th className="col-sm-8 fw-bold">{t('items')}</th>
                            <th className="col-sm-2 fw-bold" >{t('subtotal')}</th>
                            <th className="col-sm-2 fw-bold">{t('total')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr className="table-text text-center">
                            <th className="col-sm-8 fw-bold">{licenseOption.find(e => e.i2 == props.packageType)?.label + ` ${t('license:licensePackage')}`}</th>
                            <th>{'$' + props.subtotal}</th>
                            <th>{'$' + props.subtotal}</th>
                        </tr>
                        { props.packageType != 1 && props.packageType != 8 && props.fivePartitions == 32 && <tr className="table-text text-center">
                            <th className="col-sm-8 fw-bold">{t('license:5Partitions')}</th>
                            <th>{'$' + props.price5Part}</th>
                            <th>{'$' + props.price5Part}</th>
                        </tr>}
                        { props.packageType != 1 && props.packageType != 8 && props.tenPartitions == 64 && <tr className="table-text text-center">
                            <th className="col-sm-8 fw-bold">{t('license:10Partitions')}</th>
                            <th>{'$' + props.price10Part}</th>
                            <th>{'$' + props.price10Part}</th>
                        </tr>}
                        { props.packageType != 1 && props.packageType != 8 && props.twentyPartitions == 128 && <tr className="table-text text-center">
                            <th className="col-sm-8 fw-bold">{t('license:20Partitions')}</th>
                            <th>{'$' + props.price20Part}</th>
                            <th>{'$' + props.price20Part}</th>
                        </tr>}
                        <tr className="table-text text-center">
                            <th className="col-sm-8"></th>
                            <th className="fw-bold text-black">{t('grandTotal')}</th>
                            <th>{'$'+ props.price}</th>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    )
}