import { AuthContextProps, useAuth } from "react-oidc-context";
import React from "react";

export interface IExceptionDetails {
    Code: string;
    Type: string;
    Message: string;
    Model: { [id: string]: string[] };
}

interface IException {
    Error: IExceptionDetails
}

export interface IPagedRequest extends Request{
    pageNumber: number;
    perPage: number;
    orderBy: string;
    filter:string;
}

export interface IPagedResultSet<T> extends Response{
  totalRecords:number,
  results:T[],
  orderedBy:string
}
export type ValidationError={
    errorCode:string;
    fieldName:string;
    message:string;
}
export type ResponseStatus={
    errorCode:string;
    messages:string;
    erros:ValidationError[]
}
export interface IPatchResponse{
    id:number;
    changes:number;
    responseStatus:ResponseStatus;
}
export type PatchPropertyUpdate={
    name:string;
    value:any;
}
export interface IPatchRequest{
    properties:PatchPropertyUpdate[]
}

// Response
export type GenericAddResponse={
    id:number;
    message:string;
}
export type GenericDeleteResponse={
    id:number;
}
export type GenericFindResponse<T> = {
  result:T;
}
export type GenericPagedResponse<T> = IPagedResultSet<T> &{
}
export type GenericUpdateResponse = IPatchResponse & {
}

// Request
export type GenericPagesRequest={
}
export type GenericUpdateRequest={
}

class API {
    public async get<T>(route: string, auth?:AuthContextProps, contentType?:string): Promise<T|null> { return await this.apiCall<T>(route,"GET", {}, auth, contentType) };
    public async post<T>(route: string, body: object = {}, auth?:AuthContextProps): Promise<T|null> { return await this.apiCall<T>(route, "POST", body, auth); }
    public async put<T>(route: string, body: object = {}, auth?:AuthContextProps): Promise<T|null> { return await this.apiCall<T>(route, "PUT", body, auth); }
    public async delete<T>(route: string, body: object = {}, auth?:AuthContextProps): Promise<T|null> { return await this.apiCall<T>(route, "DELETE", body, auth); }
    
    public async  apiCall<T>(route: string,  method: string = "GET", body: object = {}, auth?:AuthContextProps, contentType?:string ):Promise<T|null|any> {
        const opts: RequestInit = {
            method,
            headers: {
                "Content-Type": contentType == null ? "application/json; charset=utf-8" : contentType,
                "Accept": contentType == null ? "application/json" : contentType ,
                "Access-Control-Allow-Origin": "*",
                "Authorization": auth == null ? '' : `Bearer ${auth.user?.access_token}`
            },
            credentials: 'include',
            mode: 'cors'
        };

        if (method !== "GET" && body !== null) {
            opts.body = JSON.stringify(body);
        }
        
        const response = await fetch(route, opts);
        if (response.status !== 200 && response.status !== 204 && response.status !== 201) {
            let error: IException|null = null;

            if (response.body !== null) {
                error = await response.json();
            }

            return Promise.reject(error !== null ? error : response.status);
        }

        try {
            if (response.body !== null)
            {
                const result: T = await response.json();
                return result;
            }
            return response.body
        } catch (e) {
            return e;
        }
    }
}

export default new API();