import { faVcard } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { t } from "i18next";
import React, { useEffect, useState } from "react";
import { useAuth } from "react-oidc-context";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import API from "../../common/API";
import { Invoice, ViewInvoiceDTO } from "../../common/store";
import ElavonPayment from "./ElavonPayment";
import LoadingPageComponent from "../../components/shared/LoadingPageComponent";

export default function(){

    const [searchParams] = useSearchParams(); 
    var returnUrl = searchParams.get("ReturnUrl")
    const auth = useAuth()
    const navigate = useNavigate()

    var referrer = document.referrer;
    if (returnUrl?.startsWith("#system/licensing"))
        returnUrl = referrer + returnUrl

    const { id } = useParams();
    const[invoice, setInvoice] = useState<Invoice>()
    const [loading, setLoading] = useState<boolean>(false)

    useEffect(() => {
        const getInvoice = async () => {
            console.log("GET INVOICE")
            try{
                const url:string = `/api/v1/invoices/${id}` 
                const response:ViewInvoiceDTO|null = await API.get(url, auth)
                setInvoice(response?.invoice)
            }
            catch(e:any)
            {
                alert(e.errors?.Error!)
                window.open(returnUrl!, '_self')
            }
        }
        getInvoice()
    },[])

    const postFunction= (isInvoice?:boolean) => {
        console.log(isInvoice)
        if (isInvoice == false)
        {    if (returnUrl != null)
                window.open(returnUrl!, '_self')
        }
        else
        {
            console.log("HERE1")
            createOnlineLicense()
        }
    }

    const createOnlineLicense = async() => {
        console.log("HERE")
        setLoading(true)
        const response: any = await API.post("api/v1/license/OnlineLicenceInvoiced/"+ invoice?.id, {}, auth)
        if (returnUrl != null)
                window.open(returnUrl!, '_self')
    }

    return(
        <div className="card mb-4">
            <div className="card-header">
                <h2 className='mb-4 header-text '>
                    <FontAwesomeIcon icon="file-invoice-dollar" className="fs-3 me-2" />{`${t("system")} ${t("invoice:invoice")}`}
                </h2>
            </div>
            {loading && <LoadingPageComponent />}
            {!loading && invoice!= null && <ElavonPayment invoice={invoice} postFunction={postFunction} licensePayment={true} />}
        </div>
    )

}