import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { aiphoneLicenseTiers, CompanyFeatures, HostedDeviceCost, HostedDeviceType, OptionType } from "../../common/store";
import useAppSelector from "../../hooks/useAppSelector";
import { getCompanyFeatureGate } from "../../redux/slices/config";
import RadioComponent from "../shared/RadioComponent";
import { LicenseTier } from "../shared/Enums";


type Props =  {
    hostedTerm: string,
    setHostedTerm: (value: React.SetStateAction<string>) => void,
    packageType: number,
    setPackageType: (value: React.SetStateAction<number>) => void,
    subtotal:number,
    price: number,
    numberOfPanels: number,
    setNumberOfPanels:(value: React.SetStateAction<number>) => void,
    tier: number,
    licenseTier: number,
    setLicenseTier: (value: React.SetStateAction<number>) => void
    multicard: number,
    setMulticard: (value: React.SetStateAction<number>) => void,
    numberOfDoors: number,
    numberOfElevators: number,
    numberOfIOExpanders:number,
    setNumberOfDoors: (value: React.SetStateAction<number>) => void,
    setNumberOfElevators: (value: React.SetStateAction<number>) => void
    setNumberOfIOExpanders: (value: React.SetStateAction<number>) => void
    hostedDevicesPrice: HostedDeviceCost[] 
}

export function HostedLicensePackageComponent(props:Props) {
    const { t } = useTranslation(); 
    // const _licenseTiers:OptionType[] = [{label:"ProtectorNet", value:"0"}, {label:"Odyssey", value:"1", i1:0}]
    // const hostedTermOptions:OptionType[] = [{ value: '12', label: t('deployment:yearly') },{ value: '3', label: t('deployment:quarterly') }]
    const hostedTermOptions:OptionType[] = [{ value: '12', label: t('deployment:yearly') }]
    const companyFeatureGate: CompanyFeatures = useAppSelector(getCompanyFeatureGate)
    
    const _licenseTiers:OptionType[] = companyFeatureGate.allowedOdyssey ?  [{label:"ProtectorNet", value:"0"}, {label:"Odyssey", value:"1"}] :[{label:"ProtectorNet", value:"0"}]

    const [prsExpanders, setPrsExpanders] = useState<number>(0)
    const [poePanels, setPoePanels] = useState<number>(0)

    useEffect(() => {
        props.setNumberOfPanels(prsExpanders + poePanels)
    }, [prsExpanders, poePanels])

    return(
        <div className="card-body bg-opacity-75 row ">
            <div className="col-xl-6 mt-2">
                <div className="mb-4">
                    <RadioComponent radioClass={"col-7 col-sm-7 col-form-label"} labelClass={"col-4 col-form-label text-center"} label={t("system")} options={companyFeatureGate.oem == "Aiphone" ? aiphoneLicenseTiers : _licenseTiers} currentVal={props.licenseTier.toString()} setCurrentVal={props.setLicenseTier} />
                </div>
                <RadioComponent labelClass={"col-4 col-form-label text-center"} label={t('deployment:schedule')} options={hostedTermOptions} currentVal={props.hostedTerm} setCurrentVal={props.setHostedTerm} />
                <div className="form-group row mb-4 mt-4">
                    <label className="col-5 col-form-label text-center" >
                        <h3 className="d-none d-sm-block">{t('license:multiCard')}</h3>
                        <h3 className="d-block d-sm-none fs-4">{t('license:multiCard')}</h3>
                    </label>
                    <div className="col-5 form-check form-switch">
                        <input className="align-bottom form-check-input mt-2 ms-2 " type="checkbox" id="flexSwitchCheckDefault" checked={props.multicard == 16 || props.packageType == 8} onChange={(e) => props.setMulticard(props.multicard == 16? 0 : 16)}  />
                    </div>
                </div>
                {props.tier == 0 && <div>
                    <div className="form-group row mb-4 mt-4">
                        <label className="col-5 col-form-label text-center" >
                            <h3 className="d-none d-sm-block">{t('deployment:numberOfPrsExpanders')}</h3>
                            <h3 className="d-block d-sm-none fs-4">{t('deployment:numberOfPrsExpanders')}</h3>
                        </label>
                        <div className="col-3 ">
                            <input className="align-bottom form-control  " min={0} max={2000} maxLength={4} type="number" id="flexSwitchCheckDefault" value={prsExpanders}  onChange={(e) => setPrsExpanders(Number(e.target.value))}  />
                        </div>
                    </div>
                    <div className="form-group row mb-4 mt-4">
                        <label className="col-5 col-form-label text-center" >
                            <h3 className="d-none d-sm-block">{t('deployment:numberOfPoePanels')}</h3>
                            <h3 className="d-block d-sm-none fs-4">{t('deployment:numberOfPoePanels')}</h3>
                        </label>
                        <div className="col-3 ">
                            <input className="align-bottom form-control  " min={0} max={2000} maxLength={4} type="number" id="flexSwitchCheckDefault" value={poePanels}  onChange={(e) => setPoePanels(Number(e.target.value))}  />
                        </div>
                    </div>
                    <div className="form-group" style={{ display: props.numberOfPanels > 2000 ? 'block' : 'none' }} >
                        <p className="text-danger text-center">{t('deployment:maxPanels')}</p>
                    </div>
                    <div className="form-group" style={{ display: props.numberOfPanels < 1 ? 'block' : 'none' }} >
                        <p className="text-danger text-center">{t('deployment:zeroPanels')}</p>
                    </div>
                </div>}
                
                {props.tier == 1 && <div>
                    <div className="form-group row mb-4 mt-4">
                        <label className="col-5 col-form-label text-center" >
                            <h3 className="d-none d-sm-block">{t('deployment:numberOfDoors')}</h3>
                            <h3 className="d-block d-sm-none fs-4">{t('deployment:numberOfDoors')}</h3>
                        </label>
                        <div className="col-2 ">
                            <input className="align-bottom form-control  " min={0} max={2000} maxLength={4} type="number" id="flexSwitchCheckDefault" value={props.numberOfDoors}  onChange={(e) => props.setNumberOfDoors(Number(e.target.value))}  />
                        </div>
                        {props.hostedDevicesPrice.find((e) => e.DeviceType == HostedDeviceType.Doors)?.LargestDiscountAmount! > 1 && <div className="col-4">
                            <p className="text-info text-begin">{`Discount Applied For ${props.hostedDevicesPrice.find((e) => e.DeviceType == HostedDeviceType.Doors)?.LargestDiscountAmount!} Doors` }</p>
                        </div>}
                    </div>
                    <div className="form-group row mb-4 mt-4">
                        <label className="col-5 col-form-label text-center" >
                            <h3 className="d-none d-sm-block">{t('deployment:numberOfElevators')}</h3>
                            <h3 className="d-block d-sm-none fs-4">{t('deployment:numberOfElevators')}</h3>
                        </label>
                        <div className="col-2 ">
                            <input className="align-bottom form-control  " min={0} max={2000} maxLength={4} type="number" id="flexSwitchCheckDefault" value={props.numberOfElevators}  onChange={(e) => props.setNumberOfElevators(Number(e.target.value))}  />
                        </div>
                        {props.hostedDevicesPrice.find((e) => e.DeviceType == HostedDeviceType.Elevators)?.LargestDiscountAmount! > 1 && <div className="col-4">
                            <p className="text-info text-begin">{`Discount Applied For ${props.hostedDevicesPrice.find((e) => e.DeviceType == HostedDeviceType.Doors)?.LargestDiscountAmount!} Elevators` }</p>
                        </div>}
                    </div>
                    <div className="form-group row mb-4 mt-4">
                        <label className="col-5 col-form-label text-center" > 
                            <h3 className="d-none d-sm-block">{t('deployment:numberOfIOExpanders')}</h3>
                            <h3 className="d-block d-sm-none fs-4">{t('deployment:numberOfIOExpanders')}</h3>
                        </label>
                        <div className="col-2 ">
                            <input className="align-bottom form-control  " min={0} max={2000} maxLength={4} type="number" id="flexSwitchCheckDefault" value={props.numberOfIOExpanders}  onChange={(e) => props.setNumberOfIOExpanders(Number(e.target.value))}  />
                        </div>
                        {props.hostedDevicesPrice.find((e) => e.DeviceType == HostedDeviceType.IOExpanders)?.LargestDiscountAmount! > 1 && <div className="col-4">
                            <p className="text-info text-begin">{`Discount Applied For ${props.hostedDevicesPrice.find((e) => e.DeviceType == HostedDeviceType.Doors)?.LargestDiscountAmount!} Expanders` }</p>
                        </div>}
                    </div>
                </div>}

            </div>
            <div className="col-xl-6 ms-auto me-auto mb-4 mt-2">
                <table className="table table-sm  fs--1 mb-0 mt-4">
                    <thead className="border-bottom" >
                        <tr className="text-center table-header-text ">
                            <th className="col-sm-2 fw-bold" >{t('deployment:schedule')}</th>
                            <th className="col-sm-4 fw-bold">{t('devices')}</th>
                            {props.tier == 1 && <th className="col-sm-2 fw-bold">{t('invoice:amount')}</th>}
                            <th className="col-sm-2 fw-bold" >{`${t('unitPrice')} ${t('perMonth')}`}</th>
                            <th className="col-sm-2 fw-bold">{t('total')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {props.tier == 0 && <tr className="table-text text-center">
                            <th>{hostedTermOptions.find(e => e.value == props.hostedTerm)?.label}</th>
                            <th className="fw-bold">{props.numberOfPanels + ' Panel(s)'}</th>
                            <th>{`$${props.subtotal} ` }</th>
                            <th>{`$${props.price}`}</th>
                        </tr>}
                        {props.tier == 1 && 
                            props.hostedDevicesPrice.map((e:HostedDeviceCost) => (

                                e.DeviceType == HostedDeviceType.Doors &&
                                <tr className="table-text text-center">
                                    <th>{hostedTermOptions.find(e => e.value == props.hostedTerm)?.label}</th>
                                    <th className="fw-bold">{`Door(s)${e.DiscountAmount != 1 ? ` -${e.DiscountAmount} Doors Discount` : ""}`}</th>
                                    <th>{`${e.Amount!} ` }</th>
                                    <th>{`$${e.Subtotal!} ` }</th>
                                    <th>{`$${e.Total!}`}</th>
                                </tr>
                            ))
                        }
                        {props.tier == 1 && 
                            props.hostedDevicesPrice.map((e:HostedDeviceCost) => (

                                e.DeviceType == HostedDeviceType.Elevators &&
                                <tr className="table-text text-center">
                                    <th>{hostedTermOptions.find(e => e.value == props.hostedTerm)?.label}</th>
                                    <th className="fw-bold">{`Elevator(s)${e.DiscountAmount != 1 ? ` -${e.DiscountAmount} Elevator Discount` : ""}`}</th>
                                    <th>{`${e.Amount!} ` }</th>
                                    <th>{`$${e.Subtotal!} ` }</th>
                                    <th>{`$${e.Total!}`}</th>
                                </tr>
                            ))
                        }
                        {props.tier == 1 && 
                            props.hostedDevicesPrice.map((e:HostedDeviceCost) => (

                                e.DeviceType == HostedDeviceType.IOExpanders &&
                                <tr className="table-text text-center">
                                    <th>{hostedTermOptions.find(e => e.value == props.hostedTerm)?.label}</th>
                                    <th className="fw-bold">{`IO Expanders(s)${e.DiscountAmount != 1 ? ` -${e.DiscountAmount} IO Expanders Discount` : ""}`}</th>
                                    <th>{`${e.Amount!} ` }</th>
                                    <th>{`$${e.Subtotal!} ` }</th>
                                    <th>{`$${e.Total!}`}</th>
                                </tr>
                            ))
                        }
                        <tr className="table-text text-center">
                            <th></th>
                            <th className="col-sm-4"></th>
                            {props.tier == 1 && <th></th>}
                            <th className="fw-bold text-black">{t('grandTotal')+ ' (Without Tax)'}</th>
                            <th>{`$${props.price}`}</th>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    )
}