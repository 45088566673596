import { t } from "i18next";
import React from "react";
import { AuthContextProps } from "react-oidc-context";
import API from "../API";
import { Invoice, OptionType } from "../store";

export default async function(auth:AuthContextProps, deploymentId:number, numberOfPanels:number, hostedTerm:string,
    multicard:number, setPaymentDetails:Function, setInvoice:Function, setLoading:Function, setSection:Function, licenseTier:number, 
    numberOfDoors: number, numberOfElevators: number, numberOfIOExpanders: number ){
    try {
        var object = {DeploymentId: deploymentId, NumPanels: numberOfPanels, Schedule: Number(hostedTerm), Multicard: multicard == 16, 
            TierType: licenseTier, NumberOfDoors: numberOfDoors, NumberOfElevators: numberOfElevators, NumberOfIOExpanders: numberOfIOExpanders }
        const response: Invoice|null = await API.post(`api/v1/hosteddeployment/invoice`,object, auth)
        var details:OptionType[] = [
            {label: t('deployment:numberOfPanels'), value: numberOfPanels.toString()},
            {label: t('deployment:schedule'), value: hostedTerm == '12' ? t('deployment:yearly'):t('deployment:quarterly')}, 
            {label: t('license:multiCard'), value: (multicard == 16 ).toString()},
        ]
        setPaymentDetails({title: 'Hosted Deployment Purchase', details: details })
        setInvoice(response)
        setLoading(false)
        setSection("payment")
    }
    catch (e: any) {
        setLoading(false)
        alert(e.errors?.Error!)
    }
}