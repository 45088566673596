import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { t } from "i18next";
import { useEffect, useState } from "react";
import reactSelect from "react-select";
import { OptionType } from "../../common/store";
import HeaderSectionsComponent from "../../components/shared/HeaderSectionsComponent";
import pic1 from '../../assets/images/AiphoneLicenseGuide/InfoPic1.png'
import pic2 from '../../assets/images/AiphoneLicenseGuide/InfoPic2.png'
import pic3 from '../../assets/images/AiphoneLicenseGuide/InfoPic3.png'
import pic4 from '../../assets/images/AiphoneLicenseGuide/InfoPic4.png'

export default function()
{
    const [headerOptions, setHeaderOption] =useState<OptionType[]>([])
    const [currentSection, setCurrentSection] = useState<string>("Registration")
    
    useEffect(() => {

        setHeaderOption([{label:"Registration", value:"Registration"}, {label:"Deployment Licensing", value:"Deployment Licensing"}, {label: "Re-Arming Deployment", value:"Re-Arming Deployment"}])
    },[])

    return (
        <div>
            <div className="row">
                <div className="row">
                    <h1 className=' ms-1  mb-3 mb-sm-4 float-start  header-text'><FontAwesomeIcon icon="tablet-button" className="fs-3 me-2" />{"AC-NIO License Guide"}</h1>
                </div>
                <div className="col-sm-3">
                    <HeaderSectionsComponent label={`${t("License Guide")}`} currentSection={currentSection} setCurrentSection={setCurrentSection} sections={headerOptions} />
                </div>
                <div className="col-sm-9">
                    <div className="card">
                        <div className=" card-header pb-0 mb-0">
                            <div className='mb-4 header-text d-inline '>
                                <span className="h3">{"AC-NIO Licensing Guide"}</span>
                            </div>
                        </div>
                        <div className="card-body pt-2 pb-1" style={{fontFamily: "poppins"}}>
                            <p className="mb-1 fs-4">The scope of this guide is to cover the licensing process of Local Deployments in the AC NIO Dealer Portal.</p>
                            <p className="fs-4">Please note that Request/Response keys are only good for 24 hours and will need to be regenerated if that amount of time has passed</p>
                        </div>
                    </div> 
                    {currentSection == "Registration" && 
                        <div className="card">
                            <div className=" card-header pb-0 mb-2">
                                <div className='mb-4 header-text d-inline '>
                                    <span className="h3">Registration</span>
                                </div>
                            </div>
                            <div className="card-body pt-1" style={{fontFamily: "poppins"}}>
                                <span  className="fs-4">1. Browse to the AC NIO authorization portal</span><a className="fs-4" href="https://auth.acnio-license.aiphone.com"> https://auth.acnio-license.aiphone.com/</a> <span className="fs-4"> and click the register button directly below the credentials login prompts.</span>
                                <p className="fs-4" style={{marginTop:"1rem"}}>2. Fill out the fields to register your company to the dealer portal and click Register again.</p>
                                <p className="fs-4">3. A confirmation email will be sent out, go to your email and click the confirmation link embedded inside. </p>
                                <p className="fs-4">4. A Registration page will be opened and you can fill out the requested field with your Company name. Once done, click Next.</p>
                                <p className="fs-4">5. The next page will be contact details for your company. Fill out the fields on this page (only fields marked with * are required but recommend filling out all available fields). Once done, click Next.</p>
                                <p className="fs-4">6. This page will be for the Administrator for this account. Fill out all required fields and click Next.</p>
                                <p className="fs-4">7. The final page here is optional, if desired, other users can be added and available permissions can be set as desired. Regardless of whether more users will be added, once done click Register.</p>
                            </div>
                        </div> 
                    }
                    {currentSection == "Deployment Licensing" &&
                        <div className="card">
                            <div className=" card-header pb-0 mb-2">
                                <div className='mb-4 header-text d-inline '>
                                    <span className="h3">Deployment Licensing</span>
                                </div>
                            </div>
                            <div className="card-body pt-1 fs-4" style={{fontFamily: "poppins"}}>
                                <span >1. Browse to the AC NIO dealer portal</span><a href="https://portal.acnio-license.aiphone.com/"> https://portal.acnio-license.aiphone.com/</a> <span> and login.</span>
                                <p style={{marginTop:"1rem"}}>2. From the main dashboard, select Deployments from the menu along the left side of the screen or Add Deployments right below it.</p>
                                <p>3. Click Add Deployment from the top right corner of this page. On this page, fill out the name and description of the deployment and click Next.</p>
                                <img className="border-0 me-1" style={{width: "40%"}} src={pic1}></img>
                                <p style={{marginTop:"1rem"}}>4. Fill out the Contact Details as well as check off any email subscriptions that would be desired in relation to the AC NIO system and click Next.</p>
                                <p>5. Fill out the Address page and once completed, click on Create Deployment.</p>
                                <p>6. This will take the user to the Method of payment page. The only available form of payment at this time is Pre-paid license code. Fill out the purchased pre-paid license code and click Next. </p>
                                <p>7. The user will be taken to the Activate page This will list the Account Number which will be put into AC NIO to generate the request key in the next step, a box for the request key, license term, tier and package.</p>
                                <p>8. Copy the account number into the Account Number field in AC NIO and then hit Update my License in the software. This will generate the Request key which will be input into the Request Key field on the Activate page. Copy this over and confirm all the information on this page looks correct. Proceed by hitting the Response button</p>
                                <img className="border-0 me-1" style={{width: "50%"}} src={pic2}></img>
                                <p style={{marginTop:"1rem"}}>9. Finally this will take you to the Response page which will provide the response key. Copy this back into the Response Key field as illustrated on the Activate Page into the AC NIO software licensing page and click ‘Update My License’ to complete the license registration.</p>
                            </div>
                        </div>
                    }
                    {currentSection == "Re-Arming Deployment" &&
                        <div className="card">
                            <div className=" card-header pb-0 mb-2">
                                <div className='mb-4 header-text d-inline '>
                                    <span className="h3">Re-Arming Deployment</span>
                                </div>
                            </div>
                            <div className="card-body pt-1 fs-4" style={{fontFamily: "poppins"}}>
                                <span >1. Browse to the AC NIO dealer portal</span><a href="https://portal.acnio-license.aiphone.com/"> https://portal.acnio-license.aiphone.com/</a> <span> and login.</span>
                                <p style={{marginTop:"1rem"}}>2. From the main dashboard, select Deployments from the menu along the left side of the screen.</p>
                                <p>3. Click the edit button to the left of the deployment name which will take the user to the Deployment Settings page. Click on License details on the left side of the screen.</p>
                                <img className="border-0 me-1" style={{width: "40%"}} src={pic3}></img>
                                <p style={{marginTop:"1rem"}}>4. This page will present a few options including Add-On features which can be applied via pre-paid feature cards, Renew and Re-arm licenses. When a license has fully expired, it can be renewed via the renew button which will go back to the previous section asking for a pre-paid code. The Rearm option will allow for a Request/Response key exchange similar to the licensing section which will reinstate the pre-existing license.</p>
                                <img className="border-0 me-1" style={{width: "60%"}} src={pic4}></img>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}