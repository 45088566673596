import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import TextInputComponent from "../../components/shared/TextInputComponent";
import { validate as EmailValidate } from 'email-validator';
import API from "../../common/API";
import RadioComponent from "../../components/shared/RadioComponent";
import { OptionType } from "../../common/store";

export default function RegisterLink() {

    //Company Details
    const [companyName, setCompanyName] = useState<string>('')
    const [companyType, setCompanyType] = useState<string>('')
    const [email, setEmail] = useState<string>('')
    const [isInvoiceable, setIsInvoiceable] = useState<boolean>(false)
    const [linkSent, setLinkSent] = useState<boolean>(false)
    const companyTypeOptions:OptionType[] = [{ value: 'Dealer', label: 'Dealer' },{ value: 'User', label: 'End User' },{ value: 'Distributor', label: 'Distributor' }]

    const sendLink = async () => {
        try{
            const object = {Email:email, CompanyName:companyName, CompanyType:companyType, IsInvoiceable: isInvoiceable}
            const response:any = await API.post(`/api/v1/sendRegisterLink`, object)
            setLinkSent(true)
        }
        catch(e:any){
            alert(e)
        }
    }

    return(
        <div className="card ">
            {!linkSent && 
            <div className="mb-4">
                <div className="card-header">
                    <h1 className="">
                        Send Register Link
                    </h1>
                </div>
                <div className="card-body bg-opacity-75 mt-4 ">
                    <TextInputComponent value={companyName} label={"Company Name"} setText={setCompanyName} />
                    <RadioComponent label={"Company Type"} options={companyTypeOptions} currentVal={companyType} setCurrentVal={setCompanyType}  />
                    <TextInputComponent value={email} label={"Email"} setText={setEmail} validator={EmailValidate} validateText={"This is not a valid e-mail address."}/>
                    <div className="form-group row mb-4 ">
                        <label className="col-sm-3 col-form-label text-center" ><h3>Is Invoiceable</h3></label>
                        <div className="col-sm-5 form-check form-switch">
                            <input className="align-bottom form-check-input mt-2 ms-2 " type="checkbox" id="flexSwitchCheckDefault" checked={isInvoiceable} onChange={(e) => setIsInvoiceable(!isInvoiceable)} />
                        </div>
                    </div>
                </div>
                <div className=" mb-4 pe-5">
                    <button className="btn float-end btn-info add-btn-text " disabled={(!EmailValidate(email))} onClick={() => sendLink() }>
                        <span >Register Company And Send Link</span>
                    </button>
                </div>
            </div>
            }
            {linkSent && 
                <div>
                    <div className="card-header align-middle">
                        <h1 className="text-center">
                            Register Link Sent
                        </h1>
                    </div>
                </div>
            }
        </div>
    )
}