import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { CountryDropdown, RegionDropdown, CountryRegionData } from "react-country-region-selector";
import { useTranslation } from "react-i18next";

export default function({country, region, setCountry, setRegion, mandatory}:
    {country:string, region:string, setCountry:(value: React.SetStateAction<string>) => void, setRegion:(value: React.SetStateAction<string>) => void, mandatory?:boolean}){

    const {t} = useTranslation()
    const [initCountry, setInitCountry] = useState<string>(country)
    useEffect(() => {
        if (country != initCountry)
            setRegion('')
    },[country])
    return(
        <div>
            <div className="form-group row mb-4">
                    <div className="col-1 d-none d-sm-block"></div>
                    <label className="col-4 col-sm-2 col-form-label text-begin ms-3" >
                        <h3 className="d-none d-sm-block">{t('country')}</h3>
                        <h3 className="d-block d-sm-none fs-4">{t('country')} {mandatory != null && 
                            <FontAwesomeIcon icon="star-of-life" className=" mt-2 d-inline d-sm-none text-center" style={{"fontSize": "0.65rem"}} />}
                        </h3>
                    </label>
                    <div className="col-7 col-sm-5">
                        <CountryDropdown defaultOptionLabel={t('selectCountry')} whitelist={['US', 'CA', 'SG', 'AU', 'JP', 'KR','ID', 'MY', 'TH', 'VN', 'PH', 'NZ', 'TW', 'BB']}
                         classes="form-select-lg form-select "  value={country} onChange={(e) => setCountry(e)}  />
                    </div>
                    {mandatory != null &&
                        <div className="col-1 d-none d-sm-block">
                            <FontAwesomeIcon icon="star-of-life" className="mt-2" style={{"fontSize": "0.65rem"}} />
                        </div>
                    }
            </div>
            <div className="form-group row mb-4">
                    <div className="col-1 d-none d-sm-block"></div>
                    <label className="col-4 col-sm-2 col-form-label text-begin ms-3" >
                        <h3 className="d-none d-sm-block">{t('region')}</h3>
                        <h3 className="d-block d-sm-none fs-4">{t('region')}{mandatory != null && 
                            <FontAwesomeIcon icon="star-of-life" className=" mt-2 d-inline d-sm-none text-center" style={{"fontSize": "0.65rem"}} /> }
                        </h3>
                    </label>
                    <div className="col-7 col-sm-5">
                        <RegionDropdown defaultOptionLabel={t('selectRegion')} classes="form-select-lg form-select " value={region} country={country} onChange={(e) => {setRegion(e); console.log(e)}}  />
                    </div>
                    {mandatory != null &&
                        <div className="col-1 d-none d-sm-block">
                            <FontAwesomeIcon icon="star-of-life" className="mt-2" style={{"fontSize": "0.65rem"}} />
                        </div>
                    }
            </div>
        </div>
    )
}