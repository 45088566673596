import React, { Component, ReactNode, useState } from 'react';
import { Link, Navigate, useLocation } from 'react-router-dom';
import './NavMenu.css';
import { Route, Routes } from 'react-router-dom';
import { LinkContainer } from 'react-router-bootstrap';
import { useAuth } from 'react-oidc-context';
import { CreateSigninRequestArgs } from 'oidc-client-ts';
import Api from '../common/API'
import { sign } from 'crypto';
import { Container, Navbar, NavDropdown } from 'react-bootstrap';
import { changeLanguage, t } from 'i18next';
import { availableLanguages } from '../languages/lang';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import onClickOutside from 'react-onclickoutside';
import AccountDropdown from '../components/navbar/AccountDropdown';
import LanguageDropdown from '../components/navbar/LanguageDropdown';
import ThemeOptions from '../components/navbar/ThemeOptions';
import { CompanyFeatures } from '../common/store';
import { getCompanyFeatureGate } from '../redux/slices/config';
import useAppSelector from '../hooks/useAppSelector';

type Props = {
    children: ReactNode
}
//by passing this argument to signinRedirect, we can go to login page directly
const arg: CreateSigninRequestArgs = {
    prompt: "login"
}
export function NavMenu({toggle, setToggle, setTheme}: {toggle: boolean, setToggle: (value: React.SetStateAction<boolean>) => void, setTheme: (value: React.SetStateAction<string>) => void}) {
    const {t, i18n} = useTranslation()
    const auth = useAuth();
    const sysAdmin:boolean = auth.user?.profile.role == "SysAdmin"
    const location = useLocation().pathname
    const name = auth.user?.profile.name
    const companyFeatureGate: CompanyFeatures = useAppSelector(getCompanyFeatureGate)
    switch (auth.activeNavigator) {
        case "signinSilent":
            return <div>Signing you in...</div>;
        case "signoutRedirect":
            return <div>Signing you out...</div>;
    }
    if (auth.isLoading) {
        return <div>Loading...</div>;
    }
    let modal = auth.isAuthenticated ? "" : "modal"

    const logout = () => {
        auth.removeUser()
        auth.clearStaleState()
    }
    

    return (
        <div>
            <header>
                <nav className="navbar-expand navbar-bg navbar-toggleable" >
                    <div className="container-fluid">
                        {auth.isAuthenticated && <button className="navbar-toggler mr-2" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>}
                        {auth.isAuthenticated && 
                        <div  className="navbar py-1 navbar-bg" id="navbarSupportedContent" >
                            <div className="navbar-nav nav-tabs flex-grow-0 float-start me-3 border-0" onClick={() => setToggle(!toggle)}>
                                <div className="sidebar-toggle d-flex " onClick={() => setToggle(!toggle)}>
                                    <a type='button' className=" hamburger align-self-center" onClick={() => setToggle(!toggle)}></a>
                                </div>
                            </div>
                            {auth.isAuthenticated && 
                                <div className='navbar-nav nav-tabs flex-grow-0 float-end me-3 mt-1 border-0'>
                                    {companyFeatureGate.oem == "Hartmann" && <ThemeOptions setThemeVal = {setTheme} />}
                                    <LanguageDropdown />
                                    <AccountDropdown name={name} logout={logout} />
                                </div>
                            }
                        </div>}
                    </div>
                </nav>
            </header>
            <section>
                <div className="modal fade" id="exampleModal" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">Modal title</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                Need login
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                <button className="btn btn-primary" onClick={() => void auth.signinRedirect()}>Log in</button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );

}
 
