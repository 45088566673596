import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { t } from "i18next";
import React from "react";
import { CompanyFeatures } from "../common/store";
import TextInputComponent from "../components/shared/TextInputComponent";
import useAppSelector from "../hooks/useAppSelector";
import { getCompanyFeatureGate } from "../redux/slices/config";

export default function(){
    const companyFeatureGate: CompanyFeatures = useAppSelector(getCompanyFeatureGate)
    return(
        <div className="w-100 ms-auto me-auto ">
            <div className="pb-0">
                <h1 className=' mb-4 ms-1  header-text'><FontAwesomeIcon icon="circle-info" className="fs-3 me-2" />{`${t("support")}`}</h1>
            </div>
            <div className='card w-100  '>
                {companyFeatureGate.oem == "Hartmann" && <div className="card-body  mb-5">
                    <div className="form-group row mt-3">
                        <FontAwesomeIcon icon="phone" className="col-1 text-end mt-2 fs-3 text-dark" />
                        <input className={" bg-white fw-bolder fs-4 col-10 text-dark" } style={{'border':'none'}} type="text" value={"+1-877-411-0101"} disabled/>
                    </div>
                    <div className="form-group row mt-4">
                        <FontAwesomeIcon icon="envelope" className="col-1 text-end mt-2 fs-3 text-dark" />
                        <input className={" bg-white fw-bolder fs-4 col-10 text-dark" } style={{'border':'none'}} type="text" value={"Sales@hartmann-controls.com"} disabled/>
                    </div>
                    <div className="form-group row mt-4">
                        <FontAwesomeIcon icon="location-dot" className="col-1 text-end mt-2 fs-3 text-dark" />
                        <input className={" bg-white fw-bolder fs-4 col-10 text-dark" } style={{'border':'none'}} type="text" value={"10 Lockhart Rd, Barrie, ON Canada L4N 9G8"} disabled/>
                    </div>
                </div>}
                {companyFeatureGate.oem == "Aiphone" && <div className="card-body  mb-5">
                    <div className="form-group row mt-3">
                        <FontAwesomeIcon icon="phone" className="col-1 text-end mt-2 fs-3 text-dark" />
                        <input className={" bg-white fw-bolder fs-4 col-10 text-dark" } style={{'border':'none'}} type="text" value={"(800) 692-0200"} disabled/>
                    </div>
                    <div className="form-group row mt-4">
                        <FontAwesomeIcon icon="envelope" className="col-1 text-end mt-2 fs-3 text-dark" />
                        <input className={" bg-white fw-bolder fs-4 col-10 text-dark" } style={{'border':'none'}} type="text" value={"info@aiphone.com"} disabled/>
                    </div>
                    <div className="form-group row mt-4">
                        <FontAwesomeIcon icon="location-dot" className="col-1 text-end mt-2 fs-3 text-dark" />
                        <input className={" bg-white fw-bolder fs-4 col-10 text-dark" } style={{'border':'none'}} type="text" value={"Cherry Hill, NJ"} disabled/>
                    </div>
                </div>}
            </div>
        </div>
    )
}