import { t } from "i18next";
import React, { useState } from "react";
import { AiphoneEndUserLicenseAgreement, EndUserLicenseAgreement } from "../../common/EULA";
import { CompanyFeatures } from "../../common/store";
import useAppSelector from "../../hooks/useAppSelector";
import { getCompanyFeatureGate } from "../../redux/slices/config";

export default function ({ showModal, setShowModal, actionFunction }: { showModal: boolean, setShowModal: (value: React.SetStateAction<boolean>) => void, actionFunction: Function }) {
    const [boxChecked, setBoxChecked] = useState<boolean>(false)
    const clickModal = () => {
        setShowModal(false)
        actionFunction()
    }
    const companyFeatureGate: CompanyFeatures = useAppSelector(getCompanyFeatureGate)
    return (
        <div className={"modal fade show"} style={{ display: "block", background: " rgba(0, 0, 0, 0.6)" }}>
            <div className="modal-dialog modal-dialog-centered" style={{ maxWidth: "900px" }}>
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">{`${t("endUserLicenseAgreement")}`}</h5>
                        <button type="button" className="btn-close" onClick={e => setShowModal(false)}></button>
                    </div>
                    <div className="modal-body">
                        <textarea rows={10} className="col-12 border-0">{companyFeatureGate.oem == "Aiphone" ? AiphoneEndUserLicenseAgreement :EndUserLicenseAgreement}</textarea>
                    </div>
                    <div className="modal-footer" >
                        <div className=" me-auto">
                            <input className="me-2 form-check-input " type="checkbox" id="flexSwitchCheckDefault" checked={boxChecked} onChange={(e) => setBoxChecked(!boxChecked)} />
                            <span className="text-center" >{`${t("eulaCheck")}`}</span>
                        </div>
                        <button type="button" className="btn btn-primary" disabled={!boxChecked} onClick={() => clickModal()}>{`${t("pay")}`}</button>
                    </div>
                </div>
            </div>
        </div>
    )
}