import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AxiosResponse } from "axios";
import React, { useEffect, useState } from "react";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import { useAuth } from "react-oidc-context";
import API from "../../common/API";
import { CompanyEmail, DeploymentPaymentInfo, GetCompanyRequest, OptionType, PhoneNumberType, Roles } from "../../common/store";
import PhoneNumberComponent from "../../components/shared/PhoneNumberComponent";
import RadioComponent from "../../components/shared/RadioComponent";
import TextInputComponent from "../../components/shared/TextInputComponent";
import { validate as EmailValidate, validate } from 'email-validator';
import SuccessErrorMessage from "../../components/shared/SuccessErrorMessage";
import LoadingPageComponent from "../../components/shared/LoadingPageComponent";
import CountryRegionComponent from "../../components/shared/CountryRegionComponent";
import { useTranslation } from "react-i18next";
import { AdressDetailsComponent } from "../../components/shared/AddressDetails";
import { ContactDetailsComponent } from "../../components/shared/ContactDetails";
import HeaderSectionsComponent from "../../components/shared/HeaderSectionsComponent";
import PaymentInfo from "../Payment/PaymentInfo";
import PaginationListComponent from "../../components/shared/PaginatedListComponent";
import { PaginationComponent } from "../../components/shared/PaginationComponent";
import { faCircle } from "@fortawesome/free-solid-svg-icons";

export default function () {
    const auth = useAuth();
    const {t, i18n} = useTranslation()
    const userIdentifier = auth.user?.profile.client_id
    const [showSuccessMessage, setShowSuccessMessage] = useState<boolean>(false)
    const [showErrorMessage, setShowErrorMessage] = useState<boolean>(false)
    const [errorMessage, setErrorMessage] = useState<string>("")
    const [successMessage, setSuccessMessage] = useState<string>("")
    const [loading, setLoading] = useState<boolean>(true)
    const [manageCompany, setManageCompany] = useState<boolean>(false)
    const [managePayments, setManagePayments] = useState<boolean>(false)
    const companyTypeOptions:OptionType[] = [{ value: 'Dealer', label: t('dealer') },{ value: 'User', label: t('endUser') },{ value: 'Distributor', label: t('distributor') }]
    const [headerOptions, setHeaderOptions] = useState<OptionType[]>([{label:t('contactDetails'), value: 'contactDetails'}, {label:t('companyEmails'), value: 'companyEmails'}, 
    {label:t('addressDetails'), value: 'addressDetails'} ])
    const [currentSection, setCurrentSection] = useState<string>('contactDetails')

    //Company Details
    const [companyName, setCompanyName] = useState<string>('')
    const [companyType, setCompanyType] = useState<string>('')

    //Contact Info
    const [website, setWebsite] = useState<string>('')
    const [email, setEmail] = useState<string>('')
    const [phoneNumbers, setPhoneNumbers] = useState<PhoneNumberType[]>([])
    const [currentPhoneTypeOption, setCurrentTypeOption] = useState<string | undefined>('Work')
    const [currentPhoneNumber, setCurrentPhoneNumber] = useState<string>('')
    const [currentExtension, setCurrentExtension] = useState<string>('')
    const [address, setAddress] = useState<string>('')
    const [city, setCity] = useState<string>('')
    const [postalCode, setPostalCode] = useState<string>('')
    const [country, setCountry] = useState<string>('')
    const [region, setRegion] = useState<string>('')
    const [id, setId] = useState<number>(0)
    const [emailSubscription, setEmailSubscription] =  useState<number>(0)

    //Company Emails
    const [companyEmails, setCompanyEmails] = useState<CompanyEmail[]>([])
    const [companyEmail, setCompanyEmail] = useState<string>('')
    const [allowedInvoice, setAllowedInvoice] = useState<boolean>()

    const companyEmailValidate =  EmailValidate(companyEmail)

    // Payment Details
    const [payReference, setPayReference] = useState<string|undefined>()
    const [payExpiry, setPayExpiry] = useState<Date|undefined>()
    const [deploymentPayments, setDeploymentPayments]  = useState<DeploymentPaymentInfo[]|null>()


    // pagination
    const [perPage, setPerPage] = useState<number>(10)
    const [pageNumber, setPageNumber] = useState<number>(1)
    const [maxPageNumber, setMaxPageNumber] = useState<number>(0)
    const [pages, setPages] = useState<number[]>([])
    const [orderBy, setOrderBy] = useState<string>('name')
    const [orderDirection, setOrderDirection] = useState<string>('Asc')
    const [searchValue, setSearchValue] = useState<string>('')
    const [check, setCheck] = useState<any>()

    //header
    const header: OptionType[] = [
        { value: "name", label: t('name'), i1: 1 },
        { value: "samePayment", label: t('samePayment'), func: setCheck}
    ]

    useEffect(() => {
        console.log(country)
    }, [country])

    useEffect(() => {
        getRoles()
    }, [])

    const getCompany = async () => {
        try{
            const response:any = await API.get(`/api/v1/Company/${userIdentifier}`, auth)
            const data:GetCompanyRequest = response.result
            setLoading(false)
            setCompanyName(data.companyName)
            setCompanyType(data.companyType)
            setWebsite(data.website)
            setEmail(data.email)
            setPhoneNumbers(data.phoneNumbers)
            setAddress(data.street)
            setCity(data.city)
            setPostalCode(data.postalCode)
            setCountry(data.country)
            setRegion(data.region)
            setId(data.companyId)
            setEmailSubscription(data.emailSubscriptions)
            setCompanyEmails(data.companyEmails!)
            setPayReference(data.cardNumber)
            setPayExpiry(data.paymentExpiry)
        }
        catch(e:any){
            alert(e.errors?.Error!)
        }
    }
    const getRoles = async () => {
        try{
            const response:any = await API.get(`/api/v1/user/roles/${userIdentifier}`, auth)
            const data:Roles = response.result
            setManageCompany(data.manageCompany)
            setManagePayments(data.managePayments)
            getCompany()
            if (data.managePayments == true ){
                let options = headerOptions;
                options.push({ value: 'paymentInfo', label:t('paymentInformation')})
                setHeaderOptions(options)
                getDeploymentPayments()
            }
        }
        catch(e:any){
            alert(e.errors?.Error!)
        }
    }

    const buildFilterString = (filter: string) => {
        return 'Name.ToLower().Contains(' + encodeURI('"' + filter.toLowerCase() + '"') + ')'
    }

    const getDeploymentPayments = async () => {
        try{
            const response:any = await API.get(`/api/v1/Company/deploymentPayments/${userIdentifier}?PerPage=${perPage}&PageNumber=${pageNumber}&Filter=${buildFilterString(searchValue)}&OrderBy=${orderBy} ${orderDirection}`, auth)
            if (response == null || response == undefined)
                return
            setMaxPageNumber(Math.ceil(response.totalRecords / perPage))
            const pageArray: number[] = []
            for (let index = 1; index <= Math.ceil(response.totalRecords / perPage); index++) {
                pageArray.push(index)
            }
            setPages(pageArray)
            if (response.results == null)
                response.results = []
            setDeploymentPayments(response.results)
        }
        catch(e:any){
            console.log(e.errors?.Error!)
        }
    }

    const updateDeploymentPayment = async (data:any) => {
        try{
            const response = await API.put(`api/v1/company/setDeploymentPayment/${data.id}/${!data.samePayment}`, {}, auth)
            getDeploymentPayments()
        }
        catch (e: any){ 
            setErrorMessage(e.errors?.Error!)
        } 
    }

    const checkAll = async (data:boolean) => {
        try{
            if (deploymentPayments == null || deploymentPayments == undefined || deploymentPayments.length == 0)
                return

            let ids = deploymentPayments?.map(e => e.id)
            let strIds = ids.join(",")
            
            const response = await API.put(`api/v1/company/setDeploymentPayment/${strIds}/${data}`, {}, auth)
            getDeploymentPayments()
        }
        catch (e: any){ 
            setErrorMessage(e.errors?.Error!)
        } 
    }

    useEffect(() => {
        updateDeploymentPayment(check)
    }, [check])

    useEffect(() => {
        getDeploymentPayments()
      }, [pageNumber, orderBy, orderDirection,searchValue])

    const UpdateCompany = async () => {
        const object:GetCompanyRequest = {
            companyName: companyName,companyType: companyType,
            website: website,email: email,
            phoneNumbers: phoneNumbers,
            street: address,city: city,postalCode: postalCode,
            country: country,region: region, companyId:id,
            emailSubscriptions: emailSubscription,
            companyEmails: companyEmails
        }
        try{
            const response = await API.put("/api/v1/Company", object, auth)
            window.scrollTo(0, 0)
            setSuccessMessage("Company Settings Have Been Successfully Updated")
            setShowSuccessMessage(true)
            setShowErrorMessage(false)
        }
        catch (e: any){ 
            setErrorMessage(e.errors?.Error!)
            setShowErrorMessage(true)
            setShowSuccessMessage(false)
        } 
    }

    const handleCompanyEmailAdd = () =>
    {
        if (email.toLowerCase() == companyEmail.toLowerCase() )
        {
            alert("Same Email As Company Email")
            return
        }
        if (companyEmails.find((e:CompanyEmail) => e.emailAddress == companyEmail ))
        {
            alert("Duplicate Email")
            return
        }
        const object:CompanyEmail = {emailAddress: companyEmail, attachInvoice: false }
        companyEmails.push(object)
        setCompanyEmail('')
    }

    const handleRemoveCompanyEmail = (e: CompanyEmail) => 
    {
        const index = companyEmails.indexOf(e);
        const newCompanyEmail =  [...companyEmails]
        newCompanyEmail.splice(index, 1)
        setCompanyEmails(newCompanyEmail)
    }

    const changeAttachInvoice = (e: CompanyEmail) =>
    {
        const index = companyEmails.indexOf(e);
        const object:CompanyEmail = {emailAddress: e.emailAddress, attachInvoice: !e.attachInvoice }
        const newCompanyEmail =  [...companyEmails]
        newCompanyEmail.splice(index, 1,object )
        setCompanyEmails(newCompanyEmail)

    }

    return(
        <div className="">
            {loading && <LoadingPageComponent/>}
            {!loading && 
            <div className="row" >
                <SuccessErrorMessage showErrorMessage={showErrorMessage} showSuccessMessage={showSuccessMessage} setShowErrorMessage={setShowErrorMessage}
                setShowSuccessMessage={setShowSuccessMessage} errorMessage={errorMessage} successMessage={successMessage} />
                <div className=" mb-4  bg-transparent">
                    <div className="">
                        <h5 className="float-end">{companyTypeOptions.find( e => e.value == companyType)?.label}</h5>
                        <h1 className=' header-text'><FontAwesomeIcon icon="building" className="fs-3 me-2" />{companyName}</h1>
                    </div>
                </div>
                <div className="col-sm-3">
                    <HeaderSectionsComponent label={"Company Settings"} currentSection={currentSection} setCurrentSection={setCurrentSection} sections={headerOptions} />
                </div>
                <div className={`col-sm-9 ${currentSection != "paymentInfo" ? 'card' : ''}`}>
                    {currentSection == "contactDetails" && <div className="">
                        < ContactDetailsComponent hosted={false} company={true} phoneNumbers={phoneNumbers} setPhoneNumbers={setPhoneNumbers} website={website} email={email} 
                        currentExtension={currentExtension} currentPhoneNumber ={currentPhoneNumber} currentPhoneTypeOption={currentPhoneTypeOption}
                        setCurrentExtension={setCurrentExtension} setCurrentPhoneNumber={setCurrentPhoneNumber} 
                        setCurrentTypeOption={setCurrentTypeOption} setEmail={setEmail} setWebsite={setWebsite}
                        emailSubscription={emailSubscription} setEmailSubscription={setEmailSubscription} />                 
                    </div>}
                    
                    {currentSection == "companyEmails" &&
                        <div className="mb-4">
                            <div className="card-header">
                                <h2 className='mb-4 header-text '><FontAwesomeIcon icon="address-book" className="fs-3 me-2" />{t('companyEmails')}</h2>
                            </div>
                            <div className="card-body">
                                <div className="form-group row ">
                                    <div className="col-1 d-none d-sm-block"></div>
                                    <label className="col-3 col-sm-2 col-form-label text-begin ms-3" >
                                        <h3 className="d-none d-sm-block">{t('email')}</h3>
                                        <h3 className="d-block d-sm-none fs-4">{t('email')}</h3>
                                    </label>
                                    <div className={ "col-7 col-sm-5" }>
                                        <input className="form-control-lg form-control  input-text py-0" type="text" value={companyEmail} onChange={(e) => setCompanyEmail!(e.target.value)}  />
                                        {(companyEmail != '' && !companyEmailValidate) && 
                                        <div className="col-12 mt-2">
                                            <p className="" style={{"color": "#FF0000"}}>{"This is not a valid e-mail address."}</p>
                                        </div>}
                                    </div>
                                    <div className="col-1">
                                        <button className="btn p-0 mt-1 " disabled={!companyEmailValidate} onClick={() => handleCompanyEmailAdd()}>
                                            <FontAwesomeIcon className="text-info fs-2" icon="plus" />
                                        </button>
                                    </div>
                                </div>

                                {companyEmails.length != 0 && <table className="my-0 table mt-3">
                                    <thead>
                                        <tr>
                                            <th></th>
                                            <th  className="d-table-cell col-6 h5">{`${t('email')}`}</th>
                                            <th className="d-table-cell col-3 h5">{`${t('attachInvoices')}`}</th>
                                            <th className="d-table-cell col-2 h5"></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {companyEmails.map((e: CompanyEmail) => (
                                            <tr className="">
                                                <td></td>
                                                <td className="d-xl-table-cell"><h5>{e.emailAddress}</h5></td>
                                                <td className="d-xl-table-cell">
                                                    <input className="align-bottom form-check-input mt-2 ms-4 text-center " type="checkbox" id="flexSwitchCheckDefault" checked={e.attachInvoice} 
                                                    onChange={() => changeAttachInvoice(e)}   />
                                                </td>
                                                <td>
                                                    <button className="border-0  btn btn-outline-danger" onClick={() => handleRemoveCompanyEmail(e)}  >
                                                        <FontAwesomeIcon icon="delete-left" />
                                                    </button>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>}
                            </div>
                        </div>
                    }

                    {currentSection == "addressDetails" && <div className="">
                        <AdressDetailsComponent  region={region} country={country} city={city} postalCode={postalCode} address={address}
                                        setAddress={setAddress} setRegion={setRegion} setCity={setCity} setCountry={setCountry} setPostalCode={setPostalCode} />
                    </div>}
                    
                    {
                        currentSection == "paymentInfo" &&
                        <div>
                            <PaymentInfo payExpiry={payExpiry} payReference={payReference} id={Number(id)} setPayReference={setPayReference} isCompany={true} />
                            {deploymentPayments != null && deploymentPayments != undefined && <div className="card mt-2">
                                <div className="card-header pb-0">
                                    <div className="row">
                                        <h2 className='mb-4 col-6 header-text '><FontAwesomeIcon icon="address-book" className="fs-3 me-2" />{t('deploymentPayments')}</h2>
                                        <div className='col-6 ps-0 pe-0'>
                                            <div className='input-group-navbar input-group'>
                                            <input className=" float-end form-control input-text" placeholder={t("deployment:deploymentSearch")} type="text" value={searchValue} onChange={(e) => setSearchValue(e.target.value)} />
                                            <button className="btn">
                                                <FontAwesomeIcon icon="magnifying-glass" className=" ms-2" />
                                            </button>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <button className="btn float-end btn-dribbble ms-2  " onClick={() => checkAll(false)} >
                                                <span >{t('uncheckAll')}</span>
                                                <FontAwesomeIcon fill="transparent"  icon={faCircle} className=" ms-2" />
                                            </button>
                                            <button className="btn float-end btn-dribbble ms-2 " onClick={() => checkAll(true)} >
                                                <span >{t('checkAll')}</span>
                                                <FontAwesomeIcon icon="circle-check" className=" ms-2" />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-body pt-0">
                                     <PaginationListComponent
                                        isEditable={false}
                                        isRemovable={false}
                                        list={deploymentPayments!}
                                        headerValues={header}
                                        repopulate={getDeploymentPayments}
                                        label={t('deployment')}
                                        setOrderBy={setOrderBy}
                                        orderBy={orderBy}
                                        orderDirection={orderDirection}
                                        setOrderDirection={setOrderDirection}
                                    /> 
                                    <PaginationComponent
                                        pageNumber={pageNumber}
                                        pages={pages}
                                        maxPageNumber={maxPageNumber}
                                        setPageNumber={setPageNumber} />     
                                </div>
                            </div>}
                        </div>
                    }
                    {manageCompany && currentSection != "paymentInfo" &&
                    <div className=" pe-5 mt-2 mb-4 float-end">
                        <button className="btn float-end btn-twitter  " onClick={() => UpdateCompany()} disabled={!EmailValidate(email)}>
                            <span >{t('update')}</span>
                        </button>
                    </div>}
                </div>
            </div>}
        </div>
        
    )
}