
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { t } from 'i18next';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import useAppSelector from '../../hooks/useAppSelector';
import { selectName } from '../../redux/slices/config';

export default AccountDropdown 

function AccountDropdown ({name, logout}:{name:string|undefined,  logout: () => void}){
    const [userDropdown, setUserDropdown] = useState<boolean>(false)
    const handleOutsideClick = (event:any) => {
        if(event.target.id != "accountDropdown")
            setUserDropdown(false)
    }
    const { t } = useTranslation();
    document.addEventListener("click", handleOutsideClick);

    const navigate = useNavigate()
    name = useAppSelector(selectName)

    return(
        <div id="accountDropdown" >
            <ul id="accountDropdown" className="mt-1 px-0" >
                <li id="accountDropdown" className='nav-link dropdown-toggle' style={{"zIndex" : "3"}} onClick={() => setUserDropdown(!userDropdown)}>
                    <FontAwesomeIcon id="accountDropdown" icon="user-shield"  className=" ms-2 mt-1"/>
                    <a type='button' id="accountDropdown" className="ms-1 text-decoration-none text-dark" >{name}</a>
                    <div id="accountDropdown" tabIndex={0} className={userDropdown ?'dropdown-menu dropdown-menu-end show ' : 'dropdown-menu dropdown-menu-end'} >
                        <a id="accountDropdown"  className="dropdown-item" onClick={() => navigate("/manage")}><h6>{t('account')}</h6></a>
                        <a id="accountDropdown" className="dropdown-item"  onClick={() => logout()}><h6>{t('logout')}</h6></a>
                    </div>
                </li>
            </ul>
        </div>
    )
}