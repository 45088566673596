import { t } from "i18next";
import React from "react";
import { AuthContextProps } from "react-oidc-context";
import API from "../API";
import { HostedPaymentDetailsDTO, OptionType, ProductPricing } from "../store";

export default async function(auth:AuthContextProps, setProductPricing:Function ){
    try{
        const response:ProductPricing[]|null = await API.get(`/api/v1/pricing`, auth)
        setProductPricing(response)
    }
    catch(e:any)
    {
        alert(e.errors?.Error!)
    }
}