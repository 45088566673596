import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import React from "react";
import LoadingPageComponent from "../shared/LoadingPageComponent";
import TextInputComponent from "../shared/TextInputComponent";

export function ResponseComponent({ loading, responseKey, setResponseKey }: { loading: boolean, responseKey: string, setResponseKey: (value: React.SetStateAction<string>) => void, }) {
    const { t } = useTranslation();
    return (
        <div>
            {loading && <LoadingPageComponent text={"Updating License"} />}
            {!loading &&
                <div className=" card">
                    <div className=" card-header">
                        <h2 className=' mb-4 header-text'><FontAwesomeIcon icon="key" className="fs-3 me-2" />{t('license:responseKey')}</h2>
                    </div>
                    <div className="card-body">
                        <TextInputComponent appendClass="btn btn-outline-secondary" onClick={() => {navigator.clipboard.writeText(responseKey)}} append={"copy"} value={responseKey} label={t('license:responseKey')} setText={setResponseKey} noEdit />
                    </div>
                    <div className="mb-4 pe-5 float-end">
                        <button className="btn float-end btn-secondary " onClick={() => window.location.reload()} >
                            <span >{t('deployment:deploymentDetails')}</span>
                        </button>
                    </div>
                </div>
            }
        </div>
    )
}