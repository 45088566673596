import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { t } from "i18next";
import React, { useEffect, useState } from "react";
import { useAuth } from "react-oidc-context";
import { useNavigate } from "react-router-dom";
import API from "../../common/API";
import { Invoice, OptionType, Transaction, ViewInvoiceDTO } from "../../common/store";
import PaginationListComponent from "../../components/shared/PaginatedListComponent";
import { PaginationComponent } from "../../components/shared/PaginationComponent";
import ViewInvoice from "./ViewInvoice";
import Payment from "../Payment/ElavonPayment";

export default function({latestInvoiceId, deploymentId, pastDue}:{latestInvoiceId:number, deploymentId:number, pastDue:boolean})
{
    const auth = useAuth()
    const [transactions, setTransactions] = useState<Transaction[]>()
    const values: OptionType[] = [{ value: "id", label: "Id" },{ value: "amount", label: t("invoice:amount") }, { value: "messageId", label: t("invoice:message") },
    { value: "responseTimestamp", label: t("invoice:occurredOn") },{ value: "authCode", label: t("invoice:authorizationCode") }]

    const sm_values: OptionType[] = [{ value: "amount", label: t("invoice:amount") }, { value: "messageId", label: t("invoice:message") },
    { value: "responseTimestamp", label: t("invoice:occurredOn") }]

    const [perPage, setPerPage] = useState<number>(5)
    const [pageNumber, setPageNumber] = useState<number>(1)
    const [maxPageNumber, setMaxPageNumber] = useState<number>(0)
    const [pages, setPages] = useState<number[]>([])

    const[pastDueInvoice, setPastDueInvoice] = useState<Invoice|null>()

    useEffect(() => {
        getTransactions()

    },[pageNumber])

    useEffect(() => {
        if (pastDue)
            getPastDueInvoice()
    }, [])


    const postPastDuePayment = async (isInvoice?: boolean) => {

        try {
            const url = `api/v1/hosteddeployment/${deploymentId}/pastDuePayment/${isInvoice == null ? false : isInvoice}/${pastDueInvoice?.invoiceNumber}`
            const response: any = await API.post(url, {}, auth)
            window.location.reload()
        }
        catch (e: any) {
            alert(e.errors?.Error!)
        }
    }

    const getPastDueInvoice = async () => {
        try{
            const url:string = `/api/v1/invoices/pastDueInvoice/${deploymentId}` 
            const response:ViewInvoiceDTO|null = await API.get(url, auth)

            setPastDueInvoice(response?.invoice)
        }
        catch(e:any)
        {
            alert(e.errors?.Error!)
        }
    }
    const getTransactions = async () => {
        try{
            const url:string = `/api/v1/transactions/${deploymentId}?PerPage=${perPage}&PageNumber=${pageNumber}` 
            const response: any = await API.get(url, auth)
            if (response != null)
            {
                if (response.results == null)
                    response.results = []

                setMaxPageNumber(Math.ceil(response.totalRecords/ perPage))
                const pageArray:number[] = []
                for (let index = 1; index <= Math.ceil(response.totalRecords/ perPage); index++){
                pageArray.push(index)
                }
                setPages(pageArray)

                response.results.map((e:Transaction) => {
                    e.responseTimestamp = e.responseTimestamp == null ? "" : new Date(e?.responseTimestamp!).toLocaleDateString() + " " + new Date(e?.responseTimestamp!).toLocaleTimeString()
                  })
                  
                setTransactions(response.results)
            }
        }
        catch(e:any)
        {
            alert(e.errors?.Error!)
        }
    }

    return(
        <div>
            {pastDueInvoice != null && <div className=" mb-4 ms-auto me-auto card shadow border-2" >
                    <div className=" card-header border-0 ">
                        <h2 className='mb-4 header-text d-inline '><FontAwesomeIcon icon="wallet" className="fs-3 me-2" />{`${t("deployment:pastdue") + " " + t("invoice:invoice")}`}</h2>
                    </div>
                    <div className="card-body bg-opacity-75 ">
                            <Payment invoice={pastDueInvoice} postFunction={postPastDuePayment} licensePayment={false} hostedPayment={true} showEula={true} />
                    </div>
                </div>}
            {latestInvoiceId != null && latestInvoiceId != 0  &&<div className="card">
                <div className="card-header">
                    <h2 className='mb-4 header-text d-inline '><FontAwesomeIcon icon="file-invoice-dollar" className="fs-3 me-2" />{`${t("invoice:latestInvoice")}`}</h2>
                </div>
                 <div className="card-body py-0">
                    <ViewInvoice iId={latestInvoiceId} showPayStatus={true} />
                </div>
            </div>}
            {transactions != null && <div className="card">
                <div className="card-header">
                    <h2 className='mb-4 header-text d-inline '><FontAwesomeIcon icon="wallet" className="fs-3 me-2" />{`${t("invoice:transactions")}`}</h2>
                </div>
                <div className="card-body py-0 d-none d-sm-block">
                    <PaginationListComponent list={transactions!} headerValues={values} isEditable={false} isRemovable={false} />
                    <PaginationComponent 
                        pageNumber={pageNumber} 
                        pages={pages} 
                        maxPageNumber={maxPageNumber} 
                        setPageNumber={setPageNumber} />
                </div>
                <div className="card-body py-0 d-block d-sm-none">
                    <PaginationListComponent list={transactions!} headerValues={sm_values} isEditable={false} isRemovable={false} />
                    <PaginationComponent 
                        pageNumber={pageNumber} 
                        pages={pages} 
                        maxPageNumber={maxPageNumber} 
                        setPageNumber={setPageNumber} />
                </div>
            </div>}
        </div>
    )
}