import React, { ChangeEvent, FormEvent, FormEventHandler, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import TextInputComponent from "../../components/shared/TextInputComponent";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { aiphoneLicenseTiers, CompanyFeatures, DeploymentResponse, Features, GetDeployment, HostedDeviceCost, HostedDeviceType, HostedPaymentDetailsDTO, Invoice, LicenseDTO, OptionType, PaymentDetails, ProductPricing, Roles, WizardOption } from "../../common/store";
import { validate as EmailValidate } from 'email-validator';
import API from "../../common/API";
import { useAuth } from "react-oidc-context";
import LoadingPageComponent from "../../components/shared/LoadingPageComponent";
import RadioComponent from "../../components/shared/RadioComponent";
import { useTranslation } from "react-i18next";
import { AdressDetailsComponent } from "../../components/shared/AddressDetails";
import { ContactDetailsComponent } from "../../components/shared/ContactDetails";
import { LocalLicensePackage } from "../../components/deployment/LocalLicensePackage";
import { HostedLicensePackageComponent } from "../../components/deployment/HostedLicensePackage";
import SelectComponent from "../../components/shared/SelectComponent";
import LicenseAddOnTextComponent from "../../components/shared/LicenseAddOnTextComponent";
import Payment from "../Payment/ElavonPayment";
import CreateDeployment from "../../common/Functions/CreateDeployment";
import ActivateLicense from "../../common/Functions/ActivateLicense";
import CreateLicense from "../../common/Functions/CreateLicense";
import CreatePackage from "../../common/Functions/CreatePackage";
import CreateLicenseInvoice from "../../common/Functions/CreateLicenseInvoice";
import CreateHostedInvoice from "../../common/Functions/CreateHostedInvoice";
import RemoveInvoice from "../../common/Functions/RemoveInvoice";
import GetActiveLicense from "../../common/Functions/GetActiveLicense";
import GetHostedPaymentDetails from "../../common/Functions/GetHostedPaymentDetails";
import { ProgressWizard } from "../../components/shared/ProgressComponent";
import { DeploymentType, PaymentMethod, LicenseTier } from "../../components/shared/Enums";
import GetPricing from "../../common/Functions/GetPricing";
import PostalCodeValidator from "../../common/Functions/PostalCodeValidator";
import axios, { AxiosError } from "axios";
import { Modal } from "react-bootstrap";
import { getCompanyFeatureGate, getFeatureGate, getHostedDomain, getHostedOdysseyDomain, showACECard } from "../../redux/slices/config";
import useAppSelector from "../../hooks/useAppSelector";
import RequiredFields from "../../components/shared/RequiredFields";
import { ModalComponent } from "../../components/shared/ModalComponent";
import licenseInfoImg from "../../assets/images/licenseInfo.png"
import liceseInfoImgAiphone from "../../assets/images/licenseInfoAiphone.png"
import { object } from "prop-types";
import { GetDeploymentById } from "../../common/Functions/GetDeployment";

export default function () {
    const { t } = useTranslation();
    const navigate = useNavigate()
    const [loading, setLoading] = useState<boolean>(false)
    var auth = useAuth();
    const showACE = useAppSelector(showACECard)
    const userIdentifier = auth.user?.profile.client_id;
    const { state  } = useLocation();
    const licenseTierRouteType : any = state || {};
    const { id, accNumber, renew, hosted, online } = useParams();
    const [langCheck, setLangCheck] = useState<string>('admin')
    const [section, setSection] = useState<string>(id != null ? hosted != null ? "method" : "method" : "deployment")

    const companyFeatureGate: CompanyFeatures = useAppSelector(getCompanyFeatureGate)
    const featureGate: Features = useAppSelector(getFeatureGate)
    const hostedDomain: string = useAppSelector(getHostedDomain)
    const hostedOdysseyDomain: string = useAppSelector(getHostedOdysseyDomain)

    const _hostedLicenseTiers = [{ label: "ProtectorNet", value: "0" }, { label: "Odyssey", value: "1" }, {
        label: "ProtectorNet Hosted Door Count", value: "2"
    }, { label: "Odyssey Hosted Door Count", value: "3" }, { label: "Enable ACE", value: "4" }, { label: "User Count", value: "5" },]
    const _licenseTiers = companyFeatureGate.oem == "Aiphone" ? aiphoneLicenseTiers : companyFeatureGate.allowedOdyssey ? [{ label: "ProtectorNet", value: "0" }, { label: "Odyssey", value: "1" }] :[{label:"ProtectorNet", value:"0"}];
    const deploymentTypes: OptionType[] = featureGate.hostedDeployments ? [{ value: '0', label: t('deployment:licensed') }, { value: '1', label: t('deployment:hosted') }] 
        : [{ value: '0', label: t('deployment:licensed') }]
    const [prepaid, setPrepaid] = useState<boolean>( hosted != null ? companyFeatureGate.allowedHostedPayment? false : true : !(companyFeatureGate.allowedLocalLicensePayment)? true : false)

    const sectionsWizard: WizardOption[] = [
        { label: t('deployment:deploymentDetails'), icon: "circle-info", value: "deployment" },
        { label: t('contactDetails'), icon: "address-book", value: "contact" },
        { label: t('address'), icon: "location-dot" , value: "address"},
        { label: t('license:method'), icon: "credit-card" , value: "method"},
        { label: t('license:package'), icon: "cube", value: "package" },
        { label: t('activate'), icon: "check-square", value: "activate" },
        { label: t('payment'), icon: "wallet", value: "payment" },
        { label: t('response'), icon: "key", value: "response" }
    ]

    const activatingSectionsWizard: WizardOption[] = [
        { label: t('deployment:deploymentDetails'), icon: "circle-info", value: "deployment" },
        { label: t('contactDetails'), icon: "address-book", value: "contact" },
        { label: t('address'), icon: "location-dot" , value: "address"},
        { label: t('license:method'), icon: "credit-card" , value: "method"},
        { label: t('license:package'), icon: "cube", value: "package" },
        { label: t('activate'), icon: "check-square", value: "activate" },
        { label: t('response'), icon: "key", value: "response" }
    ]

    const prepaidSectionsWizrd: WizardOption[] = [
        { label: t('deployment:deploymentDetails'), icon: "circle-info", value: "deployment" },
        { label: t('contactDetails'), icon: "address-book", value: "contact" },
        { label: t('address'), icon: "location-dot" , value: "address"},
        { label: t('license:method'), icon: "credit-card", value: "method" },
        { label: t('activate'), icon: "check-square", value: "activate" },
        { label: t('response'), icon: "key", value: "response" }
    ]

    const onlineSectionsWizard: WizardOption[] = [
        { label: t('deployment:deploymentDetails'), icon: "circle-info" , value: "deployment"},
        { label: t('contactDetails'), icon: "address-book" , value: "contact"},
        { label: t('address'), icon: "location-dot" , value: "address"}
    ]
    const prepaidHostedSectionsWizard: WizardOption[] = [
        { label: t('deployment:deploymentDetails'), icon: "circle-info", value: "deployment" },
        { label: t('contactDetails'), icon: "address-book", value: "contact" },
        { label: t('address'), icon: "location-dot", value: "address" },
        { label: t('license:method'), icon: "credit-card", value: "method" },
        { label: t('license:addOns'), icon: "credit-card", value: "addOns" },
        { label: t('activate'), icon: "check-square", value: "overview" }
    ]
    const hostedSectionsWizard: WizardOption[] = [
        { label: t('deployment:deploymentDetails'), icon: "circle-info", value: "deployment"},
        { label: t('contactDetails'), icon: "address-book", value: "contact" },
        { label: t('address'), icon: "location-dot", value: "address" },
        { label: t('license:package'), icon: "cube", value: "package" },
        { label: t('payment'), icon: "wallet", value: "payment" }
    ]


    const licenseOption: OptionType[] = [{ i1: 50, i2: 1, label: t("package:essentials"), value: 'ProNetE:OdysseyE' }, { i1: 100, i2: 2, label: t("package:small"), value: 'ProNetS:OdysseyS' },
    { i1: 250, i2: 4, label: t("package:mid"), value: 'ProNetM:OdysseyM' }, { i1: 400, i2: 256, label: t("package:large"), value: 'ProNetL:OdysseyL' },
    { i1: 750, i2: 8, label: t("package:enterprise"), value: 'ProNetEnt:OdysseyEnt' },
    { i1: 750, i2: 512, label: t("package:basic"), value: 'ProNetBas:OdysseyBas' }, { i1: 1024, i2: 1024, label: t("package:standard"), value: 'ProNetSt:OdysseySt' }]

    //Pricing Info
    const [productPricing, setProductPricing] = useState<ProductPricing[] | null>()
    const [subtotal, setSubtotal] = useState<number>()
    const [price5Part, setPrice5Part] = useState<number>()
    const [price10Part, setPrice10Part] = useState<number>()
    const [price20Part, setPrice20Part] = useState<number>()
    const [price, setPrice] = useState<number>(50)
    const [hostedDevicesPrice, setHostedDevicesPrice]= useState<HostedDeviceCost[]>([])
    //Deployment Info
    const [name, setName] = useState<string>('')
    const [type, setType] = useState<DeploymentType>(hosted != null ? DeploymentType.Hosted : DeploymentType.Local)
    const [description, setDescription] = useState<string>('')
    const [isOnline, setOnline] = useState<boolean>(online != null ? true : false)
    //Hosted Deployment Related Info
    const [subdomain, setSubdomain] = useState<string>('')
    const [hostedTerm, setHostedTerm] = useState<string>("12")
    const [numberOfPanels, setNumberOfPanels] = useState<number>(1)
    const [numberOfDoors, setNumberOfDoors] = useState<number>(0)
    const [numberOfElevators, setNumberOfElevators] = useState<number>(0)
    const [numberOfIOExpanders, setNumberOfIOExpanders] = useState<number>(0)
    //Address Info
    const [address, setAddress] = useState<string>('')
    const [city, setCity] = useState<string>('')
    const [postalCode, setPostalCode] = useState<string>('')
    const [country, setCountry] = useState<string>('')
    const [region, setRegion] = useState<string>('')
    //Contact Info
    const [contactName, setContactName] = useState<string>('')
    const [email, setEmail] = useState<string>('')
    const [phoneNumber, setPhoneNumber] = useState<string>('')
    const [emailSubscription, setEmailSubscription] = useState<number>(0)
    //License Info
    const [accountNumber, setAccountNumber] = useState<number>(accNumber != null ? Number(accNumber) : 0)
    const [deploymentId, setDeploymentId] = useState<number>(id != null ? Number(id) : 0)
    const [licenseTerm, setLicenseTerm] = useState<string>("12")
    const [multicard, setMulticard] = useState<number>(16)
    const [fivePartitions, setFivePartitions] = useState<number>(0)
    const [tenPartitions, setTenPartitions] = useState<number>(0)
    const [twentyPartitions, setTwentyPartitions] = useState<number>(0)
    const [packageType, setPackageType] = useState<number>(1)
    const [licenseTier, setLicenseTier] = useState<number>(companyFeatureGate.oem == "Aiphone" ? LicenseTier.Odyssey :LicenseTier.ProtectorNet)
    const [licenseId, setLicenseId] = useState<number>()
    const [prepaidCode, setPrepaidCode] = useState<string>('')
    const [aceCode, setAceCode] = useState<string>('');
    const [userCountCode, setUserCountCode] = useState<string>('');
    const [licenseAddonValue, setlicenseAddonValue] = useState<string>('0')
    const [isACEEnabled, setIsACEEnabled] = useState<string>("false");
    const [userCount, setUserCount] = useState<number>(2000);
    const [isACEChecked, setIsACEChecked] = useState<boolean>(false);
    const [isUserCountChecked, setIsUserCountChecked] = useState<boolean>(false);


    //Activation Info
    const [registrationKey, setRegistrationKey] = useState<string>("")
    const [registrationKeyCopy, setRegistrationKeyCopy] = useState<string>("")
    const [responseKey, setResponseKey] = useState<string>('')
    const [activatingLicense, setActivatingLicense] = useState<boolean>(false)
    //Invoice
    const [invoice, setInvoice] = useState<Invoice | null>()
    const [paymentDetails, setPaymentDetails] = useState<PaymentDetails>()


    //modal
    const [showMulticardModal, setShowMulticardModal] = useState<boolean>(false)
    const [showAccountNumberModal, setShowAccountNumberModal] = useState<boolean>(false)

    //
    const [canPayBeforeActivate, setCanPayBeforeActivate] = useState<boolean>(companyFeatureGate.allowPayBeforeActivate)

    const createDeployment = async () => {
        CreateDeployment(auth, name, type, description, contactName, email, emailSubscription,
            phoneNumber, address, city, postalCode, region, country, subdomain, userIdentifier, isOnline, prepaid,licenseTier,
            setLoading, setDeploymentId, setAccountNumber, setSection, navigate,setLicenseTier)
    }

    const createLicense = async (isInvoice?: boolean) => {
        if (activatingLicense)
            ActivateLicense(auth, accountNumber, registrationKey, licenseId!, setLoading, setSection, setResponseKey)
        else {
            CreateLicense(auth, deploymentId, packageType, licenseTerm, multicard, twentyPartitions, fivePartitions, tenPartitions, renew,
                online, registrationKey, setLoading, setSection, setResponseKey, invoice?.invoiceNumber!, licenseTier, navigate, invoice?.id!, isInvoice)
        }
    }
    const createPackage = async (isInvoice?: boolean) => {
        CreatePackage(auth, deploymentId, numberOfPanels, hostedTerm, multicard, setLoading, navigate, licenseTier, numberOfDoors, 
            numberOfElevators, numberOfIOExpanders, isInvoice)
    }
    const createLicenseIvoice = async () => {
        CreateLicenseInvoice(auth, deploymentId, packageType, licenseTerm, multicard, twentyPartitions, fivePartitions, tenPartitions, renew,
            online, registrationKey, setPaymentDetails, setInvoice, setLoading, setSection, licenseTier)
    }
    const createHostedInvoice = async () => {
        CreateHostedInvoice(auth, deploymentId, numberOfPanels, hostedTerm, multicard, setPaymentDetails, setInvoice, setLoading, 
            setSection, licenseTier, numberOfDoors, numberOfElevators, numberOfIOExpanders)
    }

    const removeInvoice = async () => {
        RemoveInvoice(auth, invoice!, setSection, setLoading)
    }

    const getPricing = async () => {
        GetPricing(auth, setProductPricing)
    }

    const selectMethod = (event: FormEvent<HTMLInputElement>) => {
        if (event.currentTarget.value == 'prepaid') {
            setPrepaid(true)
        }
        else {
            setPrepaid(false)
        }
    }

    const validateCard = async () => {
        if (prepaid) {
            if (prepaidCode == null) {
                return
            }
            var object = {Code: prepaidCode}
            try{
                var response: any = await API.post(`api/v1/license/IsValidCard`, object, auth)
               
                var responseTier = response.tier;

               var cardTier = LicenseTier[responseTier];
               var currentTier = LicenseTier[licenseTier];
                console.log(cardTier,licenseTier, currentTier, type);
                if(type == DeploymentType.Hosted && licenseTier == 0 && (responseTier == LicenseTier.OdysseyCloud || responseTier == LicenseTier.Odyssey)){
                    alert("Invalid Card. Current License Card is not supported for "+currentTier+" Hosted Deployment")
                    return;
                }

                if(type == DeploymentType.Hosted && licenseTier == 1 && (responseTier == LicenseTier.ProtectorNetCloud || responseTier == LicenseTier.ProtectorNet)){
                    alert("Invalid Card. Current License Card is not supported for "+currentTier+" Hosted Deployment")
                    return;
                }

                if (type == DeploymentType.Hosted && (response.tier == LicenseTier.OdysseyCloud || response.tier == LicenseTier.ProtectorNetCloud)) {
                    setlicenseAddonValue(response.value);
                } else if( type == DeploymentType.Local && (response.tier == LicenseTier.Odyssey || response.tier == LicenseTier.ProtectorNet)){
                   
                   
                }else{
                    alert("Invalid Card. Current License Card is not supported ")
                    return;
                }
                if (response.tier == LicenseTier.Odyssey && !companyFeatureGate.allowedOdyssey)
                {
                    alert("Invalid Card")
                    return;
                }
                setLicenseId(response.id);
                setLicenseTerm(response.term);
                setPackageType(response.package)
                setLicenseTier(response.tier);
                if(type == DeploymentType.Hosted)
                    setSection("addOns")
                else if (online == null)
                    setSection("activate")
                else
                    createLicenseFromCard()
            }
            catch (e: any) {
                console.log (e)
                alert(e.errors?.Error!)
            }

        } else {
            setSection("package")
        }
    }
    const createLicenseFromCard = async () => {
        setLoading(true)
        var object = {Code: prepaidCode,
            DeploymentId: deploymentId,
            RequestKey: registrationKey,
            Multicard: multicard==0 ? false : true}

        try{
            if (online == null)
            {
                var response:any =  await API.post(`api/v1/license/licenseFromCard`,object, auth)
                setResponseKey(response.responseKey)
                setLoading(false)
                setSection("response")
            }
            else
            {
                var response:any =  await API.post(`api/v1/license/licenseFromCard/Online`,object, auth)
                navigate(`/deployment/${deploymentId}`)
            }
        }
        catch (e: any) {
            setLoading(false)
            alert(e.errors?.Error!)
        }
    }
    const activateLicenseCard = async () => {
        setLoading(true)
        var object = {
            UserId:userIdentifier,
            LicenseCard: prepaidCode,
            ACECard: aceCode,
            UserCountCard: userCountCode,
            UserCount: userCount != undefined && userCount > 0 ? userCount : 0
        }

        try{
            var response :any = await API.post(`api/v1/hosteddeployment/activate/${deploymentId}/licensecard`,object,auth)
            navigate(`/deployment/${deploymentId}`)
            setLoading(false)
            
        }catch(e:any){
            setLoading(false)
            alert(e.errors?.Error!)
        }
        
    }
    useEffect(() => {
        if (type != DeploymentType.Hosted) {


            setPrepaid(!(companyFeatureGate.allowedLocalLicensePayment)? true : false)

            const productNamePre = Number(licenseTier) == 1 ? licenseOption.find(e => e.i2 == packageType)?.value?.split(":")[1] : licenseOption.find(e => e.i2 == packageType)?.value?.split(":")[0]
            const productName = productNamePre + (licenseTerm == "12" ? "1Yr" : "5Yr")
            const fivePart = "ProNet5Partitions" + (licenseTerm == "12" ? "1Yr" : "5Yr")
            const tenPart = "ProNet10Partitions" + (licenseTerm == "12" ? "1Yr" : "5Yr")
            const twentyPart = "ProNet20Partitions" + (licenseTerm == "12" ? "1Yr" : "5Yr")

            setSubtotal((productPricing?.find(e => e.partNumber == productName)?.price!))
            setPrice5Part(packageType != 1 && packageType != 8 && licenseTier != 1 && fivePartitions == 32 ? productPricing?.find(e => e.partNumber == fivePart)?.price! : 0)
            setPrice10Part(packageType != 1 && packageType != 8 && licenseTier != 1 && tenPartitions == 64 ? productPricing?.find(e => e.partNumber == tenPart)?.price! : 0)
            setPrice20Part(packageType != 1 && packageType != 8 && licenseTier != 1 && twentyPartitions == 128 ? productPricing?.find(e => e.partNumber == twentyPart)?.price! : 0)

            if (packageType == 1 || packageType == 8)
            {
                setFivePartitions(0)
                setTenPartitions(0)
                setTwentyPartitions(0)
            }
            let temp = (productPricing?.find(e => e.partNumber == productName)?.price!) +
            (packageType != 1 && packageType != 8 && licenseTier != 1 && fivePartitions == 32 ? productPricing?.find(e => e.partNumber == fivePart)?.price! : 0) +
            (packageType != 1 && packageType != 8 && licenseTier != 1 && tenPartitions == 64 ? productPricing?.find(e => e.partNumber == tenPart)?.price! : 0) +
            (packageType != 1 && packageType != 8 && licenseTier != 1 && twentyPartitions == 128 ? productPricing?.find(e => e.partNumber == twentyPart)?.price! : 0)

            setPrice(temp)
        }
        else {
                
            setPrepaid(companyFeatureGate.allowedHostedPayment? false : true)
            if (licenseTier == 0)
            {
                const panelProductName = "CldPerPanel"
                const panelsPrice = (productPricing?.find(e => e.partNumber == panelProductName)?.price!) * 12
                setSubtotal(panelsPrice / 12)
                setPrice((hostedTerm == "12" ? numberOfPanels * panelsPrice : numberOfPanels * (panelsPrice / 4)))
            }
            
        }

    }, [packageType, fivePartitions, twentyPartitions, tenPartitions, numberOfPanels, licenseTier, licenseTerm, hostedTerm, productPricing,type])

    useEffect(() => {
        if (licenseTier == 1 && type == 1)
        {
            const keys = Object.keys(HostedDeviceType).filter((v) => isNaN(Number(v)))
            let hostedList:HostedDeviceCost[] = []
            let totalPrice = 0;
            keys.forEach((key, index) => {
                const deviceProductName = "OdysseyDevice"+ key;
                const amount = key == "Doors" ? numberOfDoors : key == "Elevators" ? numberOfElevators : numberOfIOExpanders

                const devicePrices = productPricing?.filter(e => e.partNumber.includes(deviceProductName))
                let devicePrice = devicePrices == undefined ? 0 : devicePrices[0] == undefined ? 0 : devicePrices[0].price;
                if (devicePrices != undefined)
                {
                    devicePrices.sort((a, b) => {
                        if (Number(a.partNumber.split(deviceProductName)[1]) < Number(b.partNumber.split(deviceProductName)[1]))
                            return 1;
                        else 
                            return -1;
                    });
                    let amountLeft = amount;
                    let tempList:HostedDeviceCost[] = []
                    let largestDiscountAmount = 0;
                    for (let i = 0; i < devicePrices.length; i++)
                    {
                        var e = devicePrices[i];
                        var priceAmount = Number(e.partNumber.split(deviceProductName)[1])
                        if (amountLeft > priceAmount || (priceAmount == 1 && amountLeft == 1))
                        {
                            if (largestDiscountAmount == 0)
                                largestDiscountAmount = priceAmount
                            devicePrice = e.price
                            const deviceTotal = e.price * (hostedTerm == "12" ? 12: 3) * (priceAmount == 1 ? amountLeft : amountLeft - priceAmount) 
                            let newCost:HostedDeviceCost = {
                                DeviceType : HostedDeviceType[key as keyof typeof HostedDeviceType],
                                Subtotal: e.price,
                                Total: deviceTotal,
                                Amount: priceAmount == 1 ? amountLeft : amountLeft - priceAmount,
                                DiscountAmount: priceAmount,
                                LargestDiscountAmount: largestDiscountAmount
                            }
                            tempList.push(newCost)
                            totalPrice = totalPrice + deviceTotal
                            amountLeft = priceAmount == 1 ? 0 : priceAmount
                        }
                    }
                    tempList.reverse();
                    tempList.forEach(e => hostedList.push(e))
                }
            })
            setHostedDevicesPrice(hostedList)
            setPrice(totalPrice)
        }
    }, [numberOfDoors, numberOfElevators, numberOfIOExpanders, hostedTerm, licenseTier])

    useEffect(() => {
        licenseTierRouteType != null && licenseTierRouteType.licenseTierType != undefined && setLicenseTier(licenseTierRouteType.licenseTierType)
        const getRoles = async () => {
            try {
                const response: any = await API.get(`/api/v1/user/roles/${userIdentifier}`, auth)
                const data: Roles = response.result
                if (hosted != null && !data.manageHostedDeployments)
                    navigate('/deployment')
                else if (!data.manageDeployments && !data.manageHostedDeployments)
                    navigate('/deployment')
                if (id != null && hosted == null)
                    GetActiveLicense(auth, id!, navigate, setSection, setInvoice, setActivatingLicense, setLicenseId, setPackageType, 
                        setLicenseTerm, setLicenseTier, renew!, setFivePartitions, setTenPartitions, setTwentyPartitions,setMulticard, companyFeatureGate)
                else if (hosted != null && id != null)
                    GetHostedPaymentDetails(auth, id!, setInvoice, setNumberOfPanels, setHostedTerm, setMulticard, setPaymentDetails, setSection)
            }
            catch (e: any) {
                navigate('/deployment')
            }
        }
        getRoles()
        getPricing()

    }, [])

    useEffect(() => {
        setFivePartitions(0)
        setTenPartitions(0)
        setTwentyPartitions(0)
    }, [licenseTier])

    useEffect(() => {
        if (langCheck != t('admin')) {
            // setSection(id != null ? hosted != null ? t('license:package') : t('license:method') : t('deployment:deploymentDetails'))
            setLangCheck(t('admin'))
        }
    }, [sectionsWizard])

    useEffect(() => {
        if (activatingLicense == true)
        {

        }
    }, [activatingLicense])

    useEffect(() => {

        if (section == "method")
        {
            if ( type != DeploymentType.Hosted)
            {
                
                if ((companyFeatureGate.allowedLocalLicensePayment == true))
                {
                    if (!companyFeatureGate.allowedLicenseCard)
                        setSection("package")
                }
            }
            else {
                if ((companyFeatureGate.allowedHostedPayment == true))
                {
                    if (!companyFeatureGate.allowedHostedLicenseCard)
                        setSection("package")
                }
            }
        }
    },[section])

    useEffect(() =>{
        if (registrationKey.length == 29)
            return
        if (registrationKey.length > 29)
            setRegistrationKey(registrationKey.substring(0,29))
        if (registrationKey.endsWith("-") && registrationKey.length % 6 != 0)
            setRegistrationKey(registrationKey.substring(0,registrationKey.length - 1))
        if (registrationKey.length % 6 == 0)
        {
            if (!registrationKey.endsWith("-") && registrationKey.length != 0)
            {
                const temp = registrationKey[registrationKey.length - 1]
                setRegistrationKey(registrationKey.substring(0, registrationKey.length - 1) + "-" + temp)
                setRegistrationKeyCopy(registrationKey.substring(0, registrationKey.length - 1) + "-" + temp)
            }
            return
        }
        var newKey = registrationKey.replaceAll("-", "")
        if (newKey.length % 5 == 0)
        {    
            if (registrationKeyCopy.length < registrationKey.length)
            {   
                setRegistrationKey(registrationKey + "-")
                setRegistrationKeyCopy(registrationKey + "-")
            }
        }
        else
            setRegistrationKeyCopy(registrationKey)

    }, [registrationKey])

   
    return (
        <div className="">
            <div className=" border-0">
                <h1 className=' ms-1 header-text d-inline'><FontAwesomeIcon icon="tablet-screen-button" className="fs-3 me-2" />{renew == null ? t('deployment:addDeployment') : "Renew License"}</h1>
            </div>
            {/* {(type != DeploymentType.Hosted && !isOnline) && <ProgressWizard sections={sectionsWizard} currentSection={section} />}
            {(type != DeploymentType.Hosted && isOnline && renew == null) && <ProgressWizard sections={onlineSectionsWizard} currentSection={section} />}
            {type == DeploymentType.Hosted && <ProgressWizard sections={hostedSectionsWizard} currentSection={section} />} */}
            {
                type == DeploymentType.Hosted
                    ? prepaid
                        ? <ProgressWizard sections={prepaidHostedSectionsWizard} currentSection={section} />
                        : <ProgressWizard sections={sectionsWizard} currentSection={section} />
                    : !isOnline
                        ? prepaid
                            ? <ProgressWizard sections={prepaidSectionsWizrd} currentSection={section} />
                            : activatingLicense ?
                                  <ProgressWizard sections={activatingSectionsWizard} currentSection={section} />
                                : <ProgressWizard sections={sectionsWizard} currentSection={section} />
                        : renew == null
                            ? <ProgressWizard sections={onlineSectionsWizard} currentSection={section} />
                            : <div />
            }

            {section == "deployment" &&
                <div className=" mb-4 mt-2 ms-auto me-auto card shadow border-2">
                    <div className=" card-header border-0 ">
                        <h2 className='mb-4 header-text'><FontAwesomeIcon icon="circle-info" className="fs-3 me-2" />{t('deployment:deploymentDetails')}</h2>
                        <RequiredFields />
                    </div>
                    <div className="card-body bg-opacity-75 pt-0 ">
                        <div className="form-group row mb-4">
                            <div className="col-sm-3"></div>
                            <div className="col-sm-9 mb-2">
                                <div className="border-0 p-2 rounded-3">
                                    {type == DeploymentType.Local && <span className="h5 text-primary ms-3">{`${t('description:licensedDeployment')}`}</span>}
                                    {type == DeploymentType.Hosted && <span className="h5 text-primary ms-3">{`${t('description:hostedDeployment')}`}</span>}
                                </div>
                            </div>
                            <div className="col-1 d-none d-sm-block"></div>
                            <label className="col-5 col-sm-2 col-form-label text-begin" >
                                <h3 className="d-none d-sm-block ms-3">{t('deployment:deploymentType')}</h3>
                                <h3 className="d-block d-sm-none fs-4 mt-2 ms-3">{t('deployment:deploymentType')}</h3>
                            </label>
                            <div className=" col-6 col-sm-9">
                                {/* <SelectComponent placeholder={t('deployment:deploymentType')} className="form-select form-control-lg" options={deploymentTypes} onChange={(e: any) => setType(Number(e.target.value))} value={deploymentTypes.find(e => Number(e.value) == type)?.value} /> */}
                                <RadioComponent radioClass={"col-sm-12 col-form-label"} options={deploymentTypes} currentVal={deploymentTypes.find(e => Number(e.value) == type)?.value!} setCurrentVal={setType} />
                            </div>
                        </div>
                        <TextInputComponent mandatory value={name} label={t('name')} setText={setName} />
                        <TextInputComponent value={description} label={t('description')} setText={setDescription} />
                        {type == DeploymentType.Hosted &&
                            <div>
                                <div className="mb-4">
                                    <RadioComponent radioClass={"col-7 col-sm-7 col-form-label"} labelClass={"col-2 col-form-label text-begin"} label={t("system")} options={_licenseTiers} currentVal={licenseTier!= undefined ? licenseTier.toString() : "0"} setCurrentVal={setLicenseTier} />
                                </div>
                                <TextInputComponent mandatory value={subdomain} label={t("deployment:subdomain")} setText={setSubdomain} maxLength={10} minLength={2} alphanumeric />
                                <div className="row">
                                    <div className="col-0 col-sm-3"></div>
                                    <div className="col-12 col-sm-3 text-center">
                                        <p className="text-info h4">{`${subdomain}${licenseTier != null && licenseTier.toString() == "0" ? hostedDomain : hostedOdysseyDomain}`}</p>
                                    </div>
                                </div>
                            </div>}
                        {/* {type == 0 &&
                            <div className="form-group row mb-4 ">
                                <label className="col-sm-3 col-form-label text-center" ><h3>Online Licensing</h3></label>
                                <div className="col-sm-5 form-check form-switch">
                                    <input className="align-bottom form-check-input mt-2 ms-2 " type="checkbox" id="flexSwitchCheckDefault" checked={isOnline} onChange={(e) => setOnline(!isOnline)} />
                                </div>
                            </div>
                        } */}
                    </div>
                    <div className=" mb-4 pe-5">
                        <button className="btn float-end btn-github add-btn-text " disabled={name == '' || type == DeploymentType.Unkown || (type == DeploymentType.Hosted && subdomain == '')
                            || (type == DeploymentType.Hosted && subdomain.length < 2) || (type == 1 && /[^0-9a-zA-Z]/.test(subdomain))} onClick={() => setSection("contact")}>
                            <span >{t('next')}</span>
                            <FontAwesomeIcon icon="chevron-right" className="ms-2" />
                        </button>
                    </div>
                </div>
            }
            {
                section == "contact" &&
                <div className=" mb-4 mt-2 ms-auto me-auto card shadow border-2">
                    < ContactDetailsComponent info={t('contactInfo')} hosted={type == 1} company={false} addPage contactName={contactName} email={email} currentPhoneNumber={phoneNumber} setCurrentPhoneNumber={setPhoneNumber}
                        setEmail={setEmail} setContactName={setContactName} emailSubscription={emailSubscription} setEmailSubscription={setEmailSubscription}/>

                    <div className=" mb-4 pe-5">
                        <button className="btn float-end btn-github add-btn-text " disabled={(contactName == '') || (!EmailValidate(email))} onClick={() => setSection('address')}>
                            <span >{t('next')}</span>
                            <FontAwesomeIcon icon="chevron-right" className="ms-2" />
                        </button>
                        <button className="btn float-start btn-github add-btn-text ms-4 " onClick={() => setSection("deployment")}>
                            <FontAwesomeIcon icon="chevron-left" className="me-2" />
                            <span >{t('previous')}</span>
                        </button>
                    </div>
                </div>
            }
            {
                section == "address" &&
                <div>
                {!loading &&
                <div className=" mb-4 mt-2 ms-auto me-auto card shadow border-2">
                    <div className="mb-4 ">
                        <AdressDetailsComponent addPage region={region} country={country} city={city} postalCode={postalCode} address={address}
                            setAddress={setAddress} setRegion={setRegion} setCity={setCity} setCountry={setCountry} setPostalCode={setPostalCode} />
                        <div className="mb-4 pe-5">
                            <button className="btn float-end btn-instagram add-btn-text " disabled={((address == '') || (city == '') || (postalCode == '') || (country == '') ||
                                (region == '') || (!PostalCodeValidator(postalCode, country)))} onClick={() => createDeployment()}>
                                <span >{t('deployment:createDeployment')}</span>
                            </button>
                            <button className="btn float-start btn-github add-btn-text ms-4" onClick={()=>setSection("contact")}>
                                <FontAwesomeIcon icon="chevron-left" className="me-2" />
                                <span >{t('previous')}</span>
                            </button>
                        </div>
                    </div>
                </div>}
                {loading && <div className="mt-5 py-5"><LoadingPageComponent text={t('deployment:creatingDeployment')} /></div>}
                </div>
            }
            {
                section == "method" &&
                <div className=" mb-4 mt-2 ms-auto me-auto card shadow border-2">
                    <div className=" card-header border-0 ">
                        <h2 className='mb-4 header-text'><FontAwesomeIcon icon="credit-card" className="fs-3 me-2" />{t('license:method')}</h2>
                        {prepaid && !loading && <RequiredFields />}
                    </div>
                    {!loading && 
                        <div className="mb-4 ">
                            <div className="card-body bg-opacity-75 py-0">
                                <div className="mb-4" >
                                    {prepaid && <div className="row mb-5 align-top px-3">
                                        <div className="col-sm-3"></div>
                                        <div className="col-12 col-sm-4 ">
                                            {companyFeatureGate.oem == "Hartmann" && <div className="card px-0 border border-4 shadow" style={{"borderRadius": "30px"}} >
                                                <img src="/images/licenseCard.en.png" className="img-responsive w-100" style={{ "margin": "0 auto","borderRadius": "30px" }} />
                                            </div>}
                                            {companyFeatureGate.oem == "Aiphone" && <div className="card px-0 border-0 shadow-none">
                                                <img src="/images/LicenseCard-Aiphone.png" className="img-responsive w-100" style={{ "margin": "0 auto" }} />
                                            </div>}
                                            <div>{`${t('licenseCardCodeInfo')}`}</div>
                                        </div>
                                    </div>}
                                    <div className="row px-3">
                                        {!((type == DeploymentType.Local && companyFeatureGate.allowedLocalLicensePayment) || (type == DeploymentType.Hosted && companyFeatureGate.allowedHostedPayment)) && <div className="col-sm-1"></div>}
                                        {((type == DeploymentType.Local && companyFeatureGate.allowedLocalLicensePayment) || (type == DeploymentType.Hosted  && companyFeatureGate.allowedHostedPayment)) && 
                                        <div className="col-12 col-sm-2 px-0 mb-3">
                                            <label className="text-begin mt-1">
                                                <h3 className="d-inline">{companyFeatureGate.allowedInvoice ? t('payOnlineOrInvoice') :t('payOnline')}</h3>
                                                <input className="my-2 ms-1" type="radio" value="invoice" name="method" checked={!prepaid} onChange={selectMethod}/>
                                            </label>
                                        </div>}
                                        <div className="col-12 col-sm-1 mb-3">
                                            <label className=" text-begin mt-1">
                                                <h3 className="d-inline">{t('prepaid')}</h3>
                                                <input className="my-2 ms-1" type="radio" value="prepaid" name="method" checked={prepaid} onChange={selectMethod}/>
                                            </label>
                                        </div>
                                        <div className="col-1 d-none d-sm-block"></div>
                                        <div className="col-12 col-sm-8 ">
                                            {prepaid? <TextInputComponent maxLength={29} labelClass={"d-none"} mandatory value={prepaidCode} 
                                            validator={() => prepaidCode.length == 29 && /[A-Za-z0-9]{6}-[A-Za-z0-9]{4}-[A-Za-z0-9]{5}-[A-Za-z0-9]{4}-[A-Za-z0-9]{6}/.test(prepaidCode)} validateText={"Invalid Code"} 
                                            label={t('license:prepaidCode')} setText={setPrepaidCode} placeholder={t('license:enterPrepaidCode')} upperCase/>: <div />}
                                        </div>
                                    </div>
                                </div>
                                
                                <button className="btn float-end btn-github add-btn-text " onClick={validateCard} disabled={prepaid && ( prepaidCode?.length != 29 || !(/[A-Za-z0-9]+-[A-Za-z0-9]+-[A-Za-z0-9]+-[A-Za-z0-9]+-[A-Za-z0-9]+/.test(prepaidCode)))}>
                                    <span >{t('next')}</span>
                                </button>
                            </div>
                        </div>
                        }
                        {loading && <LoadingPageComponent text={'Finishing Creating License'} />}
                </div>
            }
            {
                section == "addOns" &&
                <div className="mb-4 mt-2 ms-auto me-auto card shadow border-2">
                    <div className="card-header border-0 ">
                        <h2 className='mb-4 header-text'><FontAwesomeIcon icon="credit-card" className="fs-3 me-2" />{t('license:addOns')}</h2>
                    </div>
                    <div className="row">
                            <div className="col-md-6 mb-4 ">
                                <div className="form-group row">
                                    <div className="col-1 d-none d-sm-block"></div>
                                <p className="h3 col-5 text-begin mb-4 ms-3" style={{fontWeight:'bold'}}>{"Current License Tier"}</p>
                                </div>
                                <TextInputComponent label={"Panel Count"} noEdit value={licenseAddonValue} />
                                <TextInputComponent value={licenseTerm == "60" ? `5 ${t('years')}` : `1 ${t('years')}`} label={t('license:licenseTerm')} noEdit />
                                {licenseTier == LicenseTier.OdysseyCloud && showACE && <TextInputComponent label={"ACE"} noEdit value={isACEEnabled}/>}
                                <TextInputComponent label={"User Count"} noEdit value={userCount.toString()} />
                            </div>
                            <div className="col-md-6 mb-4 ">
                            <div className="form-group row">
                                <p className="h3 col-4 text-begin mb-4 ms-3" style={{fontWeight:'bold'}}>{"License Add Ons"}</p>
                                </div>
                                {<div className="mt-3 form-group row">
                                    {licenseTier == LicenseTier.OdysseyCloud && showACE ? <LicenseAddOnTextComponent maxLength={29} label={"ACE"} value={aceCode} defaultValue={"false"}
                                        setText={setAceCode} placeholder={t('license:enterPrepaidCode')} upperCase licenseValue={isACEEnabled} setLicenseValue={setIsACEEnabled} 
                                        buttonLabel={"Add"} buttonClick={() => { }}  showAdd={false} tierType={licenseTier} type={type}/>: <div/>}

                                </div>}
                                <div className="mt-3 form-group row ">
                                    <LicenseAddOnTextComponent maxLength={29} label={"User Count"} value={userCountCode} defaultValue={"2000"}
                                        setText={setUserCountCode} placeholder={t('license:enterPrepaidCode')} upperCase
                                        licenseValue={userCount.toString()} setLicenseValue={setUserCount}
                                        buttonLabel={"Add"} buttonClick={() => { } } showAdd={false} tierType={licenseTier} type={type}/>
                                </div>
                            </div>
                   </div> 
                   <div className=" mb-4 pe-5">
                        <button className="btn float-end btn-github add-btn-text " onClick={activateLicenseCard}>
                            <span >{t('activate')}</span>
                            <FontAwesomeIcon icon="chevron-right" className="ms-2" />
                        </button>
                        <button className="btn float-start btn-github add-btn-text ms-4 " onClick={() => setSection("method")}>
                            <FontAwesomeIcon icon="chevron-left" className="me-2" />
                            <span >{t('previous')}</span>
                        </button>
                    </div>
                </div>
                    
            }
            {
                section == "package" &&
                <div className=" mt-2 ms-auto me-auto card shadow border-2" >
                    <div className=" card-header border-0 ">
                        <h2 className='header-text'><FontAwesomeIcon icon="cube" className="fs-3 me-2" />{t('deployment:selectPackage')}</h2>
                    </div>
                    <div className="float-start mb-0">
                        {/* <RadioComponent  options={licenseTiers} currentVal={licenseTier.toString()} setCurrentVal={setLicenseTier} /> */}
                    </div>
                    {(!loading && type != 1) &&
                        <div className="mb-4 ">
                            <LocalLicensePackage price10Part={price10Part!} price20Part={price20Part!} price5Part={price5Part!} subtotal={subtotal!} licenseTier={licenseTier} setLicenseTier={setLicenseTier} tier={licenseTier}
                                tenPartitions={tenPartitions} setTenPartitions={setTenPartitions} fivePartitions={fivePartitions} setFivePartitions={setFivePartitions}
                                twentyPartitions={twentyPartitions} setTwentyPartitions={setTwentyPartitions} licenseTerm={licenseTerm} setLicenseTerm={setLicenseTerm} packageType={packageType} setPackageType={setPackageType} price={price} />
                            <div className=" mb-4 pe-5">
                                {online == null && 
                                
                                <div>
                                    <button className="btn float-end btn-github add-btn-text ms-2 " onClick={() => setSection("activate")}>
                                        <span >{`${t('activate')}`}</span>
                                    </button>
                                </div>
                                
                                }
                                {online != null && <button className="btn float-end btn-flickr add-btn-text " onClick={() => createLicenseIvoice()}>
                                    <span >{`${t('invoice:createInvoice')}`}</span>
                                </button>}
                                { !companyFeatureGate.allowedLocalLicensePayment && <button className="btn float-start btn-github add-btn-text ms-4 " onClick={() => setSection("method")}>
                                    <FontAwesomeIcon icon="chevron-left" className="me-2" />
                                    <span >{t('previous')}</span>
                                </button>}
                            </div>
                        </div>
                    }
                    {(!loading && type == 1) &&
                        <div className="mb-4">
                            <HostedLicensePackageComponent licenseTier={licenseTier} setLicenseTier={setLicenseTier} tier={licenseTier} packageType={packageType} setPackageType={setPackageType}
                                hostedTerm={hostedTerm} setHostedTerm={setHostedTerm} subtotal={subtotal!} price={price} numberOfPanels={numberOfPanels} setNumberOfPanels={setNumberOfPanels} multicard={multicard}
                                setMulticard={setMulticard} numberOfDoors={numberOfDoors} numberOfElevators={numberOfElevators} setNumberOfDoors={setNumberOfDoors} setNumberOfElevators={setNumberOfElevators}
                                numberOfIOExpanders={numberOfIOExpanders} setNumberOfIOExpanders={setNumberOfIOExpanders} hostedDevicesPrice={hostedDevicesPrice}
                                />
                            <div className=" mb-4 pe-5">
                                {licenseTier == 0 && <button className="btn float-end btn-flickr add-btn-text " disabled={numberOfPanels > 2000 || numberOfPanels < 1} onClick={() => createHostedInvoice()}>
                                    {/* <span >{t('license:createPackage')}</span> */}
                                    <span>{`${t('invoice:createInvoice')}`}</span>
                                </button>}
                                {licenseTier == 1 && <button className="btn float-end btn-flickr add-btn-text " disabled={price == undefined || price < 1} onClick={() => createHostedInvoice()}>
                                    {/* <span >{t('license:createPackage')}</span> */}
                                    <span>{`${t('invoice:createInvoice')}`}</span>
                                </button>}
                            </div>
                        </div>
                    }
                    {loading && <LoadingPageComponent text={'Finishing Creating License'} />}
                </div>
            }
            {section == "activate" &&
                <div className=" mb-4 mt-2 ms-auto me-auto card shadow border-2" >
                    <div className=" card-header border-0">
                        <h2 className='mb-4 header-text'><FontAwesomeIcon icon="check-square" className="fs-3 me-2" />{t('license:activateLicense')}</h2>
                        <RequiredFields/>
                        <ModalComponent modalTitle={`${t("multicardInfo")}`} modalText={`${t("multicardInfoMessage")}`} 
                             showModal={showMulticardModal} setShowModal={setShowMulticardModal}/>
                        <ModalComponent modalTitle={`${t("license:accountNumber")}`} modalText={`${t("accountNumberInfoMessage")}`} 
                             showModal={showAccountNumberModal} setShowModal={setShowAccountNumberModal}/>
                    </div>
                    {loading && <LoadingPageComponent />}
                    {!loading &&
                        <div className="mb-4 ">
                            <div className="card-body bg-opacity-75 ">
                                <div className="form-group row mb-2">
                                    <div className="col-sm-3"></div>
                                    <div className="col-12 col-sm-8 ">
                                        <div className="ps-3 mb-2">
                                            <span className="">{`${t("license:accountNumberInfo")}`}</span>
                                        </div>
                                        {companyFeatureGate.oem == "Hartmann" && <img  src={licenseInfoImg} className="img-responsive"  ></img>}
                                        {companyFeatureGate.oem == "Aiphone" && <img  src={liceseInfoImgAiphone} className="img-responsive"  ></img>}
                                    </div>
                                </div>
                                {/* <TextInputComponent value={accountNumber.toString()} label={t('license:accountNumber')} noEdit /> */}
                                <div className="form-group row ">
                                    <div className="col-1 d-none d-sm-block"></div>
                                    <label className="col-4 col-sm-2 col-form-label text-begin mb-4 ms-3">
                                        <h3 className="d-none d-sm-block ">{t('license:accountNumber')}</h3><h3 className="d-block d-sm-none fs-4 ">{t('license:accountNumber')}</h3>
                                    </label>
                                    <div className="col-7 col-sm-5">
                                        <input disabled  className=" form-control bg-white fw-bolder fs-3 pt-0 " type="text"  value={accountNumber.toString()} style={{"border": "none"}}/>
                                    </div>
                                </div>
                                <div className="form-group row mb-3 mb-sm-0">
                                    <div className="col-1 d-none d-sm-block pe-0">
                                        <FontAwesomeIcon icon="info-circle" className="ms-2 align-middle fs-4 float-end py-2 text-secondary" onClick={() => setShowMulticardModal(true)}/>
                                    </div>
                                    <label className="col-4 col-sm-2 col-form-label text-begin mb-4 ms-3" >
                                        <h3 className="d-none d-sm-block">{t('license:multiCard')}</h3>
                                        <h3 className="d-block d-sm-none fs-4">{t('license:multiCard')}<FontAwesomeIcon icon="info-circle" className="ms-2 align-middle fs-4 text-secondary" onClick={() => setShowMulticardModal(true)}/></h3>
                                    </label>
                                    <div className=" col-7 col-sm-7 form-check form-switch" style={{paddingLeft: "0.7rem"}}>
                                        <input className="align-bottom form-check-input mt-2 ms-1 " type="checkbox" id="flexSwitchCheckDefault" checked={multicard == 16} onChange={(e) => setMulticard(multicard == 16 ? 0 : 16)} />
                                        <div className="mt-1">
                                            <span className="ms-2  align-middle">{t("license:multicardWarning")}</span>
                                        </div>
                                    </div>
                                </div>
                                <TextInputComponent mandatory={canPayBeforeActivate ? undefined : true } value={registrationKey} label={t('license:registrationKey')} setText={setRegistrationKey}
                                validator={() => /[A-Za-z0-9]{5}-[A-Za-z0-9]{5}-[A-Za-z0-9]{5}-[A-Za-z0-9]{5}-[A-Za-z0-9]{5}/.test(registrationKey)} validateText={"Invalid Request Key"} upperCase/>
                                <TextInputComponent value={licenseTerm == "60" ? `5 ${t('years')}` : `1 ${t('years')}`} label={t('license:licenseTerm')} noEdit />
                                <TextInputComponent label={"License Tier"} noEdit value={_licenseTiers.find((e) => e.value == licenseTier.toString())?.label! }/>
                                <TextInputComponent value={licenseOption.find(e => e.i2 == packageType)?.label!} label={t('license:package')} noEdit />
                                {type == DeploymentType.Hosted && < TextInputComponent label={"Value"} noEdit value={licenseAddonValue} />}
                                
                            </div>
                            <div className=" mb-4 pe-5">
                                {prepaid ?
                                    <button className="btn float-end btn-flickr add-btn-text " disabled={registrationKey == "" || !(/[A-Za-z0-9]{5}-[A-Za-z0-9]{5}-[A-Za-z0-9]{5}-[A-Za-z0-9]{5}-[A-Za-z0-9]{5}/.test(registrationKey))} onClick={() => createLicenseFromCard()}>
                                        <span >{`${t('response')}`}</span>
                                    </button>
                                    : 
                                    activatingLicense ?
                                    <button className="btn float-end btn-flickr add-btn-text " disabled={registrationKey == "" || !(/[A-Za-z0-9]{5}-[A-Za-z0-9]{5}-[A-Za-z0-9]{5}-[A-Za-z0-9]{5}-[A-Za-z0-9]{5}/.test(registrationKey))} onClick={() => createLicense()}>
                                        <span >{`${t('response')}`}</span>
                                    </button>
                                    : 
                                    <div>
                                        {canPayBeforeActivate &&
                                        <div className="float-end ">
                                            {registrationKey == "" && <p className="ms-2 fs-4">** {t('payBeforeActivate') }</p>}
                                            <button className="btn float-end btn-flickr add-btn-text ms-2 " onClick={() => createLicenseIvoice()}>
                                                <span >{`${t('invoice:createInvoice')}`}</span>
                                            </button> 
                                        </div>
                                        }
                                        {!canPayBeforeActivate && <button className="btn float-end btn-flickr add-btn-text " disabled={registrationKey == "" || !(/[A-Za-z0-9]{5}-[A-Za-z0-9]{5}-[A-Za-z0-9]{5}-[A-Za-z0-9]{5}-[A-Za-z0-9]{5}/.test(registrationKey))} onClick={() => createLicenseIvoice()}>
                                            <span >{`${t('invoice:createInvoice')}`}</span>
                                        </button>}
                                    </div> 
                                }
                                {!activatingLicense && <button className="btn float-start btn-github add-btn-text ms-4 " onClick={() => setSection(prepaid ?"method": "package")}>
                                    <FontAwesomeIcon icon="chevron-left" className="me-2" />
                                    <span >{t('previous')}</span>
                                </button>}
                            </div>
                        </div>
                    }
                </div>
            }
            {section == "overview" &&
                <div className=" mb-4 mt-2 ms-auto me-auto card shadow border-2" >
                    <div className=" card-header border-0">
                        <h2 className='mb-4 header-text'><FontAwesomeIcon icon="check-square" className="fs-3 me-2" />{t('license:activateLicense')}</h2>
                        
                    </div>
                </div>
            }
            {section == "response" &&
                <div className=" mb-4 mt-2 ms-auto me-auto card shadow border-2" >
                    <div className=" card-header border-0 ">
                        <h2 className='mb-4 header-text'><FontAwesomeIcon icon="key" className="fs-3 me-2" />{t('license:responseKey')}</h2>
                    </div>
                    <div className="card-body bg-opacity-75 ">
                        <TextInputComponent appendClass="btn btn-outline-secondary" onClick={() => {navigator.clipboard.writeText(responseKey)}} append={"copy"} value={responseKey} label={t('license:responseKey')} labelClass={"col-8 col-sm-3 col-form-label text-center mb-4 ms-3"} inputClass={"col-12 col-sm-5"} noEdit />
                    </div>
                    <div className=" mb-4 pe-5">
                        <button className="btn float-end btn-github add-btn-text " disabled={(registrationKey == "" && !canPayBeforeActivate) && online == null} onClick={() => navigate(`/deployment/${deploymentId}`)}>
                            <span >{`${t('exit')}`}</span>
                        </button>
                    </div>
                </div>
            }
            {
                section == "payment" &&
                <div className=" mb-4 mt-2 ms-auto me-auto card shadow border-2" >
                    <div className=" card-header border-0 ">
                        <h2 className='mb-4 header-text'><FontAwesomeIcon icon="wallet" className="fs-3 me-2" />{t('payment')}</h2>
                    </div>
                    <div className="card-body bg-opacity-75 ">
                            <Payment invoice={invoice} postFunction={type == DeploymentType.Hosted ? createPackage : createLicense} 
                            licensePayment={type == DeploymentType.Hosted ? false : true} hostedPayment={type == DeploymentType.Hosted ? true : false} showEula={true} />
                        <button className="btn float-start btn-github add-btn-text ms-4 " onClick={() => removeInvoice()}>
                            <FontAwesomeIcon icon="chevron-left" className="me-2" />
                            <span >{t('previous')}</span>
                        </button>
                    </div>
                </div>
            }
        </div>
    )
}