import { t } from "i18next";
import React from "react";
import { AuthContextProps } from "react-oidc-context";
import { NavigateFunction } from "react-router-dom";
import API from "../API";

export default async function(auth:AuthContextProps, deploymentId:number,packageType:number, licenseTerm:string, multicard:number,
    twentyPartitions:number, fivePartitions:number, tenPartitions:number, renew:string|undefined, online: string|undefined, registrationKey:string,
    setLoading:Function, setSection:Function, setResponseKey:Function, invoiceNumber:string, licenseTier:number, navigate:NavigateFunction, invoiceId: number, isInvoice?: boolean){
    setLoading(true)
        var object = {
            Method: 4,
            DeploymentId: deploymentId,
            Package: packageType,
            Duration: Number(licenseTerm),
            Features: multicard + twentyPartitions + fivePartitions + tenPartitions,
            Demo: false,
            Description: '',
            LicenseId: null,
            Renew: renew != null,
            RequestKey: online != null ? "" : registrationKey,
            InvoiceNumber: invoiceNumber,
            TierType: licenseTier,
            IsInvoice: isInvoice
        }
        try {
            if (online == null)
            {
                const response: any = await API.post( "api/v1/license", object, auth)
                setResponseKey(response.responseKey)
                setLoading(false)
                if (response.responseKey == "" || response.responseKey == null || response.responseKey == undefined)
                    navigate(`/deployment/${deploymentId}`)
                setSection("response")
            }
            else{
                const response: any = await API.post("api/v1/license/OnlineLicenceInvoiced/"+ invoiceId, {}, auth)
                navigate(`/deployment/${deploymentId}`)
            }
        }
        catch (e: any) {
            setLoading(false)
            alert(e.errors?.Error!)
        }
}