import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { t } from "i18next";
import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import Cards from 'react-credit-cards-2'
import 'react-credit-cards-2/es/styles-compiled.css'
import { useAuth } from "react-oidc-context";
import API from "../../common/API";
import { AuthorizeCardInfo } from "../../common/store";
import LoadingPageComponent from "../../components/shared/LoadingPageComponent";
import ElavonPayment from "./ElavonPayment";

export default function ({payReference, payExpiry, id, setPayReference, isCompany = false}:
    {payReference?:string, payExpiry?:Date|undefined, id:number, setPayReference:Function, isCompany?: boolean})
{
    const [payRef, setPayRef] = useState<string|null>(payReference!)
    const [payExp, setPayExp] = useState<string>('')

    const [loading, setLoading] = useState<boolean>(false)
    var auth = useAuth();

    const validateCard = (cardnumber:string) =>
    {
        //American Express only has 15 digits
        if (cardnumber.startsWith('37') || cardnumber.startsWith('34'))
            setPayRef(cardnumber.substring(0,2) + "*********" + cardnumber.slice(-4))
        //Discover Card
        else if (cardnumber.startsWith('6'))
        {
            var secondSection = cardnumber.startsWith("60") ? "11" : cardnumber.startsWith("62") ? "**" : cardnumber.startsWith("64") ? "4*" : "5*"
            setPayRef(cardnumber.substring(0,2) + secondSection + "********" + cardnumber.slice(-4) )   
        }
    }


    const authInfo:AuthorizeCardInfo = {id:id}

    const updateCardNumber = (cardnumber:string, expDate:string) =>
    {
        setPayRef(cardnumber)
        setPayReference(cardnumber)
        setPayExp(expDate.substring(0,2) + "/" + expDate.slice(-2))
        validateCard(cardnumber)
    }

    const removeCard = async () =>
    {
        setLoading(true)
        try{
            const response:any = await API.put(`api/v1/${isCompany ? "company" : "deployment"}/removePaymentInfo/${id}`,{},  auth)
            setPayRef("***********")
            setPayReference("***********")
            setPayExp("**/**")
            setLoading(false)
        }
        catch (e: any) {
            alert(e.errors?.Error!)
            setLoading(false)
        }

    }

    useEffect(() => {
        if (payReference != null){
            if (payExpiry != null)
                setPayExp(payExpiry!.toString().split("-")[1] + "/" + payExpiry!.toString().split("-")[0].slice(-2))
            validateCard(payReference)
        }
    }, [])
    
    return(
        <div>
            <div className=" mb-4">
                <div className="card">
                    <div className=" card-header pb-0">
                        <h2 className='mb-4 header-text d-inline'><FontAwesomeIcon icon="wallet" className="fs-3 me-2" />{`${t('paymentInformation')}`}</h2>
                        {payReference != null && 
                        <button className="d-inline float-end btn btn-danger" onClick={() => removeCard()}>
                            <FontAwesomeIcon icon="multiply" className="fs-3" />
                        </button>}
                    </div>
                    { loading == false && <div className="card-body row">
                        {payReference != null && <div className="col-12">
                            <Cards
                                expiry={payExp}
                                number={payRef}
                                name={" "}
                                cvc={""}
                                focused={"number"}
                                />
                        </div>}
                        <div className="mt-3">
                            <ElavonPayment authorize={authInfo} postFunction={updateCardNumber} companyAuthorize={isCompany} />
                        </div>
                    </div>}
                    {loading && <LoadingPageComponent />}
                </div>
            </div>
        </div>
    )
}