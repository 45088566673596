import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { NavItem } from "react-bootstrap";

export default function SuccessErrorMessage({ successMessage, showSuccessMessage, setShowSuccessMessage,
    errorMessage, showErrorMessage, setShowErrorMessage, warningMessage, showWarningMessage, setShowWarningMessage,
    successOnClick, errorOnClick, warningOnClick }:
    {
        successMessage?: string, showSuccessMessage?: boolean, setShowSuccessMessage?: (value: React.SetStateAction<boolean>) => void,
        errorMessage?: string, showErrorMessage?: boolean, setShowErrorMessage?: (value: React.SetStateAction<boolean>) => void,
        warningMessage?: string, showWarningMessage?: boolean, setShowWarningMessage?: (value: React.SetStateAction<boolean>) => void,
        successOnClick?: Function, errorOnClick?: Function, warningOnClick?: Function
    }) {

    const handleClickShowErrorMessage = () => {
        if (setShowErrorMessage != null)
            setShowErrorMessage(!showErrorMessage)
    }
    const handleClickShowSuccessMessage = () => {
        if (setShowSuccessMessage != null)
            setShowSuccessMessage(!showSuccessMessage)
    }

    const handleClickShowWarningMessage = () => {
        if (setShowWarningMessage != null)
            setShowWarningMessage(!showWarningMessage)
    }

    const handleErrorOnClick = () => {
        if (errorOnClick != null)
            errorOnClick()
    }

    const handleSuccessOnClick = () => {
        if (successOnClick != null)
            successOnClick()
    }

    const handleWarningOnClick = () => {
        if (warningOnClick != null)
            warningOnClick()
    }

    return (
        <div>
            <div onClick={handleSuccessOnClick} className="p-3 alert alert-success " style={{ display: showSuccessMessage ? 'block' : 'none' }}>
                <button className="me-2 d-inline float-end bg-transparent border-0 " onClick={handleClickShowSuccessMessage} >
                    <FontAwesomeIcon icon="multiply" type="button" />
                </button>
                <h5 className="text-center d-inline">{successMessage}</h5>
            </div>
            <div onClick={handleErrorOnClick} className="p-3 alert alert-danger" style={{ display: showErrorMessage ? 'block' : 'none' }}>
                {setShowErrorMessage != null && <button className="me-2 d-inline float-end bg-transparent border-0  " onClick={handleClickShowErrorMessage}>
                    <FontAwesomeIcon icon="multiply" type="button" />
                </button>}
                <h5 className="text-center d-inline">{`${errorMessage}`}</h5>
            </div>
            <div onClick={handleWarningOnClick} className="p-3 alert alert-warning" style={{ display: showWarningMessage ? 'block' : 'none' }}>
                {setShowWarningMessage != null && <button className="me-2 d-inline float-end bg-transparent border-0  " onClick={handleClickShowWarningMessage}>
                    <FontAwesomeIcon icon="multiply" type="button" />
                </button>}
                <h5 className="text-center d-inline">{`${warningMessage}`}</h5>
            </div>
        </div>
    )
}