import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import { AuthProvider, AuthProviderProps } from "react-oidc-context";
import { OidcClient, OidcClientSettingsStore, UserManagerSettings, WebStorageStateStore } from 'oidc-client-ts';
import { library } from '@fortawesome/fontawesome-svg-core'
import { Provider, useDispatch, useSelector } from 'react-redux';
import {
  faCheckSquare, faCoffee, faDeleteLeft, faEye, faPlus, faCog, faEdit, faMultiply, faChevronRight, faChevronLeft, faUserShield, faTabletScreenButton, faTabletButton,
  faUsers, faCircleXmark, faCircle, faHeartPulse, faBolt, faMagnifyingGlass, faTableColumns, faBuilding, faBars, faCirclePlus, faFileInvoiceDollar, faFileLines,
  faFlag, faDoorClosed, faPersonShelter, faTicket, faAddressBook, faLocationDot, faBox, faCube, faIdCard, faCircleInfo, faScrewdriverWrench, faReply, faKey, faCreditCard, faUser,
  faWallet, faArrowRight, faPhone, faEnvelope, faWrench, faClock, faDownload, faFloppyDisk, faStarOfLife, faMinus, faCircleExclamation, faInfo, faInfoCircle, faCopy, faCloudArrowDown, faArrowDown, faArrowUp, faCheck, faCircleCheck
} from '@fortawesome/free-solid-svg-icons'
import useAppDispatch from './hooks/useAppDispatch';
import useAppSelector from './hooks/useAppSelector';
import { selectConfig, setDevelopment, setPublish } from './redux/slices/config';
import { store } from './redux/reduxStore';
import Starter from './Starter';

library.add(faCheckSquare, faCoffee, faDeleteLeft, faEye, faPlus, faCog, faEdit, faMultiply, faChevronRight, faChevronLeft, faUserShield, faTabletScreenButton,
  faTabletButton, faUsers, faCircleXmark, faCircle, faHeartPulse, faBolt, faMagnifyingGlass, faTableColumns, faBuilding, faBars, faCirclePlus, faFileInvoiceDollar, faFileLines,
  faFlag, faDoorClosed, faPersonShelter, faTicket, faAddressBook, faLocationDot, faBox, faCube, faIdCard, faCircleInfo, faScrewdriverWrench, faReply,
  faKey, faCreditCard, faUser, faWallet, faArrowRight, faPhone, faEnvelope, faWrench, faClock, faDownload, faFloppyDisk, faStarOfLife, faMinus, faCircleExclamation, faInfoCircle, faCopy,
  faCloudArrowDown, faArrowDown, faArrowUp, faCheck, faCheckSquare, faCircleCheck)


const releasePortalURL: string = "https://dev-dealer.hartmann-controls.com"
const releaseAuthURL: string = "https://obsidianauth.hartmann-controls.com"



ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <Starter />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

