import { t } from "i18next";
import React from "react";
import { AuthContextProps } from "react-oidc-context";
import API from "../API";
import { Invoice } from "../store";

export default async function(auth:AuthContextProps, invoice:Invoice, setSection:Function, setLoading:Function){

    try {
        //const response: any = await API.delete(`api/v1/invoice/${invoice?.id}`, {}, auth)
        setSection("package")
    }
    catch (e: any) {
        setLoading(false)
        alert(e.errors?.Error!)
    }
}