import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { AuthContextProps, useAuth } from 'react-oidc-context'
import API from '../../common/API'
import { DeploymentDTO, OptionType } from '../../common/store'
import LoadingPageComponent from '../shared/LoadingPageComponent';
import PaginationListComponent from '../shared/PaginatedListComponent';
import { faPlay } from '@fortawesome/free-solid-svg-icons';
import TextInputComponent from '../shared/TextInputComponent';



function LicenseHistoryComponent({ id, auth }: { id: string | undefined, auth: AuthContextProps }) {
    const { t } = useTranslation();
    const [deployHistory, setDeployHistory] = useState<DeploymentDTO[]>([]);
    const [loading, setLoading] = useState<boolean>(false)
    const [responseLoading, setResponseLoading] = useState<boolean>(false)

    const [responseKey, setResponseKey] = useState<string>("")
    const [showActivationModal, setShowActivationModal] = useState<boolean>(false)
    const [selectedLicense, setSelectedLicense] = useState<DeploymentDTO|null>(null)
    const [registrationKey, setRegistrationKey] = useState<string>("")

    const values: OptionType[] = [{ value: "type", label: t('license:type') }, { value: "package", label: t('license:licensePackage') }, { value: "licenseTerm", label: t('license:licenseTerm') },
    { value: "activationDate", label: t('license:activationDate') }, { value: "expirationDate", label: t('license:expirationDate') }]
    const licenseOption: OptionType[] = [{ i1: 50, i2: 1, label: t("package:essentials"), value: '' }, { i1: 100, i2: 2, label: t("package:small"), value: '' }, { i1: 250, i2: 4, label: t("package:mid"), value: '' },
    { i1: 400, i2: 256, label: t("package:large"), value: '' }, { i1: 750, i2: 8, label: t("package:enterprise"), value: '' },
    { i1: 750, i2: 512, label: t("package:basic"), value: '' }, { i1: 1024, i2: 1024, label: t("package:standard"), value: '' }]

    const getLicenseHistory = async () => {
        setLoading(true)
        try {
            const response: DeploymentDTO[] | null = await API.get(`/api/v1/Deployment/${id}/history`, auth)
            if (typeof (response?.length) != 'undefined' && response != null) {
                response.map((e: DeploymentDTO) => {
                    e.package = licenseOption.find(x => x.i2 == e.package)?.label!
                    e.licenseTerm = e.licenseTerm.toString() + " " + t('years')
                })
                setDeployHistory(response)
            }
            setLoading(false)
        }
        catch (e: any) {
            alert(e.errors?.Error!)
            setLoading(false)
        }
    }

    const activateLicense = async(data:any) => {
        setResponseLoading(true)
        var object = {
            AccountNumber: data.accountNumber,
            RequestKey:  registrationKey
        }
        try {
            const response: any = await API.post(`api/v1/license/activate/${data.id}`, object, auth)
            setResponseKey(response.responseKey)
            getLicenseHistory()
            setResponseLoading(false)
        }
        catch (e: any) {
            setResponseLoading(false)
            alert(e.errors?.Error!)
        }
    }

    const startActivate = async(data:DeploymentDTO) =>
    {
        console.log(data)
        setResponseKey("")
        setRegistrationKey("")
        setSelectedLicense(data)
        setShowActivationModal(true)
    }

    useEffect(() => {
        if (deployHistory.length == 0)
            getLicenseHistory();
    }, [])
    return (
        <div>
            <div className='card'>
                <div className="card-header">
                    <h2 className='mb-4 header-text'><FontAwesomeIcon icon="address-book" className="fs-3 me-2" />{t('license:licenseHistory')}</h2>
                </div>
                {loading == true && <LoadingPageComponent />}
                {loading == false &&
                    <table className="table  table-sm  fs--1 mb-0 mt-4 table-hover ">
                    <thead className="border-bottom">
                        <tr className='text-center table-header-text'>
                            {values.map((e: OptionType) => (
                                <th className="col-1" key={Math.random()}>
                                    <div>
                                         <h4>{e.label}</h4>
                                    </div> </th>
    
                            ))}
                            <th className="col-1"></th>
                        </tr>
                    </thead>
                    <tbody className="">
                        {deployHistory.map((e: any) => (
                            <tr className="table-text text-center" key={Math.random()}>
                                {values.map((a: OptionType) => (
                                    <td key={Math.random()}>
                                        <h5>{e[a.value!]}</h5>
                                    </td>
                                ))}
                                <td className="text-center">
                                    {e.activationDate == null && <button className="border-0  btn btn-info"  onClick={() => startActivate(e)}  >
                                        <span>{t('activate')}</span>
                                        <FontAwesomeIcon className='ms-2' icon={faPlay} />
                                    </button>}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                }
            </div>
            { showActivationModal && 
                
                <div className={"modal fade show"} style={{ display: "block", background: " rgba(0, 0, 0, 0.6)" }}>
                    <div className="modal-dialog modal-dialog-centered" style={{ maxWidth: "1200px" }}>
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">{`${t("activate")}`}</h5>
                                <button type="button" className="btn-close" onClick={e => setShowActivationModal(false)}></button>
                            </div>
                            <div className="modal-body">
                                <TextInputComponent value={selectedLicense!.accountNumber.toString()} label={t('license:accountNumber')} noEdit />
                                <TextInputComponent mandatory value={registrationKey} label={t('license:registrationKey')} setText={setRegistrationKey}
                                    validator={() => /[A-Za-z0-9]{5}-[A-Za-z0-9]{5}-[A-Za-z0-9]{5}-[A-Za-z0-9]{5}-[A-Za-z0-9]{5}/.test(registrationKey)} validateText={"Invalid Request Key"} upperCase/>
                                {responseLoading == true && <LoadingPageComponent />}
                                {responseKey != "" && <TextInputComponent appendClass="btn btn-outline-secondary" onClick={() => {navigator.clipboard.writeText(responseKey)}} append={"copy"} 
                                    value={responseKey} label={t('license:responseKey')} labelClass={"col-8 col-sm-2 col-form-label  mb-4 ms-3"} inputClass={"col-12 col-sm-5"} noEdit />}
                            </div>
                            <div className="modal-footer" >
                                <button className="btn btn-flickr float-end ms-2" disabled={responseKey != "" || registrationKey == "" || !(/[A-Za-z0-9]{5}-[A-Za-z0-9]{5}-[A-Za-z0-9]{5}-[A-Za-z0-9]{5}-[A-Za-z0-9]{5}/.test(registrationKey))}
                                 onClick={() => activateLicense(selectedLicense)}>{`${t("activate")}`}</button>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}

export default LicenseHistoryComponent