import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { t } from "i18next";
import React, { CSSProperties, useEffect, useState } from "react";
import { useAuth } from "react-oidc-context";
import { Link } from "react-router-dom";
import API from "../../common/API";
import { CompanyFeatures, OptionType, SoftwareDownload } from "../../common/store";
import LoadingPageComponent from "../../components/shared/LoadingPageComponent";
import createBlob from "../../scripts/createBlob";
import download from "../../scripts/download";
import aiphoneLogo from "../../assets/images/productLogos/aiphone.png"
import atomLogo from "../../assets/images/productLogos/atom.png"
import odysseyLogo from "../../assets/images/productLogos/odyssey.png"
import protectorNetLogo from "../../assets/images/productLogos/protectornet.png"
import odysseySoftwarePic from "../../assets/images/softwareImages/odyssey.png"
import protectorNetSoftwarePic from "../../assets/images/softwareImages/protectornet.png"
import hcAtomSoftwarePic from "../../assets/images/softwareImages/hc-atom.png"
import aiphoneAtomSoftwarePic from "../../assets/images/softwareImages/ac-host.png"
import { ModalComponent } from "../../components/shared/ModalComponent";
import useAppSelector from "../../hooks/useAppSelector";
import { getCompanyFeatureGate } from "../../redux/slices/config";

export default function(){
    const auth = useAuth()
    const [showSoftwareArray, setShowSoftwareArray] = useState<boolean[]>([])
    const [productArray, setProductArray] = useState<SoftwareDownload[]>([])
    const [loading, setLoading] = useState<boolean>(true)
    const [showModal, setShowModal] = useState<boolean>(false)
    const path = "../src/assets/installs/"

    useEffect(() => {
        setLoading(true)
        getDownloads()

    }, []) 
    
    const getDownloads = async () => {
        try{
            const response:SoftwareDownload[]|null = await API.get(`/api/v1/productsList`, auth)
            if (response != null)
            {
                const array:boolean[] = []
                response.forEach((e:SoftwareDownload) => {
                    array.push(false)
                })
                setShowSoftwareArray(array)
                setProductArray(response)
                setLoading(false)
            }
        }
        catch(e:any){
            alert(e.errors?.Error!)
        }
    }

    const downloadFile = async (software:string, e:any) => {
        e.stopPropagation()
        try{
            const url:string = `/api/v1/product/${software}?token=${encodeURIComponent(auth.user?.access_token!)}` 
            window.open(url)
        }
        catch(e:any){
            alert(e.errors?.Error!)
        }
    }

    const getLogo = (product:string) => {
        if (product.toLowerCase().includes("odyssey"))
            return odysseyLogo
        else if (product.toLowerCase().includes("protector"))
            return protectorNetLogo
        else if (product.toLowerCase().includes("atom"))
            return atomLogo
        else if (product.toLowerCase().includes("ac nio"))
            return aiphoneLogo
    }

    const getSoftwarePic = (product:string) => {
        if (product.toLowerCase().includes("odyssey") || product.toLowerCase().includes("ac nio"))
            return odysseySoftwarePic
        else if (product.toLowerCase().includes("protector"))
            return protectorNetSoftwarePic
        else if (product.toLowerCase().includes("atom"))
            return hcAtomSoftwarePic
    }

    const getSoftwareInfo = (product:string) => {
        if (product.toLowerCase().includes("atom"))
            return t("softwareInfo:atom")
        else if (product.toLowerCase().includes("2.10"))
            return t("softwareInfo:protectorNet210")
        else if (product.toLowerCase().includes("odyssey") || product.toLowerCase().includes("ac nio"))
            return t("softwareInfo:odyssey")
        else if (product.toLowerCase().includes("protector"))
            return t("softwareInfo:protectorNet")
    }

    return (
        <div className="p-0 container-fluid row">
            <h1 className=" mb-3 d-inline col-12 col-sm-6"><FontAwesomeIcon icon="cloud-arrow-down" className="fs-3 me-2" />{`${t("software")}`}</h1>
            {loading && <LoadingPageComponent/>} 
            {!loading && <div className=" mt-4 mt-sm-2 row  col-12 bg-white">
                {productArray?.map((product:SoftwareDownload) => {
                    return(
                    <div key={product.productName} className="col-12 col-xl-6 col-xxl-4 card shadow-none mb-0 py-2" >
                        <div className="card-header pb-0">
                            <div className="fs-4 col-6 text-black"  >
                                <h4 className="" >{`${product.productName}`}</h4>
                                <h5>{product.version}</h5>
                            </div>
                        </div>
                        <div className="card-body px-1">
                            <div className="mb-3 bg-light border card h-100 ">
                                <div className="p-3 card-body row">
                                    <div className="mb-3">
                                        <img src={getSoftwarePic(product.productName)} className="img-responsive w-100" 
                                        style={{ "margin": "0 auto","borderRadius": "30px" }} />
                                    </div>
                                    <div className="">
                                        <p className="lh-lg">{getSoftwareInfo(product.productName)}</p>
                                    </div>
                                    <div className="align-items-end mt-auto">
                                        <div className="btn rounded-3 btn-primary" onClick={(e) => downloadFile(product.file, e)}>
                                            <h6 className="d-inline" style={{"color": "white"}} >Download</h6>
                                            {/* <FontAwesomeIcon icon="download" className="fs-6" /> */}
                                        </div>
                                        <img className="border-0 me-1 float-end" src={getLogo(product.productName)}></img>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    )
                })}
            </div>}
        </div>
    )
}