import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { buildStyles, CircularProgressbar } from "react-circular-progressbar";
import reactMarkdown from "react-markdown";
import { useAuth } from "react-oidc-context";
import API from "../../common/API";
import { Backup, BackupInfo, Invoice, OdysseyInfo, OptionType } from "../../common/store";
import useAppSelector from "../../hooks/useAppSelector";
import { isAdmin } from "../../redux/slices/config";
import ElavonPayment from "../../views/Payment/ElavonPayment";
import LoadingPageComponent from "../shared/LoadingPageComponent";
import { ModalComponent } from "../shared/ModalComponent";
import PaginationListComponent from "../shared/PaginatedListComponent";
import { PaginationComponent } from "../shared/PaginationComponent";
import RadioComponent from "../shared/RadioComponent";
import TextInputComponent from "../shared/TextInputComponent";

export default function({DeploymentId}:{DeploymentId:number}){

    const auth = useAuth()
    const [backups, setBackups] = useState<Backup[]>()
    const [loading, setLoading] = useState<boolean>(false)
    const [backupLoading, setBackupLoading] = useState<boolean>(false)
    const backupType:OptionType[] = [{label:t("freeBackupTier"), value:"0"}, {label:t("fullBackup"), value:"1"}]
    const [perPage, setPerPage] = useState<number>(15)
    const [pageNumber, setPageNumber] = useState<number>(1)
    const [maxPageNumber, setMaxPageNumber] = useState<number>(0)
    const [pages, setPages] = useState<number[]>([])
    const isSysAdmin = useAppSelector(isAdmin)

    const [disabled, setDisabled] = useState<boolean | null>(false)
    const [showDisableModal, setShowDisableModal] = useState<boolean>(false)

    const [backupInfo, setBackupInfo] = useState<BackupInfo | null>()
    const [backupTime, setbackUpTime] = useState<Date|null>()

    const [backupInvoice, setBackupInvoice] = useState<Invoice|null>()
    const [invoiceTerm, setInvoiceTerm] = useState<string | null>("3")

    const [section, setSection] = useState<string>("status")
    const [prevSection, setPrevSection] = useState<string>("schedule")

    const backupTermOptions:OptionType[] = [{ value: '12', label: t('deployment:yearly') },{ value: '3', label: t('deployment:quarterly') }]
    const [backupTerm, setBackupTerm] = useState<string | null>()

    const [pendingBackupTerm, setPendingBackupTerm] = useState<string | null>()
    const [showScheduleModal, setShowScheduleModal] = useState<boolean>(false)
    const [showPurgeModal, setShowPurgeModal] = useState<boolean>(false)
    const values: OptionType[] = [{ value: "name", label: t("name") }, { value: "timeStamp", label: t("invoice:occurredOn") },
    { value: "size", label: t("size") }]
    const values_sm: OptionType[] = [{ value: "name", label: t("name") }]

    const [addOnAmount, setAddOnAmount] = useState<number>(1)

    useEffect(() => {
        getBackups()
    },[pageNumber])

    useEffect(() => {
        getBackupInfo()
    },[])

    const postDelete = async () => {
        getBackupInfo()
        getBackups()
    }
    const getBackupInfo = async () => {
        try{
            setLoading(true)
            const response:BackupInfo | null = await API.get(`/api/v1/Backup/backupInfo/${DeploymentId}`, auth)
            setbackUpTime(response?.lastBackedUpOn)
            setBackupInfo(response)
            setDisabled(response?.disabled!)
            setBackupTerm(response?.schedule?.toString())
            setPendingBackupTerm(response?.schedule?.toString())
            setLoading(false)
            
        }
        catch(e:any){
            alert(e.errors?.Error!)
            setBackupLoading(false)
        }
    }
    const getBackups = async () => {
        try{
            setBackupLoading(true)
            const response:any = await API.get(`/api/v1/backups/${DeploymentId}?PerPage=${perPage}&PageNumber=${pageNumber}`, auth)
            if (response != null)
                {
                    if (response.results == null)
                        response.results = []

                    setMaxPageNumber(Math.ceil(response.totalRecords/ perPage))
                    const pageArray:number[] = []
                    for (let index = 1; index <= Math.ceil(response.totalRecords/ perPage); index++){
                    pageArray.push(index)
                    }
                    setPages(pageArray)

                    response.results.map((e:Backup) => {
                        e.timeStamp = e.timeStamp == null ? "" : new Date(e?.timeStamp!).toLocaleDateString() + " " + new Date(e?.timeStamp!).toLocaleTimeString()
                      })

                    setBackups(response.results)
                    setBackupLoading(false)
                }
        }
        catch(e:any){
            alert(e.errors?.Error!)
            setBackupLoading(false)
        }
    }
    const scheduleBackup = async () => {
        setLoading(true)
        try{
            const response= await API.post(`api/v1/odyssey/scheduleBackup/${backupInfo?.deploymentId}`, {}, auth)
            await getBackupInfo()
            getBackups()
            setLoading(false)
        }
        catch (e: any){ 
            alert(e.errors?.Error!)
            setLoading(false)
        }
    }
    const createBackupInvoice = async() => {
        setLoading(true)
        try{
            const response:Invoice|null  = await API.post(`api/v1/payment/backupInvoice/deployment/${backupInfo?.deploymentId}/${Number(invoiceTerm)}`, {}, auth)
            setBackupInvoice(response)
            setLoading(false)
            setPrevSection("schedule")
            setSection("invoice")
        }
        catch(e: any ){
            setLoading(false)
            alert(e.errors?.Error!)
        }
    }
    const createBackupAddOnInvoice = async() => {
        setLoading(true)
        try{
            const response:Invoice|null  = await API.post(`api/v1/payment/backupAddOnInvoice/deployment/${backupInfo?.deploymentId}/${addOnAmount}`, {}, auth)
            setBackupInvoice(response)
            setLoading(false)
            setPrevSection("addOn")
            setSection("invoice")
        }
        catch(e: any ){
            setLoading(false)
            alert(e.errors?.Error!)
        }
    }

    const removeAddOnSize = async() => {
        setLoading(true)
        try{
            const response:Invoice|null  = await API.put(`api/v1/backup/removeAddOnSize/${backupInfo?.deploymentId}/${addOnAmount}`, {}, auth)
            await getBackupInfo()
            setLoading(false)
            setSection("status")
        }
        catch(e: any ){
            setLoading(false)
            alert(e.errors?.Error!)
        }
    }

    const undoRemoveAddOnSize = async() => {
        setLoading(true)
        try{
            const response:Invoice|null  = await API.put(`api/v1/backup/undoRemoveAddOnSize/${backupInfo?.deploymentId}`, {}, auth)
            await getBackupInfo()
            setLoading(false)
            setSection("status")
        }
        catch(e: any ){
            setLoading(false)
            alert(e.errors?.Error!)
        }
    }

    const postFunction = async() => {
        await getBackupInfo()
        setSection("status")
    }
    const downgradeBackup = async() => {
        setLoading(true)
        try{
            const response= await API.put(`api/v1/backup/downgrade/${backupInfo?.deploymentId}`, {}, auth)
            await getBackupInfo()
            setLoading(false)
        }
        catch (e: any){ 
            alert(e.errors?.Error!)
            setLoading(false)
        }
    }
    const undoDowngrade = async() => {
        setLoading(true)
        try{
            const response= await API.put(`api/v1/backup/undodowngrade/${backupInfo?.deploymentId}`, {}, auth)
            await getBackupInfo()
            setLoading(false)
        }
        catch (e: any){ 
            alert(e.errors?.Error!)
            setLoading(false)
        }
    }

    const purgeBackups = async() => {
        setShowPurgeModal(false)
        setLoading(true)
        try{
            const response= await API.delete(`api/v1/backup/purge/${backupInfo?.deploymentId}`, {}, auth)
            await getBackups()
            await getBackupInfo()
            setLoading(false)
        }
        catch (e: any){ 
            alert(e.errors?.Error!)
            setLoading(false)
        }
    }

    const disableBackup = async() => {
        setShowDisableModal(false)
        try{
            const response= await API.put(`api/v1/backup/disableToggle/${backupInfo?.deploymentId}`, {}, auth)
            setDisabled(!disabled)
            await getBackupInfo()
            setLoading(false)
        }
        catch (e: any){ 
            alert(e.errors?.Error!)
            setLoading(false)
        }
    }

    const updateSchedule = async() => {
        setDisabled(!disabled)
        setShowDisableModal(false)
        try{
            const response= await API.put(`api/v1/backup/updateSchedule/${backupInfo?.deploymentId}/${Number(pendingBackupTerm)}`, {}, auth)
            await getBackupInfo()
            setSection("status")
            setShowScheduleModal(false)
            setLoading(false)
        }
        catch (e: any){ 
            alert(e.errors?.Error!)
            setLoading(false)
        }
    }

    return(
        <div>
            <ModalComponent actionFunction={disableBackup} modalTitle={`${ disabled == true ? t("enable"): t("disable")} ${t("backup")}`} 
                    modalText={`${ disabled == true ? t("enable"): t("disable")} ${t("backup")}`} 
                    buttonText={ disabled == true ? t("enable"): t("disable")} showModal={showDisableModal} setShowModal={setShowDisableModal}/>
            <ModalComponent actionFunction={updateSchedule} modalTitle={`${t("deployment:updateSchedule")}`} modalText={`${t("deployment:updateScheduleMessage")}`} 
                    buttonText={t("deployment:updateSchedule")} showModal={showScheduleModal} setShowModal={setShowScheduleModal}/>
            <ModalComponent actionFunction={purgeBackups} modalTitle={`${t("purgeBackups")}`} modalText={`${t("purgeBackupsMessage")}`} 
                    buttonText={t("purgeBackups")} showModal={showPurgeModal} setShowModal={setShowPurgeModal}/>
            {!loading && section == "status" && <div>
                {<div className="col-sm-12 ">
                    <div className="card p-0 col-sm-12 h-100">
                        <div className=" card-header pb-0 mb-2">
                            <h2 className='mb-4 header-text d-inline'><FontAwesomeIcon icon="floppy-disk" className="fs-3 me-2" />{`${t('backup')}`}</h2>
                            { backupInfo?.backupType == 0 && <button className="float-end me-3 btn btn-bitbucket d-inline" onClick={() => setSection("schedule")} >{`${t('upgradeBackupTier')}`}</button>}
                            { backupInfo?.backupType == 1 && backupInfo?.pendingBackupType == null && 
                            <button className="float-end me-3 btn btn-pinterest d-inline" onClick={() => downgradeBackup()} >{`${t('downgradeBackup')}`}</button>}
                            { backupInfo?.pendingBackupType != null  && backupInfo?.pendingBackupType == 0  && 
                            <button className="float-end me-3 btn btn-warning d-inline" onClick={() => undoDowngrade()} >{`${t('undoDowngrade')}`}</button>}
                            { backupTerm != null && <button className="float-end me-3 btn btn-info d-inline mt-1 mt-sm-0" onClick={() => setSection("changeSchedule")} >{`${t('deployment:updateSchedule')}`}</button>}
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-12 col-sm-8 mt-3">
                                    <TextInputComponent labelClass={"col-4 col-sm-5 col-form-label text-center mb-4 ms-3"} inputClass={"col-6 col-sm-5 pt-1"} label={t("backupTier")} 
                                        value={ backupType.find((e) => e.value == backupInfo?.backupType?.toString()!)?.label!} noEdit/>
                                    {backupInfo?.pendingBackupType  != null && backupInfo?.pendingBackupType == 0 && 
                                        <TextInputComponent labelColor={"text-warning"} inputColor={"text-warning"} labelClass={"col-4 col-sm-5 col-form-label text-center mb-4 ms-3"} inputClass={"col-6 col-sm-5 pt-1"} 
                                        label={t("pendingBackupTier")} value={ backupType.find((e) => e.value == backupInfo?.pendingBackupType?.toString()!)?.label!} noEdit/>}
                                    <TextInputComponent labelClass={"col-4 col-sm-5 col-form-label text-center mb-4 ms-3"} inputClass={"col-7 col-sm-5 pt-1"} label={t("latestBackup")} 
                                        value={backupTime == null? t("noBackup") :new Date(backupTime!.toString()!).toLocaleString()!} noEdit/>
                                    {backupInfo?.nextPayment != null && <TextInputComponent labelClass={"col-4 col-sm-5 col-form-label text-center mb-4 ms-3"} inputClass={"col-7 col-sm-5 pt-1"} label={t("deployment:renewalDate")} 
                                        value={new Date(backupInfo?.nextPayment!.toString()!).toLocaleDateString()!} noEdit/>}
                                    {/* <TextInputComponent labelClass={"col-4 col-sm-5 col-form-label text-center mb-4 ms-3"} inputClass={"col-6 col-sm-5 pt-1"} label={t("currentlyBackingUp")} value={backupInfo?.currentlyBackingUp ? t("true") : t("false")} noEdit/>   */}
                                    {disabled != null && <div className="form-group row">
                                        <label className="col-4 col-sm-5 col-form-label text-center mb-4 ms-3" ><h3>{`${t("deployment:disabled")}`}</h3></label>
                                        <div className="col-7 col-sm-5 pt-1 form-check form-switch">
                                            <input className="align-bottom form-check-input mt-2 " style={{marginLeft:"-0.8em"}} type="checkbox" id="flexSwitchCheckDefault" checked={disabled!} onChange={() => true} onClick={() => setShowDisableModal(true) }/>
                                        </div>
                                    </div>}
                                    { backupTerm != null && <TextInputComponent  labelClass={"col-4 col-sm-5 col-form-label text-center mb-4 ms-3"} inputClass={"col-6 col-sm-5"} 
                                    label={t("deployment:schedule")} value={`${backupTerm == "3" ? "Quarterly" : "Yearly"}`} noEdit/>}
                                    { backupInfo?.pendingSchedule != null && <TextInputComponent labelColor={"text-warning"} inputColor={"text-warning"}  labelClass={"col-4 col-sm-5 col-form-label text-center mb-4 ms-3"} inputClass={"col-6 col-sm-5"} 
                                    label={t("deployment:pendingSchedule")} value={`${backupInfo?.pendingSchedule.toString() == "3" ? "Quarterly" : "Yearly"}`} noEdit/>}
                                    {backupInfo?.addOnSize != null && <TextInputComponent  labelClass={"col-4 col-sm-5 col-form-label text-center mb-4 ms-3"} inputClass={"col-6 col-sm-5"} 
                                    label={t("addOnSize")} value={`${backupInfo.addOnSize} GB`} noEdit/>}
                                    { backupInfo?.pendingAddOnSize != null && <TextInputComponent labelColor={"text-warning"} inputColor={"text-warning"}  labelClass={"col-4 col-sm-5 col-form-label text-center mb-4 ms-3"} inputClass={"col-6 col-sm-5"} 
                                    label={t("pendingAddOnSize")} value={`${backupInfo?.pendingAddOnSize.toString()} GB`} noEdit/>}
                                </div>
                                <div className="col-12 col-sm-4  d-flex align-items-center">
                                    <div className={`${backupTerm != null ?"w-75":"w-50"} ms-auto me-auto ms-sm-0`}>
                                        <CircularProgressbar styles={buildStyles({trailColor: '#d6d6d6', pathColor:"", textColor:"black", textSize:"12px"})} 
                                        value={backupInfo?.currentBackupsSize! == 0 ? backupInfo?.currentBackupsSizeString == "0 B"? 0:backupInfo?.backupLimit! * 0.001 
                                        :backupInfo?.currentBackupsSize! } maxValue={backupInfo?.backupLimit!} text={backupInfo?.currentBackupsSizeString} />
                                        <div className="text-center mt-2">
                                            <span className="text-center h4 ">
                                                {backupInfo?.addOnSize != null && <FontAwesomeIcon className="text-primary fs-3 me-2 " icon="plus" type="button"  onClick={() => {setSection("addOn"); setAddOnAmount(1)}} />}
                                                {`${t("sizeLimit")}: ${backupInfo?.backupLimit!} GB`}
                                                {backupInfo?.addOnSize != null && backupInfo?.addOnSize != 0 && <FontAwesomeIcon className="text-primary fs-3 ms-2 " icon="minus" type="button"  onClick={() => {setSection("removeAddOn"); setAddOnAmount(1)}} />}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <button className="float-end me-3 btn btn-pinterest d-inline" onClick={() => backup()} >{`${t('backup')}`}</button> */}
                            <button className="float-end me-3 btn btn-dribbble d-inline mt-3" onClick={() => scheduleBackup()} >{`${t('Run Backup')}`}</button>
                            {backupInfo?.pendingAddOnSize != null && <button className="float-end me-3 btn btn-facebook d-inline mt-3" 
                                onClick={() => undoRemoveAddOnSize()} >{`${t('undoRemoveAddOnSize')}`}</button>}
                        </div>
                    </div>
                </div>}
                <div className="card">
                    <div className="card-header">
                        <h2 className='mb-4 header-text d-inline '><FontAwesomeIcon icon="wallet" className="fs-3 me-2" />{`${t("backups")}`}</h2>
                        {backups != null && backups.length != 0 && isSysAdmin && <button className="float-end me-3 btn btn-pinterest d-inline" onClick={() => setShowPurgeModal(true)} >{`${t('purgeBackups')}`}</button>}
                    </div>
                    {backups != null && !backupLoading && 
                    <div>
                        <div className="card-body py-0 d-none d-sm-block">
                            <PaginationListComponent list={backups!} headerValues={values} isEditable={false} isRemovable={isSysAdmin} deleteString={"/api/v1/backup/"} repopulate={postDelete}/>
                            <PaginationComponent pageNumber={pageNumber} pages={pages} maxPageNumber={maxPageNumber} setPageNumber={setPageNumber} />
                        </div>
                        <div className="card-body py-0 d-block d-sm-none">
                            <PaginationListComponent list={backups!} headerValues={values_sm} isEditable={false} isRemovable={isSysAdmin} deleteString={"/api/v1/backup/"} repopulate={postDelete}/>
                            <PaginationComponent pageNumber={pageNumber} pages={pages} maxPageNumber={maxPageNumber} setPageNumber={setPageNumber} />
                        </div>
                    </div>
                    
                    }
                    {backupLoading && <LoadingPageComponent />}
                </div>
            </div>}
            {loading && <LoadingPageComponent />}
            {!loading && section == "invoice" && backupInvoice != null &&
                <div className=" mb-4 ms-auto me-auto card shadow border-2" >
                    <div className=" card-header border-0 ">
                        <h2 className=' header-text'>
                            <FontAwesomeIcon icon="credit-card" className="fs-3 me-2" />
                            <span>{`${t('payment')}`}</span>
                        </h2>
                    </div>
                    <div className="card-body bg-opacity-75 ">
                        <ElavonPayment invoice={backupInvoice} postFunction={postFunction} />
                        <button className="float-start me-3 btn btn-github d-inline" onClick={() => setSection(prevSection)} >{`${t('previous')}`}</button>
                    </div>
                </div>
            }
            {
                !loading && section == "schedule" && 
                <div className=" mb-4 ms-auto me-auto card shadow border-2" >
                    <div className=" card-header border-0 ">
                        <h2 className=' header-text'>
                            <FontAwesomeIcon icon="clock" className="fs-3 me-2" />
                            <span>{`${t('deployment:schedule')}`}</span>
                        </h2>
                    </div>
                    <div className="card-body bg-opacity-75 ">
                        <RadioComponent labelClass={"col-4 col-sm-5 col-form-label text-center mb-4 ms-3"} label={t('deployment:schedule')} 
                                    options={backupTermOptions} currentVal={invoiceTerm!} setCurrentVal={setInvoiceTerm} radioClass={"col-7 col-sm-5 pt-1"} />

                        <button className="float-start me-3 btn btn-github d-inline" onClick={() => setSection("status")} >{`${t('previous')}`}</button>
                        <button className="float-end me-3 btn btn-dribbble d-inline" onClick={() => createBackupInvoice()} >{`${t('invoice:createInvoice')}`}</button>
                    </div>
                </div>
            }
            {
                section == "changeSchedule" &&
                <div>
                    {backupTerm != null && <div className="card">
                        <div className=" card-header">
                            <h2 className='mb-4 header-text d-inline '><FontAwesomeIcon icon="clock" className="fs-3 me-2" />{`${t("deployment:updateSchedule")}`}</h2>
                        </div> 
                        <div className="card-body bg-white">
                            <div className="col-12 col-sm-8 d-block d-sm-none">
                                <RadioComponent labelClass={"col-12 col-sm-5 col-form-label text-center mb-4 ms-0 ms-sm-3"} label={t('deployment:schedule')} 
                                options={backupTermOptions} currentVal={pendingBackupTerm!} setCurrentVal={setPendingBackupTerm} radioClass={"col-12 col-sm-5 text-center"} />
                            </div>
                            <div className="col-12 col-sm-8 d-none d-sm-block">
                                <RadioComponent labelClass={"col-12 col-sm-5 col-form-label text-center mb-4 ms-0 ms-sm-3"} label={t('deployment:schedule')} 
                                options={backupTermOptions} currentVal={pendingBackupTerm!} setCurrentVal={setPendingBackupTerm} radioClass={"col-12 col-sm-5"} />
                            </div>
                        </div>
                        <div className="card-footer pt-0 ">
                            <button className="float-start me-3 btn btn-github d-inline" onClick={() => setSection("status")} >{`${t('previous')}`}</button>
                            <div className="mb-1 pe-3 float-end ">
                                <button className="btn float-end btn-dribbble add-btn-text " onClick={() => setShowScheduleModal(true)} >
                                    <span >{`${t('deployment:updateSchedule')}`}</span>
                                </button>
                            </div>
                        </div>
                    </div>}
                </div>
            }
            {
                !loading && section == "addOn" && 
                <div className="card col-sm-6 col-12 ">
                    <div className=" card-header " >
                        <h3 className='mb-4 header-text d-inline '><FontAwesomeIcon icon="wrench" className="fs-3 me-2" />{`${t('addOnSize')}`}</h3>
                    </div>
                    <div className="card-body bg-white">
                        <div className="form-group row">
                            <label className="col-4 col-sm-5 col-form-label text-center mb-4 ms-3" >
                                <h3 className="fs-5">{`${t('invoice:quantity')}`}</h3>
                            </label>
                            <div className="col-6 col-sm-5">
                                <div className="input-group ">
                                    <input  className="align-bottom form-control  " min={1} max={10} type="number" id="flexSwitchCheckDefault" value={addOnAmount}  
                                        onChange={(e) => setAddOnAmount(Number(e.target.value))}/>
                                    <div className="input-group-append border-0 ">
                                        <span className=" bg-transparent form-control input-text">
                                            <h4 className="fs-5" >GB</h4>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group mt-2" style={{ display: (addOnAmount + backupInfo?.addOnSize!) > 10 ? 'block' : 'none' }} >
                                <p className="text-danger text-center">{`${t('maxAddOnSize')}`}</p>
                            </div>
                        </div>
                    </div>
                    <div className="card-footer pt-0 ">
                        <button className="float-start me-3 btn btn-github d-inline" onClick={() => setSection("status")} >{`${t('previous')}`}</button>
                        <div className="mb-1 pe-3 float-end ">
                            <button className="btn float-end btn-pinterest add-btn-text" disabled={addOnAmount + backupInfo?.addOnSize! > 10} onClick={() => createBackupAddOnInvoice()}  >
                                <span >{`${t('invoice:createInvoice')}` }</span>
                            </button>
                        </div>
                    </div>
                </div>
            }
            {
                !loading && section == "removeAddOn" && 
                <div className="card col-sm-6 col-12 ">
                    <div className=" card-header " >
                        <h3 className='mb-4 header-text d-inline '><FontAwesomeIcon icon="wrench" className="fs-3 me-2" />{`${ t('delete')} ${t('addOnSize')}`}</h3>
                    </div>
                    <div className="card-body bg-white">
                        <div className="form-group row">
                            <label className="col-4 col-sm-5 col-form-label text-center mb-4 ms-3" >
                                <h3 className="fs-5">{`${t('invoice:quantity')}`}</h3>
                            </label>
                            <div className="col-6 col-sm-5">
                                <div className="input-group ">
                                    <input  className="align-bottom form-control  " min={1} max={10} type="number" id="flexSwitchCheckDefault" value={addOnAmount}  
                                        onChange={(e) => setAddOnAmount(Number(e.target.value))}/>
                                    <div className="input-group-append border-0 ">
                                        <span className=" bg-transparent form-control input-text">
                                            <h4 className="fs-5" >GB</h4>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group mt-2" style={{ display: (addOnAmount ) > backupInfo?.addOnSize! ? 'block' : 'none' }} >
                                <p className="text-danger text-center">{`${t('removeAddOnSpaceError')}`}</p>
                            </div>
                        </div>
                    </div>
                    <div className="card-footer pt-0 ">
                        <button className="float-start me-3 btn btn-github d-inline" onClick={() => setSection("status")} >{`${t('previous')}`}</button>
                        <div className="mb-1 pe-3 float-end ">
                            <button className="btn float-end btn-pinterest add-btn-text" disabled={addOnAmount > backupInfo?.addOnSize!} onClick={() => removeAddOnSize()}  >
                                <span >{`${ t('delete')} ${t('addOnSize')}` }</span>
                            </button>
                        </div>
                    </div>
                </div>
            }

        </div>
        
    )
}