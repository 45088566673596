import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useTranslation } from "react-i18next";

export default function InvalidPage() {
    const { t } = useTranslation();
    return(
        <div className="w-75 ms-auto me-auto card" style={{marginTop:'20%'}}>
            <div className="card-header align-middle">
                <h1 className="text-center">
                    {t('404Error')}
                    <FontAwesomeIcon icon="cog"  className="fa-spin ms-4"/>
                </h1>

            </div>
        </div>
    )
}