
export const EndUserLicenseAgreement: string = `Software End User License Agreement

This End User License Agreement, including the Order Form which by this reference is incorporated herein (this “Agreement”), is a binding agreement between HARTMANN CONTROLS CORP. (”Licensor”) and the person or entity identified on the Order Form as the licensee of the Software (”Licensee”).
LICENSOR PROVIDES THE SOFTWARE SOLELY ON THE TERMS AND CONDITIONS SET FORTH IN THIS AGREEMENT AND ON THE CONDITION THAT LICENSEE ACCEPTS AND COMPLIES WITH THEM. BY CHECKING THE CHECKBOX AND CLICKING THE “REGISTER” BUTTON BELOW YOU (A) ACCEPT THIS AGREEMENT AND AGREE THAT LICENSEE IS LEGALLY BOUND BY ITS TERMS; AND (B) REPRESENT AND WARRANT THAT: (I) YOU ARE AT LEAST 18 YEARS OF AGE OR OLDER AND HAVE CAPACITY TO ENTER INTO THIS AGREEMENT; AND (II) IF THE LICENSEE IS A CORPORATION, GOVERNMENTAL ORGANIZATION OR OTHER LEGAL ENTITY, YOU HAVE THE RIGHT, POWER AND AUTHORITY TO ENTER INTO THIS AGREEMENT ON BEHALF OF LICENSEE AND BIND LICENSEE TO ITS TERMS. IF LICENSEE DOES NOT AGREE TO THE TERMS OF THIS AGREEMENT, LICENSOR WILL NOT AND DOES NOT LICENSE THE SOFTWARE TO LICENSEE AND IT MUST NOT BE UTILIZED BY THE LICENSEE.
NOTWITHSTANDING ANYTHING TO THE CONTRARY IN THIS AGREEMENT OR YOUR OR LICENSEE’S ACCEPTANCE OF THE TERMS AND CONDITIONS OF THIS AGREEMENT, NO LICENSE IS GRANTED (WHETHER EXPRESSLY, BY IMPLICATION OR OTHERWISE) UNDER THIS AGREEMENT, AND THIS AGREEMENT EXPRESSLY EXCLUDES ANY RIGHT, CONCERNING ANY SOFTWARE THAT LICENSEE DID NOT ACQUIRE LAWFULLY OR THAT IS NOT A LEGITIMATE, AUTHORIZED COPY OF LICENSOR’S SOFTWARE.

1.  Definitions. For purposes of this Agreement, the following terms have the following meanings:
 
(a) ”Authorized Users” means the following individual persons authorized to use the Software pursuant to the license granted under this Agreement: solely those individuals authorized to use the Software pursuant to the license granted under this Agreement, as set forth on the Order Form.
(b) ”Documentation” means user manuals, technical manuals and any other materials provided by Licensor, in printed, electronic or other form, that describe the installation, operation, use or technical specifications of the Software.
(c) ”Intellectual Property Rights” means any and all registered and unregistered rights granted, applied for or otherwise now or hereafter in existence under or related to any patent, copyright, trade-mark, trade secret, database protection or other intellectual property rights laws, and all similar or equivalent rights or forms of protection, in any part of the world.
(d) ”Licensee” has the meaning set forth in the preamble.
(e) ”License Fees” means the down payment and ongoing license fees, including all taxes thereon, required to be paid by Licensee for the license granted under this Agreement and payable pursuant to the Order Form.
(f) ”Licensor” has the meaning set forth in the preamble.
(g) ”Order Form” means the order form filled out and submitted by or on behalf of Licensee, and accepted by Licensor, for Licensee’s purchase of the license for the Software granted under this Agreement.
(h) ”Person” means an individual, corporation, partnership, joint venture, governmental authority, unincorporated organization, trust, association or other entity.
(i) ”Software” means the software programs for which Licensee is purchasing a license, as expressly set forth in the Order Form.
(j) ”Term” has the meaning set forth in Section 11.
(k) ”Third Party” means any Person other than Licensee or Licensor.
(l) ”Update” has the meaning set forth in Section 7.2.
 
2.  License Grant and Scope. Subject to and conditional on the Licensee’s ongoing payment of the License Fees and Licensee’s strict compliance with all terms and conditions set forth in this Agreement, Licensor hereby grants to Licensee a non-exclusive, non-transferable, non-sublicensable, limited license during the Term to access and use, solely by and through its Authorized Users, the Software and Documentation, solely as set forth in this Section 2 and subject to all conditions and limitations set forth in Section 4 or elsewhere in this Agreement. This license grants Licensee the right, exercisable solely by and through Licensee’s Authorized Users, to:
2.1 Access and make use of, in accordance with the Documentation, the Software. The Licensee shall not attempt to download, copy or discover the source code of the Software. 
 
2.2  Use the Software in accordance with this Agreement and the Documentation, solely as set forth in the Documentation and solely for Licensee’s internal business purposes. Such use is permitted from any computer or device by which the Licensee can access the Software.
 
 
3.  Third-Party Materials. The Software includes software, content, data or other materials, including related documentation, that are owned by Persons other than Licensor and that are provided to Licensee on licensee terms that are in addition to and/or different from those contained in this Agreement (”Third-Party Licenses”). Third-party Licenses may be in effect for all Third Party Licensed materials used in the Software, and the Licensee hereby acknowledges and agrees that it is bound by and shall comply with all Third-party Licenses. Any breach by Licensee or any of its Authorized Users of any Third-party License is also a breach of this Agreement. The Licensee and Licensor acknowledge and agree that provided the Licensee abides by the provisions of this Agreement, it shall be deemed to have complied with the Third-Party License terms for the purposes of this Agreement only.
 
4.  Use Restrictions.
4.1  Licensee shall not, and shall require its Authorized Users not to, directly or indirectly:

(a)  use the Software or Documentation beyond the scope of the license granted under Section 2;
 
(b)  provide any other Person, including any subcontractor, independent contractor, affiliate, or service provider of Licensee, with access to or use of the Software or Documentation;
 
(c)  modify, translate, adapt or otherwise create derivative works or improvements, whether or not patentable, of the Software or Documentation or any part thereof;
 
(d)  reverse engineer, disassemble, decompile, decode or otherwise attempt to derive or gain access to the source code of the Software or any part thereof;
 
(e)  remove, delete, alter or obscure any trade-marks or any copyright, trade-mark, patent or other intellectual property or proprietary rights notices provided on or with the Software or Documentation, including any copy thereof;
 
(f)  except as expressly set forth in Section 2.1 and Section 2.3, copy the Software or Documentation, in whole or in part;
 
(h)  rent, lease, lend, sell, sublicense, assign, distribute, publish, transfer or otherwise make available the Software, or any features or functionality of the Software, to any Third Party for any reason, whether or not over a network or on a hosted basis, including in connection with the internet or any web hosting, wide area network (WAN), virtual private network (VPN), virtualization, time-sharing, service bureau, software as a service (SaaS), cloud or other technology or service;
 
(i)  use the Software or Documentation in, or in association with, the design, construction, maintenance or operation of any hazardous environments or systems, including:
 
(i)  power generation systems;
 
(ii)  aircraft navigation or communication systems, air traffic control systems or any other transport management systems;
 
(iii)  safety-critical applications, including medical or life-support systems, vehicle operation applications or any police, fire or other safety response systems; and
 
(iv)  military or aerospace applications, weapons systems or environments;
 
(j)  use the Software in any way which places undue stress on the entity hosting the software or the data of the Licensee; 

(k) use the Software in any which places undue stress on the computing power of the entity hosting the Software and data of the Licensee;

(k) use the Software or Documentation in violation of any law, regulation or rule; or
 
(k)  use the Software or Documentation for purposes of competitive analysis of the Software, the development of a competing software product or service or any other purpose that is to the Licensor’s commercial disadvantage.
 
5.  Responsibility for Use of Software. Licensee is responsible and liable for all uses of the Software and Documentation through access thereto provided by Licensee, directly or indirectly. Specifically, and without limiting the generality of the foregoing, Licensee is responsible and liable for all actions and failures to take required actions with respect to the Software and Documentation by its Authorized Users or by any other Person to whom Licensee or an Authorized User may provide access to or use of the Software or Documentation, whether such access or use is permitted by or in violation of this Agreement.
 
6.  Compliance Measures.
6.1  The Software may contain technological copy protection or other security features designed to prevent unauthorized use of the Software, including features to protect against any use of the Software that is prohibited under Section 4. Licensee shall not, and shall not attempt to, remove, disable, circumvent or otherwise create or implement any workaround to, any such copy protection or security features.

6.2  On a written request from the Licensor, Licensee shall conduct a review of its and the use of the Software by its Authorized Users and certify to Licensor in a written instrument signed by an officer of Licensee that it is in full compliance with this Agreement or, if Licensee discovers any non-compliance:
 
(a)  Licensee shall immediately remedy such non-compliance and provide Licensor with written notice thereof. Licensee shall provide Licensor with all access and assistance as Licensor requests to further evaluate and remedy such non-compliance.
 
(b)  If Licensee’s use of the Software exceeds what is considered a fair and reasonable use of the Licensor’s provided hardware capacity, Licensor shall have the remedies set forth in Section 6.4.
 
6.3  During the Term, Licensor may, in Licensor’s sole discretion, audit Licensee’s use of the Software to ensure Licensee’s compliance with this Agreement.   

6.4  If an audit/any of the measures taken or implemented under this Section 6 determines that the Licensee’s use of the Software exceeds or exceeded the use permitted by this Agreement, then:
 

(a)  Licensee shall, within ten (10) business days following the date of the Licensor’s written notification thereof, pay to Licensor the retroactive License Fees for excess use and, unless Licensor terminates this Agreement under Section 6.4(c), ensure its use throughout the remainder of the Term is in compliance with this Agreement.  

(b)  If the use of the Licensee of the Software or its use of the Computing Power of the Licensor exceeds or exceeded the use permitted by this Agreement, Licensor reserves the right to limit the Licensee’s access to the Software pending the Licensee taking all such reasonable steps required to amend its use of the Software or the Licensor’s computing power.
 
(c)  If the Licensee’s use of the Software or the Licensor’s Computing power exceeds or exceeded the expected use of the Software, the Licensor shall also have the right to terminate this Agreement and the license granted hereunder, effective immediately upon written notice to Licensee.
 
    Licensor’s remedies set forth in this Section 6.4 are cumulative and are in addition to, and not in lieu of, all other remedies the Licensor may have at law or in equity, whether under this Agreement or otherwise.
 
7.  Maintenance and Support.
7.1  Subject to Section 7.3, the license granted hereunder entitles Licensee to the access the Software; the Licensor shall provide such maintenance and support services as may be reasonably required from time to time throughout the Term. Any requests to amend the Software for the specific use of the Licensee shall be considered, but shall not be an obligation of the Licensor.
 
7.2  Maintenance and support services will include provision of such updates, upgrades, bug fixes, patches and other error corrections (collectively, “Updates”) as Licensor makes generally available free of charge to all licensees of the Software then entitled to maintenance and support services. Licensor may develop and provide Updates in its sole discretion, and Licensee agrees that Licensor has no obligation to develop any Updates at all or for particular issues. Licensee further agrees that all Updates will be deemed Software, and related documentation will be deemed Documentation, all subject to all terms and conditions of this Agreement. Maintenance and support services do not include any new version or new release of the Software that Licensor may issue as a separate or new product, and Licensor may determine whether any issuance qualifies as a new version, new release or Update in its sole discretion. The Licensor assume no liability or responsibility for any loss of data that may occur during an Update. The Licensee accepts full responsibility for ensuring they have back-up copies of any data uploaded into the Software and expressly releases the Licensor from any claim or damage caused by loss of the Licensee’s data which may occur as a result of the Licensee’s use of the Software.
 
8.  Collection and Use of Information.
8.1  Licensee acknowledges that Licensor may, directly or indirectly through the services of Third Parties, collect and store information regarding use of the Software and about equipment on which the Software is installed or through which it otherwise is accessed and used, through:

(a) the provision of maintenance and support services; and
(b) security measures included in the Software as described in Section 6.

8.2  Licensee agrees that the Licensor may use such information for any purpose related to any use of the Software by Licensee or on Licensee’s equipment, including but not limited to:
 
(a) improving the performance of the Software or developing Updates; and
(b) verifying Licensee’s compliance with the terms of this Agreement and enforcing the Licensor’s rights, including all Intellectual Property Rights in and to the Software.
 
9.  Intellectual Property Rights. Licensee acknowledges and agrees that the Software and Documentation are provided under license, and not sold, to Licensee. Licensee does not acquire any ownership interest in the Software or Documentation under this Agreement, or any other rights thereto, other than to use the same in accordance with the license granted and subject to all terms, conditions and restrictions under this Agreement. Licensor and its licensors and service providers reserve and shall retain their entire right, title and interest in and to the Software and all Intellectual Property Rights arising out of or relating to the Software, except as expressly granted to the Licensee in this Agreement
 
10.  PAYMENT. All License Fees and Support Fees are payable in advance in the manner set forth in the Order Form and are non-refundable. Any renewal of the license or maintenance and support services hereunder shall not be effective until the fees for such renewal have been paid in full.
 
11.  Term and Termination.
11.1  This Agreement and the license granted hereunder shall remain in effect for the term set forth on the Order Form or until terminated as set forth herein (the “Term”).

11.2  Licensee may terminate this Agreement by providing written notice to the Licensor, which termination shall be effective at the time when the Licensor disables the Licensee’s ability to access the Software.
 
11.3  Licensor may terminate this Agreement, effective upon written notice to Licensee, if Licensee breaches this Agreement and such breach: (i) is incapable of cure; or (ii) being capable of cure, remains uncured for five (5) business days after Licensor provides written notice thereof or if the Licensee’s use of the Software results in undue onus on the servers or hosting computers of the Licensor.
 
11.4  Licensor may terminate this Agreement, effective immediately, if Licensee files an assignment in bankruptcy or has a bankruptcy order made against it under any bankruptcy or insolvency law, makes or seeks to make a general assignment for the benefit of its creditors or applies for, or consents to, the appointment of a trustee, receiver, receiver-manager, monitor or custodian for all or a substantial part of its property.
 
11.5  Upon expiration or earlier termination of this Agreement, the license granted hereunder shall also terminate, and Licensee shall cease using the Software and Documentation. No expiration or termination shall affect Licensee’s obligation to pay all Licensee Fees and Support Fees that may have become due before such expiration or termination, or entitle Licensee to any refund, in each case except as set forth in Section 12.3(b).
 
12.  Limited Warranties, Exclusive Remedy, and Disclaimer/Warranty Disclaimer.

12.1  Licensor warrants that for a period of one (1) year following the license date set forth on the Order Form:

(a) the Software will substantially contain the functionality described in the Documentation and, when properly utilized, and operated in accordance with, the Documentation, will substantially perform in accordance therewith.
 

The warranties set forth in Section 12.1(a) and will not apply and will become null and void if Licensee breaches any provision of this Agreement, or if Licensee, any Authorized User, or any other Person provided access to the Software by Licensee or any Authorized User, whether or not in violation of this Agreement misuses the Software, including any use of the Software other than as specified in the Documentation or expressly authorized by Licensor in writing.
 

12.3  If, during the period specified in Section 12.1, the Software covered by the warranty set forth in such Section fails to perform substantially in accordance with the Documentation, and such failure is not excluded from warranty under the Section 12.2, Licensor will, subject to Licensee’s promptly notifying Licensor in writing of such failure, at its sole option, either:
 
(a) update or repair the Software, provided that Licensee provides Licensor with all information Licensor requests to resolve the reported failure, including sufficient information to enable the Licensor to recreate such failure; or
(b) refund the License Fees paid for such Software, subject to Licensee’s ceasing all use of the Software.

    If Licensor repairs or replaces the Software, the warranty will continue to run from the initial date specified on the Order Form and not from Licensee’s receipt of the repair or replacement. The remedies set forth in this Section 12.3 are Licensee’s sole remedies and Licensor’s sole liability under this Agreement.
 

12.4  EXCEPT FOR THE LIMITED WARRANTY SET FORTH IN SECTION 12.1, THE SOFTWARE AND DOCUMENTATION ARE PROVIDED TO LICENSEE “AS IS” AND WITH ALL FAULTS AND DEFECTS WITHOUT CONDITION OR WARRANTY OF ANY KIND. TO THE MAXIMUM EXTENT PERMITTED UNDER APPLICABLE LAW, LICENSOR, ON ITS OWN BEHALF AND ON BEHALF OF ITS AFFILIATES AND ITS AND THEIR RESPECTIVE LICENSORS AND SERVICE PROVIDERS, EXPRESSLY DISCLAIMS ALL CONDITIONS AND WARRANTIES, WHETHER EXPRESS, IMPLIED, STATUTORY, OR OTHERWISE, WITH RESPECT TO THE SOFTWARE AND DOCUMENTATION, INCLUDING ALL IMPLIED CONDITIONS AND WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, QUIET POSSESSION AND NON-INFRINGEMENT, AND WARRANTIES THAT MAY ARISE OUT OF COURSE OF DEALING, COURSE OF PERFORMANCE, USAGE OR TRADE PRACTICE. WITHOUT LIMITING THE FOREGOING, THE LICENSOR PROVIDES NO CONDITION, WARRANTY OR UNDERTAKING, AND MAKES NO REPRESENTATION OF ANY KIND THAT THE LICENSED SOFTWARE WILL MEET THE LICENSEE’S REQUIREMENTS, ACHIEVE ANY INTENDED RESULTS, BE COMPATIBLE OR WORK WITH ANY OTHER SOFTWARE, APPLICATIONS, SYSTEMS OR SERVICES, OPERATE WITHOUT INTERRUPTION, MEET ANY PERFORMANCE OR RELIABILITY STANDARDS OR BE ERROR FREE OR THAT ANY ERRORS OR DEFECTS CAN OR WILL BE CORRECTED.
 
13.  Limitation of Liability. TO THE FULLEST EXTENT PERMITTED UNDER APPLICABLE LAW:

13.1  IN NO EVENT WILL LICENSOR OR ITS AFFILIATES, OR ANY OF ITS OR THEIR RESPECTIVE LICENSORS OR SERVICE PROVIDERS, BE LIABLE TO LICENSEE OR ANY THIRD PARTY FOR: (a) ANY: (i) USE, INTERRUPTION, DELAY OR INABILITY TO ACCESS AND USE THE SOFTWARE; (ii) LOST REVENUES OR PROFITS; (iii) DELAYS, INTERRUPTION OR LOSS OF SERVICES, BUSINESS OR GOODWILL; (iv) LOSS OR CORRUPTION OF DATA; (v) LOSS RESULTING FROM SYSTEM OR SYSTEM SERVICE FAILURE, MALFUNCTION OR SHUTDOWN; (vi) FAILURE TO ACCURATELY TRANSFER, READ OR TRANSMIT INFORMATION; (vii) FAILURE TO UPDATE OR PROVIDE CORRECT INFORMATION; (viii) SYSTEM INCOMPATIBILITY OR PROVISION OF INCORRECT COMPATIBILITY INFORMATION; (ix) BREACHES IN SYSTEM SECURITY; OR (b) ANY CONSEQUENTIAL, INCIDENTAL, INDIRECT, SPECIAL, PUNITIVE OR EXEMPLARY DAMAGES, IN EACH CASE WHETHER ARISING OUT OF OR IN CONNECTION WITH THIS AGREEMENT, BREACH OF CONTRACT, TORT (INCLUDING NEGLIGENCE) OR OTHERWISE, REGARDLESS OF WHETHER SUCH DAMAGES WERE FORESEEABLE AND WHETHER OR NOT THE LICENSOR WAS ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.

13.2  IN NO EVENT WILL THE COLLECTIVE AGGREGATE LIABILITY OF LICENSOR AND ITS AFFILIATES, INCLUDING ANY OF ITS OR THEIR RESPECTIVE LICENSORS AND SERVICE PROVIDERS, UNDER OR IN CONNECTION WITH THIS AGREEMENT OR ITS SUBJECT MATTER, UNDER ANY LEGAL OR EQUITABLE THEORY, INCLUDING BREACH OF CONTRACT, TORT (INCLUDING NEGLIGENCE), STRICT LIABILITY AND OTHERWISE, EXCEED THE TOTAL AMOUNT PAID TO THE LICENSOR UNDER THIS AGREEMENT FOR (i) THE SOFTWARE, THAT IS THE SUBJECT OF THE CLAIM.
 
13.3  THE LIMITATIONS SET FORTH IN Section 13.1 AND Section 13.2 SHALL APPLY EVEN IF THE LICENSEE’S REMEDIES UNDER THIS AGREEMENT FAIL OF THEIR ESSENTIAL PURPOSE.
 

14.  Export Regulation. The Software and Documentation may be subject to Canadian export control laws. The Licensee shall not, directly or indirectly, export, re-export or release the Software or Documentation to, or make the Software or Documentation accessible from, any jurisdiction or country to which export, re-export or release is prohibited by law, rule or regulation. The Licensee shall comply with all applicable federal laws, regulations and rules and complete all required undertakings (including obtaining any necessary export license or other governmental approval), before exporting, re-exporting, releasing or otherwise making the Software or Documentation available outside Canada.
 
15.  Miscellaneous.
15.1  This Agreement is governed by and construed in accordance with the laws of the Province of Ontario and the federal laws of Canada applicable therein. Any legal suit, action or proceeding arising out of or related to this Agreement or the licenses granted hereunder shall be instituted exclusively in the courts of the Province of Ontario, and each party irrevocably submits to the exclusive jurisdiction of such courts in any such suit, action or proceeding. Service of process, summons, notice, or other document by mail to such party’s address set forth herein shall be effective service of process for any suit, action or other proceeding brought in any such court.

15.2  Licensor will not be responsible or liable to Licensee, or deemed in default or breach hereunder by reason of any failure or delay in the performance of its obligations hereunder where such failure or delay is due to strikes, labour disputes, civil disturbances, riot, rebellion, invasion, epidemic, hostilities, war, terrorist attack, embargo, natural disaster, acts of God, flood, tsunami, fire, sabotage, fluctuations or non-availability of electrical power, heat, light, air conditioning or Licensee equipment, loss and destruction of property or any other circumstances or causes beyond Licensor’s reasonable control.
 
15.3  All notices, requests, consents, claims, demands, waivers and other communications hereunder shall be in writing and shall be deemed to have been given: (i) when delivered by hand (with written confirmation of receipt); (ii) when received by the addressee if sent by a nationally recognized overnight courier (receipt requested); (iii) on the date sent by facsimile or e-mail (with confirmation of transmission) if sent during normal business hours of the recipient, and on the next business day if sent after normal business hours of the recipient; or (iv) on the third day after the date mailed, by certified or registered mail, return receipt requested, postage prepaid. Such communications must be sent to the respective parties at the addresses set forth on the Order Form (or to such other address as may be designated by a party from time to time in accordance with this Section 15.3).
 
15.4  This Agreement, together with the Order Form, and all other documents that are incorporated by reference herein, constitutes the sole and entire agreement between Licensee and Licensor with respect to the subject matter contained herein, and supersedes all prior and contemporaneous understandings, agreements, representations and warranties, both written and oral, with respect to such subject matter. 
 
15.5  Licensee shall not assign or otherwise transfer any of its rights, or delegate or otherwise transfer any of its obligations or performance, under this Agreement, in each case whether voluntarily, involuntarily, by operation of law or otherwise, without Licensor’s prior written consent, which consent Licensor may give or withhold in its sole discretion. For purposes of the preceding sentence, and without limiting its generality, any amalgamation, arrangement or reorganization involving Licensee will be deemed to be a transfer of rights, obligations, or performance under this Agreement for which Licensor’s prior written consent is required. No delegation or other transfer will relieve Licensee of any of its obligations or performance under this Agreement. Any purported assignment, delegation or transfer in violation of this Section 15.5 is void. Licensor may freely assign or otherwise transfer all or any of its rights, or delegate or otherwise transfer all or any of its obligations or performance under this Agreement without Licensee’s consent. This Agreement is binding upon and enures to the benefit of the parties hereto and their respective permitted successors and assigns.
 
15.6  This Agreement is for the sole benefit of the parties hereto and their respective successors and permitted assigns and nothing herein, express or implied, is intended to or shall confer on any other Person any legal or equitable right, benefit or remedy of any nature whatsoever under or by reason of this Agreement.
 
15.7  This Agreement may only be amended, modified or supplemented by an agreement in writing signed by each party hereto. No waiver by any party of any of the provisions hereof shall be effective unless explicitly set forth in writing and signed by the party so waiving. Except as otherwise set forth in this Agreement, no failure to exercise, or delay in exercising, any right, remedy, power or privilege arising from this Agreement shall operate or be construed as a waiver thereof; nor shall any single or partial exercise of any right, remedy, power or privilege hereunder preclude any other or further exercise thereof or the exercise of any other right, remedy, power or privilege.
 
15.8  If any term or provision of this Agreement is invalid, illegal, or unenforceable in any jurisdiction, such invalidity, illegality or unenforceability shall not affect any other term or provision of this Agreement or invalidate or render unenforceable such term or provision in any other jurisdiction.
 
15.9  For purposes of this Agreement, (a) the words “include,” “includes,” and “including” shall be deemed to be followed by the words “without limitation”; (b) the word “or” is not exclusive; and (c) the words “herein,” “hereof,” “hereby,” “hereto,” and “hereunder” refer to this Agreement as a whole. Unless the context otherwise requires, references herein: (i) to Sections and Exhibits refer to the Sections of, and Exhibits attached to, this Agreement; (ii) to an agreement, instrument, or other document means such agreement, instrument, or other document as amended, supplemented, and modified from time to time to the extent permitted by the provisions thereof; and (iii) to a statute means such statute as amended from time to time and includes any successor legislation thereto and any regulations promulgated thereunder. This Agreement shall be construed without regard to any presumption or rule requiring construction or interpretation against the party drafting an instrument or causing any instrument to be drafted. The Order Form and all Schedules and Exhibits referred to herein shall be construed with, and as an integral part of, this Agreement to the same extent as if they were set forth verbatim herein. Unless otherwise stated, all dollar amounts referred to in this Agreement are stated in Canadian dollars.
 
15.10  The parties confirm that it is their express wish that this Agreement, as well as any other documents related to this Agreement, including notices, schedules and authorizations, have been and shall be drawn up in the English language only. Les parties aux présentes confirment leur volonté expresse que cette convention, de même que tous les documents s’y rattachant, y compris tous avis, annexes et autorisations s’y rattachant, soient rédigés en langue anglaise seulement.
 
15.11  The headings in this Agreement are for reference only and do not affect the interpretation of this Agreement.
 




ANNEX 1
AUTHORIZED USER TERMS OF USE
These Software Terms of Use (”Terms of Use”) govern your use of the [NAME OF SOFTWARE] software (the “Software”), including all user manuals, technical manuals and any other materials provided by Licensor, in printed, electronic or other form, that describe the Software or its use or specifications (the “Documentation”) provided to you (”you” or “your”) for use pursuant to and subject to a software license agreement (the “Software License Agreement”) between HARTMANN CONTROLS CORP. (”Licensor”) and your employer or other person or entity who owns or otherwise lawfully controls the computer on which the Software is installed (”Licensee”).
BY CHECKING THE CHECKBOX BELOW AND CLICKING THE “ACCEPT” BUTTON YOU: (i) REPRESENT THAT YOU ARE DULY AUTHORIZED BY LICENSEE TO ACCESS AND USE THE SOFTWARE; AND (ii) ACCEPT THESE AUTHORIZED USER TERMS AND AGREE THAT YOU ARE LEGALLY BOUND BY THEM. IF YOU DO NOT AGREE TO THESE TERMS OF USE, DO NOT CHECK THE CHECKBOX OR CLICK THE “ACCEPT” BUTTON AND YOU WILL HAVE NO LICENSE TO, AND MUST NOT ACCESS OR USE, THE SOFTWARE.
1. License Grant. Subject to your strict compliance with these Terms of Use, Licensor hereby grants you a non-exclusive, non-transferable, non-sublicensable, limited license to use the Software solely in accordance with the Documentation, as installed on the equipment provided by Licensee and for Licensee’s internal business purposes. The foregoing license will terminate immediately on the earlier to occur of:
(a) the expiration or earlier termination of the Software License Agreement between Licensor and Licensee; or
(b) your ceasing to be authorized by Licensor to use the Software for any or no reason.
2. Use Restrictions. You shall not, directly or indirectly:
(a) use the Software or Documentation except as set forth in Section 1;
(b) modify, translate, adapt or otherwise create derivative works or improvements, whether or not patentable, of the Software or any part thereof;
(c) combine the Software or any part thereof with, or incorporate the Software or any part thereof in, any other programs;
(d) reverse engineer, disassemble, decompile, decode or otherwise attempt to derive or gain access to the source code of the Software or any part thereof;
(e) remove, delete, alter or obscure any trade-marks or any copyright, trade-mark, patent or other intellectual property or proprietary rights notices included on or in the Software or Documentation, including any copy thereof;
(f) rent, lease, lend, sell, sublicense, assign, distribute, publish, transfer or otherwise provide any access to or use of the Software or any features or functionality of the Software, for any reason, to any other person or entity, including any subcontractor, independent contractor, affiliate or service provider of Licensee, whether or not over a network and whether or not on a hosted basis, including in connection with the internet, web hosting, wide area network (WAN), virtual private network (VPN), virtualization, time-sharing, service bureau, software as a service, cloud or other technology or service;
(g) use the Software or Documentation in, or in association with, the design, construction, maintenance or operation of any hazardous environments or systems, including:
(i) power generation systems;
(ii) aircraft navigation or communications systems, air traffic control systems or any other transport management systems;
(iii) safety-critical applications, including medical or life-support systems, vehicle operation applications or any police, fire or other safety response systems; and
(iv) military or aerospace applications, weapons systems or environments;
(h) use the Software or Documentation in violation of any law, regulation or rule; or
(i) use the Software or Documentation for purposes of competitive analysis of the Software, the development of a competing software product or service or any other purpose that is to the Licensor’s commercial disadvantage.
3. Compliance Measures. The Software may contain technological copy protection or other security features designed to prevent unauthorized use of the Software, including features to protect against use of the Software:
(a) beyond the scope of the license granted to under Section 1;
(b) prohibited under Section 2.
You shall not, and shall not attempt to, remove, disable, circumvent or otherwise create or implement any workaround to, any such copy protection or security features.
4. Collection and Use of Information.
(a) Licensor may, directly or indirectly through the services of others, collect and store information regarding use of the Software and about equipment on which the Software is installed or through which it otherwise is accessed and used, by means of (i) providing maintenance and support services and (ii) security measures included in the Software as described in Section 3.
(b) You agree that the Licensor may use such information for any purpose related to any use of the Software by you, including but not limited to: (i) improving the performance of the Software or developing updates; and (ii) verifying compliance with the terms of this Agreement and enforcing Licensor’s rights, including all intellectual property rights in and to the Software.
5. Intellectual Property Rights. You acknowledge that the Software is provided under license, and not sold, to you. You do not acquire any ownership interest in the Software under this Agreement, or any other rights to the Software other than to use the Software in accordance with the license granted under this Agreement, subject to all terms, conditions and restrictions. Licensor reserves and shall retain its entire right, title and interest in and to the Software and all intellectual property rights arising out of or relating to the Software, subject to the license expressly granted to the Licensee in this Agreement. You shall safeguard all information required to access the Software from misappropriation, theft, misuse or unauthorized access.
6. Disclaimer of Liability. IN NO EVENT WILL LICENSOR OR ITS AFFILIATES, OR ANY OF ITS OR THEIR RESPECTIVE LICENSORS OR SERVICE PROVIDERS, BE LIABLE TO YOU FOR ANY USE, INTERRUPTION, DELAY OR INABILITY TO USE THE SOFTWARE. YOU ARE PROVIDED THE SOFTWARE UNDER THE SOFTWARE LICENSE AGREEMENT BETWEEN LICENSOR AND LICENSEE, SOLELY FOR THE BENEFIT OF LICENSEE AND AT LICENSEE’S DISCRETION. YOU ACKNOWLEDGE THAT YOU HAVE NO RIGHTS UNDER THAT AGREEMENT INCLUDING ANY RIGHTS TO ENFORCE ANY OF ITS TERMS. ANY OBLIGATION OR LIABILITY LICENSOR OR ITS AFFILIATES, OR ANY OF ITS OR THEIR LICENSORS OR SERVICE PROVIDERS, MAY HAVE WITH RESPECT TO YOUR USE OR INABILITY TO USE THE SOFTWARE SHALL BE SOLELY TO LICENSEE UNDER THAT AGREEMENT AND SUBJECT TO ALL LIMITATIONS OF LIABILITY SET FORTH THEREIN.
THE LICENSEE ASSUMES AL RESPONSIBILITY FOR CREATING AND MAINTAINING BACK-UPS OF ALL DATA OF THE LICENSEE THAT IS ENTERED INTO THE SOFTWARE AND ASSUMES FULL LIABILITY AND RESPONSIBILITY FOR ENSURING THAT BACK-UPS OF DATA OR ANY REPORTS CREATED BY THE SOFTWARE ARE APPROPRIATELY STORED AND MAINTAINED. THE LICENSEE EXPRESSLY RELEASES THE LICENSOR FROM ANY LIABILITY, INCLUDING AS THE HOST OF SUCH DATA OR THE SOFTWARE, FOR ANY LOSS OF DATA WHICH MAY OCCUR DURING THE USE OF THE SOFTWARE BY THE LICENSEE. 
7. Export Regulation. The Software may be subject to Canadian export control laws. You shall not, directly or indirectly, export, re-export or release the Software to, or make the Software or Documentation accessible from, any jurisdiction or country to which export, re-export, or release is prohibited by law, rule or regulation. You shall comply with all applicable federal laws, regulations and rules, and complete all required undertakings (including obtaining any necessary export license or other governmental approval), before exporting, re-exporting, releasing or otherwise making the Software available outside Canada.
8. Governing Law. These Terms of Use are governed by and construed in accordance with the laws of the Province of Ontario and the federal laws of Canada applicable therein.`


export const AiphoneEndUserLicenseAgreement = `Aiphone Corporation hereby gives you a non-exclusive license to use Aiphone.
For evaluation, the license is granted, and is time-limited.
For registered release you have to pay a license fee, by following instructions prompted by the program.

You may:
- use Aiphone on any single computer;
- use Aiphone on a second computer so long as the primary user of each copy is the same person and more than one copy is not simultaneously;
- copy Aiphone for archival purposes, provided any copy contains all of the original Software's proprietary notices.

You may not:
- permit other individuals to use Aiphone except under the terms listed above;
- modify, translate, reverse engineer, decompile, disassemble (except to the extent applicable laws specifically prohibit such restriction),
- create derivative works based on Aiphone;
- copy Aiphone (except as specified above);
- rent, lease, transfer or otherwise transfer rights to Aiphone;
- remove any proprietary notices or labels on Aiphone.

TERMINATION.
The license will terminate automatically if you fail to comply with the limitations described above. On termination, you must destroy all copies of Aiphone and Documentation.

DISCLAIMER OF WARRANTY
---------------------------------------------
Aiphone is provided on an AS IS basis, without warranty of any kind, including without limitation the warranties of merchantability, fitness for a particular purpose and non-infringement.
The entire risk as to the quality and performance of Aiphone is borne by you.
Should Aiphone prove defective, you and not Aiphone Corporation assume the entire cost of any service and repair.

Aiphone Corporation IS NOT RESPONSIBLE FOR ANY INDIRECT, SPECIAL, INCIDENTAL, OR CONSEQUENTIAL DAMAGES OF ANY CHARACTER INCLUDING, WITHOUT LIMITATION, DAMAGES FOR LOSS OF GOODWILL, WORK STOPPAGE, COMPUTER FAILURE OR MALFUNCTION, OR ANY AND ALL OTHER COMMERCIAL DAMAGES OR LOSSES.

Title, ownership rights and intellectual property rights in and to Aiphone shall remain in Aiphone Corporation Aiphone is protected by international copyright treaties.`