import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAuth } from "react-oidc-context";
import API from "../../common/API";
import { CompanyFeatures, Features, Invoice, OptionType, PaymentDetails, ProductPricing } from "../../common/store";
import useAppSelector from "../../hooks/useAppSelector";
import { getCompanyFeatureGate, getFeatureGate } from "../../redux/slices/config";
import ElavonPayment from "../../views/Payment/ElavonPayment";
import RequiredFields from "../shared/RequiredFields";
import SelectComponent from "../shared/SelectComponent";
import TextInputComponent from "../shared/TextInputComponent";
import LoadingPageComponent from "../shared/LoadingPageComponent";

export default function({licenseId,setSection, setResponseKey}
    :{licenseId:number,setSection:Function, setResponseKey:Function}){

    const auth = useAuth()
    const [productPricing , setProductPricing] = useState<ProductPricing[]|null>()
    const [addOnPrice, setAddOnPrice] = useState<number>(0)
    const [subtotal, setSubtotal] = useState<number>(0)
    const [addOnType, setAddOnType] = useState<string>('0')
    const [amount, setAmount] = useState<number>(1)
    const [invoice, setInvoice] = useState<Invoice|null>()
    const [showPayment, setShowPayment] = useState<boolean>(false)
    const [prepaidCode, setPrepaidCode] = useState<string>('')
    const { t } = useTranslation(); 
    const [loading, setLoading] = useState<boolean>(false)

    const companyFeatureGate: CompanyFeatures = useAppSelector(getCompanyFeatureGate)
    const featureGate: Features = useAppSelector(getFeatureGate)

    const AddOnTypes:OptionType[] = [{value: '0', label: t("deployment:panels"), s1:"Panels"},{value: '1', label: t("license:cameras"), s1:"Cameras"},
    {value: '2', label: t("license:doors"), s1:"Doors"},{value: '3', label: t("license:partitions"), s1:"Partitions"},
    {value: '4', label: t("license:elevators"), s1:"Elevators"},{value: '5', label: t("addOn:inputs"), s1:"Inputs"},
    {value: '6', label: t("addOn:outputs"), s1:"Outputs"},{value: '7', label: t("addOn:maps"), s1:"Maps"},
    {value: '8', label: t("users"), s1:"Users"},{value: '9', label: t("addOn:locksets"), s1:"Locksets"}]

    const getAddOnInvoice = async () => {
        var object = {LicenseId: licenseId, Feature: Number(addOnType), Count: amount}
        try{
            const response: Invoice|null = await API.post(`api/v1/license/invoice/addOn`,object, auth)
            setInvoice(response)
            setShowPayment(true)
        }
        catch (e: any) {
            alert(e.errors?.Error!)
        }
    }

    const getAddOnResponseKey = async(isInvoiced?:boolean) =>{
        var object = {LicenseId: licenseId, Feature: Number(addOnType), Count: amount, InvoiceId: invoice?.id}
        try{
            setLoading(true)
            if (isInvoiced != null && isInvoiced == true)
            {
                const response:string|null = await API.post(`api/v1/license/addOn/true`,object, auth)
                setResponseKey(response)
            }
            else{
                const response:string|null = await API.post(`api/v1/license/addOn`,object, auth)
                setResponseKey(response)
            }
            setLoading(false)
            setSection("Response")
        }
        catch (e: any) {
            alert(e.errors?.Error!)
        }
    }

    const getAddOnPrice = async () => {
        try{
            const response:ProductPricing[]|null = await API.get(`/api/v1/pricing/addon`, auth)
            setProductPricing(response)
        }
        catch(e:any)
        {
            alert(e.errors?.Error!)
        }
    }

    const getAddonThroughCard = async () => {

        alert("This feature is currently not implemented")
    }

    useEffect(() => {
        getAddOnPrice()
    },[])

    useEffect(() => {
        const addOnPriceName = AddOnTypes.find(x => x.value == addOnType)?.s1 + ("AddOn")
        setAddOnPrice((productPricing?.find(e => e.partNumber == addOnPriceName)?.price! ))
        setSubtotal((productPricing?.find(e => e.partNumber == addOnPriceName)?.price! ) * amount)

    }, [productPricing,addOnType, amount ])

    return (
        <div>
            {(companyFeatureGate.allowedLocalLicensePayment) && 
            <div>{
                showPayment &&
                
                <div>
                    {loading && <LoadingPageComponent />}
                    {!loading && <div className=" mb-4 ms-auto me-auto card shadow border-2" >
                        <div className=" card-header border-0 ">
                            <h2 className='mb-4 header-text'><FontAwesomeIcon icon="credit-card" className="fs-3 me-2" />{t('payment')}</h2>
                        </div>
                        <div className="card-body bg-opacity-75 ">
                            <ElavonPayment licensePayment={true} invoice={invoice} postFunction={getAddOnResponseKey}  />
                        </div>
                    </div>}
                </div>
                }
                {
                !showPayment &&
                <div className="card">
                    <div className="card-header pb-0">
                        <h2 className='mb-4 header-text d-inline'><FontAwesomeIcon icon="screwdriver-wrench" className="fs-3 me-2" />{`${t('license:addAddOns')}`}</h2>
                    </div>
                    <div className="card-body row">
                        <div className="form-group row ">
                            <label className="col-sm-3 col-form-label text-center mb-4" ><h3>{`${t('license:addOnType')}`}</h3></label>
                            <div className="col-sm-3">
                                <SelectComponent className="form-select form-control-lg" options={AddOnTypes} onChange={(e: any) => setAddOnType(e.target.value)} value={AddOnTypes.find(x => x.value == addOnType)?.value} />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-sm-3 col-form-label text-center" ><h3>{`${t("invoice:quantity")}`}</h3></label>
                            <div className="col-sm-3">
                                <input className="align-bottom form-control  " min={1} type="number" id="flexSwitchCheckDefault" value={amount}  onChange={(e) => setAmount(Number(e.target.value))}  />
                            </div>
                        </div>
                        <div className="col-sm-12 ms-auto me-auto mb-4">
                            <table className="table table-sm fs--1 mb-0 mt-4">
                                <thead className="border-bottom" >
                                    <tr className="text-center table-header-text ">
                                        <th className="col-sm-6 fw-bold">{`${t("invoice:quantity")}`}</th>
                                        <th className="col-sm-2 fw-bold" >{t('subtotal')}</th>
                                        <th className="col-sm-2 fw-bold">{t('total')}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr className="table-text text-center">
                                        <th className="fw-bold">{amount + ` ${AddOnTypes.find(e => e.value == addOnType)?.label}(s)`}</th>
                                        <th>{`$${addOnPrice}` }</th>
                                        <th>{`$${subtotal}`}</th>
                                    </tr>
                                    <tr className="table-text text-center">
                                        <th className="col-sm-6"></th>
                                        <th className="fw-bold text-black">{t('grandTotal')+ ` ${t('withoutTax')}`}</th>
                                        <th>{`$${subtotal}`}</th>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="card-footer ">
                        <div className="mb-4 pe-3 float-end mt-4">
                            <button className="btn float-end btn-info add-btn-text " onClick={() => getAddOnInvoice()} >
                                <span >{`${t('addOn:getAddOn')}`}</span>
                            </button>
                        </div>
                    </div>
                </div>
                }
            </div>
            }
            {
                !showPayment &&
                <div className=" mb-4 ms-auto me-auto card shadow border-2" >
                    <div className=" card-header border-0 ">
                        <h2 className='mb-4 header-text'><FontAwesomeIcon icon="screwdriver-wrench" className="fs-3 me-2" />{t('license:addAddOnsWithCard')}</h2>
                        <RequiredFields />
                    </div>
                    <div className="card-body bg-opacity-75 ">
                        <div className="row ">
                            <div className="col-12 col-sm-8 ">
                                <TextInputComponent maxLength={29} mandatory value={prepaidCode} 
                                validator={() => prepaidCode.length == 29 && /[A-Za-z0-9]{6}-[A-Za-z0-9]{4}-[A-Za-z0-9]{5}-[A-Za-z0-9]{4}-[A-Za-z0-9]{6}/.test(prepaidCode)} validateText={"Invalid Code"} 
                                label={t('license:prepaidCode')} setText={setPrepaidCode} placeholder={t('license:enterPrepaidCode')} upperCase/>
                            </div>
                        </div>
                    </div>
                    <div className="card-footer ">
                        <div className="mb-4 pe-3 float-end">
                            <button className="btn float-end btn-info add-btn-text " disabled={!(prepaidCode.length == 29 && /[A-Za-z0-9]{6}-[A-Za-z0-9]{4}-[A-Za-z0-9]{5}-[A-Za-z0-9]{4}-[A-Za-z0-9]{6}/.test(prepaidCode))} onClick={() => getAddonThroughCard()} >
                                <span >{`${t('addOn:getAddOn')}`}</span>
                            </button>
                        </div>
                    </div>
                </div>
            }
    </div>
        
    )
}