import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { t } from "i18next";
import React, { useEffect, useState } from "react";
import { useAuth } from "react-oidc-context";
import API from "../../common/API";
import { Invoice, OdysseyInfo, OptionType, PanelDTO } from "../../common/store";
import ElavonPayment from "../../views/Payment/ElavonPayment";
import LoadingPageComponent from "../shared/LoadingPageComponent";
import PaginationListComponent from "../shared/PaginatedListComponent";
import TextInputComponent from "../shared/TextInputComponent";
import { CircularProgressbar, buildStyles  } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { PaginationComponent } from "../shared/PaginationComponent";
import useAppSelector from "../../hooks/useAppSelector";
import { selectConfig } from "../../redux/slices/config";


type Props = {
    onlinePanels: PanelDTO[] | undefined,
    deploymentId: number
  }


export function OdysseyInfoComponent(props: Props) {
    const auth = useAuth()
    const backupType:OptionType[] = [{label:t("freeBackupTier"), value:"0"}, {label:t("fullBackup"), value:"1"}]
    const onlinePanelHeaders:OptionType[] = [{value: "macAddress", label: "MAC Address"}, {value: "panelType", label:"Panel Type"}]
    const [loading, setLoading] = useState<boolean>(false)

    const [panels, setPanels] = useState<PanelDTO[]|null>()
    const [perPage, setPerPage] = useState<number>(10)
    const [pageNumber, setPageNumber] = useState<number>(1)
    const [maxPageNumber, setMaxPageNumber] = useState<number>(0)
    const [pages, setPages] = useState<number[]>([])
    const [odysseyInfo, setOdysseyInfo] = useState<OdysseyInfo | null>()
    const releaseMode = useAppSelector(selectConfig)


    const getPanels = async() => {
        try{
            setLoading(true)
            const response:any = await API.get(`/api/v1/odyssey/panels/${props.deploymentId}?PerPage=${perPage}&PageNumber=${pageNumber}`, auth)
            if (response != null)
            {
                if (response.results == null)
                    response.results = []

                setMaxPageNumber(Math.ceil(response.totalRecords/ perPage))
                const pageArray:number[] = []
                for (let index = 1; index <= Math.ceil(response.totalRecords/ perPage); index++){
                pageArray.push(index)
                }
                setPages(pageArray)
                setPanels(response.results)
                setLoading(false)
            }
        }
        catch(e:any){
            alert(e.errors?.Error!)
            setLoading(false)
        }
    }

    const getOdysseyInfo = async () => {
        try{
            setLoading(true)
            const response:OdysseyInfo | null = await API.get(`/api/v1/odyssey/odysseyInfo/${props.deploymentId}`, auth)
            setOdysseyInfo(response)
            setLoading(false)
            
        }
        catch(e:any){
            alert(e.errors?.Error!)
            setLoading(false)
        }
    }

    const sendTestNotification = async() => {
        try{
            const response:OdysseyInfo | null = await API.post(`/api/v1/odyssey/sendTestNotification/${props.deploymentId}`,{}, auth)
        }
        catch(e:any){
            alert(e.errors?.Error!)
        }
    }

    const sendTestNotificationByTopic = async() => {
        try{
            const response:OdysseyInfo | null = await API.post(`/api/v1/odyssey/sendTestNotification/topic`,{}, auth)
        }
        catch(e:any){
            alert(e.errors?.Error!)
        }
    }


    useEffect(() => {
        getPanels()
    },[pageNumber])

    useEffect(() => {
        getOdysseyInfo()
    }, [])

    return(
        <div>
            <div className="row">
                <div className="col-sm-12">
                    <div className="p-0 card col-sm-12 h-100">
                        <div className=" card-header pb-0">
                            <h2 className='mb-4  header-text d-inline'><FontAwesomeIcon icon="heart-pulse" className="fs-3 me-2" />{`${t('applicationStatus')}`}</h2>
                            {!releaseMode && <button className="btn btn-primary mb-4 float-end" onClick={() => sendTestNotification()}>Send Test Notifcation</button>}
                            {!releaseMode && <button className="btn btn-secondary mb-4 float-end me-2" onClick={() => sendTestNotificationByTopic()}>Send Test Topic Notifcation</button>}
                        </div>
                        {!loading && <div className="card-body ">
                            <div className="row">
                                <div className="col-sm-2"></div>
                                <div className="col-sm-2 text-center">
                                    <FontAwesomeIcon className="stat ms-2" style={{width:'24px', height:'24px'}} icon="tablet-button" />
                                    <div className="flex-grow-1">
                                        <h4 className="mt-3">{`${odysseyInfo?.panelCount} ${t("deployment:panels")}`}</h4>
                                    </div>
                                </div>
                                <div className="col-sm-2 text-center">
                                    <FontAwesomeIcon className="stat ms-2" style={{width:'24px', height:'24px'}} icon="users" />
                                    <div className="flex-grow-1">
                                        <h4 className="mt-3">{`${odysseyInfo?.userCount} ${t("users")}`}</h4>
                                    </div>
                                </div>
                                <div className="col-sm-2 text-center">
                                    <FontAwesomeIcon className="stat ms-2" style={{width:'24px', height:'24px'}} icon="door-closed" />
                                    <div className="flex-grow-1">
                                        <h4 className="mt-3">{`${odysseyInfo?.doorCount} ${t("license:doors")}`}</h4>
                                    </div>
                                </div>
                                <div className="col-sm-2 text-center">
                                    <FontAwesomeIcon className="stat " style={{width:'24px', height:'24px'}} icon="person-shelter" />
                                    <h4 className="mt-3 " >{`${odysseyInfo?.partitionCount} ${t("license:partitions")}`}</h4>
                                </div>
                            </div>
                            <div className="text-center ">
                                <h4 className="mt-5">{`${t("lastUpdatedOn")} : ${new Date(odysseyInfo?.lastUpdatedOn.toString()!).toLocaleString()!}`}</h4>
                            </div>
                        </div>}
                        {loading && <LoadingPageComponent />}
                    </div>
                </div>
                {<div className="col-sm-12 mt-2">
                    <div className="card p-0 col-sm-12 h-100">
                        <div className=" card-header pb-0">
                            <h2 className='mb-4 header-text'><FontAwesomeIcon icon="box" className="fs-3 me-2" />{`${t("deployment:panels")}`}</h2>
                        </div>
                        {!loading && panels != null && <div className="card-body">
                            <PaginationListComponent isEditable={false} isRemovable={false}list={panels!}headerValues={onlinePanelHeaders} />
                            {maxPageNumber != 0 && <PaginationComponent pageNumber={pageNumber} pages={pages} maxPageNumber={maxPageNumber} setPageNumber={setPageNumber} />}
                        </div>}
                        {loading && <LoadingPageComponent />}
                    </div>
                </div>}
            </div>
        </div>
        
    )
}